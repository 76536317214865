import { Box, IconButton, InputAdornment, TextField } from "@mui/material"
import { CalendarSolidHoverSvg, CalendarSolidSvg } from "assets/svgs"
import pxToRem from "assets/theme/functions/pxToRem"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import PopOverDatePicker from "./PopOverDatePicker"

const AppDatePicker = ({
	selectedDate,
	label,
	placeholder = "Pick a date",
	setSelectedDate,
	disabled,
	disabledDates = [],
	availableStartDate = null,
	InputProps,
	selectableYearAndMonth = false,
	...props
}) => {
	const { t } = useTranslation()
	const [anchorEl, setAnchorEl] = useState(null)
	const [hoverButton, setHoverButton] = useState("")

	const openDatePicker = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const onDayClick = (date) => {
		setAnchorEl(null)
		setSelectedDate(date)
	}

	return (
		<Box>
			<TextField
				disabled={disabled}
				onClick={disabled ? null : openDatePicker}
				placeholder={t(placeholder)}
				label={label !== false ? t(label || "Pick a date") : undefined}
				InputLabelProps={{ shrink: true }}
				id="date-picker"
				value={selectedDate || ""}
				variant="outlined"
				InputProps={{
					readOnly: true,
					style: { cursor: disabled ? "not-allowed" : "pointer", padding: 0 },
					endAdornment: (
						<InputAdornment position="end">
							<IconButton
								sx={{
									padding: pxToRem(0),
									marginRight: pxToRem(10),
									color: "grey.700",
								}}
								disabled={disabled}
								aria-label="Open Date Picker"
								onMouseEnter={() => setHoverButton("open-calendar")}
								onMouseLeave={() => setHoverButton("")}
								edge="end">
								{hoverButton === "open-calendar" ? (
									<CalendarSolidHoverSvg />
								) : (
									<CalendarSolidSvg fill="currentcolor" />
								)}
							</IconButton>
						</InputAdornment>
					),
					...InputProps,
				}}
				{...props}
			/>
			<PopOverDatePicker
				anchorEl={anchorEl}
				setAnchorEl={setAnchorEl}
				selectedDate={new Date(selectedDate)}
				onDayClick={onDayClick}
				disabledDates={disabledDates}
				availableStartDate={availableStartDate}
				selectableYearAndMonth={selectableYearAndMonth}
			/>
		</Box>
	)
}

export default AppDatePicker
