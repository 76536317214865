import colors from "assets/theme/base/palette"
import typography from "assets/theme/base/typography"

const { primary } = colors
const { size } = typography

export default {
	styleOverrides: {
		root: {
			fontSize: size.sm,
			lineHeight: 0.9,

			"&.Mui-focused": {
				color: primary.main,
			},

			"&.MuiInputLabel-shrink": {
				lineHeight: 1.5,
				fontSize: size.md,

				"~ .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend": {
					fontSize: "0.85em",
				},
			},
		},

		sizeSmall: {
			fontSize: size.xs,
			lineHeight: 1.625,

			"&.MuiInputLabel-shrink": {
				lineHeight: 1.6,
				fontSize: size.sm,

				"~ .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend": {
					fontSize: "0.72em",
				},
			},
		},

		sizeLarge: {
			fontSize: size.md,
			lineHeight: 1.85,

			"&.MuiInputLabel-shrink": {
				lineHeight: 1.8,
				fontSize: size.md,

				"~ .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend": {
					fontSize: "0.72em",
				},
			},
		},
	},
}
