import { Box, Dialog, Typography, styled, useTheme } from "@mui/material"
import { CrossOutlineSvg } from "assets/svgs"
import pxToRem from "assets/theme/functions/pxToRem"
import { bool, func, node, number, shape } from "prop-types"
import { createContext, useContext } from "react"

const MAX_WIDTH = 462
const FormDialogContext = createContext()

const FormDialog = ({
	children,
	height,
	width = MAX_WIDTH,
	padding = 3,
	open,
	onClose,
	maxHeight,
}) => {
	return (
		<FormDialogContext.Provider value={{ onClose }}>
			<Dialog
				open={open}
				onClose={onClose}
				aria-labelledby="form-dialog"
				sx={({ spacing, boxShadows, borders }) => ({
					"& .MuiDialog-paper": {
						width: "100%",
						position: "relative",
						height: height ? pxToRem(height) : "auto",
						maxHeight: maxHeight,
						maxWidth: width,
						padding: spacing(padding),
						paddingTop: 0,
						boxShadow: boxShadows.md,
						borderRadius: borders.borderRadius.lg,
					},
				})}>
				{children}
			</Dialog>
		</FormDialogContext.Provider>
	)
}

const Header = ({ children, textAlign = "center", ...rest }) => {
	const { palette } = useTheme()
	const { onClose } = useContext(FormDialogContext)

	const CloseSvg = styled(CrossOutlineSvg)(() => ({
		width: "24px",
		height: "24px",
		cursor: "pointer",
		stroke: palette.grey[700], // Default stroke color
		transition: "stroke 0.3s ease-in-out",
		"&:hover": {
			stroke: palette.primary.main, // Stroke color on hover
		},
	}))

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				position: "sticky",
				top: 0,
				backgroundColor: palette.white.main,
				zIndex: 100, // to appear over the dialog content
			}}
			py={3}
			{...rest}>
			<Typography textAlign={textAlign} variant="formDialogTitle">
				{children}
			</Typography>

			<CloseSvg onClick={onClose} />
		</Box>
	)
}

const Content = ({ children, ...rest }) => {
	return (
		<Box sx={{ display: "flex", flexDirection: "column" }} {...rest}>
			{children}
		</Box>
	)
}

export default FormDialog
FormDialog.Header = Header
FormDialog.Content = Content

FormDialog.propTypes = shape({
	children: node.isRequired,
	padding: number,
	width: number,
	height: number,
	open: bool.isRequired,
	onClose: func.isRequired,
})

Header.propTypes = {
	children: node.isRequired,
}

Content.propTypes = {
	children: node.isRequired,
}

/*
USAGE
=====
<FormDialog onClose={() => setIsDialogOpen(false)} open={isDialogOpen}>
    <FormDialog.Header>{t("Login")}</FormDialog.Header>
    <FormDialog.Content>
        <AppTextField placeholder="name" fullWidth />
        <AppTextField placeholder="email" fullWidth />
        <AppTextField placeholder="phone-number" fullWidth />
        <AppTextField placeholder="email" fullWidth />
        <AppTextField placeholder="phone-number" fullWidth />
        <AppTextField placeholder="email" fullWidth />
        <AppTextField placeholder="phone-number" fullWidth />
        <AutoLoadingButton>submit</AutoLoadingButton>
    </FormDialog.Content>
</FormDialog>
*/

// TODO: add FormDialog.Actions
