import { Typography } from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { useCalendar } from "hooks"
import { useIsEnglish } from "stores/slice/app"

const POST_FIXES = {
	years: "년",
	months: "월",
}

const WIDTH_TYPES = {
	months: 80,
	years: 110,
}

const CalendarSelectBox = ({ currentValue, type, setDate }) => {
	const isEng = useIsEnglish()
	const { yearsRange } = useCalendar()

	const OPTION_TYPES = {
		months: Array.from({ length: 12 }, (_, index) => {
			const label = index + 1

			return { label, value: index }
		}),
		years: yearsRange.map((value) => ({ value, label: value })),
	}

	const postFix = POST_FIXES[type]
	const options = OPTION_TYPES[type]

	const handleChange = (e) => setDate(e.target.value)

	return (
		<Select
			value={currentValue}
			onChange={handleChange}
			sx={{
				width: WIDTH_TYPES[type],
				"& .MuiOutlinedInput-notchedOutline": {
					border: "none !important",
				},
				"& .MuiSvgIcon-root": {
					color: "black.main",
				},
			}}>
			{options.map(({ value, label }) => (
				<MenuItem key={value} value={value}>
					<Typography fontSize="size.md" fontFamily="Roboto">
						{label}
						{!isEng && postFix}
					</Typography>
				</MenuItem>
			))}
		</Select>
	)
}

export default CalendarSelectBox
