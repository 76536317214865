import { api } from "services/api"
import {
	API_FIND_ID,
	API_FIND_PASSWORD,
	API_LOGIN,
	API_LOGOUT,
	API_RESET_PASSWORD,
	API_SEND_NEW_USER_OTP,
	API_SEND_VERIFICATION_CODE,
	API_VERIFY_OTP,
} from "services/endpoints"
import * as tags from "services/tags"
import { resetStore } from "stores/slice/store"
import { removeUser } from "stores/slice/user"

const authApi = api.injectEndpoints({
	endpoints(builder) {
		async function clear() {
			const { default: store } = await import("stores/store")
			store.dispatch(removeUser())
			store.dispatch(resetStore())
		}

		return {
			login: builder.mutation({
				async queryFn(data, queryApi, extraOptions, baseQuery) {
					await clear()

					return await baseQuery({
						data,
						method: "POST",
						url: API_LOGIN,
					})
				},

				providesTags: [tags.TAG_AUTH],

				invalidatesTags: (ok) => (ok ? Object.values(tags) : []),
			}),

			logout: builder.mutation({
				async queryFn(arg, queryApi, extraOptions, baseQuery) {
					const { error, data } = await baseQuery({
						method: "POST",
						url: API_LOGOUT,
					})

					if (error) {
						return { error }
					}

					await clear()

					return { data }
				},

				invalidatesTags: (ok) => (ok ? Object.values(tags) : []),
			}),

			sendVerificationCode: builder.mutation({
				query: (data) => {
					return {
						data,
						method: "POST",
						url: API_SEND_VERIFICATION_CODE,
					}
				},
			}),

			verifyOTP: builder.mutation({
				query: (data) => {
					return {
						data,
						method: "POST",
						url: API_VERIFY_OTP,
					}
				},
			}),

			sendNewUserOTP: builder.mutation({
				query: (data) => {
					return {
						data,
						method: "POST",
						url: API_SEND_NEW_USER_OTP,
					}
				},
			}),

			findId: builder.mutation({
				query: (data) => {
					return {
						data,
						method: "POST",
						url: API_FIND_ID,
					}
				},
			}),

			findPassword: builder.mutation({
				query: (data) => {
					return {
						data,
						method: "POST",
						url: API_FIND_PASSWORD,
					}
				},
			}),

			resetPassword: builder.mutation({
				query: (data) => {
					return {
						data,
						method: "POST",
						url: API_RESET_PASSWORD,
					}
				},
			}),
		}
	},
})

export const {
	useLoginMutation,
	useLogoutMutation,
	useSendVerificationCodeMutation,
	useVerifyOTPMutation,
	useSendNewUserOTPMutation,
	useFindIdMutation,
	useFindPasswordMutation,
	useResetPasswordMutation,
} = authApi

export default authApi
