import borders from "assets/theme/base/borders"
import colors from "assets/theme/base/palette"
import typography from "assets/theme/base/typography"
// // Soft UI Dashboard PRO helper functions
import pxToRem from "assets/theme/functions/pxToRem"

const { inputBorderColor, primary, grey, transparent } = colors
const { borderRadius } = borders
const { size } = typography

export default {
	styleOverrides: {
		root: {
			backgroundColor: transparent.main,
			fontSize: size.sm,
			borderRadius: borderRadius.md,

			"&:hover .MuiOutlinedInput-notchedOutline": {
				borderColor: inputBorderColor,
			},

			"&.Mui-focused": {
				"& .MuiOutlinedInput-notchedOutline": {
					borderColor: primary.main,
				},
			},
		},

		notchedOutline: {
			borderColor: inputBorderColor,
		},

		input: {
			color: grey[900],
			padding: pxToRem(12),
			backgroundColor: transparent.main,
		},

		inputSizeSmall: {
			fontSize: size.xs,
			padding: pxToRem(10),
		},

		multiline: {
			color: grey[900],
			padding: 0,
		},
	},
}
