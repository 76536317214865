import { DRIVER, PUTTER } from "constant"
import { api } from "services/api"
import {
	API_CUSTOMERS,
	API_CUSTOMERS_ADDRESS,
	API_CUSTOMERS_ADDRESS_DEFAULT,
	API_CUSTOMERS_ALL,
	API_CUSTOMER_ADD_CLUB,
	API_CUSTOMER_ADD_CLUB_BATCH,
	API_CUSTOMER_ADD_CLUB_BATCH_VALIDATE,
	API_CUSTOMER_ADD_CLUB_VALIDATE,
	API_CUSTOMER_CLUBS,
	API_CUSTOMER_CLUB_DETAIL,
	API_CUSTOMER_IRON_CLUB_SETS,
	API_CUSTOMER_RESERVATIONS,
	API_CUSTOMER_RESERVATION_COUNT,
	API_LINK_CUSTOMER,
	API_OTHER_STORES_CUSTOMERS,
	API_SEND_OTP_TO_CUSTOMER,
} from "services/endpoints"
import {
	TAG_CUSTOMERS,
	TAG_CUSTOMERS_ALL,
	TAG_CUSTOMERS_CLUBS,
	TAG_CUSTOMERS_CLUB_DETAIL,
	TAG_CUSTOMERS_CLUB_IRON_SETS,
	TAG_CUSTOMERS_FROM_OTHER_STORES,
	TAG_CUSTOMERS_RESERVATION,
} from "services/tags"

export const customersApi = api.injectEndpoints({
	endpoints(build) {
		return {
			getCustomerList: build.query({
				query(params) {
					return {
						url: API_CUSTOMERS,
						params,
					}
				},

				providesTags: [TAG_CUSTOMERS],
			}),

			getCustomerListAll: build.query({
				query(params) {
					return {
						url: API_CUSTOMERS_ALL,
						params,
					}
				},

				providesTags: [TAG_CUSTOMERS_ALL],
			}),

			getOtherStoresCustomers: build.query({
				query(params) {
					return {
						method: "GET",
						params,
						url: API_OTHER_STORES_CUSTOMERS,
					}
				},

				providesTags: [TAG_CUSTOMERS_FROM_OTHER_STORES],
			}),

			customerDetail: build.query({
				query(id) {
					return {
						url: `${API_CUSTOMERS}/${id}`,
					}
				},

				providesTags: [TAG_CUSTOMERS],
			}),

			createCustomer: build.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: API_CUSTOMERS, // API_CUSTOMERS_DETAIL,
					}
				},
				invalidatesTags: (ok) => (ok ? [TAG_CUSTOMERS, TAG_CUSTOMERS_ALL] : []),
			}),

			updateCustomer: build.mutation({
				query({ id, data }) {
					return {
						data,
						method: "PUT",
						url: `${API_CUSTOMERS}/${id}`,
					}
				},
				invalidatesTags: (ok) => (ok ? [TAG_CUSTOMERS, TAG_CUSTOMERS_ALL] : []),
			}),

			updateCustomerAddress: build.mutation({
				query({ id, data }) {
					return {
						data,
						method: "PUT",
						url: `${API_CUSTOMERS_ADDRESS}/${id}`,
					}
				},
				invalidatesTags: (ok) => (ok ? [TAG_CUSTOMERS, TAG_CUSTOMERS_ALL] : []),
			}),

			deleteCustomerAddress: build.mutation({
				query({ id }) {
					return {
						method: "DELETE",
						url: `${API_CUSTOMERS_ADDRESS}/${id}`,
					}
				},
				invalidatesTags: (ok) => (ok ? [TAG_CUSTOMERS, TAG_CUSTOMERS_ALL] : []),
			}),

			setCustomerAddressDefault: build.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: API_CUSTOMERS_ADDRESS_DEFAULT,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_CUSTOMERS, TAG_CUSTOMERS_ALL] : []),
			}),

			createCustomerAddress: build.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: API_CUSTOMERS_ADDRESS,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_CUSTOMERS, TAG_CUSTOMERS_ALL] : []),
			}),

			testDatatable: build.query({
				query(params) {
					return {
						params: { ...params, per_page: 2 },
						url: "https://node-staging.genfitx.com/api/admin/v1/users",
					}
				},
			}),

			sendOtpToCustomer: build.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: API_SEND_OTP_TO_CUSTOMER,
					}
				},
			}),

			linkCustomer: build.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: API_LINK_CUSTOMER,
					}
				},

				invalidatesTags: (ok) =>
					ok ? [TAG_CUSTOMERS_FROM_OTHER_STORES, TAG_CUSTOMERS] : [],
			}),

			getClubsByCustomerId: build.query({
				query({ customerId, clubType, ...params }) {
					return {
						method: "GET",
						params,
						url: API_CUSTOMER_CLUBS(customerId, clubType),
					}
				},
				providesTags: [TAG_CUSTOMERS_CLUBS],
			}),

			getIronClubSetsByCustomerId: build.query({
				query({ customerId }) {
					return {
						method: "GET",
						url: API_CUSTOMER_IRON_CLUB_SETS(customerId),
					}
				},
				providesTags: [TAG_CUSTOMERS_CLUB_IRON_SETS],
			}),

			getClubDetailByCustomerId: build.query({
				query({ customerId, clubType, clubId }) {
					return {
						method: "GET",
						url: API_CUSTOMER_CLUB_DETAIL(customerId, clubType, clubId),
					}
				},
				providesTags: [TAG_CUSTOMERS_CLUB_DETAIL],
			}),

			updateCustomerClub: build.mutation({
				query({ customerId, clubType, clubId, data }) {
					return {
						data,
						method: "PUT",
						url: API_CUSTOMER_CLUB_DETAIL(customerId, clubType, clubId),
					}
				},

				invalidatesTags: [
					TAG_CUSTOMERS_CLUB_DETAIL,
					TAG_CUSTOMERS_CLUBS,
					TAG_CUSTOMERS_CLUB_IRON_SETS,
				],
			}),

			deleteCustomerClubById: build.mutation({
				query({ customerId, clubType, clubId }) {
					return {
						method: "DELETE",
						url: API_CUSTOMER_CLUB_DETAIL(customerId, clubType, clubId),
					}
				},
				invalidatesTags: (ok) =>
					ok ? [TAG_CUSTOMERS_CLUBS, TAG_CUSTOMERS_CLUB_IRON_SETS] : [],
			}),

			getReservationsByCustomerId: build.query({
				query({ customerId, fittingType, pageNo }) {
					return {
						method: "GET",
						url: API_CUSTOMER_RESERVATIONS(customerId, fittingType, pageNo),
					}
				},
				providesTags: [TAG_CUSTOMERS_RESERVATION],
			}),

			getReservationCountsByCustomerId: build.query({
				query({ customerId }) {
					return {
						method: "GET",
						url: API_CUSTOMER_RESERVATION_COUNT(customerId),
					}
				},
				providesTags: [TAG_CUSTOMERS_RESERVATION],
			}),

			addCustomerClub: build.mutation({
				query({ customerId, clubType, data }) {
					return {
						method: "POST",
						url: API_CUSTOMER_ADD_CLUB(customerId, clubType),
						data,
					}
				},
				invalidatesTags: (ok) =>
					ok ? [TAG_CUSTOMERS_CLUBS, TAG_CUSTOMERS_CLUB_IRON_SETS] : [],
			}),

			addCustomerClubValidate: build.mutation({
				query({ customerId, clubType, data }) {
					if (clubType === PUTTER || clubType === DRIVER) {
						delete data.type
					}
					return {
						method: "POST",
						url: API_CUSTOMER_ADD_CLUB_VALIDATE(customerId, clubType),
						data,
					}
				},
			}),

			addCustomerClubBatch: build.mutation({
				query({ customerId, clubType, data }) {
					return {
						data,
						method: "POST",
						url: API_CUSTOMER_ADD_CLUB_BATCH(customerId, clubType),
					}
				},
				invalidatesTags: (ok) =>
					ok ? [TAG_CUSTOMERS_CLUBS, TAG_CUSTOMERS_CLUB_IRON_SETS] : [],
			}),

			addCustomerClubBatchValidate: build.mutation({
				query({ customerId, clubType, data }) {
					return {
						method: "POST",
						url: API_CUSTOMER_ADD_CLUB_BATCH_VALIDATE(customerId, clubType),
						data,
					}
				},
			}),
		}
	},
})
