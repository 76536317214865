import { Box, Skeleton } from "@mui/material"

const FormDialogContentPlaceholder = ({ count = 3, sx, ...props }) => {
	return (
		<Box {...props}>
			{Array.from({ length: count }, (_, index) => (
				<Skeleton
					key={index}
					variant="rounded"
					height={50}
					sx={{ marginBottom: 1, ...sx }}
				/>
			))}
		</Box>
	)
}

export default FormDialogContentPlaceholder
