import locale from "date-fns/locale/en-US"
import { useEffect, useState } from "react"
import { formatV1 } from "utils/dateTime"

const monthOf = (_, no) => locale.localize.month(no)

const weekOf = (_, no) => locale.localize.day(no, { width: "abbreviated" })

export let CURRENT_YEAR = new Date().getFullYear()

const useCalendar = () => {
	const today = new Date()
	const fullYear = today.getFullYear()
	const fullMonth = today.getMonth()
	const _date = today.getDate()
	const _day = today.getDay()

	const getYearsRange = () => {
		const currentYear = new Date().getFullYear()
		const startYear = currentYear - 10 // 10 years in the past
		const endYear = currentYear + 10 // 10 years in the future

		const yearsArray = []
		for (let year = startYear; year <= endYear; year++) {
			yearsArray.push(year)
		}

		return yearsArray
	}

	const zeroPad = (value) => {
		return `${value}`.padStart(2, "0")
	}

	const [year, setYear] = useState(fullYear)
	const [month, setMonth] = useState(fullMonth)
	const [weekStartDate, setWeekStartDate] = useState(new Date(fullYear, fullMonth, _date - _day))
	const [selectedDay, setSelectedDay] = useState({
		date: today,
		day: zeroPad(_date),
		formatted: formatV1(today),
	})

	useEffect(() => {
		if (CURRENT_YEAR !== year) {
			CURRENT_YEAR = year
		}
	}, [year])

	const monthNames = Array.from({ length: 12 }, monthOf)

	const weekDays = Array.from({ length: 7 }, weekOf)

	const thisYear = () => {
		setYear(fullYear)
	}

	const previousMonth = () => {
		if (month < 1) {
			setYear(year - 1)
			setMonth(11)
		} else {
			setMonth(month - 1)
		}
	}

	const thisMonth = () => {
		setMonth(fullMonth)
	}

	const nextMonth = () => {
		if (month > 10) {
			setYear(year + 1)
			setMonth(0)
		} else {
			setMonth(month + 1)
		}
	}

	const getAllDaysInMonth = () => {
		const startDate = new Date(year, month, 1)
		const endDate = new Date(year, month + 1, 0)
		const days = []

		const firstDayOfWeek = startDate.getDay()
		const daysFromPreviousMonth = firstDayOfWeek === 0 ? 7 : firstDayOfWeek

		const totalDays = 42
		const daysFromNextMonth = totalDays - (endDate.getDate() + daysFromPreviousMonth)

		for (let i = daysFromPreviousMonth; i > 0; i--) {
			const prevDate = new Date(year, month, 0 - i + 1)
			days.push({
				date: prevDate,
				day: prevDate.getDate().toString(),
				formatted: formatV1(prevDate),
			})
		}

		for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
			const _date = new Date(date)
			days.push({
				date: _date,
				day: zeroPad(_date.getDate()),
				formatted: formatV1(_date),
			})
		}

		for (let i = 1; i <= daysFromNextMonth; i++) {
			const nextDate = new Date(year, month + 1, i)
			days.push({
				date: nextDate,
				day: zeroPad(nextDate.getDate()),
				formatted: formatV1(nextDate),
			})
		}

		return days
	}

	const previousWeek = () => {
		const previousWeekStart = new Date(
			weekStartDate.getFullYear(),
			weekStartDate.getMonth(),
			weekStartDate.getDate() - 7
		)

		setYear(previousWeekStart.getFullYear())
		setMonth(previousWeekStart.getMonth())
		setWeekStartDate(previousWeekStart)
	}

	const thisWeek = () => {
		setWeekStartDate(new Date(fullYear, fullMonth, _date - _day))
	}

	const getWeek = () => {
		const currentWeek = []
		for (let i = 0; i < 7; i++) {
			const currentDate = new Date(weekStartDate)
			currentDate.setDate(weekStartDate.getDate() + i)

			currentWeek.push({
				date: currentDate,
				day: zeroPad(currentDate.getDate()),
				formatted: formatV1(currentDate),
			})
		}
		return currentWeek
	}

	const nextWeek = () => {
		const nextWeekStart = new Date(
			weekStartDate.getFullYear(),
			weekStartDate.getMonth(),
			weekStartDate.getDate() + 7
		)

		setYear(nextWeekStart.getFullYear())
		setMonth(nextWeekStart.getMonth())
		setWeekStartDate(nextWeekStart)
	}

	const previousDay = () => {
		const prevDate = new Date(selectedDay.date)
		prevDate.setDate(prevDate.getDate() - 1)
		setYear(prevDate.getFullYear())
		setMonth(prevDate.getMonth())
		setSelectedDay({
			date: prevDate,
			day: zeroPad(prevDate.getDate()),
			formatted: formatV1(prevDate),
		})
	}

	const thisDay = () => {
		setSelectedDay({
			date: today,
			day: zeroPad(_date),
			formatted: formatV1(today),
		})
	}

	const nextDay = () => {
		const nextDate = new Date(selectedDay.date)
		nextDate.setDate(nextDate.getDate() + 1)
		setYear(nextDate.getFullYear())
		setMonth(nextDate.getMonth())
		setSelectedDay({
			date: nextDate,
			day: zeroPad(nextDate.getDate()),
			formatted: formatV1(nextDate),
		})
	}

	return {
		year,
		month,
		days: getAllDaysInMonth(),
		week: getWeek(),
		monthName: monthNames[month],
		weekDays,
		selectedDay,
		yearsRange: getYearsRange(),
		setSelectedDay,
		previousDay,
		thisDay,
		nextDay,
		setWeekStartDate,
		previousWeek,
		thisWeek,
		nextWeek,
		setMonth,
		previousMonth,
		thisMonth,
		nextMonth,
		setYear,
		thisYear,
		zeroPad,
	}
}

export default useCalendar
