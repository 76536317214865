import { Box } from "@mui/material"
import { PropTypes } from "prop-types"

const MAX_SHEET_WIDTH = 580

const FormSheet = ({ children, style, ...rest }) => {
	return (
		<Box
			// normally padding bottom is 40px (4) but more 100px is added to deal with the overlapping dropdowns, hence total 140px (14)
			pb={14}
			sx={{
				width: "100%",
				maxWidth: ({ functions: { pxToRem } }) => `${pxToRem(MAX_SHEET_WIDTH)} !important`,
				...style,
			}}
			{...rest}>
			{children}
		</Box>
	)
}

FormSheet.propTypes = {
	children: PropTypes.node.isRequired,
	style: PropTypes.object,
}

export default FormSheet
