import colors from "assets/theme/base/palette"
import typography from "assets/theme/base/typography"
import { COLOR_VARIANTS, SIZE_BASED_SPECS } from "assets/theme/components/button/root"
import pxToRem from "assets/theme/functions/pxToRem"

const { text, transparent } = colors
const { size } = typography

export default {
	base: {
		backgroundColor: transparent.main,
		...SIZE_BASED_SPECS.normal,
		color: text.main,

		"&:hover, &:focus, &:active, &:active:focus, &:active:hover": {
			opacity: 0.9,
			backgroundColor: transparent.main,
		},

		"& .material-icon, .material-icons-round, svg": {
			fontSize: `${pxToRem(16)} !important`,
		},
	},

	small: {
		...SIZE_BASED_SPECS.small,

		"& .material-icon, .material-icons-round, svg": {
			fontSize: `${pxToRem(size.sm)} !important`,
		},
	},

	large: {
		...SIZE_BASED_SPECS.large,

		"& .material-icon, .material-icons-round, svg": {
			fontSize: `${pxToRem(size.lg)} !important`,
		},
	},

	// generate 'text' variant style objects based on VARIANTS' SCHEMA
	...COLOR_VARIANTS.reduce(
		(total, { id, color }) =>
			// merge the current variant into the existing variants
			Object.assign(total, {
				[id]: {
					color: color.main,

					"&:hover": {
						color: color.main,
						opacity: 0.9,
					},

					"&:focus:not(:hover)": {
						color: color.focus,
					},

					// for loading state
					"& > .MuiLoadingButton-loadingIndicator": {
						color: color.main,
					},
				},
			}),
		{}
	),
}
