import {
	CalendarSolidSvg,
	CartSvg,
	NoteSvg,
	PersonSvg,
	QuestionMarkSvg,
	SettingSvg,
} from "assets/svgs"
import palette from "assets/theme/base/palette"
import { ICON_NAMES } from "constant"
import {
	ROUTE_DASHBOARD,
	ROUTE_FITTING,
	ROUTE_FITTING_DETAIL,
	ROUTE_HELP,
	ROUTE_HELP_DETAIL,
	ROUTE_HELP_REGISTRATION,
	ROUTE_MEMBERS,
	ROUTE_MEMBERS_DETAIL,
	ROUTE_ORDERS,
	ROUTE_PROFILE_CHANGE_PASSWORD,
	ROUTE_PROFILE_CHANGE_PHONE_NUMBER,
	ROUTE_PROFILE_MANAGEMENT,
	ROUTE_PROFILE_RECONFIRM_PASSWORD,
	ROUTE_RESERVATIONS,
	ROUTE_SETTINGS,
	ROUTE_STAFF,
	ROUTE_STAFF_ADD,
	ROUTE_STAFF_ADD_EXISTING,
	ROUTE_STAFF_ADD_NEW,
	ROUTE_STAFF_MANAGEMENT,
	ROUTE_STORES_MANAGEMENT,
	ROUTE_STORES_VICX_SYSTEM,
	ROUTE_STORES_VICX_SYSTEM_CHANGE_PASSWORD,
	ROUTE_STORES_VICX_SYSTEM_CHANGE_PASSWORD_CONFIRM,
	ROUTE_STORE_CLUB_SETTINGS,
	ROUTE_STORE_OPERATING_HOURS,
	ROUTE_STORE_ROOM_CUSTOMIZATION,
} from "urls"

const routes = [
	{
		name: "Dashboard",
		id: "dashboard",
		icon: ICON_NAMES.DASHBOARD,
		path: ROUTE_DASHBOARD,
		lazy: () => import("layouts/dashboard"),
		sidenavVisible: true,
	},
	{
		name: "Members management",
		id: "member-management",
		icon: (isSelected) => (
			<PersonSvg fill={isSelected ? palette.primary.main : palette.grey[700]} />
		),
		path: ROUTE_MEMBERS,
		lazy: () => import("layouts/members"),
		sidenavVisible: true,
		routes: [
			{
				path: ROUTE_MEMBERS_DETAIL,
				lazy: () => import("layouts/members/detail"),
				sidenavVisible: true,
			},
		],
	},
	{
		name: "Reservations management",
		id: "reservation-management",
		icon: (isSelected) => (
			<CalendarSolidSvg fill={isSelected ? palette.primary.main : palette.grey[700]} />
		),
		path: ROUTE_RESERVATIONS,
		lazy: () => import("layouts/reservations"),
		sidenavVisible: true,
	},
	{
		name: "Fittings management",
		id: "fitting-management",
		icon: (isSelected) => (
			<NoteSvg fill={isSelected ? palette.primary.main : palette.grey[700]} />
		),
		path: ROUTE_FITTING,
		lazy: () => import("layouts/fitting"),
		sidenavVisible: true,
		routes: [
			{
				path: ROUTE_FITTING_DETAIL,
				lazy: () => import("layouts/fitting/detail"),
				sidenavVisible: true,
			},
		],
	},
	{
		name: "Orders management",
		id: "order-management",
		icon: (isSelected) => {
			const selectColor = isSelected ? palette.primary.main : palette.grey[700]
			return <CartSvg fill={selectColor} stroke={selectColor} />
		},
		path: ROUTE_ORDERS,
		lazy: () => import("layouts/orders"),
		sidenavVisible: false,
	},
	// At the moment, this page is commented out. Because compnany requested to hide this page.
	// {
	// 	name: "Equipments management",
	// 	id: "equipment-management",
	// 	icon: (isSelected) => (
	// 		<ClubsSvg fill={isSelected ? palette.primary.main : palette.grey[700]} />
	// 	),
	// 	path: ROUTE_EQUIPMENT_MANAGEMENT,
	// 	lazy: () => import("layouts/equipmentManagement"),
	// 	sidenavVisible: true,
	// },
	{
		name: "Customer center",
		id: "customer-service-center",
		icon: (isSelected) => (
			<QuestionMarkSvg fill={isSelected ? palette.primary.main : palette.grey[700]} />
		),
		path: ROUTE_HELP,
		lazy: () => import("layouts/help"),
		sidenavVisible: true,
		routes: [
			{
				path: ROUTE_HELP,
				lazy: () => import("layouts/help"),
				sidenavVisible: true,
			},
			{
				path: ROUTE_HELP_DETAIL,
				lazy: () => import("layouts/help/form"),
				sidenavVisible: true,
			},
			{
				path: ROUTE_HELP_REGISTRATION,
				lazy: () => import("layouts/help/form"),
				sidenavVisible: true,
			},
		],
	},
	{
		name: "Setting",
		id: "settings",
		icon: (isSelected) => (
			<SettingSvg fill={isSelected ? palette.primary.main : palette.grey[700]} />
		),
		path: ROUTE_SETTINGS,
		lazy: () => import("layouts/settings"),
		sidenavVisible: true,
	},
	{
		name: "Personal information",
		id: "personal-information-management",
		path: ROUTE_PROFILE_RECONFIRM_PASSWORD,
		lazy: () => import("layouts/profile/ReconfirmPasswordForm"),
		sidenavVisible: false,
		routes: [
			{
				path: ROUTE_PROFILE_RECONFIRM_PASSWORD,
				lazy: () => import("layouts/profile/ReconfirmPasswordForm"),
				sidenavVisible: false,
			},
		],
	},
	{
		name: "Personal information",
		id: "personal-information-edit",
		path: ROUTE_PROFILE_MANAGEMENT,
		lazy: () => import("layouts/profile/EditForm"),
		sidenavVisible: false,
		routes: [
			{
				path: ROUTE_PROFILE_MANAGEMENT,
				lazy: () => import("layouts/profile/EditForm"),
				sidenavVisible: false,
			},
		],
	},
	{
		name: "Personal information",
		id: "personal-information-change-password",
		path: ROUTE_PROFILE_CHANGE_PASSWORD,
		lazy: () => import("layouts/profile/ChangePassword"),
		sidenavVisible: false,
		routes: [
			{
				path: ROUTE_PROFILE_CHANGE_PASSWORD,
				lazy: () => import("layouts/profile/ChangePassword"),
				sidenavVisible: false,
			},
		],
	},
	{
		name: "Personal information",
		id: "personal-information-edit",
		path: ROUTE_PROFILE_CHANGE_PHONE_NUMBER,
		lazy: () => import("layouts/profile/ChangePhoneNumber"),
		sidenavVisible: false,
		routes: [
			{
				path: ROUTE_PROFILE_CHANGE_PHONE_NUMBER,
				lazy: () => import("layouts/profile/ChangePhoneNumber"),
				sidenavVisible: false,
			},
		],
	},
	{
		name: "Store information management",
		id: "store-information-management",
		path: ROUTE_STORES_MANAGEMENT,
		lazy: () => import("layouts/stores/store"),
		isTopnavBordered: true,
		sidenavVisible: false,
	},
	{
		name: "VICX System Management",
		id: "vicx-system-management",
		path: ROUTE_STORES_VICX_SYSTEM,
		lazy: () => import("layouts/stores/store"),
		isTopnavBordered: true,
		sidenavVisible: false,
		routes: [
			{
				path: ROUTE_STORES_VICX_SYSTEM,
				lazy: () => import("layouts/stores/store"),
				isTopnavBordered: true,
				sidenavVisible: false,
			},
		],
	},
	{
		name: "VICX System Password Change",
		id: "vicx-system-change-password",
		path: ROUTE_STORES_VICX_SYSTEM_CHANGE_PASSWORD,
		lazy: () => import("layouts/stores/store/vicx/home"),
		isTopnavBordered: true,
		sidenavVisible: false,
	},
	{
		name: "VICX System Password Change",
		id: "vicx-system-change-password-confirm",
		path: ROUTE_STORES_VICX_SYSTEM_CHANGE_PASSWORD_CONFIRM,
		lazy: () => import("layouts/stores/store/vicx/home"),
		isTopnavBordered: true,
		sidenavVisible: false,
	},
	{
		name: "Room Customization",
		id: "room-customization",
		path: ROUTE_STORE_ROOM_CUSTOMIZATION,
		lazy: () => import("layouts/stores/store/RoomCustomization"),
		isTopnavBordered: true,
		sidenavVisible: false,
	},
	{
		name: "Operation Hours",
		id: "operation-hours",
		path: ROUTE_STORE_OPERATING_HOURS,
		lazy: () => import("layouts/stores/store/OperatingHours"),
		isTopnavBordered: true,
		sidenavVisible: false,
	},
	{
		name: "Club Settings",
		id: "club-settings",
		path: ROUTE_STORE_CLUB_SETTINGS,
		lazy: () => import("layouts/stores/store/clubSettings"),
		isTopnavBordered: true,
		sidenavVisible: false,
	},
	{
		name: "Staff management",
		id: "staff-management",
		path: ROUTE_STAFF,
		lazy: () => import("layouts/stores/staff"),
		sidenavVisible: false,
		routes: [
			{
				path: ROUTE_STAFF_MANAGEMENT,
				lazy: () => import("layouts/stores/staff"),
				isTopnavBordered: true,
				sidenavVisible: false,
			},
			{
				path: ROUTE_STAFF_ADD,
				lazy: () => import("layouts/stores/staff"),
				isTopnavBordered: true,
				sidenavVisible: false,
			},
			{
				path: ROUTE_STAFF_ADD_NEW,
				lazy: () => import("layouts/stores/staff"),
				isTopnavBordered: true,
				sidenavVisible: false,
			},
			{
				path: ROUTE_STAFF_ADD_EXISTING,
				lazy: () => import("layouts/stores/staff"),
				isTopnavBordered: true,
				sidenavVisible: false,
			},
		],
	},
]

if (process.env.NODE_ENV === "development") {
	routes.push({
		name: "Playground",
		id: "playground",
		icon: "abc",
		path: "/playground",
		routes: [
			{
				path: "/playground/:component",
				lazy: () => import("layouts/playground"),
				sidenavVisible: true,
			},
		],
		lazy: () => import("layouts/playground"),
		sidenavVisible: true,
	})
}

export default routes

export function findRouteByPath(path) {
	function isTheSamePath(path1, path2) {
		const path1Chucks = path1.split("/")
		const path2Chucks = path2.split("/")

		if (path1Chucks.length !== path2Chucks.length) return false

		for (let i = 0; i < path1Chucks.length; i++) {
			// also pass the check if the path is starting with ":" (placeholder for dynamic route segments)
			if (
				!(
					path1Chucks[i].startsWith(":") ||
					path2Chucks[i].startsWith(":") ||
					path1Chucks[i] === path2Chucks[i]
				)
			) {
				return false
			}
		}

		return true
	}

	for (let route of routes) {
		if (isTheSamePath(route.path, path)) return route
		if (route.routes) {
			for (let subRoute of route.routes) {
				if (isTheSamePath(subRoute.path, path)) return subRoute
			}
		}
	}
}
