import { Box, Typography, useTheme } from "@mui/material"
import { useLocalizedDateUtils } from "hooks"
import { useIsEnglish } from "stores/slice/app"
import { formatLongText } from "utils/helpers"

const DataBoxForWeekView = ({ marginTop, reservation = null, handleDataBox }) => {
	const { palette } = useTheme()
	const { getAmPmFromDateStringResDataBox } = useLocalizedDateUtils()

	const isEn = useIsEnglish()

	const startTime = getAmPmFromDateStringResDataBox(reservation.start_time)
	const endTime = getAmPmFromDateStringResDataBox(reservation.end_time)
	const starts = startTime.split(" ")
	const ends = endTime.split(" ")

	return (
		<Box
			onClick={() => handleDataBox(reservation)}
			sx={{
				minWidth: "85%",
				width: "86%",
				maxWidth: "90%",
				marginTop: marginTop,
				backgroundColor: palette.grey[200],
				borderRadius: "8px",
				cursor: "pointer",
				"&:hover": {
					outline: `1px solid ${palette.primary.main}`,
				},
			}}>
			<Box
				sx={{
					display: "flex",
					justifyContent: "flex-start",
					alignItems: "flex-start",
				}}>
				<Box
					sx={({ palette }) => ({
						width: "10px",
						height: "10px",
						borderRadius: "100%",
						backgroundColor: palette.reservationStatusColors[reservation.status],
						padding: 0,
						margin: "11px 5px 0 7px",
					})}
				/>
				<Typography
					fontSize={12}
					sx={{
						marginY: "7px",
						mr: "1px",
					}}>
					{isEn ? (
						<>
							{startTime} -
							<br />
							{endTime}
						</>
					) : (
						<>
							{starts[0]}
							<br />
							{starts[1]}-{ends[1]}
						</>
					)}
					<br />
					{formatLongText(reservation?.user?.name, 7)}
				</Typography>
			</Box>
		</Box>
	)
}

export default DataBoxForWeekView
