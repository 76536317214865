import { useTranslation } from "react-i18next"

import { ConfirmPage } from "./layout"

function SignupError({ tokenError, errorMessage }) {
	const { t } = useTranslation()

	return (
		<ConfirmPage
			title={tokenError ? t("Expiration time exceeded") : t("Page expired")}
			content={
				tokenError
					? t(
							"The link is no longer available because it has expired.\nPlease request a new link and try again."
						)
					: t(errorMessage)
			}
		/>
	)
}

export default SignupError
