import { Button, Typography, useTheme } from "@mui/material"
import pxToRem from "assets/theme/functions/pxToRem"
import { useTranslation } from "react-i18next"

const ClubAccordionButton = ({ onClick }) => {
	const { t } = useTranslation()
	const { palette } = useTheme()

	return (
		<Button
			onClick={onClick}
			variant="contained"
			sx={{
				backgroundColor: palette.secondary.main,
				height: "50px",
				width: "100%",
				borderRadius: "0 0 8px 8px",
			}}>
			<Typography
				sx={{
					fontSize: pxToRem(16),
					fontWeight: 700,
				}}>
				{t("Detailed specs")}
			</Typography>
		</Button>
	)
}

export default ClubAccordionButton
