import { FormDialog, FormDialogContentPlaceholder } from "components"
import ReservationEditForm from "layouts/reservations/ReservationEditDialog/ReservationEditForm"
import { useTranslation } from "react-i18next"
import { reservationApi } from "services/modules"

const ReservationEditDialog = ({ isOpen, setIsOpen, reservationID, openClubModal }) => {
	const { t } = useTranslation()

	const {
		data: { data: reservationDetails } = { data: null },
		isLoading: reservationDetailsIsLoading,
	} = reservationApi.useGetReservationDetailQuery(reservationID)

	const handleDialogClose = () => setIsOpen(false)

	return (
		<FormDialog onClose={handleDialogClose} open={isOpen} width={580} padding={4}>
			<FormDialog.Header>{t("Reservation information")}</FormDialog.Header>
			<FormDialog.Content>
				{reservationDetailsIsLoading ? (
					<FormDialogContentPlaceholder mb={1} />
				) : (
					reservationDetails && (
						<ReservationEditForm
							initialReservationDetails={reservationDetails}
							handleDialogClose={handleDialogClose}
							openClubModal={openClubModal}
						/>
					)
				)}
			</FormDialog.Content>
		</FormDialog>
	)
}

export default ReservationEditDialog
