import { api } from "services/api"
import {
	API_APP_USER,
	API_APP_USER_ASSIGNED_STORES,
	API_APP_USER_CHANGE_PASSWORD,
	API_APP_USER_CHANGE_PHONE_NUMBER,
	API_APP_USER_CHANGE_PHONE_NUMBER_CONFIRM,
	API_APP_USER_CHANGE_PROFILE,
	API_APP_USER_CHECK_NEW_MEMBER_INVITE,
	API_APP_USER_CHECK_PASSWORD,
	API_APP_USER_INVITE_STORE,
	API_APP_USER_SEARCH_EXISTS,
	API_APP_USER_SEARCH_NEW,
	API_APP_USER_STORE,
} from "services/endpoints"
import { TAG_APP_USER, TAG_STORE_FITTERS } from "services/tags"

export default api.injectEndpoints({
	endpoints(build) {
		return {
			getAppUser: build.query({
				query() {
					return {
						url: API_APP_USER,
					}
				},

				providesTags: [TAG_APP_USER],
			}),

			searchNewAppUser: build.query({
				query(params) {
					return {
						params,
						url: API_APP_USER_SEARCH_NEW,
					}
				},
			}),

			changeAppUserProfile: build.mutation({
				query(data) {
					return {
						data,
						method: "PUT",
						url: API_APP_USER_CHANGE_PROFILE,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_APP_USER, TAG_STORE_FITTERS] : []),
			}),

			searchAppUserExist: build.query({
				query(params) {
					return {
						params,
						url: API_APP_USER_SEARCH_EXISTS,
					}
				},
			}),

			inviteAppUserStore: build.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: API_APP_USER_INVITE_STORE,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_STORE_FITTERS] : []),
			}),

			createAppUser: build.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: API_APP_USER,
					}
				},
			}),

			updateAppUser: build.mutation({
				query({ id, ...data }) {
					return {
						data,
						method: "PUT",
						url: `${API_APP_USER}/${id}`,
					}
				},
			}),

			postAppUserStore: build.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: API_APP_USER_STORE,
					}
				},
			}),

			checkAppUserPassword: build.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: API_APP_USER_CHECK_PASSWORD,
					}
				},
			}),

			changeAppUserPassword: build.mutation({
				query(data) {
					return {
						data,
						method: "PUT",
						url: API_APP_USER_CHANGE_PASSWORD,
					}
				},
			}),

			getAppUserAssignedStores: build.query({
				query() {
					return {
						url: API_APP_USER_ASSIGNED_STORES,
					}
				},
			}),

			changeAppUserPhoneNumber: build.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: API_APP_USER_CHANGE_PHONE_NUMBER,
					}
				},
			}),

			changeAppUserPhoneNumberConfirm: build.mutation({
				query(data) {
					return {
						data,
						method: "PUT",
						url: API_APP_USER_CHANGE_PHONE_NUMBER_CONFIRM,
					}
				},
			}),

			checkNewMemberInvite: build.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: API_APP_USER_CHECK_NEW_MEMBER_INVITE,
					}
				},
			}),
		}
	},
})
