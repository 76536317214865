import { Box, Typography, useTheme } from "@mui/material"
import { MonthSelector } from "components/CalenderViews"
import CalendarSelectBox from "components/CalenderViews/CalenderComponents/CalendarSelectBox"
import SelectorWrapper from "components/CalenderViews/CalenderComponents/SelectorWrapper"
import { useTranslation } from "react-i18next"
import { useIsEnglish } from "stores/slice/app"

const styles = {
	popOver: { width: "300px" },
	default: { width: "500px" },
}

const DatePickerWrapper = ({
	selectableYearAndMonth,
	border,
	padding,
	width,
	weekDayListMarginTop,
	weekDayNameFontSize,
	datesView,
	monthName,
	weekDays,
	year,
	month,
	previousMonth,
	nextMonth,
	setMonth,
	setYear,
	variant = "default",
}) => {
	const { t } = useTranslation()
	const isEng = useIsEnglish()
	const { palette } = useTheme()

	return (
		<Box
			sx={{
				border: border && `1px solid ${palette.grey[300]}`,
				borderRadius: "10px",
				padding: padding,
				background: palette.white.main,
				width: width,
			}}>
			{selectableYearAndMonth ? (
				<Box display="flex" justifyContent="space-between" pr={1}>
					<Box>
						{/* For month */}
						<CalendarSelectBox currentValue={month} type="months" setDate={setMonth} />
						{/* For year */}
						<CalendarSelectBox currentValue={year} type="years" setDate={setYear} />
					</Box>
					<SelectorWrapper
						onClickPrevious={previousMonth}
						onClickNext={nextMonth}
						isSmall
						margin={2}
					/>
				</Box>
			) : (
				<Box display="flex" justifyContent="center">
					<MonthSelector
						margin="3px 20px 0 20px"
						monthName={monthName}
						year={year}
						nextMonth={nextMonth}
						previousMonth={previousMonth}
					/>
				</Box>
			)}

			<Box display="flex" justifyContent="center">
				<Box width={styles[variant].width}>
					<ul
						style={{
							display: "grid",
							gridTemplateColumns: "repeat(7, 1fr)",
							listStyleType: "none",
							marginTop: weekDayListMarginTop,
						}}>
						{weekDays.map((day) => (
							<Typography
								key={`${month}-${day}`}
								variant="body2"
								sx={{
									color: selectableYearAndMonth ? "info.main" : "black.main",
									fontSize: weekDayNameFontSize,
									fontWeight: selectableYearAndMonth ? "400" : "500",
									textAlign: "center",
									margin: isEng ? "3px" : "10px",
									fontFamily: "Roboto",
								}}>
								{t(day)}
							</Typography>
						))}
						{datesView}
					</ul>
				</Box>
			</Box>
		</Box>
	)
}

export default DatePickerWrapper
