import { Box } from "@mui/material"
import pxToRem from "assets/theme/functions/pxToRem"
import { BasicLayout, FormLoading, FormSheet } from "components"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { appUserApi, authApi } from "services/modules"
import { useUser } from "stores/slice/user"
import { isTrue } from "utils/string"

import SignupError from "./error"
import SignupHome from "./home"

function SignUp() {
	const [loading, setLoading] = useState(true)

	const user = useUser()
	const { state = {} } = useLocation()
	const { errorCode, is_new, token } = state

	const [logout] = authApi.useLogoutMutation()
	const [checkExistingMember, { error: checkExistingMemberError }] =
		appUserApi.usePostAppUserStoreMutation()
	const [checkNewMember, { error: checkNewMemberError }] =
		appUserApi.useCheckNewMemberInviteMutation()

	const verifyMember = async () => {
		try {
			isTrue(is_new)
				? await checkNewMember({ token }).unwrap()
				: await checkExistingMember({ token }).unwrap()
		} catch (err) {
			console.error("An error occurred:", err)
		}
	}

	useEffect(() => {
		const init = async () => {
			try {
				if (user.id !== null) {
					await logout()
				}
				if (!errorCode) {
					await verifyMember()
				}
			} catch (err) {
				console.error("An error occurred:", err)
			} finally {
				setLoading(false)
			}
		}
		init()
	}, [])

	return (
		<BasicLayout height="auto">
			<FormSheet>
				{loading ? (
					<FormLoading />
				) : (
					<Box
						sx={{
							width: pxToRem(580),
							position: "relative",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							marginX: "auto",
							marginY: 4,
						}}>
						{errorCode || checkExistingMemberError || checkNewMemberError ? (
							<SignupError
								tokenError={errorCode}
								errorMessage={
									checkExistingMemberError?.response?.data?.message ||
									checkNewMemberError?.response?.data?.message
								}
							/>
						) : (
							<SignupHome data={state} />
						)}
					</Box>
				)}
			</FormSheet>
		</BasicLayout>
	)
}
export default SignUp
