import { Box, Skeleton, Typography } from "@mui/material"
import pxToRem from "assets/theme/functions/pxToRem"
import { useEffect, useState } from "react"
import { formatLongText } from "utils/helpers"

import { HolidayView } from ".."
import DataBox from "./DataBox"

const MonthCalendarView = ({
	selectedDay,
	setSelectedDay,
	weekDays,
	days,
	month,
	palette,
	typography,
	t,
	language,
	isLoading,
	handleDataBox,
}) => {
	const [autoSetSelectedDay, setAutoSetSelectedDay] = useState(false)

	const currentDayString = new Date().toLocaleDateString("en-GB")

	let firstTwoDigits = currentDayString.substring(0, 2)

	if (firstTwoDigits.startsWith("0")) {
		firstTwoDigits = firstTwoDigits.substring(1)
	}

	const paddingTopsForEachDay = [
		0, 0.52, 0.52, 0.52, 0.52, 0.52, 0.52, 0.52, 0.52, 0.52, 0.52, 0.52, 0.52, 0.52, 0.52, 0.52,
		0.52, 0.52, 0.52, 0.47, 0.47, 0.47, 0.45, 0.45, 0.45, 0.43, 0.43, 0.52, 0.43, 0.52, 0.52,
		0.52,
	]
	const paddingLeftsForEachDay = [
		0, 0.8, 0.6, 0.6, 0.6, 0.6, 0.63, 0.65, 0.65, 0.59, 0.9, 1, 0.8, 0.84, 0.8, 0.9, 0.8, 0.9,
		0.8, 0.84, 0.7, 0.9, 0.75, 0.65, 0.65, 0.65, 0.73, 0.75, 0.73, 0.72, 0.63, 0.82,
	]

	useEffect(() => {
		setAutoSetSelectedDay(false)
	}, [isLoading])

	useEffect(() => {
		if (!autoSetSelectedDay) {
			for (let i = 0; i < days.length; i++) {
				const d = days[i]
				if (d.date instanceof Date) {
					const dateString = new Date(d.date).toLocaleDateString("en-GB")
					if (currentDayString === dateString) {
						setSelectedDay(d)
						setAutoSetSelectedDay(true)
						break
					}
				}
			}
		}
	}, [days])

	return (
		<Box
			display="grid"
			gridTemplateColumns="repeat(7, 1fr)"
			gap={0}
			sx={{
				flexGrow: 2,
				minWidth: "925px",
				maxHeight: "920px",
				// maxWidth: "1000px",
				// maxWidth: "87%",
				border: `1px solid ${palette.grey[300]}`,
				borderRadius: "10px",
				background: palette.white.main,
				placeItems: "center",
				"@media (max-width: 1400px)": {
					minWidth: "950px",
					maxWidth: "950px",
				},
			}}>
			{weekDays.map((day, index) => (
				<Box gridColumn="span 1" key={index}>
					<Typography
						fontSize={typography.size.xl}
						fontWeight={500}
						color={
							index === 0
								? palette.warning.main
								: index === 6
									? palette.secondary.main
									: palette.dark.main
						}
						paddingY={1.5}
						paddingX={0}
						margin={0}>
						{t(day)}
					</Typography>
				</Box>
			))}
			{days.map((d, index) => {
				const dateString = d.date.toLocaleDateString("en-GB")
				const today = currentDayString === dateString
				const selectedDayCheck =
					selectedDay && dateString === selectedDay.date.toLocaleDateString("en-GB")

				const backgroundColor = selectedDayCheck
					? palette.secondary.main
					: palette.transparent.main
				const color = today
					? palette.white.main
					: selectedDayCheck
						? palette.white.main
						: d.date.getMonth() === month
							? palette.black.main
							: palette.grey[700]
				const fontWeight = 500

				return (
					<Box
						gridColumn="span 1"
						key={dateString}
						borderTop={`1px solid ${palette.grey[300]}`}
						borderRight={
							[6, 13, 20, 27, 34, 41].includes(index)
								? "none"
								: `1px solid ${palette.grey[300]}`
						}
						sx={{
							width: "100%",
							height: "100%",
							cursor: "pointer",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							backgroundColor: backgroundColor,
							borderRadius:
								index === 35 ? "0 0 0 10px" : index === 41 ? "0 0 10px 0" : "none",
							borderBottom: [35, 36, 37, 38, 39, 40, 41].includes(index)
								? "none"
								: `1px solid ${palette.transparent.main}`,
							"&:hover": {
								outline: `1px solid ${palette.primary.main}`,
								borderTop: `1px solid ${palette.transparent.main}`,
								borderRight: [6, 13, 20, 27, 34, 41].includes(index)
									? "none"
									: `1px solid ${palette.transparent.main}`,
								borderBottom: [35, 36, 37, 38, 39, 40, 41].includes(index)
									? "none"
									: `1px solid ${palette.primary.main}`,
							},
						}}
						onClick={() => {
							setAutoSetSelectedDay(true)
							setSelectedDay(d)
						}}>
						<Box alignSelf="flex-start" sx={{ display: "flex" }}>
							<Typography
								paddingTop={today ? paddingTopsForEachDay[firstTwoDigits] : 1.2}
								paddingLeft={today ? paddingLeftsForEachDay[firstTwoDigits] : 2}
								margin={0}
								fontSize={18}
								fontWeight={fontWeight}
								color={color}
								sx={{
									display: "flex",
									height: "37px",
									width: "37px",
									padding: "none",
									marginTop: today ? "6px" : 0,
									marginLeft: today ? "7px" : 0,
									marginRight: 0,
									// marginRight: today ? "7px" : 0,
									// marginBottom: today ? 0 : "6px",
									borderRadius: "50%",
									backgroundColor: today
										? selectedDayCheck
											? palette.transparent.main
											: palette.primary.main
										: palette.transparent.main,
								}}>
								{d.day}
							</Typography>
							{d.holiday && (
								<HolidayView
									sx={{
										marginTop: "12px",
										marginLeft: today ? "2px" : "10px",
									}}>
									{formatLongText(d.holiday.description, 5)}
								</HolidayView>
							)}
						</Box>
						{isLoading && (
							<Skeleton
								sx={{
									marginTop: "8px",
									bgcolor: "#eaeaea",
									borderRadius: pxToRem(8),
									minWidth: "90%",
									width: "90%",
									maxWidth: "95%",
									height: "26px",
								}}
								variant="rounded"
							/>
						)}
						{!isLoading && d.reservations?.length ? (
							d.reservations
								?.slice(0, 2)
								.map((reservation) => (
									<DataBox
										key={reservation.id}
										marginTop="5px"
										language={language}
										reservation={reservation}
										handleDataBox={handleDataBox}
									/>
								))
						) : (
							<>
								<DataBox
									marginTop="5px"
									reservation={null}
									selectedDayCheck={selectedDayCheck}
								/>
								<DataBox
									marginTop="5px"
									reservation={null}
									selectedDayCheck={selectedDayCheck}
								/>
							</>
						)}
						<Typography
							fontSize={11}
							marginTop={0.6}
							color={selectedDayCheck ? palette.white.main : palette.black.main}
							fontWeight={fontWeight}>
							{d.reservations?.length === 1 || d.reservations?.length === 2
								? " "
								: d.reservations?.length
									? `+${d.reservations?.length - 2}`
									: " "}
						</Typography>
					</Box>
				)
			})}
		</Box>
	)
}

export default MonthCalendarView
