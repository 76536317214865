import { TextField, Typography, styled, useTheme } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import OutlinedInput from "@mui/material/OutlinedInput"
import Select from "@mui/material/Select"
import { ArrowHeadDownSvg } from "assets/svgs"
import pxToRem from "assets/theme/functions/pxToRem"
import { DIRECT_INPUT_VALUE, SPECIAL_INPUT_TYPES } from "constant"
import { isString } from "lodash"
import { bool, node, object, oneOf, shape, string } from "prop-types"
import { createContext, forwardRef, useContext } from "react"
import { useTranslation } from "react-i18next"
import { useIsEnglish } from "stores/slice/app"
import {
	formatClubAnglesNoUnit,
	formatClubFlexNoUnit,
	formatClubLengthNoUnit,
	formatClubSwingWeightNoUnit,
	formatClubWeightNoUnit,
} from "utils/string"

import UnitAdornment from "./UnitAdornment"

const BORDER_RADIUS_IN_PX = 8
const TOGGLE_ICON_SIZE_IN_PX = 20
const SELECT_BOX_HEIGHTS_IN_PX = {
	small: 40,
	normal: 50,
	large: 60,
}

export const MenuProps = ({ palette }, isStickyVariant) => {
	const menuStickyVariantStyles = {
		borderTopRightRadius: 0,
		borderTopLeftRadius: 0,
		borderTopWidth: 0,
	}

	return {
		PaperProps: {
			style: {
				marginTop: isStickyVariant ? "1px" : "2px", // space between select box and dropdown box - 2px from figma
				background: palette.white.main,
				boxShadow: "none",
				border: `1px solid ${palette.grey[300]}`, // TODO: want to remove by Phone Htet
				width: pxToRem(1),
				borderRadius: `${BORDER_RADIUS_IN_PX}px`,
				maxHeight: pxToRem(240),
				...(isStickyVariant && menuStickyVariantStyles),
			},

			sx: {
				// remove default menu box - vertical paddings
				"& .MuiMenu-list": {
					padding: "0 !important",
				},
			},
		},
	}
}

export const StyledSelect = styled(Select)(({
	theme: {
		typography,
		functions: { pxToRem },
		palette: { grey, black, inputBorderColor },
	},
	ownerState,
}) => {
	const { size, noBorder, placeholder, value, isStickyVariant, disabled, textColor } = ownerState
	const boxHeight = pxToRem(SELECT_BOX_HEIGHTS_IN_PX[size] - 16)

	const disabledStyles = {
		backgroundColor: `${grey[200]} !important`,
		color: textColor,
	}

	return {
		"& .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: `${inputBorderColor} !important`,
			borderRadius: isStickyVariant ? 0 : pxToRem(BORDER_RADIUS_IN_PX),
			borderWidth: noBorder ? 0 : 1,
		},

		".MuiSelect-outlined": {
			marginTop: 0,
			border: 0,
			paddingLeft: pxToRem(20),
			display: "flex",
		},

		"& .MuiSelect-select": {
			alignItems: "center",
			minHeight: `${boxHeight} !important`,
			fontSize: typography.size.md,
			lineHeight: `${boxHeight} !important`,
			color: placeholder && !value ? grey[700] : black.main,
			paddingLeft: pxToRem(size === "small" ? 10 : 20),
			paddingTop: pxToRem(8),
			paddingBottom: pxToRem(8),
			paddingRight: `${pxToRem(42)} !important`,
			...(disabled && disabledStyles),
		},

		"& .MuiSelect-icon": {
			width: TOGGLE_ICON_SIZE_IN_PX,
			height: TOGGLE_ICON_SIZE_IN_PX,
			transition: "all ease-in-out 0.3s",
			right: pxToRem(size === "small" ? 10 : 18),
			top: "auto",
		},

		// rotate arrow icon when dropdown is opened
		"& .MuiSelect-iconOpen": {
			transform: "rotate(180deg)",
		},
	}
})

const StyledTextField = styled(TextField)(({ theme, ownerState }) => {
	const {
		palette,
		typography,
		functions: { pxToRem },
		borders: { borderRadius },
	} = theme
	const { disabled, size, isEnglish, noMaxWidth = false } = ownerState
	const { grey, black } = palette

	const sizeBasedStyles = {
		small: {
			paddingLeft: pxToRem(12),
			minHeight: pxToRem(40),
			fontSize: typography.size.md,
		},
		normal: {
			paddingLeft: pxToRem(20),
			minHeight: pxToRem(50),
			fontSize: typography.size.md,
		},
		large: {
			paddingLeft: pxToRem(20),
			minHeight: pxToRem(60),
			fontSize: typography.size.md,
		},
	}

	return {
		"& .MuiInputBase-root": {
			minWidth: "100%",
			paddingLeft: sizeBasedStyles[size].paddingLeft,
			// paddingRight: isEnglish ? pxToRem(0) : "-70px",
			maxWidth: noMaxWidth ? "none" : isEnglish ? pxToRem(170) : pxToRem(140),

			"& fieldset": {
				border: `1px solid ${grey[300]}`,
			},
			"&:hover fieldset": {
				border: `1px solid ${grey[300]}`,
			},
			"&.Mui-focused fieldset": {
				border: `1px solid ${grey[300]}`,
			},
		},

		"& .MuiInputBase-input": {
			borderRadius: borderRadius.lg,
			padding: 0,
			minHeight: sizeBasedStyles[size].minHeight,
			fontSize: sizeBasedStyles[size].fontSize,
			letterSpacing: "normal",
			color: disabled ? grey[700] : black.main,
			textOverflow: disabled ? "ellipsis" : "initial",

			"&::placeholder": {
				letterSpacing: "normal",
			},
		},
	}
})

const StyledSelectBoxLabel = styled(InputLabel)(
	({
		theme: {
			spacing,
			typography,
			palette: { grey },
			functions: { pxToRem },
		},
		labelBgColor,
	}) => ({
		position: "absolute",
		top: 0,
		width: "auto",
		height: "auto",
		transform: "translateY(-55%)", // put the label at the mid of the select box's top border
		left: spacing(1),
		fontSize: `${typography.size.xs} !important`,
		fontWeight: typography.fontWeightRegular,
		color: grey[700],
		backgroundColor: labelBgColor || grey[200],
		padding: `0 ${pxToRem(10)}`,
	})
)

const AppSelectContext = createContext() // context is used to centralized all the props in the wrapper, making sure consistent prop passing throughout the component, and embrace re-rendering

const AppSelect = forwardRef(
	(
		{
			label,
			labelBgColor,
			children,
			variant,
			borderColor,
			fullWidth,
			size,
			disabled,
			placeholder,
			noBorder,
			options,
			type,
			noMaxWidth,
			...props
		},
		ref
	) => {
		const theme = useTheme()
		const {
			palette: { grey, black },
		} = theme

		const { t } = useTranslation()

		const isEnglish = useIsEnglish()

		const isStickyVariant = variant === "sticky"
		const boxBorderColor = borderColor || grey[300]
		const textColor = disabled ? grey[700] : black.main

		const autoFormatSpecialValue = (type, value) => {
			switch (type) {
				case SPECIAL_INPUT_TYPES.CLUB_WEIGHT:
					return formatClubWeightNoUnit(value)

				case SPECIAL_INPUT_TYPES.CLUB_SWING_WEIGHT:
					return formatClubSwingWeightNoUnit(value)

				case SPECIAL_INPUT_TYPES.CLUB_LENGTH:
					return formatClubLengthNoUnit(value)

				case SPECIAL_INPUT_TYPES.LOFT:
				case SPECIAL_INPUT_TYPES.LIE_ANGLE:
				case SPECIAL_INPUT_TYPES.BOUNCE_ANGLE:
					return formatClubAnglesNoUnit(value)

				case SPECIAL_INPUT_TYPES.FLEX:
					return formatClubFlexNoUnit(value)

				default:
					return value
			}
		}

		const getUnitForSpecialValue = (type, t) => {
			switch (type) {
				case SPECIAL_INPUT_TYPES.HEIGHT:
					return t("heightUnit")

				case SPECIAL_INPUT_TYPES.WEIGHT:
					return t("weightUnit")

				case SPECIAL_INPUT_TYPES.SIZE:
					return t("{{No}}")

				case SPECIAL_INPUT_TYPES.CLUB_WEIGHT:
					return "g"

				case SPECIAL_INPUT_TYPES.CLUB_SWING_WEIGHT:
					return "g"

				case SPECIAL_INPUT_TYPES.CLUB_LENGTH:
					return "inch"

				case SPECIAL_INPUT_TYPES.LOFT:
				case SPECIAL_INPUT_TYPES.LIE_ANGLE:
				case SPECIAL_INPUT_TYPES.BOUNCE_ANGLE:
					return "deg"

				case SPECIAL_INPUT_TYPES.FLEX:
					return "flex"

				default:
					return null
			}
		}

		return (
			<AppSelectContext.Provider value={{ boxBorderColor, size }}>
				<FormControl fullWidth={fullWidth}>
					{label && (
						<StyledSelectBoxLabel
							labelBgColor={labelBgColor}
							focused={false}
							shrink={true}>
							{label}
						</StyledSelectBoxLabel>
					)}

					{options?.length === 1 ? (
						<StyledTextField
							size={size}
							inputRef={ref}
							defaultValue={options[0]["label"] ?? options[0]["name"]}
							type={type}
							ownerState={{
								size,
								disabled,
								isEnglish,
								noMaxWidth,
							}}
							InputProps={{
								readOnly: true,
								endAdornment: getUnitForSpecialValue(type, t) && (
									<UnitAdornment>{getUnitForSpecialValue(type, t)}</UnitAdornment>
								),
							}}
						/>
					) : (
						<StyledSelect
							disabled={disabled}
							ref={ref}
							displayEmpty
							defaultValue=""
							IconComponent={({ className }) => {
								return options.length > 1 && !disabled ? (
									<ArrowHeadDownSvg stroke={textColor} className={className} />
								) : (
									<></>
								)
							}}
							renderValue={(value) => {
								const shownValue = value?.label || value

								const formattedShownValue =
									Object.values(SPECIAL_INPUT_TYPES).includes(type) &&
									shownValue !== t(DIRECT_INPUT_VALUE) &&
									shownValue !== t("No information")
										? autoFormatSpecialValue(type, shownValue)
										: shownValue

								if (formattedShownValue) {
									return isString(formattedShownValue) ? (
										<Typography noWrap fontSize="size.md">
											{formattedShownValue}
										</Typography>
									) : (
										formattedShownValue
									)
								} else {
									return placeholder
								}
							}}
							input={
								<OutlinedInput
									endAdornment={
										getUnitForSpecialValue(type, t) &&
										!disabled && (
											<UnitAdornment>
												{getUnitForSpecialValue(type, t)}
											</UnitAdornment>
										)
									}
								/>
							}
							MenuProps={MenuProps(theme, isStickyVariant)}
							ownerState={{
								size,
								noBorder,
								placeholder,
								boxBorderColor,
								value: props.value,
								isStickyVariant,
								disabled,
							}}
							{...props}>
							{children}
						</StyledSelect>
					)}
				</FormControl>
			</AppSelectContext.Provider>
		)
	}
)

export const StyledMenuItem = styled(MenuItem)(
	({
		theme: {
			palette,
			typography,
			spacing,
			transitions,
			functions: { pxToRem },
		},
		ownerState: { size },
	}) => ({
		color: palette.grey[700],
		fontSize: typography.size.md,
		background: "none !important",
		padding: `0 ${size === "small" ? spacing(1) : spacing(2)}`,
		transition: transitions.create("color", {
			easing: transitions.easing.easeIn,
			duration: transitions.duration.shorter,
		}),
		minHeight: `${pxToRem(SELECT_BOX_HEIGHTS_IN_PX[size || "normal"] + 10)} !important`, // menu item height = select box height + 10 - from figma
		paddingTop: pxToRem(10),
		paddingBottom: pxToRem(10),

		"&:hover": {
			color: palette.grey[800],
		},

		"&.Mui-selected": {
			display: "none",
		},
	})
)

function SelectItem({ children, hide = false, ...props }) {
	const { size } = useContext(AppSelectContext)

	return (
		<StyledMenuItem
			ownerState={{ size }}
			sx={{
				display: hide ? "none" : "flex",
				whiteSpace: "break-spaces",
			}}
			{...props}>
			{children}
		</StyledMenuItem>
	)
}

export default AppSelect

AppSelect.MenuItem = SelectItem

AppSelect.defaultProps = {
	variant: "normal",
	size: "normal",
	type: "text",
}

AppSelect.propTypes = shape({
	variant: oneOf(["normal", "sticky"]),
	size: oneOf(["small", "normal", "large"]),
	borderColor: string,
	labelBgColor: string,
	label: string,
	menuProps: object,
	disabled: bool,
	placeholder: string,
	type: string,
})

SelectItem.propTypes = {
	children: node,
}
/*

consumer guide
==============

[1] AppSelect
=============
Description - the superset of Mui Select component
width => auto | height => depends on the variant chosen
variants => normal / sticky
size => small / normal / large
label => string (if empty, no label) (with label, the padding will be reduced to half than normal - e.g 10px if normal padding is 20px)
borderColor => string (if empty, use default borderColor)
placeholder => string (shown when no value is selected)
*** the rest are the same as the Mui Select component

[2] MenuItem
============
Description - the superset of Mui MenuItem
width & height => depends on the AppSelect variant chosen
***value of MenuItem must be in this format {label: string, value: any}
its style is automatically changed based on its wrapper - AppSelect's variant
*** the rest are the same as the Mui MenuItem

USAGE (E.g)
===========
<AppSelect variant="normal" size="small" sx={{ width: "100%" }}>
    <AppSelect.MenuItem value="ABCD 골프 센터">ABCD 골프 센터</AppSelect.MenuItem>
    <AppSelect.MenuItem value="A 골프 센터">A 골프 센터</AppSelect.MenuItem>
    <AppSelect.MenuItem value=" AB 골프 센터">AB 골프 센터</AppSelect.MenuItem>
</AppSelect>

*/

// TODO: this component will be re-implemented as a pure custom component in the cooldown period
// TODO: this component will be decomposed into AppSelect and AppAutocompleteSelect
