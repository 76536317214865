import { FormSnackbar, MainPageContainer } from "components"
import AppSidenav from "components/AppSidenav"
import AppTopnav from "components/AppTopnav"
import TopLoadingBar from "components/TopLoadingBar"
import { useModals } from "components/dialog/showModal"
import Page5XX from "components/error/5XX"
import Page404 from "components/error/404"
import FindCredentials from "layouts/authentication/findCredentials"
import Login from "layouts/authentication/login"
import ResetPassword from "layouts/authentication/resetPassword"
import SignUp from "layouts/authentication/signup"
import RouteGateway from "layouts/common/RouteGateway"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import {
	Navigate,
	Outlet,
	RouterProvider,
	ScrollRestoration,
	createBrowserRouter,
	useLocation,
} from "react-router-dom"
import appRoutes, { findRouteByPath } from "routes"
import { use5xxError, use404Error, useLanguage } from "stores/slice/app"
import { removeCheckedPassword, useUser, useUserStoreRole } from "stores/slice/user"
import {
	ROUTE_ABOUT,
	ROUTE_DEFAULT,
	ROUTE_FIND_CREDENTIALS,
	ROUTE_GATEWAY,
	ROUTE_LOGIN,
	ROUTE_PROFILE,
	ROUTE_RESET_PASSWORD,
	ROUTE_SIGN_UP,
} from "urls"
import "validations/extendValidation"

function getRoutes() {
	const routes = []

	function inner(innerRoutes) {
		for (let { path, lazy, ...route } of innerRoutes) {
			if (Array.isArray(route.children)) {
				inner(route.children)
			}

			if (Array.isArray(route.routes)) {
				inner(route.routes)
			}

			if (!path) {
				continue
			}

			routes.push({ path, lazy })
		}
	}

	inner(appRoutes)

	return routes
}

export default function App() {
	const { i18n } = useTranslation()
	const language = useLanguage()

	useEffect(() => {
		if (language !== i18n.language) {
			i18n.changeLanguage(language)
		}
	}, [])

	const routes = [
		{
			path: ROUTE_GATEWAY,
			element: <RouteGateway />,
		},
		{
			path: ROUTE_SIGN_UP,
			element: (
				<AuthPageWrap isTopnavBordered={true} isSignup={true}>
					<SignUp />
				</AuthPageWrap>
			),
		},
		{
			path: ROUTE_LOGIN,
			element: (
				<AuthPageWrap>
					<Login />
				</AuthPageWrap>
			),
		},
		{
			path: ROUTE_FIND_CREDENTIALS,
			element: (
				<AuthPageWrap isTopnavBordered={true}>
					<FindCredentials />
				</AuthPageWrap>
			),
		},
		{
			path: ROUTE_RESET_PASSWORD,
			element: (
				<AuthPageWrap isTopnavBordered={true}>
					<ResetPassword />
				</AuthPageWrap>
			),
		},
		{
			path: ROUTE_ABOUT,
			lazy: () => import("layouts/about"),
			children: [{ path: `${ROUTE_ABOUT}/:section`, lazy: () => import("layouts/about") }],
		},
		{
			path: "*",
			lazy: () => import("components/error/404"),
		},
		{
			path: "/",
			element: <Root />,
			children: getRoutes(),
		},
	]

	return <RouterProvider router={createBrowserRouter(routes)} />
}

function Root() {
	const user = useUser()
	const dispatch = useDispatch()
	const { pathname } = useLocation()
	const [isSmallSidebar, setIsSmallSidebar] = useState(false)
	const is5xxError = use5xxError()
	const is404Error = use404Error()

	const route = findRouteByPath(pathname)
	const isWithSidebar = user.id !== null && route?.sidenavVisible

	// re-enable password prompt for the edit access if edit pages are navigated from other pages other than the edit pages themselves
	if (!pathname.startsWith(ROUTE_PROFILE) && user.is_password_checked) {
		dispatch(removeCheckedPassword())
	}

	if (is5xxError) {
		return <Page5XX />
	}

	if (is404Error) {
		return <Page404 />
	}

	return (
		<WithModal>
			{!user.id || !user.store_role.store_id ? (
				<Navigate to={ROUTE_LOGIN} />
			) : pathname === "/" ? (
				<Navigate to={ROUTE_DEFAULT} />
			) : (
				<>
					<TopLoadingBar />
					<ScrollRestoration />
					{isWithSidebar && (
						<AppSidenav
							isSmall={isSmallSidebar}
							onSizeToggle={() => setIsSmallSidebar((isOpen) => !isOpen)}
						/>
					)}
					<MainPageContainer
						ownerState={{
							isWithSidebar,
							isSmallSidebar,
						}}>
						<AppTopnav />
						<Outlet />
						<FormSnackbar />
					</MainPageContainer>
					{/* <CustomerCenter />  // For now this is not used */}
				</>
			)}
		</WithModal>
	)
}

function AuthPageWrap({ children, isTopnavBordered, isSignup }) {
	const user = useUser()
	const { store_id } = useUserStoreRole()

	return user.id !== null && store_id && !isSignup ? (
		<Navigate to={ROUTE_DEFAULT} />
	) : (
		<WithModal>
			<ScrollRestoration />
			<AppTopnav isBordered={isTopnavBordered} />
			{children}
			<FormSnackbar />
		</WithModal>
	)
}

function WithModal({ children }) {
	const modals = useModals()

	return (
		<>
			{children}
			{modals}
		</>
	)
}
