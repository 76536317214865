import { Box, useTheme } from "@mui/material"
import { CalendarPerforatedHoverSvg, CalendarPerforatedSvg } from "assets/svgs"
import { CoreDatePicker } from "components/DatePicker"
import { DATE_FORMATS, STRING_DATE_FORMAT } from "constant"
import { parse } from "date-fns"
import { useLocalizedDateUtils } from "hooks"
import {
	ClickableReservationInput,
	StyledDivider,
} from "layouts/reservations/components/ReservationForm/shared"
import { useTranslation } from "react-i18next"
import { holidayApi } from "services/modules"
import { stringToDate } from "utils/dateTime"
import { handleError } from "utils/helpers"

const DateSelectionBox = ({
	setValue,
	activeSelectionBoxName,
	onClick,
	selectedReservationDate,
	isDisabled,
}) => {
	const { t } = useTranslation()
	const { formatDate, formatDateString, formatCalendarDay } = useLocalizedDateUtils()
	const {
		palette: { disabled, info },
	} = useTheme()

	const currentYear = new Date().getFullYear()
	const fieldName = "reservation_date"

	const { data: { data = [] } = {} } = handleError(() =>
		holidayApi.useGetAllHolidayListByYearQuery(currentYear)
	)

	const disabledDateArray = data.map((date) =>
		formatDateString({
			dateString: date,
			formatFrom: STRING_DATE_FORMAT,
			formatTo: "dd/MM/yyyy",
		})
	)

	const yesterdayDate = new Date().setDate(new Date().getDate() - 1)
	const isActiveSelectionBox = activeSelectionBoxName === fieldName

	const handleOnDayClick = (date) => {
		setValue(fieldName, formatDate(date, DATE_FORMATS.BACKEND_RESERVATION_DF))
	}

	return (
		<Box>
			<ClickableReservationInput
				isDisabled={isDisabled}
				label={t("Reservation date")}
				value={
					selectedReservationDate &&
					formatCalendarDay(
						parse(
							selectedReservationDate,
							DATE_FORMATS.BACKEND_RESERVATION_DF,
							new Date()
						)
					)
				}
				onClick={() => onClick(fieldName)}
				Icon={isActiveSelectionBox ? CalendarPerforatedHoverSvg : CalendarPerforatedSvg}
				iconProps={{ fill: isDisabled ? disabled.main : info.main }}
			/>

			{isActiveSelectionBox && (
				<Box sx={{ display: "flex", justifyContent: "center" }}>
					<CoreDatePicker
						selectedDate={
							selectedReservationDate &&
							stringToDate({
								dateString: selectedReservationDate,
								formatFrom: DATE_FORMATS.BACKEND_RESERVATION_DF,
							})
						}
						onDayClick={handleOnDayClick}
						border={false}
						availableStartDate={yesterdayDate}
						disabledDates={disabledDateArray}
					/>

					<StyledDivider />
				</Box>
			)}
		</Box>
	)
}

export default DateSelectionBox
