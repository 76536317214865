import { Icon, IconButton } from "@mui/material"
import { ICON_NAMES } from "constant"
import PropTypes from "prop-types"
import React from "react"

function AppIconButton({ color, onClick, iconName, ...props }) {
	return (
		<IconButton color={color} onClick={onClick} {...props}>
			<Icon>{iconName}</Icon>
		</IconButton>
	)
}

AppIconButton.defaultProps = {
	color: "info",
	iconName: "",
	onClick: () => {},
}

AppIconButton.propTypes = {
	color: PropTypes.string,
	iconName: PropTypes.oneOf(Object.values(ICON_NAMES)),
	onClick: PropTypes.func,
}

export default AppIconButton
