import { Box } from "@mui/material"
import { useCalendar, useTopbarLoading } from "hooks"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { reservationApi } from "services/modules"
import { formatV1 } from "utils/dateTime"

import { FilterView, MonthCalendarView, ReservationsListCard } from "./CalenderComponents"
import MonthSelector from "./MonthSelector"

const MonthView = ({ calendarViewProps }) => {
	const { t } = useTranslation()

	const {
		language,
		palette,
		typography,
		reservationOptions,
		filters,
		setFilters,
		setResponse,
		reset,
		handleDataBox,
		plusButton,
		holidays,
	} = calendarViewProps
	const [selectedDay, setSelectedDay] = useState({ date: new Date() })

	const {
		monthName,
		year,
		weekDays,
		days,
		month,
		nextMonth,
		previousMonth,
		thisMonth,
		thisYear,
		zeroPad,
	} = useCalendar()

	const [monthlyReservation, { data: monthlyReservationListRes, isFetching }] =
		reservationApi.useLazyMonthlyReservationListQuery()

	useTopbarLoading(isFetching)

	useEffect(() => {
		monthlyReservation({
			...reservationOptions,
			date: `${year}${zeroPad(month + 1)}`,
			status: filters.join(","),
		})
	}, [filters, month, year, reservationOptions.app_user_id, reservationOptions.q])

	useEffect(() => {
		setResponse(monthlyReservationListRes)
	}, [monthlyReservationListRes?.data])

	const handleToday = () => {
		const todayObject = days.find((dayObj) => dayObj.formatted === formatV1(new Date()))

		thisYear()
		thisMonth()
		setSelectedDay(todayObject || { date: new Date() })
	}

	days.map((day) => {
		day.reservations = []
		monthlyReservationListRes?.data?.map((reservation) => {
			if (day.formatted === reservation.reservation_date) {
				day.reservations.push(reservation)
			}
		})
		return day
	})

	holidays.forEach((holiday) => {
		const index = days.findIndex(({ formatted }) => formatted === holiday.date)

		if (index !== -1) {
			days[index].holiday = holiday
		}
	})

	useEffect(() => {
		const todayObject = days.find((dayObj) => dayObj.formatted === selectedDay.formatted)

		setSelectedDay(todayObject || { date: new Date() })
	}, [monthlyReservationListRes?.data])

	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "flex-end",
					"@media (max-width: 1443px)": {
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						gap: "5px",
					},
				}}>
				<MonthSelector
					monthName={monthName}
					year={year}
					nextMonth={nextMonth}
					previousMonth={previousMonth}
				/>
				<Box
					sx={{
						"@media (max-width: 1400px)": {
							marginTop: "15px",
						},
					}}
				/>
				<FilterView
					handleToday={handleToday}
					filters={filters}
					setFilters={setFilters}
					reset={reset}
				/>
			</Box>
			<Box
				marginTop="20px"
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					gap: "20px",
					overflowX: "auto",
					scrollBehavior: "smooth",
					// "@media (max-width: 1400px)": {
					// 	flexDirection: "column",
					// 	alignItems: "center",
					// 	justifyContent: "center",
					// },
				}}>
				<MonthCalendarView
					selectedDay={selectedDay}
					setSelectedDay={setSelectedDay}
					weekDays={weekDays}
					days={days}
					month={month}
					palette={palette}
					typography={typography}
					t={t}
					language={language}
					isLoading={isFetching}
					handleDataBox={handleDataBox}
				/>
				<ReservationsListCard
					palette={palette}
					typography={typography}
					selectedDay={selectedDay}
					language={language}
					plusButton={plusButton}
					handleDataBox={handleDataBox}
				/>
			</Box>
		</Box>
	)
}

export default MonthView
