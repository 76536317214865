import { Drawer, styled } from "@mui/material"
import { SIDEBAR_WIDTHS_IN_PX } from "constant"

export const StyledSidenavRoot = styled(Drawer)(({ theme, ownerState }) => {
	const {
		transitions,
		functions: { pxToRem },
		palette,
	} = theme

	const { isSmall } = ownerState

	const SMALL_SIDEBAR_WIDTH_IN_REM = pxToRem(SIDEBAR_WIDTHS_IN_PX.SMALL)
	const NORMAL_SIDEBAR_WIDTH_IN_REM = pxToRem(SIDEBAR_WIDTHS_IN_PX.NORMAL)

	return {
		position: "absolute", // for main content width animation ( *visual layout structure => Sidenav(left) | MainPageContent[contains page contents](right) )
		left: 0,
		right: 0,
		height: "100vh",
		backgroundColor: palette.white.main,
		width: isSmall ? SMALL_SIDEBAR_WIDTH_IN_REM : NORMAL_SIDEBAR_WIDTH_IN_REM,
		transition: transitions.create("width", {
			easing: transitions.easing.easeOut,
			duration: transitions.duration.enteringScreen,
		}),

		"& .MuiPaper-root": {
			position: "static",
			border: "none",
		},
	}
})
