import { AppTabs, BasicLayout, FormSheet } from "components"
import WithFooter from "components/WithFooter"
import { FIND_CREDENTIALS_TYPES } from "constant"
import FindIdForm from "layouts/authentication/findCredentials/FindIdForm"
import FindPasswordForm from "layouts/authentication/findCredentials/FindPasswordForm"
import React, { useState } from "react"
import { useLocation } from "react-router-dom"

const tabs = [
	{ id: FIND_CREDENTIALS_TYPES.FIND_ID, name: "Find ID" },
	{ id: FIND_CREDENTIALS_TYPES.FIND_PASSWORD, name: "Find password" },
]

const FindCredentials = () => {
	const type = useLocation().state?.type || FIND_CREDENTIALS_TYPES.FIND_ID

	const [activeTab, setActiveTab] = useState(tabs.find((tabItem) => tabItem.id === type))

	return (
		<WithFooter>
			<BasicLayout height="auto">
				<FormSheet>
					<AppTabs activeTab={activeTab} setActiveTab={setActiveTab} tabItems={tabs} />
					{activeTab.id === FIND_CREDENTIALS_TYPES.FIND_ID ? (
						<FindIdForm />
					) : (
						<FindPasswordForm />
					)}
				</FormSheet>
			</BasicLayout>
		</WithFooter>
	)
}

export default FindCredentials
