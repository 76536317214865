import { Box } from "@mui/material"
import { PeopleHoverSvg, PeopleSvg } from "assets/svgs"
import {
	ClickableReservationInput,
	GridItemValue,
	SelectionBoxNoData,
	SelectionBoxPlaceholder,
	StyledDivider,
	StyledGridContainer,
	StyledGridItem,
} from "layouts/reservations/components/ReservationForm/shared"
import { useTranslation } from "react-i18next"
import { reservationApi } from "services/modules"
import { isEmptyArray } from "utils/array"

const FitterSelectionBox = ({
	setValue,
	activeSelectionBoxName,
	onClick,
	selectedFitterID,
	selectedFitterNameState,
	isDisabled,
	totalDuration = null,
	selectedReservationDate = null,
	selectedStartTime = null,
	currentFitter,
}) => {
	const { t } = useTranslation()

	const fieldName = "app_user_id"
	const isActiveSelectionBox = activeSelectionBoxName === fieldName

	const [selectedFitterName, setSelectedFitterName] = selectedFitterNameState

	const params = {
		available_time: totalDuration,
		reservation_date: selectedReservationDate,
		start_time: selectedStartTime,
	}

	const {
		data: { data: fitters } = { data: [] },
		isLoading,
		isSuccess,
	} = reservationApi.useGetSelectableFittersQuery(params, {
		skip: Object.values(params).some((param) => param === undefined || param === null),
	})

	const setSelectedFitterID = (fitter) => {
		setValue(fieldName, fitter.id)
		setSelectedFitterName(fitter.name)
	}

	const withCurrentFitter = (fitters) => {
		if (!currentFitter || fitters.find((fitter) => fitter.id === currentFitter.id)) {
			return fitters
		}

		return [currentFitter, ...fitters]
	}
	const selectableFitters = withCurrentFitter(fitters)

	return (
		<Box>
			<ClickableReservationInput
				label={t("Fitter")}
				value={selectedFitterID && selectedFitterName}
				onClick={() => onClick(fieldName)}
				Icon={isActiveSelectionBox ? PeopleHoverSvg : PeopleSvg}
				isDisabled={isDisabled}
			/>

			{isActiveSelectionBox &&
				(isLoading ? (
					<SelectionBoxPlaceholder />
				) : isSuccess && !isEmptyArray(selectableFitters) ? (
					<Box mt={2}>
						<StyledGridContainer>
							{selectableFitters.map((fitter) => (
								<StyledGridItem
									key={fitter.id}
									ownerProps={{
										isSelected: fitter.id === selectedFitterID,
									}}
									onClick={() => setSelectedFitterID(fitter)}>
									<GridItemValue>{fitter.name}</GridItemValue>
								</StyledGridItem>
							))}
						</StyledGridContainer>

						<StyledDivider mt={2} />
					</Box>
				) : (
					<SelectionBoxNoData />
				))}
		</Box>
	)
}

export default FitterSelectionBox
