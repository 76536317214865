import colors from "assets/theme/base/palette"
import pxToRem from "assets/theme/functions/pxToRem"
import { STANDARD_FONT_SIZE } from "constant"

const { dark, grey } = colors

const baseProperties = {
	fontFamily: '"GmarketSans", "Roboto", "Helvetica", "Arial", sans-serif',
	fontWeightLighter: 100,
	fontWeightLight: 300,
	fontWeightRegular: 500,
	fontWeightMedium: 600,
	fontWeightBold: 700,
	fontSizeXXS: pxToRem(STANDARD_FONT_SIZE - 6),
	fontSizeXS: pxToRem(STANDARD_FONT_SIZE - 4),
	fontSizeSM: pxToRem(STANDARD_FONT_SIZE - 2),
	fontSizeMD: pxToRem(STANDARD_FONT_SIZE),
	fontSizeLG: pxToRem(STANDARD_FONT_SIZE + 2),
	fontSizeXL: pxToRem(STANDARD_FONT_SIZE + 4),
	fontSize2XL: pxToRem(STANDARD_FONT_SIZE + 6),
	fontSize3XL: pxToRem(STANDARD_FONT_SIZE + 8),
	fontSize4XL: pxToRem(STANDARD_FONT_SIZE + 10),
}

const baseHeadingProperties = {
	fontFamily: baseProperties.fontFamily,
	color: dark.main,
	fontWeight: baseProperties.fontWeightBold,
}

const typography = {
	fontFamily: baseProperties.fontFamily,
	fontWeightLighter: baseProperties.fontWeightLighter,
	fontWeightLight: baseProperties.fontWeightLight,
	fontWeightRegular: baseProperties.fontWeightRegular,
	fontWeightMedium: baseProperties.fontWeightMedium,
	fontWeightBold: baseProperties.fontWeightBold,

	h1: {
		fontSize: pxToRem(48),
		lineHeight: 1.25,
		...baseHeadingProperties,
	},

	h2: {
		fontSize: pxToRem(36),
		lineHeight: 1.3,
		...baseHeadingProperties,
	},

	h3: {
		fontSize: baseProperties.fontSize4XL,
		lineHeight: 1.375,
		...baseHeadingProperties,
	},

	h4: {
		fontSize: baseProperties.fontSize3XL,
		lineHeight: 1.375,
		...baseHeadingProperties,
	},

	h5: {
		fontSize: baseProperties.fontSizeXL,
		lineHeight: 1.375,
		...baseHeadingProperties,
	},

	h6: {
		fontSize: baseProperties.fontSizeMD,
		lineHeight: 1.625,
		...baseHeadingProperties,
	},

	subtitle1: {
		fontFamily: baseProperties.fontFamily,
		fontSize: baseProperties.fontSizeXL,
		fontWeight: baseProperties.fontWeightLight,
		lineHeight: 1.625,
	},

	subtitle2: {
		fontFamily: baseProperties.fontFamily,
		fontSize: baseProperties.fontSizeMD,
		fontWeight: baseProperties.fontWeightLight,
		lineHeight: 1.6,
	},

	body1: {
		fontFamily: baseProperties.fontFamily,
		fontSize: baseProperties.fontSizeXL,
		fontWeight: baseProperties.fontWeightRegular,
		lineHeight: 1.625,
	},

	body2: {
		fontFamily: baseProperties.fontFamily,
		fontSize: baseProperties.fontSizeMD,
		fontWeight: baseProperties.fontWeightRegular,
		lineHeight: 1.6,
	},

	body3: {
		fontFamily: baseProperties.fontFamily,
		fontSize: baseProperties.fontSizeSM,
		fontWeight: baseProperties.fontWeightRegular,
		lineHeight: 1.5,
	},

	button: {
		fontFamily: baseProperties.fontFamily,
		fontSize: baseProperties.fontSizeSM,
		fontWeight: baseProperties.fontWeightLight,
		lineHeight: 1.5,
		textTransform: "uppercase",
	},

	caption: {
		fontFamily: baseProperties.fontFamily,
		fontSize: baseProperties.fontSizeXS,
		fontWeight: baseProperties.fontWeightLight,
		lineHeight: 1.25,
	},

	validationMessage: {
		fontFamily: baseProperties.fontFamily,
		fontSize: baseProperties.fontSizeXS,
		fontWeight: baseProperties.fontWeightRegular,
	},

	notificationMessage: {
		fontFamily: baseProperties.fontFamily,
		fontSize: baseProperties.fontSizeLG,
		fontWeight: baseProperties.fontWeightRegular,
	},

	overline: {
		fontFamily: baseProperties.fontFamily,
	},

	formDialogTitle: {
		fontFamily: baseProperties.fontFamily,
		fontSize: pxToRem(24),
		fontWeight: baseProperties.fontWeightBold,
	},

	guideText: {
		fontFamily: baseProperties.fontFamily,
		fontSize: baseProperties.fontSizeXS,
		fontWeight: baseProperties.fontWeightRegular,
		color: grey[700],
	},

	size: {
		xxs: baseProperties.fontSizeXXS,
		xs: baseProperties.fontSizeXS,
		sm: baseProperties.fontSizeSM,
		md: baseProperties.fontSizeMD,
		lg: baseProperties.fontSizeLG,
		xl: baseProperties.fontSizeXL,
		"2xl": baseProperties.fontSize2XL,
		"3xl": baseProperties.fontSize3XL,
		"4xl": baseProperties.fontSize4XL,
	},

	lineHeight: {
		sm: 1.25,
		md: 1.5,
		lg: 2,
	},
}

export default typography
