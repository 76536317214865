import { IconButton } from "@mui/material"
import Badge from "@mui/material/Badge"
import PropTypes from "prop-types"

const NotificationIcon = ({
	children,
	color,
	textColor,
	count,
	onClickHandler,
	badgeVariant,
	...rest
}) => {
	return (
		<IconButton onClick={onClickHandler} {...rest}>
			<Badge
				variant={badgeVariant}
				badgeContent={count}
				color={color}
				sx={{
					"& .MuiBadge-badge": {
						color: textColor,
					},
				}}>
				{children}
			</Badge>
		</IconButton>
	)
}

NotificationIcon.defaultProps = {
	color: "warning",
	textColor: "background.paper",
	count: 0,
}

NotificationIcon.propTypes = {
	children: PropTypes.node.isRequired,
	color: PropTypes.string,
	textColor: PropTypes.string,
	count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default NotificationIcon

// consumer guide
// ==============
// to use the AppNotiIconBox component, only need to pass FOUR props(children, color, textColor, count)

// Prop Explain
//=============
// children - You have to pass icon or react component (REQUIRED)
// color - This will change the background of noti number (DEFAULT VALUE: "warning") and you can pass any string color from palette
// textColor - This will change the number text (DEFAULT VALUE: "background.paper") and you can pass any string color from palette
// count - This will be the number of notifications (Default: 0) and you can pass number or string

// P.S - children prop will be needed to pass
//     - count is not necessarily passed but the purpose of this component is to show count
//     - the other props don't need to be passed (they all have default value and pass the prop if you want to change the colors)
