import { createSlice } from "@reduxjs/toolkit"
import { useSelector } from "react-redux"
import { STORE_SLICE } from "stores/slicekeys"

const initialState = {
	id: null,
	name: null,
	profile_picture: null,
	address: null,
	address_detail: null,
	phone_number: null,
	installation_date: null,
	passcode: null,
	device_count: null,
	current_installed_devices: null,
	devices: null,
	analyzer: null,
	storeMembers: null,
	app_user_ids: null,
}

const storeSlice = createSlice({
	name: STORE_SLICE,
	initialState,
	reducers: {
		updateStore(state, action) {
			return { ...state, ...action.payload }
		},
		resetStore() {
			return initialState
		},
	},
})

export const { updateStore, resetStore } = storeSlice.actions
export default storeSlice.reducer

export const useStore = () => {
	const store = useSelector((state) => state[STORE_SLICE])
	return store
}
