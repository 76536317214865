import { Box } from "@mui/material"
import Menu from "@mui/material/Menu"
import { ICON_NAMES } from "constant"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { removeCheckedPassword, useUser } from "stores/slice/user"
import {
	ROUTE_LOGIN,
	ROUTE_PROFILE_RECONFIRM_PASSWORD,
	ROUTE_STAFF_MANAGEMENT,
	ROUTE_STORES_MANAGEMENT,
} from "urls"

import NavbarLink from "./NavbarLink"

function DefaultNavbarMobile({ open, close, handleLogout }) {
	const dispatch = useDispatch()
	const { width } = open && open.getBoundingClientRect()
	const user = useUser()
	const { t } = useTranslation()

	const handleEditProfileClick = () => {
		dispatch(removeCheckedPassword())
	}

	return (
		<Menu
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
			anchorEl={open}
			open={Boolean(open)}
			onClose={close}
			MenuListProps={{ style: { width: `calc(${width}px - 4rem)` } }}>
			<Box px={0.5}>
				{user.id === null ? (
					<NavbarLink icon={ICON_NAMES.KEY} name={t("Login")} route={ROUTE_LOGIN} />
				) : (
					<>
						<NavbarLink
							icon={ICON_NAMES.EDIT}
							name={t("Personal information ")}
							route={ROUTE_PROFILE_RECONFIRM_PASSWORD}
							onClick={handleEditProfileClick}
						/>
						<NavbarLink
							icon={ICON_NAMES.STORE}
							name={t("Store information")}
							route={ROUTE_STORES_MANAGEMENT}
						/>
						<NavbarLink
							icon={ICON_NAMES.GROUP}
							name={t("Staff information")}
							route={ROUTE_STAFF_MANAGEMENT}
						/>
						<NavbarLink
							icon={ICON_NAMES.LOGOUT}
							name={t("Logout")}
							// route is removed to avoid multiple routing since calling logout triggers redirect
							onClick={handleLogout}
						/>
					</>
				)}
			</Box>
		</Menu>
	)
}

// Typechecking props for the DefaultNavbarMenu
DefaultNavbarMobile.propTypes = {
	open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
	close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object]).isRequired,
}

export default DefaultNavbarMobile
