import { Box, LinearProgress, styled, useTheme } from "@mui/material"
import pxToRem from "assets/theme/functions/pxToRem"
import { useEffect, useState } from "react"
import { useTopbarLoadingSelector } from "stores/slice/app"

const StyledTopLoadingBar = styled(Box)(() => ({
	width: "100vw",
	height: pxToRem(8),
	position: "fixed",
	top: 0,
	zIndex: 9999,
}))

function TopLoadingBar() {
	const {
		palette: { grey },
	} = useTheme()
	const [percentage, setPercentage] = useState(0)
	const loading = useTopbarLoadingSelector()
	const [show, setShow] = useState(false)

	useEffect(() => {
		const interval = setInterval(() => {
			setPercentage((prev) => {
				let incremental = 0.02
				if (prev < 100) return prev + incremental
				return prev
			})
		}, 0)
		if (loading) {
			setShow(true)
		}
		if (!loading && (percentage > 0 || percentage === 0)) {
			setPercentage(100)
			setTimeout(() => {
				setShow(false)
				setPercentage(0)
				clearInterval(interval)
			}, 500)
		}
		return () => {
			setPercentage(0)
			clearInterval(interval)
		}
	}, [loading])

	if (!show) return

	return (
		<StyledTopLoadingBar>
			<LinearProgress
				variant="determinate"
				value={percentage}
				sx={{ height: "100%", boxShadow: "none", backgroundColor: grey[300] }}
			/>
		</StyledTopLoadingBar>
	)
}

export default TopLoadingBar
