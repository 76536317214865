import { api } from "services/api"
import { API_HOLIDAY, API_HOLIDAY_BY_YEAR, API_HOLIDAY_FOR_CALENDAR } from "services/endpoints"
import { TAG_HOLIDAY, TAG_HOLIDAY_BY_YEAR, TAG_HOLIDAY_FOR_CALENDAR_YEAR } from "services/tags"

const holidayApi = api.injectEndpoints({
	endpoints(build) {
		return {
			getHolidayList: build.query({
				query(params) {
					return {
						url: API_HOLIDAY,
						params,
					}
				},

				providesTags: [TAG_HOLIDAY],
			}),

			getHolidayListByCalendarYear: build.query({
				query(year) {
					return {
						url: `${API_HOLIDAY_FOR_CALENDAR}/${year}`,
					}
				},

				transformResponse: ({ data }) => data,

				providesTags: [TAG_HOLIDAY_FOR_CALENDAR_YEAR],
			}),

			getAllHolidayListByYear: build.query({
				query(year) {
					return {
						method: "GET",
						url: `${API_HOLIDAY_BY_YEAR}/${year}`,
					}
				},

				providesTags: [TAG_HOLIDAY_BY_YEAR],
			}),

			createHoliday: build.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: API_HOLIDAY,
					}
				},

				invalidatesTags: (ok) =>
					ok ? [TAG_HOLIDAY, TAG_HOLIDAY_BY_YEAR, TAG_HOLIDAY_FOR_CALENDAR_YEAR] : [],
			}),

			deleteHoliday: build.mutation({
				query(id) {
					return {
						method: "DELETE",
						url: `${API_HOLIDAY}/${id}`,
					}
				},

				invalidatesTags: (ok) =>
					ok ? [TAG_HOLIDAY, TAG_HOLIDAY_BY_YEAR, TAG_HOLIDAY_FOR_CALENDAR_YEAR] : [],
			}),
		}
	},
})

export default holidayApi
