import { Typography } from "@mui/material"
import PropTypes from "prop-types"

const ErrorMessage = ({ message, ...rest }) => {
	return message ? (
		<Typography mt={1} variant="validationMessage" color="error" {...rest} display="block">
			{message}
		</Typography>
	) : null
}

ErrorMessage.propTypes = {
	message: PropTypes.string.isRequired,
}

export default ErrorMessage
