import { Box, Popover, Typography, useTheme } from "@mui/material"
import { BellSvg, LightBulbSvg } from "assets/svgs"
import { NotificationIcon } from "components"
import useAnchorElement from "hooks/useAnchorElement"
import { useTranslation } from "react-i18next"

const MOCK_NOTI = []
// const MOCK_NOTI = [
// 	"Here’s an announcement.Here’s an announcement.Here’s an announcement.Here’s an announcement.",
// 	"Here’s an announcement.Here’s an announcement.Here’s an announcement.Here’s an announcement.",
// ]

const NotiCard = ({ para, label, time }) => {
	const { t } = useTranslation()

	return (
		<Box
			sx={{
				width: 280,
				p: 2,
				bgcolor: "dashboard.main",
				borderRadius: "8px",
				mb: 1,
			}}>
			<Box display="flex" justifyContent="space-between" alignItems="center" mb={0.5}>
				<Box display="flex" alignItems="center">
					<LightBulbSvg />
					<Typography fontSize="size.sm" ml={0.5}>
						{t(label)}
					</Typography>
				</Box>
				<Typography fontSize="size.xs" color="info.main">
					{time}
				</Typography>
			</Box>
			<Typography fontSize="size.sm" fontWeight="light">
				{t(para)}
			</Typography>
		</Box>
	)
}

function Notification() {
	const { t } = useTranslation()
	const { palette } = useTheme()
	const { setAnchorEl, ...anchor } = useAnchorElement()

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	return (
		<Box>
			<NotificationIcon
				aria-describedby={anchor.id}
				onClickHandler={handleClick}
				badgeVariant="dot"
				count={0}
				sx={({ palette }) => ({
					"& .MuiBadge-dot": { top: 5, left: 9, right: 8, border: 1 },
					color: palette.grey[700],
					"&:hover": {
						color: palette.primary.main,
					},
				})}>
				<BellSvg fill={anchor.anchorEl ? palette.primary.main : "currentcolor"} />
			</NotificationIcon>
			<Popover
				onClose={handleClose}
				transformOrigin={{
					horizontal: "right",
					vertical: "top",
				}}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				sx={{
					"& .MuiPaper-root": {
						boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
					},
				}}
				{...anchor}>
				<Box sx={{ px: 2, pt: 3, pb: 2, width: 320 }}>
					<Typography fontSize="size.md" fontWeight="bold" pl={1} mb={3}>
						{t("VICX Admin notification")}
					</Typography>
					{MOCK_NOTI.length > 0 ? (
						MOCK_NOTI.map((item, index) => (
							<NotiCard
								key={index}
								label="Announcement"
								time={`5 ${t("hours ago")}`}
								para={item}
							/>
						))
					) : (
						<Typography
							fontSize="size.sm"
							color="info.main"
							mt={1}
							mb={2}
							textAlign="center">
							{t("No new notification.")}
						</Typography>
					)}
					{MOCK_NOTI.length > 0 && (
						<Typography fontSize="size.sm" textAlign="center" color="info.main" mt={2}>
							{t("Notification history will be deleted within 90 days.")}
						</Typography>
					)}
				</Box>
			</Popover>
		</Box>
	)
}

export default Notification
