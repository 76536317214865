import { InputAdornment } from "@mui/material"
import React from "react"

const UnitAdornment = ({ children }) => {
	return (
		<InputAdornment position="end">
			<span style={{ position: "absolute", right: "45px" }}>{children}</span>
		</InputAdornment>
	)
}

export default UnitAdornment
