import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import React from "react"

const FormLoading = ({ color = "#00369F", sx }) => {
	return (
		<Backdrop sx={{ color, zIndex: (theme) => theme.zIndex.drawer + 1, ...sx }} open>
			<CircularProgress color="inherit" />
		</Backdrop>
	)
}

export default FormLoading
