import colors from "assets/theme/base/palette"

const { transparent } = colors

export default {
	styleOverrides: {
		root: {
			backgroundColor: transparent.main,
		},
	},
}
