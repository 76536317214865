import { useEffect, useState } from "react"
import { formatNumberWithLeadingZeros } from "utils/string"

const useCountdown = (initialMinutes = 0, initialSeconds = 0, onDone = new Function()) => {
	const [remainingMinutes, setRemainingMinutes] = useState(initialMinutes)
	const [remainingSeconds, setRemainingSeconds] = useState(initialSeconds)
	const [isRunning, setIsRunning] = useState(false)

	useEffect(() => {
		let interval

		if (isRunning) {
			interval = setInterval(() => {
				if (remainingSeconds > 0) {
					setRemainingSeconds((remainingSeconds) => remainingSeconds - 1)
				} else {
					if (remainingMinutes > 0) {
						setRemainingSeconds(59)
						setRemainingMinutes((remainingMinutes) => remainingMinutes - 1)
					} else {
						onDone()
						setIsRunning(false)
						clearInterval(interval)
					}
				}
			}, 1000)
		}

		return () => {
			clearInterval(interval)
		}
	}, [isRunning, remainingMinutes, remainingSeconds])

	const startCountDown = () => setIsRunning(true)

	const pauseCountDown = () => setIsRunning(false)

	const resetCountDown = () => {
		setIsRunning(false)
		setRemainingMinutes(initialMinutes)
		setRemainingSeconds(initialSeconds)
	}

	const restartCountDown = () => {
		resetCountDown()
		startCountDown()
	}

	return {
		startCountDown,
		pauseCountDown,
		resetCountDown,
		restartCountDown,
		isRunning,
		remainingMinutes,
		remainingSeconds: formatNumberWithLeadingZeros(remainingSeconds, 2),
	}
}

export default useCountdown
