import { api } from "services/api"
import { API_VICX_USER } from "services/endpoints"
import { TAG_VICX_USER } from "services/tags"

const vicxUserApi = api.injectEndpoints({
	endpoints(builder) {
		return {
			getVicxUser: builder.query({
				query() {
					return {
						url: API_VICX_USER,
					}
				},
				providesTags: [TAG_VICX_USER],
			}),

			putVicxUser: builder.mutation({
				query(data) {
					const { vicxUserId, password } = data
					return {
						data: { password },
						method: "PUT",
						url: `${API_VICX_USER}/${vicxUserId}`,
					}
				},
				invalidatesTags: (ok) => (ok ? [TAG_VICX_USER] : []),
			}),
		}
	},
})

export const { useGetVicxUserQuery, usePutVicxUserMutation } = vicxUserApi

export default vicxUserApi
