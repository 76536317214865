import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Grid, Typography, useTheme } from "@mui/material"
import pxToRem from "assets/theme/functions/pxToRem"
import { AutoLoadingButton, ControlledAppTextField, ErrorMessage } from "components"
import { INCORRECT_CREDENTIALS_STATUSES } from "constant"
import { useCountDown } from "hooks"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { appUserApi } from "services/modules"
import { useSendNewUserOTPMutation, useVerifyOTPMutation } from "services/modules/auth"
import { openSnackbar } from "stores/slice/snackbar"
import { ROUTE_LOGIN } from "urls"
import { convertMsToMinutesAndSeconds } from "utils/dateTime"
import { handleApiErrors } from "utils/helpers"
import { formatPhoneNumber } from "utils/string"
import signupSchema from "validations/signup"

import { ConfirmPage, InputBox, PageTitle } from "./layout"
import ValidatePasswordForm from "./password"
import Terms from "./terms"

const OTP_LIFESPAN_IN_MS = 180000 // 3mins
const { minutes: initialCountDownMinutes, seconds: initialCountDownSeconds } =
	convertMsToMinutesAndSeconds(OTP_LIFESPAN_IN_MS)

function SignupForm({ data: { name, email, app_user_id, store_id, store_name } }) {
	const [isOtpSentForOnce, setIsOtpSentForOnce] = useState(false)
	const [isOtpExpired, setIsOtpExpired] = useState(false)
	const [isOtpVerified, setIsOtpVerified] = useState(false)
	const [signupComplete, setSignupComplete] = useState(false)
	const [isOtpOpen, setIsOtpOpen] = useState(false)

	const { t, i18n } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const {
		palette: { grey, warning },
		typography,
	} = useTheme()
	const {
		control,
		handleSubmit,
		formState,
		setValue,
		setFocus,
		setError,
		watch,
		trigger,
		getValues,
	} = useForm({
		defaultValues: {
			personalInfo: { name, email },
		},
		resolver: yupResolver(signupSchema(t)),
		mode: "all",
	})
	const errors = formState.errors
	const [watchPhoneNumber, watchPassword, watchPasswordCheck] = watch([
		"personalInfo.phone_number",
		"personalInfo.password",
		"personalInfo.passwordCheck",
	])
	const [sendOTP] = useSendNewUserOTPMutation()
	const [submitUser] = appUserApi.useUpdateAppUserMutation()
	const [verifyOTP] = useVerifyOTPMutation()

	const clearPhoneNumber = () => {
		setValue("personalInfo.phone_number", "")
		isOtpVerified && setIsOtpVerified(false)
	}

	const clearOtpError = () => {
		setError("code", null)
		setError("serverError", null)
	}

	// to acknowledge otp expiration
	const handleCountDownDone = () => {
		setIsOtpExpired(true)
		setError("code", {
			type: "expired",
			message: t(
				"The security code validity has expired. Please request new verification code."
			),
		})
		setFocus("code")
		setValue("code", "")
	}

	// otp validity count down
	const { restartCountDown, isRunning, remainingMinutes, remainingSeconds, resetCountDown } =
		useCountDown(initialCountDownMinutes, initialCountDownSeconds, handleCountDownDone)

	const handleSendAuthenticationPress = async () => {
		try {
			const result = await trigger("personalInfo")

			if (result) {
				const { name, phone_number } = getValues("personalInfo")
				const parsedPhoneNumber = phone_number.replace(/-/g, "")

				setIsOtpVerified(false)
				clearOtpError()

				const { otp } = await sendOTP({
					name,
					phone_number: parsedPhoneNumber,
				}).unwrap()

				!isOtpSentForOnce && setIsOtpSentForOnce(true)
				restartCountDown()
				setIsOtpExpired(false)

				// otp-auto-fill to ease development
				otp &&
					setValue("code", otp, {
						shouldValidate: true,
						shouldDirty: true,
						shouldTouch: true,
					})

				dispatch(
					openSnackbar({
						severity: "success",
						message: t(
							"A verification code has been sent to your mobile phone number. Please enter your verification code within 3 minutes."
						),
					})
				)

				setIsOtpOpen(true)
			}
		} catch (err) {
			const errorMessage = err?.response?.data?.message
			handleApiErrors(err, dispatch, setError, errorMessage)
			setError("personalInfo.phone_number", errorMessage)
		}
	}

	const handleVerifyCode = async () => {
		try {
			const {
				code,
				personalInfo: { phone_number },
			} = getValues()
			const parsedPhoneNumber = phone_number.replace(/-/g, "")

			const { verified } = await verifyOTP({
				phone_number: parsedPhoneNumber,
				code,
			}).unwrap()

			if (verified) {
				setIsOtpVerified(true)
				setIsOtpOpen(false)
				resetCountDown()

				dispatch(
					openSnackbar({
						severity: "success",
						message: t("Your phone number is verified."),
					})
				)
			}
		} catch (err) {
			if (INCORRECT_CREDENTIALS_STATUSES.includes(err.response?.status)) {
				setError("serverError", {
					type: "serverError",
					message: t("The verification code is incorrect. Please double-check."),
				})
			} else {
				setError("serverError", {
					type: "serverError",
					message: err?.response?.data?.message,
				})
			}
		}
	}

	const onSubmitHandler = async (data) => {
		const { email, password, phone_number } = data.personalInfo
		try {
			const parsedPhoneNumber = phone_number.replace(/-/g, "")
			const { is_success } = await submitUser({
				id: app_user_id,
				password,
				phone_number: parsedPhoneNumber,
				store_id,
				...data.terms,
			}).unwrap()

			if (is_success) {
				setSignupComplete(true)
				dispatch(
					openSnackbar({
						severity: "success",
						message: t("Your email is", { email }),
					})
				)
			}
		} catch (err) {
			handleApiErrors(err, dispatch, setError)
		}
	}

	const onClickSubmit = () => {
		const terms = getValues("terms")
		if (terms?.terms_of_use && terms?.privacy_policy) {
			handleSubmit(onSubmitHandler)()
		} else {
			dispatch(
				openSnackbar({
					severity: "error",
					message: t("Please accept the required terms."),
				})
			)
		}
	}

	const areFieldsPassed = () => {
		const isPersonalInfoValid = !errors?.personalInfo
		return watchPhoneNumber && watchPassword && watchPasswordCheck && isPersonalInfoValid
	}

	const getConfirmMessage = () => {
		return i18n.language === "en"
			? `Your store participation is complete.\nLogin and explore the ${store_name} admin`
			: t(
					"Your store participation is complete.\nLogin and explore the ABCD Golf Center admin.",
					{ value: store_name }
				)
	}

	return (
		<>
			{signupComplete ? (
				<ConfirmPage
					title={t("Welcome")}
					content={getConfirmMessage()}
					buttonName={t("Sign in")}
					onClick={() => navigate(ROUTE_LOGIN)}
				/>
			) : (
				<>
					<PageTitle label={t("Involve")} />

					<Box sx={{ width: "100%", position: "relative" }}>
						<Box
							display="flex"
							alignItems="center"
							justifyContent="end"
							variant="caption"
							fontWeight="regular"
							sx={{ position: "absolute", top: 0, right: 0, mt: 1 }}>
							<Box
								width={pxToRem(6)}
								height={pxToRem(6)}
								bgcolor={warning.main}
								marginRight={pxToRem(4)}
								borderRadius={pxToRem(6)}
							/>
							<Typography color={grey[700]} fontSize={typography.size.xs}>
								{t("Required fields")}
							</Typography>
						</Box>
						<Box sx={{ p: 4 }}>
							<InputBox label={t("ID (e-mail)")} required>
								<ControlledAppTextField
									name="personalInfo.email"
									type="email"
									control={control}
									disabled={true}
								/>
							</InputBox>
							<InputBox label={t("Password")} required>
								<ValidatePasswordForm
									errors={errors}
									control={control}
									watchPassword={watchPassword}
									watchPasswordCheck={watchPasswordCheck}
								/>
							</InputBox>
							<InputBox label={t("Name")} required>
								<ControlledAppTextField
									name="personalInfo.name"
									type="text"
									control={control}
									disabled={true}
								/>
							</InputBox>
							<InputBox label={t("Phone number")} required>
								<Grid container spacing={1}>
									<Grid item xs={7.05}>
										<ControlledAppTextField
											name="personalInfo.phone_number"
											type="text"
											control={control}
											error={Boolean(errors.personalInfo?.phone_number)}
											placeholder={t("Enter phone number")}
											disabled={isRunning || isOtpVerified}
											onChange={(e) =>
												setValue(
													"personalInfo.phone_number",
													formatPhoneNumber(e.target.value)
												)
											}
										/>
									</Grid>
									<Grid item xs={4.95}>
										<AutoLoadingButton
											disabled={isRunning || !areFieldsPassed()}
											size="large"
											color="primary"
											variant="contained"
											fullWidth
											sx={{ height: pxToRem(60), textWrap: "nowrap" }}
											onClick={() => {
												isOtpVerified
													? clearPhoneNumber()
													: handleSendAuthenticationPress()
											}}>
											{isOtpVerified ? t("Reset") : t("Send code")}
										</AutoLoadingButton>
									</Grid>
									{errors.personalInfo?.phone_number && (
										<Typography
											mt={1}
											ml={1}
											variant="validationMessage"
											color="error"
											sx={{ textWrap: "none" }}>
											{errors.personalInfo.phone_number.message}
										</Typography>
									)}
								</Grid>

								{isOtpOpen && !isOtpVerified && (
									<Box mb={2}>
										<Box sx={{ display: "flex" }} gap={1}>
											<Box sx={{ position: "relative", flexGrow: 1 }}>
												<ControlledAppTextField
													name="code"
													error={Boolean(errors.code)}
													placeholder={t("OTP input")}
													control={control}
													withClearButton={false}
												/>
												<Typography
													variant="body2"
													color="error"
													sx={({ spacing }) => ({
														position: "absolute",
														right: spacing(2),
														top: "50%",
														transform: "translateY(-50%)",
													})}>{`${remainingMinutes}:${remainingSeconds}`}</Typography>
											</Box>

											<AutoLoadingButton
												disabled={isRunning}
												variant="contained"
												size="large"
												color="secondary"
												onClick={handleSendAuthenticationPress}
												sx={{ textWrap: "nowrap" }}>
												{t("Resend code")}
											</AutoLoadingButton>
										</Box>

										{(errors.code || errors.serverError) && (
											<ErrorMessage
												message={
													errors.code
														? errors.code.message
														: errors.serverError.message
												}
											/>
										)}

										<Box mt={2}>
											<AutoLoadingButton
												disabled={isOtpExpired || !!errors.code}
												size="large"
												variant="contained"
												onClick={handleVerifyCode}
												color="primary"
												fullWidth>
												{t("Confirm")}
											</AutoLoadingButton>
										</Box>
									</Box>
								)}
							</InputBox>
						</Box>
						<Box
							sx={(theme) => ({
								pt: 2,
								paddingX: 4,
								borderTop: `1px solid ${theme.palette.grey["300"]}`,
								width: "100%",
							})}>
							<Terms setValue={setValue} />
							<AutoLoadingButton
								disabled={!isOtpVerified}
								variant="contained"
								fullWidth
								sx={{ height: pxToRem(60), marginTop: pxToRem(60) }}
								onClick={onClickSubmit}>
								{t("Join")}
							</AutoLoadingButton>
						</Box>
					</Box>
				</>
			)}
		</>
	)
}

export default SignupForm
