import { FormControl, FormControlLabel, Radio, RadioGroup, Typography, styled } from "@mui/material"
import { AppCheckBox, FormDialogInputLabel, RequiredInfoGuideText } from "components"
import { StyledFlexBox } from "components/dialog/shared"
import TimeDurationPopOver from "layouts/reservations/components/ReservationForm/TimeDurationPopOver"
import {
	SelectionBoxNoData,
	SelectionBoxPlaceholder,
} from "layouts/reservations/components/ReservationForm/shared"
import { Fragment, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { reservationApi } from "services/modules"
import { isEmptyArray } from "utils/array"

const StyledFormControlLabel = styled(FormControlLabel)(({ theme: { spacing } }) => ({
	margin: 0,
	marginBottom: spacing(2),
}))

const StyledFormControl = styled(FormControl)(
	({
		theme: {
			palette,
			spacing,
			borders: { borderRadius },
		},
		ownerProps: { isDisabled },
	}) => ({
		padding: spacing(2),
		paddingBottom: 0,
		border: `1px solid ${palette.grey[300]}`,
		borderRadius: borderRadius.lg,
		width: "100%",
		backgroundColor: isDisabled ? palette.grey[200] : "transparent",
	})
)

const StyledRadio = styled(Radio)(() => ({
	padding: 0,
	"& .MuiSvgIcon-root": {
		width: "24px",
		height: "24px",
	},
}))

const FittingTypeSelectionBox = ({
	setValue,
	setTotalDuration,
	selectedFittingTypeID,
	selectedFittingTypeItemIDsInStr,
	isDisabled,
}) => {
	const { t } = useTranslation()

	const {
		data: { data: fittingTypes } = { data: [] },
		isLoading,
		isSuccess,
	} = reservationApi.useGetReservationFittingTypesQuery()

	const setSelectedFittingTypeID = (fittingTypeID) => {
		setValue("fitting_type_id", fittingTypeID)
	}

	const setSelectedFittingTypeItemIDs = (fittingTypeItemIDsInStr) => {
		setValue("fitting_type_item_ids", fittingTypeItemIDsInStr)
	}

	// toggle flow for allow_multiple_select [true] fitting types
	const getIDStr = (idsInArr) => idsInArr.join(",")

	const removeFittingTypeItem = (itemIDs, itemToGetRemoved) => {
		setSelectedFittingTypeItemIDs(
			getIDStr(itemIDs.filter((id) => id !== String(itemToGetRemoved.id)))
		)
		setTotalDuration((totalDuration) => totalDuration - itemToGetRemoved.duration)
	}

	const appendFittingTypeItem = (itemIDs, itemToGetAppended) => {
		setSelectedFittingTypeItemIDs(getIDStr([...itemIDs, String(itemToGetAppended.id)]))
		setTotalDuration((totalDuration) => totalDuration + itemToGetAppended.duration)
	}

	// single select flow for allow_multiple_select [false] fitting types
	const selectFittingTypeItem = (itemToGetSelected) => {
		setSelectedFittingTypeItemIDs(String(itemToGetSelected.id)) // converted to String to use string prototype methods upon it
		setTotalDuration(itemToGetSelected.duration)
	}

	useEffect(() => {
		if (!selectedFittingTypeID && !isLoading && isSuccess) {
			!isEmptyArray(fittingTypes) && setSelectedFittingTypeID(fittingTypes[0].id)
		}
	}, [isLoading, isSuccess])

	return (
		<>
			<StyledFlexBox alignItems="center" justifyContent="space-between">
				<StyledFlexBox gap={0} sx={{ alignItems: "center" }}>
					<FormDialogInputLabel isRequired label={t("Fitting type")} />
					<TimeDurationPopOver />
				</StyledFlexBox>
				<RequiredInfoGuideText />
			</StyledFlexBox>

			<StyledFormControl ownerProps={{ isDisabled }}>
				<RadioGroup
					aria-labelledby="fitting-types-label"
					defaultValue="female"
					name="fitting-type">
					{/* renders fitting types */}
					{isLoading ? (
						<SelectionBoxPlaceholder />
					) : isSuccess && !isEmptyArray(fittingTypes) ? (
						fittingTypes.map((fittingType) => {
							const isSelectedFittingType = selectedFittingTypeID === fittingType.id

							return (
								<Fragment key={fittingType.id}>
									<StyledFormControlLabel
										disabled={isDisabled}
										control={
											<StyledRadio
												checked={isSelectedFittingType}
												onClick={() => {
													setSelectedFittingTypeID(fittingType.id)

													// reset selectedFittingTypeItemIDs & totalDuration on selectedFittingTypeID change
													setSelectedFittingTypeItemIDs("")
													setTotalDuration(0)
												}}
											/>
										}
										value={fittingType.id} // just for accessibility
										label={
											<Typography ml={1} variant="body2" color="info.main">
												{t(fittingType.name)}
											</Typography>
										}
									/>

									{/* renders fitting type items under the appropriate fitting type - only shows when that fitting type is selected */}
									{!isEmptyArray(fittingType) && isSelectedFittingType && (
										<RadioGroup
											sx={{ paddingLeft: 3 }}
											aria-labelledby="fitting-type-items-label"
											name="fitting-type-items">
											{fittingType.fitting_type_items.map(
												(fittingTypeItem) => {
													const selectedFittingTypeItemIDsInArr =
														(selectedFittingTypeItemIDsInStr &&
															selectedFittingTypeItemIDsInStr.split(
																","
															)) ||
														[]

													const fittingTypeItemIDInStr = String(
														fittingTypeItem.id
													) // converted to String to use string prototype methods upon it

													const isSelectedFittingTypeItem =
														selectedFittingTypeItemIDsInArr.includes(
															fittingTypeItemIDInStr
														)

													return (
														<StyledFormControlLabel
															key={fittingTypeItemIDInStr}
															disabled={isDisabled}
															control={
																fittingType.allow_multiple_select ? (
																	<AppCheckBox
																		isDisabled={isDisabled}
																		isChecked={
																			isSelectedFittingTypeItem
																		}
																		onToggle={() =>
																			isSelectedFittingTypeItem
																				? removeFittingTypeItem(
																						selectedFittingTypeItemIDsInArr,
																						fittingTypeItem
																					)
																				: appendFittingTypeItem(
																						selectedFittingTypeItemIDsInArr,
																						fittingTypeItem
																					)
																		}
																		variant="round"
																		size="small"
																	/>
																) : (
																	<StyledRadio
																		checked={
																			isSelectedFittingTypeItem
																		}
																		onClick={() =>
																			selectFittingTypeItem(
																				fittingTypeItem
																			)
																		}
																	/>
																)
															}
															value={fittingTypeItemIDInStr} // just for accessibility
															label={
																<Typography
																	ml={1}
																	variant="body2"
																	color="info.main">
																	{t(fittingTypeItem.name)}
																</Typography>
															}
														/>
													)
												}
											)}
										</RadioGroup>
									)}
								</Fragment>
							)
						})
					) : (
						<SelectionBoxNoData />
					)}
				</RadioGroup>
			</StyledFormControl>
		</>
	)
}

export default FittingTypeSelectionBox
