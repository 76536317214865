import { DRIVER, PUTTER } from "constant"
import { api } from "services/api"
import {
	API_ALL_BRANDS,
	API_CLUB_BRANDS,
	API_CLUB_MODEL,
	API_STORE_INSTOCK_CLUBS,
	API_STORE_ROOM_CLUB_COPY,
	API_STORE_ROOM_CLUB_COPY_CLUB_LIST,
	API_STORE_ROOM_CLUB_ID,
	API_STORE_ROOM_IRON_CLUB,
	API_STORE_ROOM_SAVE_CLUB,
	API_STORE_ROOM_SAVE_CLUB_BATCH,
	API_STORE_ROOM_SAVE_CLUB_BATCH_VALIDATE,
	API_STORE_ROOM_SAVE_CLUB_VALIDATE,
} from "services/endpoints"
import {
	TAG_CLUB_BRANDS,
	TAG_CLUB_MODELS,
	TAG_ROOM_CLUBS,
	TAG_ROOM_CLUB_COPY_CLUBS,
	TAG_ROOM_CLUB_DETAIL,
	TAG_ROOM_IRON_CLUBS,
	TAG_STORE_INSTOCK_CLUBS,
} from "services/tags"

export default api.injectEndpoints({
	endpoints(build) {
		return {
			searchStoreInstockClub: build.query({
				query({ params, clubType }) {
					return {
						url: API_STORE_INSTOCK_CLUBS(clubType),
						params: params,
					}
				},

				providesTags: [TAG_STORE_INSTOCK_CLUBS],
			}),

			getClubBrands: build.query({
				query() {
					return {
						url: API_CLUB_BRANDS,
					}
				},

				providesTags: [TAG_CLUB_BRANDS],
			}),

			getAllBrands: build.query({
				query() {
					return {
						url: API_ALL_BRANDS,
					}
				},

				providesTags: [TAG_CLUB_BRANDS],
			}),

			saveClubToRoom: build.mutation({
				query({ data, clubType, roomId }) {
					return {
						data,
						method: "POST",
						url: API_STORE_ROOM_SAVE_CLUB(roomId, clubType),
					}
				},
				invalidatesTags: (ok) => (ok ? [TAG_ROOM_CLUBS] : []),
			}),

			saveClubToRoomValidate: build.mutation({
				query({ data, clubType, roomId }) {
					if (clubType === PUTTER || clubType === DRIVER) {
						delete data.type
					}
					return {
						data,
						method: "POST",
						url: API_STORE_ROOM_SAVE_CLUB_VALIDATE(roomId, clubType),
					}
				},
			}),

			saveBatchClubToRoom: build.mutation({
				query({ data, clubType, roomId }) {
					return {
						data,
						method: "POST",
						url: API_STORE_ROOM_SAVE_CLUB_BATCH(roomId, clubType),
					}
				},
				invalidatesTags: (ok) => (ok ? [TAG_ROOM_CLUBS, TAG_ROOM_IRON_CLUBS] : []),
			}),

			saveBatchClubToRoomValidate: build.mutation({
				query({ data, clubType, roomId }) {
					return {
						data,
						method: "POST",
						url: API_STORE_ROOM_SAVE_CLUB_BATCH_VALIDATE(roomId, clubType),
					}
				},
			}),

			getClubsByStoreRoomId: build.query({
				query({ roomId, clubType }) {
					return {
						method: "GET",
						url: API_STORE_ROOM_SAVE_CLUB(roomId, clubType),
					}
				},
				providesTags: [TAG_ROOM_CLUBS],
			}),

			getIronClubsByStoreRoomId: build.query({
				query({ roomId }) {
					return {
						method: "GET",
						url: API_STORE_ROOM_IRON_CLUB(roomId),
					}
				},
				providesTags: [TAG_ROOM_IRON_CLUBS],
			}),

			getRoomClubDetailById: build.query({
				query({ roomId, clubId, clubType }) {
					return {
						method: "GET",
						url: API_STORE_ROOM_CLUB_ID(roomId, clubType, clubId),
					}
				},
				providesTags: [TAG_ROOM_CLUB_DETAIL],
			}),

			deleteRoomClubById: build.mutation({
				query({ roomId, clubId, clubType }) {
					return {
						method: "DELETE",
						url: API_STORE_ROOM_CLUB_ID(roomId, clubType, clubId),
					}
				},
				invalidatesTags: (ok) => (ok ? [TAG_ROOM_CLUBS, TAG_ROOM_IRON_CLUBS] : []),
			}),

			getClubCopyClubListsByStoreRoomId: build.query({
				query({ roomId }) {
					return {
						method: "GET",
						url: API_STORE_ROOM_CLUB_COPY_CLUB_LIST(roomId),
					}
				},
				providesTags: [TAG_ROOM_CLUB_COPY_CLUBS],
			}),

			saveClubCopyClubList: build.mutation({
				query({ roomId, data }) {
					return {
						data,
						method: "POST",
						url: API_STORE_ROOM_CLUB_COPY(roomId),
					}
				},
				invalidatesTags: (ok) => (ok ? [TAG_ROOM_CLUBS, TAG_ROOM_IRON_CLUBS] : []),
			}),

			updateRoomClub: build.mutation({
				query({ roomId, clubType, clubId, data }) {
					return {
						data,
						method: "PUT",
						url: API_STORE_ROOM_CLUB_ID(roomId, clubType, clubId),
					}
				},

				invalidatesTags: (ok) =>
					ok ? [TAG_ROOM_CLUB_DETAIL, TAG_ROOM_CLUBS, TAG_ROOM_IRON_CLUBS] : [],
			}),

			getClubModels: build.query({
				query({ clubType, filter }) {
					return {
						url: API_CLUB_MODEL(clubType),
						params: { filter },
					}
				},

				providesTags: [TAG_CLUB_MODELS],
			}),
		}
	},
})
