import { Box, Grid, Typography, useTheme } from "@mui/material"
import { CheckCircle18Svg, CheckCircle18SvgHover } from "assets/svgs"
import pxToRem from "assets/theme/functions/pxToRem"
import AppSwitch from "components/AppSwitch"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import DefaultShaftImg from "../../assets/images/default_shaft.png"

function IronClubCard({ head, index, name, customIronSet, setCustomIronSet, isLastItem = false }) {
	const {
		palette: { grey },
	} = useTheme()
	const { watch, setValue, getValues } = useForm()

	const { t } = useTranslation()
	const [useStockShaft, setUseStockShaft] = useState(customIronSet[index].is_use)

	const isAdded = customIronSet[index].is_use

	const number = customIronSet[index].head.data.type

	const updateHeadStockShaft = () => {
		const shaft = {
			name: "",
			brand: "",
			image: DefaultShaftImg,
			data: null,
		}
		const selectedIronSet = {
			[index]: {
				...customIronSet[index],
				shaft,
				shaft_id: null,
				isStockShaft: useStockShaft,
			},
		}
		setCustomIronSet({ ...customIronSet, ...selectedIronSet })
	}

	const addShaftToIronSet = () => {
		const selectedShaft = getValues(name)
		if (customIronSet[index].is_use) {
			customIronSet[index].is_use = false
			setUseStockShaft(false)
		} else {
			const ironSet = {
				head: {
					name: head?.name,
					brand: head?.brand?.name,
					image: head?.photos?.path,
					data: head,
				},
				shaft: {
					name: selectedShaft?.shaft?.shaft_name,
					brand: selectedShaft?.shaft?.brand?.name,
					image: selectedShaft?.shaft?.photos?.path,
					data: selectedShaft?.shaft,
				},
				is_use: true,
			}
			if (!selectedShaft) {
				delete ironSet?.shaft
			}
			customIronSet = {
				...customIronSet,
				[index]: ironSet,
			}

			setUseStockShaft(true)
		}
		setCustomIronSet({ ...customIronSet })
	}

	watch((formData) => {
		updateHeadStockShaft(parseInt(formData[name]))
	})

	useEffect(() => {
		if (!useStockShaft) {
			delete customIronSet[index]?.shaft
			customIronSet[index].isStockShaft = false
			setValue(name, null)
			setCustomIronSet({ ...customIronSet })
		}
	}, [useStockShaft])

	return (
		<Grid
			container
			height={pxToRem(50)}
			borderBottom={` ${isLastItem ? "0" : "1px"} solid ${grey[300]}`}>
			<Grid item xs={2} container borderRight={`1px solid ${grey[300]}`} alignItems="center">
				<Box
					onClick={addShaftToIronSet}
					sx={{
						width: "100%",
						textAlign: "center",
						cursor: "pointer",
						paddingTop: pxToRem(8),
					}}>
					{isAdded ? <CheckCircle18SvgHover /> : <CheckCircle18Svg />}
				</Box>
			</Grid>
			<Grid item container alignItems="center" xs={2} borderRight={`1px solid ${grey[300]}`}>
				<Typography flex={1} textAlign="center" fontSize="size.md">
					{number}
					{/* {head.clubType === WEDGE ? number : t("No. {{number}}", { number })} */}
				</Typography>
			</Grid>
			<Grid item container xs={8} direction="row">
				<Grid
					item
					container
					paddingX={pxToRem(14)}
					alignItems="center"
					justifyContent="space-between">
					<Typography fontSize="size.md">{t("Stock shaft")}</Typography>
					<AppSwitch
						disabled={!isAdded}
						defaultChecked={useStockShaft}
						onChange={() => {
							setUseStockShaft((prev) => !prev)
						}}
					/>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default IronClubCard
