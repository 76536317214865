import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Typography } from "@mui/material"
import { AvatarSvg } from "assets/svgs"
import pxToRem from "assets/theme/functions/pxToRem"
import { AppCopyTooltip, AutoLoadingButton, ControlledAppTextField, ErrorMessage } from "components"
import {
	COMMON_REGEXES,
	INCORRECT_CREDENTIALS_STATUSES,
	NOT_FOUND_STATUSES,
	SPECIAL_INPUT_TYPES,
} from "constant"
import { useCountDown } from "hooks"
import { useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useFindIdMutation, useSendVerificationCodeMutation } from "services/modules/auth"
import { openSnackbar } from "stores/slice/snackbar"
import { ROUTE_LOGIN } from "urls"
import { convertMsToMinutesAndSeconds } from "utils/dateTime"
import { handleApiErrors } from "utils/helpers"
import { findIdSchema } from "validations"

const OTP_LIFESPAN_IN_MS = 180000 // 3 mins
const { minutes: initialCountDownMinutes, seconds: initialCountDownSeconds } =
	convertMsToMinutesAndSeconds(OTP_LIFESPAN_IN_MS)

const FindIdForm = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [isOtpSentForOnce, setIsOtpSentForOnce] = useState(false)
	const [isOtpExpired, setIsOtpExpired] = useState(false)
	const [foundEmail, setFoundEmail] = useState("")
	const [isPhoneFieldDisabled, setIsPhoneFieldDisabled] = useState(false)

	const {
		formState,
		handleSubmit,
		setError,
		clearErrors,
		getValues,
		trigger,
		setValue,
		setFocus,
		control,
		watch,
	} = useForm({
		resolver: yupResolver(findIdSchema(t)),
		mode: "all",
	})

	const errors = formState.errors
	// otp auto-fill for development purpose
	const code = watch("code")

	const [sendVerCode] = useSendVerificationCodeMutation()
	const [findId] = useFindIdMutation()

	// to acknowledge otp expiration
	const handleCountDownDone = () => {
		setIsOtpExpired(true)
		setError("code", {
			type: "expired",
			message: t("The valid period has expired. Request new code."),
		})
		setFocus("code")
		setValue("code", "")
	}

	// otp validity count down
	const { restartCountDown, isRunning, remainingMinutes, remainingSeconds } = useCountDown(
		initialCountDownMinutes,
		initialCountDownSeconds,
		handleCountDownDone
	)

	// disable sendOtp / sendOtpAgain buttons depending on personal info fields' state
	// currentSchemaFlow -> personalInfo -> name, phone_number, ...
	// memoised since useCountDown triggers intensive re-renders
	const personalInfoFieldNames = Object.values(findIdSchema(t).fields.personalInfo.fields)
	const personalInfoFieldsIsDirty = useMemo(
		() =>
			formState.dirtyFields.personalInfo &&
			Object.keys(formState.dirtyFields.personalInfo).length ===
				personalInfoFieldNames.length, // get field's length from schema
		[formState]
	)

	const isResendOtpButtonsDisabled =
		isRunning || !personalInfoFieldsIsDirty || Boolean(errors.personalInfo)

	const isSubmitButtonDisabled = isOtpExpired || !formState.isDirty || !formState.isValid

	const handleSendAuthenticationPress = async () => {
		const { name, phone_number } = getValues("personalInfo")
		const validPhoneNumber = phone_number.match(COMMON_REGEXES.KR_PHONE_FORMAT)

		if (validPhoneNumber) {
			try {
				clearErrors("userNotFound") // clear any api errors before the next api call

				const result = await trigger("personalInfo")

				if (result) {
					const { otp } = await sendVerCode({
						name,
						phone_number,
					}).unwrap()

					if (!isOtpSentForOnce) {
						setIsOtpSentForOnce(true)
						setIsPhoneFieldDisabled(true) // disable phone number modification if otp is already sent for once
					}

					restartCountDown()
					setIsOtpExpired(false)

					// otp auto-fill for development purpose
					otp &&
						setValue("code", otp, {
							shouldValidate: true,
							shouldDirty: true,
							shouldTouch: true,
						})

					dispatch(
						openSnackbar({
							severity: "success",
							message: !isOtpSentForOnce
								? t(
										"A security code has been sent to the mobile number provided. Enter the code within 3 minutes."
									)
								: t("The security code has been resent."),
						})
					)
				}
			} catch (err) {
				if (NOT_FOUND_STATUSES.includes(err.response?.status)) {
					setError("userNotFound", {
						type: "userNotFound",
						message: t("The information is incorrect. Check credentials."),
					})
				} else {
					handleApiErrors(err, dispatch, setError)
				}
			}
		} else {
			setError("personalInfo.phone_number", { message: t("Re-check your phone number.") })
		}
	}

	const handleFindIdPress = handleSubmit(async (values) => {
		try {
			const {
				data: { email },
			} = await findId({
				...values.personalInfo,
				code: values.code,
			}).unwrap()

			// dispatch(
			// 	openSnackbar({
			// 		severity: "success",
			// 		message: t("Your email is", { email }),
			// 	})
			// )

			setFoundEmail(email)
		} catch (err) {
			if (INCORRECT_CREDENTIALS_STATUSES.includes(err.response?.status)) {
				setError("invalidCode", {
					type: "invalidCode",
					message: t("The verification code is incorrect. Check credentials."),
				})
				setError("code")
			} else {
				handleApiErrors(err, dispatch, setError)
			}
		}
	})

	const handleLoginPress = () =>
		navigate(ROUTE_LOGIN, {
			state: {
				email: foundEmail,
			},
		})

	return (
		<Box p={4}>
			{foundEmail ? (
				<>
					<Typography variant="body2" sx={{ color: "grey.700" }}>
						{t(
							"Successfully found your VICX admin account. Login after confirming your ID."
						)}
					</Typography>
					<Box gap={1} mt={4} sx={{ display: "flex", alignItems: "center" }}>
						<AvatarSvg />
						<AppCopyTooltip
							title="Click to copy the ID (email)"
							textToBeCopied={foundEmail}>
							<Typography sx={{ cursor: "pointer" }} variant="body2">
								{foundEmail}
							</Typography>
						</AppCopyTooltip>
					</Box>
				</>
			) : (
				<>
					<Box mb={2}>
						<ControlledAppTextField
							name="personalInfo.name"
							placeholder={t("Enter name")}
							error={Boolean(errors.personalInfo?.name || errors.userNotFound)}
							control={control}
							autoComplete="off"
							size="normal"
						/>

						{errors.personalInfo?.name && (
							<ErrorMessage message={errors.personalInfo?.name.message} />
						)}
					</Box>

					<Box mb={2}>
						<Box sx={{ display: "flex" }} gap={1}>
							<ControlledAppTextField
								name="personalInfo.phone_number"
								type={SPECIAL_INPUT_TYPES.TEL}
								disabled={isPhoneFieldDisabled}
								showCloseIconOnDisabledInput={true}
								placeholder={t("Enter phone number")}
								error={Boolean(
									errors.personalInfo?.phone_number || errors.userNotFound
								)}
								control={control}
								autoComplete="off"
								size="normal"
								sx={{ maxWidth: pxToRem(290) }}
							/>

							<AutoLoadingButton
								disabled={!personalInfoFieldsIsDirty || isPhoneFieldDisabled}
								size="normal"
								color="primary"
								variant="contained"
								sx={{ textWrap: "nowrap", flexGrow: 1 }}
								onClick={handleSendAuthenticationPress}>
								{t("Send code")}
							</AutoLoadingButton>
						</Box>

						{(errors.personalInfo?.phone_number || errors.userNotFound) && (
							<ErrorMessage
								message={
									errors.personalInfo?.phone_number?.message ||
									errors.userNotFound?.message
								}
							/>
						)}
					</Box>

					{isOtpSentForOnce && (
						<Box mb={2}>
							<Box sx={{ display: "flex" }} gap={1}>
								<Box
									sx={{
										position: "relative",
										flexGrow: 1,
										maxWidth: pxToRem(340),
									}}>
									<ControlledAppTextField
										name="code"
										placeholder={t("Enter code")}
										error={Boolean(errors.code)}
										control={control}
										withClearButton={false}
										defaultValue={code}
										size="normal"
									/>

									<Typography
										variant="body2"
										color="error"
										sx={({ spacing }) => ({
											position: "absolute",
											right: spacing(2),
											top: "50%",
											transform: "translateY(-50%)",
										})}>{`${remainingMinutes}:${remainingSeconds}`}</Typography>
								</Box>

								<AutoLoadingButton
									disabled={isResendOtpButtonsDisabled}
									variant="contained"
									size="normal"
									color="secondary"
									onClick={handleSendAuthenticationPress}
									sx={{ textWrap: "nowrap", flex: 1 }}>
									{t("Resend code")}
								</AutoLoadingButton>
							</Box>

							{(errors.code || errors.invalidCode) && (
								<ErrorMessage
									message={errors.code?.message || errors.invalidCode?.message}
								/>
							)}
						</Box>
					)}
				</>
			)}

			{(isOtpSentForOnce || foundEmail) && (
				<Box mt={6}>
					<AutoLoadingButton
						disabled={foundEmail ? false : isSubmitButtonDisabled}
						size="normal"
						onClick={foundEmail ? handleLoginPress : handleFindIdPress}
						variant="contained"
						color="primary"
						fullWidth>
						{foundEmail ? t("Login") : t("Confirm")}
					</AutoLoadingButton>
				</Box>
			)}
		</Box>
	)
}

export default FindIdForm
