const columns = (t) => [
	{
		accessorKey: "fitting-type",
		header: t("Option"),
	},
	{
		accessorKey: "individual",
		header: t("Individual"),
	},
	{
		accessorKey: "all",
		header: t("All"),
	},
]

export default columns
