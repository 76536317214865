import { Box, Button, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { absoluteCenter } from "utils/general"

export default function ErrorComponent({ subtitle, confirm, goto }) {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	return (
		<Box width="100%" height="100%" sx={absoluteCenter} bgcolor="light.main">
			<Box display="flex" flexDirection="column" alignItems="center" sx={absoluteCenter}>
				<Typography variant="h1">:(</Typography>

				<Typography
					mt={3}
					variant="h1"
					style={{
						fontSize: "26px",
						fontWeight: "700",
						lineHeight: "40px",
						letterSpacing: "0px",
						textAlign: "center",
					}}>
					{t(subtitle)}
				</Typography>

				<Button
					size="normal"
					sx={{
						mt: 4,
						width: "200px",
					}}
					variant="contained"
					onClick={() => {
						dispatch(confirm())
						goto && navigate(goto)
					}}>
					{t("Confirm")}
				</Button>
			</Box>
		</Box>
	)
}
