import Typography from "@mui/material/Typography/Typography"

export { default as MonthView } from "./MonthView"
export { default as MonthSelector } from "./MonthSelector"
export { default as WeekView } from "./WeekView"
export { default as DayView } from "./DayView"
export { default as ListView } from "./ListView"

export function HolidayView({ children, sx, ...props }) {
	return (
		<Typography
			fontSize={16}
			fontWeight={500}
			color="error.main"
			sx={{ marginLeft: "10px", ...sx }}
			{...props}>
			{children}
		</Typography>
	)
}
