import Modal from "@mui/material/Modal"
import { cloneElement, useEffect, useState } from "react"

export default function showModal(propsOrComponent, replace = true) {
	let Component, props

	if (typeof propsOrComponent === "function") {
		Component = propsOrComponent
	} else if (typeof propsOrComponent === "object") {
		// destructuring without declaration
		;({ Component, ...props } = propsOrComponent)
	} else {
		throw new TypeError("showModal: first argument must be a function or an object")
	}

	document.dispatchEvent(
		new CustomEvent(`modal:${replace ? "replace" : "add"}`, {
			detail: {
				Component: (
					<Component
						closeModal={() => {
							document.dispatchEvent(new CustomEvent("modal:remove"))
						}}
					/>
				),
				props,
			},
		})
	)
}

export function useModals() {
	const [modals, setModals] = useState([])
	const [props, setProps] = useState({})

	useEffect(() => {
		function add({ detail: { Component, props } }) {
			setModals((state) => state.concat(Component))

			if (props) {
				setProps(props)
			}
		}

		function remove() {
			setModals((state) => {
				const newState = state.concat()

				newState.pop()

				return newState
			})
		}

		function replace({ detail: { Component, props } }) {
			setModals((state) => {
				const newState = state.concat()

				if (newState.length) {
					newState[newState.length - 1] = Component
				} else {
					newState.push(Component)
				}

				return newState
			})

			if (props) {
				setProps(props)
			}
		}

		document.addEventListener("modal:add", add)
		document.addEventListener("modal:remove", remove)
		document.addEventListener("modal:replace", replace)

		return () => {
			document.removeEventListener("modal:add", add)
			document.removeEventListener("modal:remove", remove)
			document.removeEventListener("modal:replace", replace)
		}
	}, [])

	const children = modals.map((node, index) => cloneElement(node, { key: "Modal_" + index }))

	return (
		children.length > 0 && (
			<Modal open {...props}>
				<div>{children}</div>
			</Modal>
		)
	)
}
