import { Box } from "@mui/material"
import { ControlledAppTextField, FormDialogInputLabel } from "components"
import { useTranslation } from "react-i18next"

const MemoBox = ({ control, errors, isDisabled }) => {
	const { t } = useTranslation()
	return (
		<Box mb={3}>
			<FormDialogInputLabel label={t("Memo")} isRequired={false} mb={1} mt={2} />
			<ControlledAppTextField
				control={control}
				withClearButton={false}
				name="normal.memo"
				error={errors.normal?.memo}
				multiline
				rows={3}
				disabled={isDisabled}
				sx={{
					"& .MuiInputBase-root": {
						padding: 2,
					},
				}}
			/>
		</Box>
	)
}

export default MemoBox
