import FormLoading from "components/FormLoading"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { commonApi } from "services/modules"
import { ROUTE_SIGN_UP } from "urls"

const navigationRoutes = {
	INVITE_STORE: ROUTE_SIGN_UP,
}

function RouteGateway() {
	const navigate = useNavigate()
	const [data, setData] = useState(null)

	const [trigger] = commonApi.useVerifyTokenMutation()

	const getTokenFromURL = () => {
		const queryString = window.location.search
		const urlParams = new URLSearchParams(queryString)
		return urlParams.get("token")
	}

	// Send token to server to verify
	const sendTokenToServer = async (token) => {
		try {
			const { data } = await trigger(token).unwrap()
			setData({ ...data, token })
		} catch (err) {
			console.error("There was an error sending the token", err)

			const { code, message } = err.response.data
			setData({ code, message })
		}
	}

	const navigateBasedOnTypeAndError = (data) => {
		const { type, errorCode } = data

		if (!errorCode && navigationRoutes[type]) {
			navigate(navigationRoutes[type], { state: data })
		} else if (errorCode === 10022) {
			navigate(ROUTE_SIGN_UP, { state: data })
		} else {
			// todo: navigate to error page - token not found
		}
	}

	useEffect(() => {
		const token = getTokenFromURL()

		if (token) {
			sendTokenToServer(token)
		} else {
			// todo: navigate to error page - token not found
			if (process.env.NODE_ENV === "development") {
				console.error("Token not found in URL.")
			}
		}
	}, [])

	useEffect(() => {
		if (data) {
			navigateBasedOnTypeAndError(data)
		}
	}, [data])

	return data ? null : <FormLoading />
}

export default RouteGateway
