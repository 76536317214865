import { Button } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"

const ClubFormButton = ({ onClick, label, disabled }) => {
	const { t } = useTranslation()

	return (
		<Button
			variant="contained"
			color="primary"
			sx={{ marginTop: "20px", width: "100%" }}
			disabled={disabled}
			onClick={onClick}>
			{label || t("Save")}
		</Button>
	)
}

export default ClubFormButton
