import { Box, Button } from "@mui/material"
import { FormDialogInputLabel, showModal } from "components"
import { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"

const ClubSelectionButton = ({
	setValue,
	customer,
	selectedClubs,
	selectedRoomID,
	runIfSettable,
	selectedFittingTypeID,
	selectedFittingTypeItemIDsInStr,
	isDisabled,
	openClubModal,
}) => {
	const { t } = useTranslation()
	const fieldName = "items"

	// TODO: to develop a better solution
	// TODO: this useRef is used to prevent the component render multiple times which cause the dialogs behavioural issue
	const isMounted = useRef(null)

	const handleClubSettingsClick = () => {
		runIfSettable(fieldName, () =>
			import("./ClubSettingsDialog").then(({ default: ClubSettingsDialog }) => {
				showModal(
					{
						Component: ({ closeModal }) => (
							<ClubSettingsDialog
								saveFittingClubs={(clubs) => setValue(fieldName, clubs)}
								customer={customer}
								initialClubs={selectedClubs}
								roomId={selectedRoomID}
								setIsOpen={closeModal}
								selectedFittingTypeID={selectedFittingTypeID}
								selectedFittingTypeItemIDsInStr={selectedFittingTypeItemIDsInStr}
							/>
						),
						hideBackdrop: true,
					},
					false
				)
			})
		)
	}

	useEffect(() => {
		if (!isMounted.current && openClubModal && !isDisabled) {
			handleClubSettingsClick()
			isMounted.current = true
		}
	}, [])

	return (
		<Box>
			<FormDialogInputLabel label={t("Fitting club")} mb={1} mt={2} />

			<Button
				fullWidth
				onClick={handleClubSettingsClick}
				variant="contained"
				color="secondary"
				disabled={isDisabled}>
				{t("Fitting club setting")}
			</Button>
		</Box>
	)
}

export default ClubSelectionButton
