import { object, string } from "yup"

export const driverClubShaftSchema = (t, register = "store") => {
	return object({
		brand: string().required(t("Enter the brand")),
		model: string().required(t("Enter the model")),
		flex:
			register === "customer" ? string().nullable() : string().required(t("Select the flex")),
		weight:
			register === "customer"
				? string().nullable()
				: string().required(t("Select the weight")),
		length:
			register === "customer"
				? string().nullable()
				: string().required(t("Select the length")).nullable(),
	})
}

//  NOTE: ** Register can be customer or store. depend on register type some of fields are not required.
const driverClubFormSchema = (t, register) =>
	object({
		club: string().required(t("Select the club")),
		total_weight:
			register === "customer" ? string().nullable() : string().required().nullable(),
		swing_weight: register === "customer" ? string().nullable() : string().required(),
		head: object({
			brand: string().required(t("Enter the brand")),
			model: string().required(t("Enter the model")),
			loft:
				register === "customer"
					? string().nullable()
					: string().required(t("Select the loft")),
			lie_angle:
				register === "customer"
					? string().nullable()
					: string().required(t("Select the lie angle")).nullable(),
		}),
		shaft: driverClubShaftSchema(t, register),
	}).required()

export default driverClubFormSchema
