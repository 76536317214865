import styled from "@emotion/styled"
import { Switch } from "@mui/material"

const DefaultStyledSwitch = styled((props) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, ownerState }) => ({
	width: 44,
	height: 24,
	padding: 0,
	"& .MuiSwitch-switchBase": {
		padding: 0,
		margin: 4,
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(20px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#2F4BBC",
				opacity: 1,
				border: 0,
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5,
				backgroundColor: "#D5D5D5",
			},
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#33cf4d",
			border: "6px solid #fff",
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color: theme.palette.mode === "light" ? "#ffffff" : theme.palette.grey[600],
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
		},
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
		width: 16,
		height: 16,
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2,
		backgroundColor: ownerState?.disable
			? "#D5D5D5 !important"
			: theme.palette.mode === "light"
				? "#D5D5D5"
				: "#39393D",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500,
		}),
	},
}))

export default DefaultStyledSwitch
