import { Box, Skeleton, Typography } from "@mui/material"
import pxToRem from "assets/theme/functions/pxToRem"
import { useLocalizedDateUtils } from "hooks"
import { useTranslation } from "react-i18next"

import { HolidayView } from ".."
import DataBoxForWeekView from "./DataBoxForWeekView"

const WeekCalendarView = ({ week, palette, typography, isLoading, handleDataBox }) => {
	const { t } = useTranslation()
	const { formatWeekCalendarDay } = useLocalizedDateUtils()

	return (
		<Box
			sx={{
				flexGrow: 2,
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				padding: 0,
				margin: 0,
				border: `1px solid ${palette.grey[300]}`,
				borderRadius: "8px",
				background: palette.white.main,
				minWidth: "750px",
				width: "100%",
				maxWidth: "1300px",
			}}>
			{week.map((day, index) => {
				const showDay = day.date.getDate().toString()
				const currentDay = new Date()
				const today =
					currentDay.toLocaleDateString("en-GB") === day.date.toLocaleDateString("en-GB")

				return (
					<Box key={index} width="100%" minHeight="800px">
						<Box
							sx={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}>
							<Box>
								<Typography
									fontSize={typography.size.lg}
									fontWeight={500}
									color={
										index === 0
											? palette.warning.main
											: index === 6
												? palette.secondary.main
												: palette.dark.main
									}
									textAlign="center"
									padding={0}
									margin={0}
									sx={{
										width: "32px",
										height: "32px",
										borderRadius: "50%",
										paddingTop: "2px",
										"@media (max-width: 1400px)": {
											paddingTop: "3px",
										},
										"@media (max-width: 992px)": {
											paddingTop: "3.2px",
										},
										"@media (max-width: 990px)": {
											paddingTop: "5px",
										},
										marginRight: today ? "3px" : "-5px",
										marginBottom: today ? "3.6px" : 0,
										backgroundColor: today
											? palette.primary.main
											: palette.transparent.main,
										color:
											index === 0
												? palette.warning.main
												: index === 6 && !today
													? palette.secondary.main
													: today
														? palette.white.main
														: palette.dark.main,
									}}>
									{showDay}
								</Typography>
							</Box>
							<Box marginTop={pxToRem(2)}>
								<Typography
									fontSize={typography.size.lg}
									fontWeight={500}
									color={
										index === 0
											? palette.warning.main
											: index === 6
												? palette.secondary.main
												: palette.dark.main
									}
									textAlign="center"
									paddingY="19px"
									paddingX={0}
									margin={0}>
									{` (${formatWeekCalendarDay(day.date, t)})`}
								</Typography>
							</Box>
						</Box>
						<Box
							sx={{
								borderTop: `1px solid ${palette.grey[300]}`,
								borderRight:
									index === 6 ? "none" : `1px solid ${palette.grey[300]}`,
								width: "100%",
								paddingY: "10px",
								minHeight: "740px",
								maxHeight: "740px",
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "center",
								overflowY: "auto",
								"&::-webkit-scrollbar": {
									width: "3px",
								},
								"&::-webkit-scrollbar-track": {
									background: "transparent",
								},
								"&::-webkit-scrollbar-thumb": {
									background: "rgba(0, 0, 0, 0.2)",
									borderRadius: "8px",
								},
								"&::-webkit-scrollbar-thumb:hover": {
									background: "rgba(0, 0, 0, 0.4)",
								},
							}}>
							{isLoading && (
								<Skeleton
									variant="rounded"
									sx={{ borderRadius: "8px", color: "#eaeaea" }}
									width="85%"
									height={80}
								/>
							)}
							{!isLoading && (
								<>
									{day.holiday && (
										<HolidayView>{day.holiday.description}</HolidayView>
									)}

									{day.reservations?.map((reservation, index) => {
										return (
											<DataBoxForWeekView
												key={reservation.id}
												marginTop={index === 0 ? "1px" : "5px"}
												reservation={reservation}
												handleDataBox={handleDataBox}
											/>
										)
									})}
								</>
							)}
						</Box>
					</Box>
				)
			})}
		</Box>
	)
}

export default WeekCalendarView
