import borders from "assets/theme/base/borders"
import colors from "assets/theme/base/palette"
import typography from "assets/theme/base/typography"

const { primary, inputBorderColor, dark } = colors
const { size } = typography
const { borderWidth } = borders

export default {
	styleOverrides: {
		root: {
			fontSize: size.sm,
			color: dark.main,

			"&:hover:not(.Mui-disabled):before": {
				borderBottom: `${borderWidth[1]} solid ${inputBorderColor}`,
			},

			"&:before": {
				borderColor: inputBorderColor,
			},

			"&:after": {
				borderColor: primary.main,
			},
		},
	},
}
