import { useTheme } from "@mui/material"
import { useCalendar } from "hooks"
import { useEffect, useState } from "react"

import DatePickerWrapper from "./DatePickerComponents/DatePickerWrapper"

const styles = {
	popOver: {
		width: "348px",
		dayBtnWidth: "35.7px",
		dayBtnPadding: "10px",
		dayBtnPaddingRight: "10px",
		dayBtnMarginTop: "10px",
		dayBtnMarginLeft: "2px",
	},
	default: {
		width: "500px",
		dayBtnWidth: "70px",
		dayBtnPadding: "25px",
		dayBtnPaddingRight: "40px",
		dayBtnMarginTop: "25px",
		dayBtnMarginLeft: "18px",
	},
}

const CoreDatePicker = ({
	selectableYearAndMonth,
	selectedDate,
	onDayClick,
	border = true,
	disabledDates = [],
	availableStartDate,
	variant = "default",
}) => {
	const { palette } = useTheme()
	const [hoveredDate, setHoveredDate] = useState(null)

	const { monthName, weekDays, year, month, days, previousMonth, nextMonth, setMonth, setYear } =
		useCalendar()

	function isValidDate(date) {
		const parsedDate = Date.parse(date)
		return !isNaN(parsedDate)
	}

	useEffect(() => {
		if (isValidDate(selectedDate)) {
			const date = new Date(selectedDate)
			setMonth(date.getMonth())
			setYear(date.getFullYear())
		}
	}, [])

	const getDateButtonStyle = (dateString, viewMonth, disabled, selectableYearAndMonth) => {
		const currentDay = new Date()
		const today = currentDay.toLocaleDateString("en-GB") === dateString
		const selectedDayCheck =
			selectedDate && dateString === selectedDate.toLocaleDateString("en-GB")

		return {
			border: "none",
			outline: hoveredDate === dateString ? `1px solid ${palette.primary.main}` : "none",
			backgroundColor: today
				? palette.primary.main
				: selectedDayCheck
					? palette.calendar.main
					: disabled
						? palette.grey[300]
						: palette.transparent.main,
			borderRadius: today ? "20px" : "7px",
			textAlign: "center",
			width: selectableYearAndMonth ? styles[variant].dayBtnWidth : "40px",
			fontSize: selectableYearAndMonth ? "14px" : "18px",
			fontFamily: selectableYearAndMonth && "Roboto",
			padding: today ? "10px" : styles[variant].dayBtnPadding,
			paddingRight:
				selectableYearAndMonth || today ? "8px" : styles[variant].dayBtnPaddingRight,
			marginTop: today && !selectableYearAndMonth ? styles[variant].dayBtnMarginTop : "10px",
			marginLeft: today && !selectableYearAndMonth ? styles[variant].dayBtnMarginLeft : "0px",
			cursor: disabled ? "not-allowed" : "pointer",
			color: today
				? palette.white.main
				: selectedDayCheck
					? palette.white.main
					: viewMonth === month
						? palette.black.main
						: palette.grey[700],
			transition: "border 0.3s ease",
		}
	}

	return (
		<DatePickerWrapper
			selectableYearAndMonth={selectableYearAndMonth}
			border={border}
			padding={selectableYearAndMonth ? "10px" : "30px 10px"}
			width={styles[variant].width} //"348px"
			weekDayListMarginTop="18px"
			weekDayNameFontSize={selectableYearAndMonth ? "12px" : "18px"}
			monthName={monthName}
			weekDays={weekDays}
			year={year}
			month={month}
			previousMonth={previousMonth}
			nextMonth={nextMonth}
			setMonth={setMonth}
			setYear={setYear}
			variant={variant}
			datesView={days.map((d) => {
				const dateString = d.date.toLocaleDateString("en-GB")
				const disabled =
					(disabledDates.length && disabledDates.includes(dateString)) ||
					(availableStartDate && new Date(availableStartDate) >= d.date)
				const parsedDay = parseInt(d.day, 10)
				return (
					<li
						key={dateString}
						onMouseEnter={() => !disabled && setHoveredDate(dateString)}
						onMouseLeave={() => !disabled && setHoveredDate(null)}>
						<button
							style={getDateButtonStyle(
								dateString,
								d.date.getMonth(),
								disabled,
								selectableYearAndMonth
							)}
							disabled={disabled}
							onClick={() => onDayClick(d.date)}>
							{parsedDay}
						</button>
					</li>
				)
			})}
		/>
	)
}

export default CoreDatePicker
