import { Box } from "@mui/material"
import { PageLayout } from "components/layout"
import PropTypes from "prop-types"

const BasicLayout = ({ children, height, sx, pageProps }) => {
	return (
		<PageLayout {...pageProps}>
			<Box
				// position="absolute"
				// width="100%"
				// height="100%"
				sx={{
					backgroundSize: "cover",
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat",
				}}
			/>
			<Box
				px={1}
				width="100%"
				mx="auto"
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					...sx,
				}}
				height={height}>
				{children}
			</Box>
		</PageLayout>
	)
}

BasicLayout.defaultProps = {
	height: "100%",
}

BasicLayout.propTypes = {
	image: PropTypes.string,
	children: PropTypes.node.isRequired,
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default BasicLayout
