import {
	Avatar,
	Box,
	Chip,
	Icon,
	IconButton,
	Paper,
	Popover,
	Tab,
	Tabs,
	Typography,
	useTheme,
} from "@mui/material"
import { CalendarSolidSvg, PenSvg } from "assets/svgs"
import pxToRem from "assets/theme/functions/pxToRem"
import { showModal } from "components/dialog"
import { RESERVATION_STATUSES } from "constant"
import { useLocalizedDateUtils } from "hooks"
import useAnchorElement from "hooks/useAnchorElement"
import ReservationEditDialog from "layouts/reservations/ReservationEditDialog"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { reservationApi } from "services/modules"
import { useIsEnglish } from "stores/slice/app"
import { formatV1 } from "utils/dateTime"
import { formatLongText, handleError } from "utils/helpers"
import { replaceWithDash } from "utils/string"

const { IN_PROGRESS, COMPLETED, RESERVED } = RESERVATION_STATUSES

const RESERVATION_STATUS_CARD_TABS = [
	{ name: "All" },
	{ name: "Reserve_short", key: RESERVED.value },
	{ name: IN_PROGRESS.name, key: IN_PROGRESS.value },
	{ name: COMPLETED.name, key: COMPLETED.value },
]

function CustomTabPanel({ children, value, index, ...other }) {
	return (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}>
			{value === index && children}
		</Box>
	)
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	}
}

function ReservationCard({
	time,
	reservationHolderName,
	trainer,
	fittingType,
	status,
	handleEditReservation,
}) {
	const { t } = useTranslation()
	const {
		typography,
		palette: { grey, reservationStatusColors },
	} = useTheme()

	const { getAmPmFromDateStringResDataBox } = useLocalizedDateUtils()

	return (
		<Paper
			variant="outlined"
			sx={{
				width: "280px !important",
				px: 2,
				pt: 1.5,
				pb: 1,
				mb: 1,
				borderRadius: "8px",
				bgcolor: "background.paper",
			}}>
			<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
				<Typography
					fontWeight="bold"
					fontSize="size.md"
					textTransform="lowercase"
					lineHeight="lineHeight.lg">
					{getAmPmFromDateStringResDataBox(time)}
				</Typography>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "flex-end",
					}}>
					<Typography fontSize={typography.size.sm} color={grey[700]}>
						{`${t("Fitter")} ${formatLongText(trainer, 4)}`}
					</Typography>
					<Icon
						sx={({ palette }) => ({
							ml: 2,
							cursor: "pointer",
							color: palette.grey[700],
							"&:hover": { color: palette.primary.main },
						})}
						onClick={handleEditReservation}>
						<PenSvg fill="currentcolor" />
					</Icon>
				</Box>
			</Box>
			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
				<Avatar
					sx={{
						fontSize: typography.size.xs,
						mr: 1,
						bgcolor: reservationStatusColors[status],
					}}>
					{t(RESERVATION_STATUSES[status].shortName)}
				</Avatar>
				<Box sx={{ flex: 1 }}>
					<Typography
						fontWeight={typography.fontWeightBold}
						fontSize={typography.size.md}
						lineHeight={typography.lineHeight.md}>
						{reservationHolderName}
					</Typography>
					<Box sx={{ position: "relative", height: pxToRem(22) }}>
						<Typography
							noWrap
							fontSize={typography.size.sm}
							sx={{ position: "absolute", width: "100%" }}>
							{t(fittingType.name)}
						</Typography>
					</Box>
				</Box>
			</Box>
		</Paper>
	)
}

function ReservationStatusCard() {
	const [selectedTab, setSelectedTab] = useState(0)
	const { t } = useTranslation()
	const { typography, palette } = useTheme()
	const isEn = useIsEnglish()

	const { formatCalendarDay } = useLocalizedDateUtils()

	const { setAnchorEl, ...anchor } = useAnchorElement()

	const { data = [] } = handleError(() =>
		reservationApi.useListByTodayQuery(formatV1(new Date()))
	)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleChange = (event, newValue) => {
		setSelectedTab(newValue)
	}

	const handleEditReservationClick = (item) => {
		showModal(({ closeModal }) => (
			<ReservationEditDialog
				isOpen
				reservationID={item.id}
				setIsOpen={() => {
					closeModal()
					handleClose()
				}}
			/>
		))
	}

	return (
		<Box mr={2} bgcolor={palette.background.paper}>
			<IconButton
				onClick={handleClick}
				sx={({ palette }) => ({
					color: palette.grey[700],
					"&:hover": {
						color: palette.primary.main,
					},
				})}>
				<CalendarSolidSvg fill={anchor.anchorEl ? palette.primary.main : "currentcolor"} />
			</IconButton>
			<Popover
				sx={{
					"& .MuiPaper-root": {
						boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
						width: 320,
					},
				}}
				onClose={handleClose}
				transformOrigin={{
					horizontal: "right",
					vertical: "top",
				}}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				{...anchor}>
				<Box
					sx={{
						display: "flex",
						mx: 3,
						alignItems: "center",
						justifyContent: "space-between",
					}}>
					<Typography
						my={3}
						fontWeight={typography.fontWeightBold}
						fontSize={typography.size.md}
						lineHeight={typography.lineHeight.sm}>
						{t("Reservation status")}
					</Typography>
					<Chip
						size="small"
						sx={{
							height: "auto",
							"& .MuiChip-label": {
								display: "block",
								whiteSpace: "normal",
								textAlign: "center",
							},
							fontWeight: "bold",
							fontSize: "size.sm",
							color: "text.main",
							textWrap: "wrap",
							bgcolor: "grey.300",
							borderRadius: "8px",
						}}
						label={`${t("Today")} :  ${formatCalendarDay(new Date())}`}
					/>
				</Box>
				<Box>
					<Box sx={{ border: 1, borderColor: palette.grey[200] }}>
						<Tabs
							indicatorColor="transparent"
							value={selectedTab}
							onChange={handleChange}
							aria-label="reservation tabs"
							sx={{
								mx: 0.4,
								py: 0.5,
								"& button: ": { px: 0, widows: 0, height: 0, mx: 0 },
							}}>
							{RESERVATION_STATUS_CARD_TABS.map((tab, index) => {
								const { name, key } = tab
								const itemCount =
									name === "All"
										? data.length
										: data.filter((item) => item.status === key).length

								const tabWidth =
									name === "All"
										? pxToRem(35)
										: name === "In-Progress"
											? pxToRem(110)
											: pxToRem(80)

								const modifiedTabLabel = replaceWithDash(name, / /).replace("d", "")
								return (
									<Tab
										disableRipple={false}
										sx={{
											fontWeight: typography.fontWeightBold,
											padding: isEn ? 0 : 1,
											minWidth: pxToRem(isEn ? 20 : 60),
											width: isEn ? tabWidth : "auto",
										}}
										key={name}
										label={
											<Typography
												textAlign="center"
												textTransform="initial"
												whiteSpace="normal"
												fontWeight="bold"
												fontSize="size.sm">
												{`${t(modifiedTabLabel)} (${itemCount})`}
											</Typography>
										}
										{...a11yProps(index)}
									/>
								)
							})}
						</Tabs>
					</Box>

					{RESERVATION_STATUS_CARD_TABS.map((tab, index) => {
						const { name, key } = tab
						return (
							<CustomTabPanel
								key={index}
								value={selectedTab}
								index={index}
								sx={{ maxHeight: "22rem", overflowY: "scroll" }}>
								<Box sx={{ backgroundColor: palette.grey[200], p: 2 }}>
									{data.length ? (
										data.map((item) => {
											const Comp = (
												<ReservationCard
													key={item.id}
													time={item.start_time}
													trainer={item.appUser.name}
													reservationHolderName={item.user.name}
													fittingType={item.fitting_type}
													status={item.status}
													handleEditReservation={() =>
														handleEditReservationClick(item)
													}
												/>
											)

											return name === "All"
												? Comp
												: item.status === key && Comp
										})
									) : (
										<Typography
											fontWeight="regular"
											fontSize="size.md"
											sx={{
												marginY: pxToRem(32),
												textAlign: "center",
												color: "#878C9F",
											}}>
											{t("There is no reservation status.")}
										</Typography>
									)}
								</Box>
							</CustomTabPanel>
						)
					})}
				</Box>
			</Popover>
		</Box>
	)
}

export default ReservationStatusCard
