import { Box, Skeleton, Typography } from "@mui/material"

import { HolidayView } from ".."
import DataBoxForDayView from "./DataBoxForDayView"

const DayCalendarView = ({ selectedDay, t, palette, typography, isLoading, handleDataBox }) => {
	const { morningReservations, eveningReservations, holiday } = selectedDay

	const isLeftCountBigger = morningReservations?.length > eveningReservations?.length
	const isRightZero = eveningReservations?.length === 0

	return (
		<Box
			sx={{
				flexGrow: 2,
				display: "flex",
				justifyContent: "center",
				alignItems: "flex-start",
				padding: 0,
				margin: 0,
				border: `1px solid ${palette.grey[300]}`,
				borderRadius: "8px",
				background: palette.white.main,
				minWidth: "750px",
				width: "100%",
				maxWidth: "1300px",
				// "@media (max-width: 1020px)": {
				// 	minWidth: "700px",
				// 	width: "90%",
				// 	maxWidth: "1000px",
				// 	flexDirection: "column",
				// },
			}}>
			<DaySectorInfo
				holiday={holiday}
				palette={palette}
				typography={typography}
				label={t("AM")}
				data={morningReservations}
				drawBorderLeft={false}
				drawBorderRight={!isRightZero && isLeftCountBigger}
				isLoading={isLoading}
				handleDataBox={handleDataBox}
			/>
			<DaySectorInfo
				holiday={holiday}
				palette={palette}
				typography={typography}
				label={t("PM")}
				data={eveningReservations}
				drawBorderLeft={isRightZero || !isLeftCountBigger}
				drawBorderRight={false}
				// drawBorderTop={true}
				isLoading={isLoading}
				handleDataBox={handleDataBox}
			/>
		</Box>
	)
}

export default DayCalendarView

const DaySectorInfo = ({
	palette,
	typography,
	label,
	data,
	drawBorderLeft,
	drawBorderRight,
	// drawBorderTop = false,
	isLoading,
	handleDataBox,
	holiday,
}) => {
	return (
		<Box
			sx={{
				flexGrow: 1,
				width: "100%",
				minHeight: "100%",
				borderLeft: drawBorderLeft ? `1px solid ${palette.grey[300]}` : "none",
				borderRight: drawBorderRight ? `1px solid ${palette.grey[300]}` : "none",
				// "@media (max-width: 1020px)": {
				// 	borderLeft: "none",
				// 	borderRight: "none",
				// 	borderTop: drawBorderTop ? `1px solid ${palette.grey[300]}` : "none",
				// },
			}}>
			<Box
				sx={{
					borderBottom: `1px solid ${palette.grey[300]}`,
					height: "60px",
					maxHeight: "60px",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}>
				<Typography
					sx={{
						fontSize: typography.size.lg,
						fontWeight: 500,
						maxHeight: "60px",
					}}>
					{label}
				</Typography>
			</Box>
			<Box
				sx={{
					height: "100%",
					marginBottom: "20px",
				}}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}>
					{isLoading ? (
						<Skeleton
							variant="rounded"
							sx={{ borderRadius: "8px", color: "#eaeaea", mt: "20px" }}
							width="90%"
							height={60}
						/>
					) : (
						<>
							{holiday && (
								<HolidayView
									sx={{
										fontSize: typography.size.lg,
										fontWeight: 500,
										maxHeight: "60px",
										marginTop: "15px",
									}}>
									{holiday.description}
								</HolidayView>
							)}
							{!holiday && !data.length ? (
								<></>
							) : (
								data.map((item, index) => (
									<DataBoxForDayView
										key={index}
										marginTop={index === 0 ? "20px" : "10px"}
										reservation={item}
										handleDataBox={handleDataBox}
									/>
								))
							)}
						</>
					)}
				</Box>
			</Box>
		</Box>
	)
}
