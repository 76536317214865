import { useEffect, useState } from "react"

/**
 *	Debounce input text with specific delay time.
 * @param {string} value
 * @param {number} delay value in milliseconds
 * @returns string
 */
function useDebounce(value, delay = 1000) {
	const [debouncedValue, setDebouncedValue] = useState(value)

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			setDebouncedValue(value)
		}, delay)

		return () => clearTimeout(timeOutId)
	}, [value, delay])

	return debouncedValue
}

export default useDebounce
