import {
	DRIVER,
	EQUIPMENT_CLUB,
	EQUIPMENT_HEAD,
	EQUIPMENT_SHAFT,
	HYBRID,
	IRON,
	PUTTER,
	WEDGE,
	WOOD,
} from "constant"

export const API_AUTH = "/auth"
export const API_LOGOUT = `${API_AUTH}/logout`
export const API_LOGIN = `${API_AUTH}/login`
export const API_SEND_VERIFICATION_CODE = `${API_AUTH}/send-verification-code`
export const API_VERIFY_OTP = `${API_AUTH}/verify-otp`
export const API_SEND_NEW_USER_OTP = `${API_AUTH}/send-new-user-otp`
export const API_FIND_ID = `${API_AUTH}/find-app-user`
export const API_FIND_PASSWORD = `${API_AUTH}/find-password`
export const API_VERIFY_TOKEN = `${API_AUTH}/token`
export const API_RESET_PASSWORD = `${API_AUTH}/reset-password`
export const API_UPDATE_STORE = `${API_AUTH}/store`

export const API_STORE = "/store"
export const API_STORE_HELP = `${API_STORE}/help`
export const API_STORE_WORKDAY = `${API_STORE}/workday`
export const API_STORE_MEMBERS = `${API_STORE}/members`
export const API_STORE_MEMBERS_SEARCH = `${API_STORE_MEMBERS}/search`
export const API_STORE_FITTERS = `${API_STORE}/fitters`
export const API_STORE_ROOMS = `${API_STORE}/room`
export const API_STORE_MEMBERS_RESIGN = `${API_STORE_MEMBERS}/resign`
export const API_STORE_MEMBERS_CHANGE_ROLE = `${API_STORE_MEMBERS}/change-role`
export const API_STORE_MEMBERS_FITTER = (appUserId) => `${API_STORE_MEMBERS}/${appUserId}/fitter`
export const API_HOLIDAY = `${API_STORE}/holiday`
export const API_HOLIDAY_BY_YEAR = `${API_HOLIDAY}/list`
export const API_HOLIDAY_FOR_CALENDAR = `${API_HOLIDAY}/calendar`

export const ClubTypeAsPath = {
	[DRIVER]: "drivers",
	[IRON]: "irons",
	[WEDGE]: "wedges",
	[PUTTER]: "putters",
	[HYBRID]: "hybrids",
	[WOOD]: "woods",
}

const EQUIPMENT_TYPES = {
	head: "heads",
	shaft: "shafts",
}

export const API_CUSTOMER_CLUBS = (customerId, clubType) =>
	`customers/${customerId}/my-clubs/${clubType ? ClubTypeAsPath[clubType] : ""}`

export const API_CUSTOMER_ADD_CLUB = (customerId, clubType) =>
	`customers/${customerId}/my-clubs/${ClubTypeAsPath[clubType]}`

export const API_CUSTOMER_ADD_CLUB_VALIDATE = (customerId, clubType) =>
	`customers/${customerId}/my-clubs/${ClubTypeAsPath[clubType]}/validate`

export const API_CUSTOMER_ADD_CLUB_BATCH = (customerId, clubType) =>
	`customers/${customerId}/my-clubs/${ClubTypeAsPath[clubType]}/sets/batch`

export const API_CUSTOMER_ADD_CLUB_BATCH_VALIDATE = (customerId, clubType) =>
	`customers/${customerId}/my-clubs/${ClubTypeAsPath[clubType]}/sets/validate`

export const API_CUSTOMER_IRON_CLUB_SETS = (customerId) =>
	`customers/${customerId}/my-clubs/${ClubTypeAsPath.Iron}/sets`

export const API_CUSTOMER_CLUB_DETAIL = (customerId, clubType, clubId) =>
	`customers/${customerId}/my-clubs/${ClubTypeAsPath[clubType]}/${clubId}`

export const API_CUSTOMER_RESERVATIONS = (customerId, fittingType, pageNo) =>
	`customers/${customerId}/reservations?fitting_type=${fittingType}&page=${pageNo}&per_page=2`

export const API_CUSTOMER_RESERVATION_COUNT = (customerId) =>
	`customers/${customerId}/reservations/count`

export const API_STORE_ROOM_SAVE_CLUB = (roomId, clubType) =>
	`${API_STORE_ROOMS}/${roomId}/${
		Object.keys(ClubTypeAsPath).includes(clubType) ? ClubTypeAsPath[clubType] : clubType
	}`

export const API_STORE_ROOM_SAVE_CLUB_VALIDATE = (roomId, clubType) =>
	`${API_STORE_ROOMS}/${roomId}/${
		Object.keys(ClubTypeAsPath).includes(clubType) ? ClubTypeAsPath[clubType] : clubType
	}/validate`

export const API_STORE_ROOM_IRON_CLUB = (roomId) => `${API_STORE_ROOMS}/${roomId}/irons/sets`

export const API_STORE_ROOM_SAVE_CLUB_BATCH = (roomId, clubType) =>
	`${API_STORE_ROOMS}/${roomId}/${ClubTypeAsPath[clubType]}/sets/batch`

export const API_STORE_ROOM_SAVE_CLUB_BATCH_VALIDATE = (roomId, clubType) =>
	`${API_STORE_ROOMS}/${roomId}/${ClubTypeAsPath[clubType]}/sets/validate`

export const API_STORE_ROOM_CLUB_COPY = (roomId) => `${API_STORE_ROOMS}/${roomId}/club-copy`

export const API_STORE_ROOM_CLUB_COPY_CLUB_LIST = (roomId) =>
	`${API_STORE_ROOMS}/${roomId}/club-copy/club-lists`

export const API_STORE_ROOM_CLUB_ID = (roomId, clubType, clubId) =>
	`${API_STORE_ROOM_SAVE_CLUB(roomId, clubType)}/${clubId}`

export const API_STORE_INSTOCK_CLUBS = (clubType) => `club-data/${ClubTypeAsPath[clubType]}`
export const API_CLUB_MODEL = (clubType) => `${API_STORE_INSTOCK_CLUBS(clubType)}/models`

export const API_DRIVER_CLUB_OPTIONS = (clubType) => `valid/${ClubTypeAsPath[clubType]}`

export const API_IRON_NUMBER_OPTIONS = `valid/iron-sets`

export const API_VALID_USER_COMMON = `valid/user-common`

export const API_ANALYZERS = "/analyzers"
export const API_ANALYZERS_OFFICIAL = `${API_ANALYZERS}/official`

export const API_APP_USER = "/app-user"
export const API_APP_USER_STORE = `${API_APP_USER}/store`
export const API_APP_USER_SEARCH_NEW = `${API_APP_USER}/search/new`
export const API_APP_USER_SEARCH_EXISTS = `${API_APP_USER}/search/exists`
export const API_APP_USER_INVITE_STORE = `${API_APP_USER}/invite-store`
export const API_APP_USER_CHECK_PASSWORD = `${API_APP_USER}/check-password`
export const API_APP_USER_CHANGE_PASSWORD = `${API_APP_USER}/change-password`
export const API_APP_USER_ASSIGNED_STORES = `${API_APP_USER}/assigned-stores`
export const API_APP_USER_CHANGE_PHONE_NUMBER = `${API_APP_USER}/change-phone-number`
export const API_APP_USER_CHECK_NEW_MEMBER_INVITE = `${API_APP_USER}/check-new-member-invite`
export const API_APP_USER_CHANGE_PHONE_NUMBER_CONFIRM = `${API_APP_USER}/change-phone-number/confirm`
export const API_APP_USER_CHANGE_PROFILE = `${API_APP_USER}/profile`

export const API_RESERVATION = "/reservation"
export const SELECTABLE = "selectable"
export const API_RESERVATION_TODAY = `${API_RESERVATION}/today`
export const API_RESERVATION_MONTHLY = `${API_RESERVATION}/monthly`
export const API_RESERVATION_WEEKLY = `${API_RESERVATION}/weekly`
export const API_RESERVATION_DAILY = `${API_RESERVATION}/daily`
export const API_RESERVATION_FITTING_TYPES = `${API_RESERVATION}/fitting-types`
export const API_RESERVATION_SELECTABLE_MONTH = `${API_RESERVATION}/${SELECTABLE}/month`
export const API_RESERVATION_SELECTABLE_ROOMS = `${API_RESERVATION}/${SELECTABLE}/rooms`
export const API_RESERVATION_SELECTABLE_DAY = `${API_RESERVATION}/${SELECTABLE}/day`
export const API_SEND_RESERVATION_NOTIFICATION = (reservationId) =>
	`${API_RESERVATION}/${reservationId}/kakao`
export const API_RESERVATION_SELECTABLE_FITTERS = `${API_RESERVATION}/${SELECTABLE}/fitters`
export const API_RESERVATION_CHECK_CLUBS = (reservationId) =>
	`${API_RESERVATION}/${reservationId}/check-clubs`
export const API_RESERVATION_MEMO = (reservationId) => `${API_RESERVATION}/${reservationId}/memo`

export const API_VICX_USER = "/vicx-user"

export const API_CUSTOMERS = "/customers"
export const API_CUSTOMERS_ALL = `${API_CUSTOMERS}/all`
export const API_ACTIVE_CUSTOMERS = `${API_CUSTOMERS}/list`
export const API_CUSTOMERS_ADDRESS = `${API_CUSTOMERS}/address`
export const API_CUSTOMERS_ADDRESS_DEFAULT = `${API_CUSTOMERS_ADDRESS}/default`
export const API_OTHER_STORES_CUSTOMERS = `${API_CUSTOMERS}/other-stores`
export const API_SEND_OTP_TO_CUSTOMER = `${API_CUSTOMERS}/otp`
export const API_LINK_CUSTOMER = `${API_CUSTOMERS}/link`

export const API_CLUB_BRANDS = "/brands"
export const API_ALL_BRANDS = "/brands/list"

export const API_FITTING_MANAGEMENT = "/fitting-management"

export const API_EQUIPMENT_MANAGEMENT = (equipment, clubType) => {
	let uri = `/store/instock`
	if (equipment === EQUIPMENT_CLUB) {
		uri = uri + `/${ClubTypeAsPath[clubType]}`
	} else uri = uri + `/${equipment}`
	return uri
}

export const API_EQUIPMENT_MANAGEMENT_DELETE = (equipment, clubType, clubComponent) => {
	let uri = `/store/instock`
	if (equipment === EQUIPMENT_CLUB) {
		uri = uri + `/${ClubTypeAsPath[clubType]}/${clubComponent}`
	} else uri = uri + `/${equipment}`
	return uri
}

export const API_STORE_EQUIPMENTS = (clubType) => `/club-data/${ClubTypeAsPath[clubType]}`
export const API_STORE_ACCESSORIES_EQUIPMENTS = (equipment) => `/accessory-data/${equipment}`
export const API_EQUIPMENT_INSTOCKS = (equipment, clubType) => {
	let instockItem
	if (equipment === EQUIPMENT_CLUB) {
		instockItem = `${ClubTypeAsPath[clubType]}/${equipment}`
	} else if (equipment === EQUIPMENT_HEAD || equipment === EQUIPMENT_SHAFT) {
		instockItem = `${ClubTypeAsPath[clubType]}/${EQUIPMENT_TYPES[equipment]}`
	} else instockItem = equipment
	return `/store/instock/${instockItem}/`
}
