import { styled } from "@mui/material"
import Popover from "@mui/material/Popover"

import CoreDatePicker from "./CoreDatePicker"

const CustomPopover = styled(Popover)`
	.MuiPaper-root {
		box-shadow: none;
		background-color: transparent;
		border-radius: 10px;
	}
`
const PopOverDatePicker = ({
	anchorEl,
	setAnchorEl,
	selectedDate,
	onDayClick,
	disabledDates = [],
	selectableYearAndMonth,
	availableStartDate = null,
}) => {
	const handleClose = () => {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)
	const id = open ? "simple-popover" : undefined

	return (
		<CustomPopover
			id={id}
			open={open}
			anchorEl={anchorEl}
			onClose={handleClose}
			sx={{
				marginTop: "1px",
				// margin: "0 0 0 -190px",
			}}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "left",
			}}>
			<CoreDatePicker
				selectableYearAndMonth={selectableYearAndMonth}
				selectedDate={selectedDate}
				onDayClick={onDayClick}
				disabledDates={disabledDates}
				availableStartDate={availableStartDate}
				variant="popOver"
			/>
		</CustomPopover>
	)
}

export default PopOverDatePicker
