import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Typography } from "@mui/material"
import { AutoLoadingButton, ControlledAppTextField, ErrorMessage } from "components"
import { INCORRECT_CREDENTIALS_STATUSES } from "constant"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useFindPasswordMutation } from "services/modules/auth"
import { openSnackbar } from "stores/slice/snackbar"
import { ROUTE_RESET_PASSWORD } from "urls"
import { handleApiErrors } from "utils/helpers"
import { findPasswordSchema } from "validations"

const BASE_APP_URL = window.location.origin
const FindPasswordForm = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [isResetEmailSentForOnce, setIsResetEmailSentForOnce] = useState(false)

	const { formState, handleSubmit, setError, clearErrors, control } = useForm({
		resolver: yupResolver(findPasswordSchema(t)),
		mode: "all",
	})

	const errors = formState.errors

	const [findPassword] = useFindPasswordMutation()

	const handleFindPasswordPress = handleSubmit(async (values) => {
		try {
			await findPassword({
				...values,
				return_url: `${BASE_APP_URL}${ROUTE_RESET_PASSWORD}`,
			}).unwrap()

			!isResetEmailSentForOnce && setIsResetEmailSentForOnce(true)

			dispatch(
				openSnackbar({
					severity: "success",
					message: isResetEmailSentForOnce
						? t("The link has been resent.")
						: t("The password reset link has been sent."),
				})
			)
		} catch (err) {
			if (INCORRECT_CREDENTIALS_STATUSES.includes(err.response?.status)) {
				setError("serverError", {
					type: "incorrectCredentials",
					message: t("The information is incorrect. Check credentials."),
				})
				;["name", "email"].forEach((field) => setError(field))
			} else {
				handleApiErrors(err, dispatch, setError)
			}
		}
	})

	return (
		<Box p={4}>
			{isResetEmailSentForOnce ? (
				<Typography variant="body2" sx={{ color: "grey.700" }}>
					{t("The password reset link has been sent.")}{" "}
					<Typography variant="body2" sx={{ color: "primary.main", display: "inline" }}>
						{t("The sent link is valid only for 30 minutes.")}
					</Typography>{" "}
					{t("If you can’t find the email, please check your spam folder.")}
				</Typography>
			) : (
				<>
					<Box mb={2}>
						<ControlledAppTextField
							name="name"
							placeholder={t("Enter name")}
							error={Boolean(errors.name)}
							control={control}
							autoComplete="off"
							size="normal"
						/>

						{errors.name && <ErrorMessage message={errors.name.message} />}
					</Box>

					<Box mb={2}>
						<ControlledAppTextField
							name="email"
							placeholder={t("Enter ID (email)")}
							error={Boolean(errors.email)}
							control={control}
							autoComplete="off"
							size="normal"
							onClear={() => clearErrors("serverError")}
						/>

						{errors.email && <ErrorMessage message={errors.email.message} />}

						{errors.serverError && (
							<ErrorMessage message={errors.serverError.message} />
						)}
					</Box>
				</>
			)}

			<Box mt={6}>
				<AutoLoadingButton
					disabled={!formState.isDirty || !formState.isValid}
					size="normal"
					onClick={handleFindPasswordPress}
					variant="contained"
					color="primary"
					fullWidth>
					{isResetEmailSentForOnce ? t("Resend link") : t("Confirm")}
				</AutoLoadingButton>
			</Box>
		</Box>
	)
}

export default FindPasswordForm
