import axios from "axios"
import i18n from "i18next"

export const customAxios = axios.create({
	baseURL: `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_URL}`,
	withCredentials: true,
})

customAxios.defaults.headers = {
	...customAxios.defaults.headers,
	// needs to be a getter to make `Accept-Language` a computed value
	// as `i18n.language` can be update anytime by users
	get "Accept-Language"() {
		let lang = i18n.language || process.env.REACT_APP_DEFAULT_LANGUAGE
		return lang
	},
}

export default customAxios
