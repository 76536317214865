import createCache from "@emotion/cache"
import { CacheProvider } from "@emotion/react"
import { CssBaseline, ThemeProvider } from "@mui/material"
import theme from "assets/theme"
import intl from "i18n"
import React from "react"
import ReactDOM from "react-dom/client"
import { I18nextProvider } from "react-i18next"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/lib/integration/react"

import App from "./App"
import "./i18n"
import store, { persistor } from "./stores/store"

const cache = createCache({
	key: "css",
	prepend: true,
})

const root = document.getElementById("root")

const reactRoot = ReactDOM.createRoot(root)

console.info(`v${process.env.REACT_APP_VICXADMIN_VERSION}`)

export const AppMainWrapper = ({ children }) => (
	<React.StrictMode>
		<I18nextProvider i18n={intl}>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<CacheProvider value={cache}>
						<ThemeProvider theme={theme}>
							<CssBaseline />
							{children}
						</ThemeProvider>
					</CacheProvider>
				</PersistGate>
			</Provider>
		</I18nextProvider>
	</React.StrictMode>
)

reactRoot.render(
	<AppMainWrapper>
		<App />
	</AppMainWrapper>
)
