import colors from "assets/theme/base/palette"
import pxToRem from "assets/theme/functions/pxToRem"

const { grey } = colors

export default {
	borderColor: grey[300],

	borderWidth: {
		0: 0,
		1: pxToRem(1),
		2: pxToRem(2),
		3: pxToRem(3),
		4: pxToRem(4),
		5: pxToRem(5),
	},

	borderRadius: {
		xs: pxToRem(1.6),
		sm: pxToRem(2),
		md: pxToRem(6),
		lg: pxToRem(8),
		xl: pxToRem(12),
		xxl: pxToRem(16),
		xxxl: pxToRem(20),
		select: pxToRem(50),
		section: pxToRem(160),
	},
}
