export const ROUTE_GATEWAY = "/gateway"

// auth
export const ROUTE_LOGIN = "/login"
export const ROUTE_FIND_CREDENTIALS = "/find-credentials"
export const ROUTE_SIGN_UP = "/signup"

export const ROUTE_AUTH = "/auth"
export const ROUTE_RESET_PASSWORD = "/reset-password"

export const ROUTE_DASHBOARD = "/dashboard"

export const ROUTE_PROFILE = "/profile"
export const ROUTE_PROFILE_RECONFIRM_PASSWORD = `${ROUTE_PROFILE}/reconfirm-password`
export const ROUTE_PROFILE_MANAGEMENT = `${ROUTE_PROFILE}/management`
export const ROUTE_PROFILE_CHANGE_PASSWORD = `${ROUTE_PROFILE}/change-password`
export const ROUTE_PROFILE_CHANGE_PHONE_NUMBER = `${ROUTE_PROFILE}/change-phone-number`

export const ROUTE_MEMBERS = "/members"
export const ROUTE_MEMBERS_DETAIL = `${ROUTE_MEMBERS}/:id`

// reservation
export const ROUTE_RESERVATIONS = "/reservations"

// fitting
export const ROUTE_FITTING = "/fitting"
export const ROUTE_FITTING_DETAIL = `${ROUTE_FITTING}/:id`

// order
export const ROUTE_ORDERS = "/orders"

// store
export const ROUTE_STORES = "/stores"
export const ROUTE_STORES_MANAGEMENT = `${ROUTE_STORES}/management`
export const ROUTE_STORES_VICX_SYSTEM = `${ROUTE_STORES}/vicx-system`
export const ROUTE_STORES_VICX_SYSTEM_CHANGE_PASSWORD = `${ROUTE_STORES_VICX_SYSTEM}/change-password`
export const ROUTE_STORES_VICX_SYSTEM_CHANGE_PASSWORD_CONFIRM = `${ROUTE_STORES_VICX_SYSTEM_CHANGE_PASSWORD}/confirm`
export const ROUTE_STORE_OPERATING_HOURS = `${ROUTE_STORES}/operating-hours`
export const ROUTE_STORE_CLUB_SETTINGS = `${ROUTE_STORES}/club-settings`
export const ROUTE_STORE_ROOM_CUSTOMIZATION = `${ROUTE_STORES}/room-customization`

// staff
export const ROUTE_STAFF = "/staff"
export const ROUTE_STAFF_ADD = `${ROUTE_STAFF}/add`
export const ROUTE_STAFF_ADD_NEW = `${ROUTE_STAFF_ADD}/new`
export const ROUTE_STAFF_ADD_EXISTING = `${ROUTE_STAFF_ADD}/existing`
export const ROUTE_STAFF_MANAGEMENT = `${ROUTE_STAFF}/management`

export const ROUTE_SETTINGS = "/settings"

export const ROUTE_TEMP = "/temp"

export const ROUTE_EQUIPMENT_MANAGEMENT = "/equipment-management"

export const ROUTE_HELP = "/help"
export const ROUTE_HELP_DETAIL = `${ROUTE_HELP}/:id`
export const ROUTE_HELP_REGISTRATION = `${ROUTE_HELP}/registration`

export const ROUTE_DEFAULT = ROUTE_DASHBOARD

export const ROUTE_ABOUT = "/about"
export const ROUTE_TOS = `${ROUTE_ABOUT}/tos`
export const ROUTE_PRIVACY_POLICY = `${ROUTE_ABOUT}/privacy`
export const ROUTE_MARKETING_CONSENT_POLICY = `${ROUTE_ABOUT}/marketing-consent`
