import { object, string } from "yup"

export function putterOnlyShaftSchema(t) {
	return object({
		brand: string().required(t("Enter the brand")),
		model: string().required(t("Enter the model")),
		flex: string().required(t("Select the flex")),
		material: string().required(t("Select the Material")),
		grip_size: string().required(t("Select the grip size")),
	})
}

export function putterClubShaftSchema(t, register) {
	return object({
		brand: string().required(t("Enter the brand")),
		model: string().required(t("Enter the model")),
		weight:
			register === "customer"
				? string().nullable()
				: string().required(t("Select the weight")),
		length:
			register === "customer"
				? string().nullable()
				: string().required(t("Select the length")),
	})
}

//  NOTE: ** Register can be customer or store. depend on register type some of fields are not required.
const putterClubFormSchema = (t, register) =>
	object({
		club: string().required(t("Select the club")),
		total_weight:
			register === "customer" ? string().nullable() : string().required().nullable(),
		swing_weight: register === "customer" ? string().nullable() : string().required(),
		head: object({
			brand: string().required(t("Enter the brand")),
			model: string().required(t("Enter the model")),
			loft:
				register === "customer"
					? string().nullable()
					: string().required(t("Select the loft")),
			lie_angle:
				register === "customer"
					? string().nullable()
					: string().required(t("Select the lie angle")).nullable(),
		}),
		shaft: putterClubShaftSchema(t, register),
	}).required()

export default putterClubFormSchema
