import styled from "@emotion/styled"
import { useTheme } from "@mui/material"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Stack from "@mui/material/Stack"
import TableContainer from "@mui/material/TableContainer"
import Typography from "@mui/material/Typography"
import { flexRender } from "@tanstack/react-table"
import { PolygonSvg } from "assets/svgs"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import AppPagination from "./pagination"

const TableWrapper = styled.div`
	table {
		text-align: center;
		color: #878c9f;
		width: 100%;
		border-spacing: 0;
		border: 1px solid #d5d5d5;
		border-radius: 8px;
	}

	thead {
		height: 60px;
		background-color: #dce3ff;
		color: #000;
	}

	th {
		&:hover {
			cursor: pointer;
		}
	}

	td {
		padding: 15px 15px;
		border-top: 1px solid #d5d5d5;
	}

	td,
	th {
		font-size: 1rem;
		white-space: nowrap;
	}

	.data-row {
		&:hover {
			background-color: #f5f5f5;
		}
	}

	.selected-row,
	.selected-row:hover {
		background-color: #dce3ff;
	}

	tbody > tr:hover {
		background-color: rgb(245, 245, 245, 0.4);
		cursor: pointer;
	}
`

const iconStyle = {
	height: "16px",
	width: "16px",
}

/*
import {
	getCoreRowModel,
	getPaginationRowModel,
	useReactTable,
} from "@tanstack/react-table"

const table = useReactTable({
	data,
	columns,
	getCoreRowModel: getCoreRowModel(),
	getPaginationRowModel: getPaginationRowModel(),
})
*/
export function PureTable({ table, isLoading, disabledSorting, onRowClick }) {
	const { palette } = useTheme()
	const { t } = useTranslation()
	const [currentPage, setCurrentPage] = useState(1)

	const [hoveredHeader, setHoveredHeader] = useState({})

	const totalPages = table.getPageCount()

	useEffect(() => {
		table.setPageIndex(0)
		setCurrentPage(1)
	}, [totalPages])

	return (
		<Box py={3}>
			<TableContainer sx={{ backgroundColor: palette.white.main, borderRadius: "8px" }}>
				<TableWrapper>
					<table>
						<thead>
							{table.getHeaderGroups().map((headerGroup) => (
								<tr key={headerGroup.id}>
									{headerGroup.headers.map((header) => {
										let cursor
										let canSort = header.column.getCanSort()
										const sorted = header.column.getIsSorted()

										if (disabledSorting === true) {
											cursor = "default"
											canSort = false
										} else if (!canSort) {
											cursor = "default"
										}

										const canDecorate =
											canSort && hoveredHeader.id === header.id

										return (
											<th
												key={header.id}
												colSpan={header.colSpan}
												onMouseEnter={() => {
													setHoveredHeader(header)
												}}
												onMouseLeave={() => {
													setHoveredHeader({})
												}}
												style={{
													maxWidth: header.getSize(),
													cursor: cursor,
													textUnderlineOffset: canDecorate && 8,
													textDecorationLine: canDecorate && "underline",
												}}>
												<Stack
													direction="row"
													justifyContent="center"
													gap={0.5}>
													<Box
														paddingLeft={iconStyle.width}
														sx={{ mb: 0.5, whiteSpace: "nowrap" }}>
														{flexRender(
															header.column.columnDef.header,
															header.getContext()
														)}
													</Box>

													<Box width={iconStyle.width}>
														{canSort &&
															hoveredHeader.id === header.id && (
																<PolygonSvg
																	onClick={() => {
																		header.column.toggleSorting(
																			sorted !== "desc"
																		)
																	}}
																	style={{
																		...iconStyle,
																		marginTop: 5,
																		transform:
																			sorted === "desc" &&
																			"rotate(180deg)",
																	}}
																/>
															)}
													</Box>
												</Stack>
											</th>
										)
									})}
								</tr>
							))}
						</thead>
						<tbody>
							{table.getRowModel().rows.map((row) => {
								return (
									<tr
										key={row.id}
										className={`data-rows ${
											row.getIsSelected() ? "selected-row" : ""
										}`}
										onClick={() => onRowClick?.(row)}>
										{row.getVisibleCells().map((cell) => {
											return (
												<td key={cell.id}>
													{flexRender(
														cell.column.columnDef.cell,
														cell.getContext()
													)}
												</td>
											)
										})}
									</tr>
								)
							})}
						</tbody>
					</table>
				</TableWrapper>
			</TableContainer>

			{totalPages !== 0 && (
				<AppPagination
					currentPage={currentPage}
					onChange={(page) => {
						table.setPageIndex(page - 1)
						setCurrentPage(page)
						table.resetRowSelection()
					}}
					totalPages={totalPages}
					onFirst={{
						onClick: () => table.setPageIndex(0),
						disabled: !table.getCanPreviousPage(),
					}}
					onPrevious={{
						onClick: () => table.previousPage(),
						disabled: !table.getCanPreviousPage(),
					}}
					onLast={{
						onClick: () => table.setPageIndex(totalPages - 1),
						disabled: !table.getCanNextPage(),
					}}
					onNext={{
						onClick: () => table.nextPage(),
						disabled: !table.getCanNextPage(),
					}}
				/>
			)}

			{(isLoading || totalPages === 0) && (
				<Box sx={{ textAlign: "center", paddingTop: "10%" }}>
					{isLoading ? (
						<CircularProgress />
					) : (
						totalPages === 0 && <Typography>{t("No data available")}</Typography>
					)}
				</Box>
			)}
		</Box>
	)
}
