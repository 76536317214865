import { Box, Icon, Menu, Typography, useTheme } from "@mui/material"
import { HomeSvg, VicxAdminLogoSvg } from "assets/svgs"
import { AutoLoadingButton, LangSwitch } from "components"
import AppAvatar from "components/AppAvatar"
import { ICON_NAMES } from "constant"
import useAnchorElement from "hooks/useAnchorElement"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { findRouteByPath } from "routes"
import { appUserApi } from "services/modules"
import { useStore } from "stores/slice/store"
import { removeCheckedPassword, useUser, useUserStoreRole } from "stores/slice/user"
import {
	ROUTE_DASHBOARD,
	ROUTE_LOGIN,
	ROUTE_MARKETING_CONSENT_POLICY,
	ROUTE_PRIVACY_POLICY,
	ROUTE_PROFILE_RECONFIRM_PASSWORD,
	ROUTE_SIGN_UP,
	ROUTE_STAFF_MANAGEMENT,
	ROUTE_STORES_MANAGEMENT,
	ROUTE_TOS,
} from "urls"

import EditProfilePicture from "./EditProfilePicture"
import FullWidthContainer from "./FullWidthContainer"
import { LogoutDesktop, LogoutMobile } from "./Logout"
import NavbarLink from "./NavbarLink"
import Notification from "./Notification"
import ReservationStatusCard from "./ReservationStatusCard"
import StoreSelector from "./StoreSelector"

const termsPaths = [ROUTE_TOS, ROUTE_PRIVACY_POLICY, ROUTE_MARKETING_CONSENT_POLICY]

const hideLoginButtonPaths = [ROUTE_LOGIN, ROUTE_SIGN_UP, ...termsPaths]

function AppTopnav({ transparent, light, action, isBordered }) {
	const { pathname } = useLocation()
	const theme = useTheme()

	const {
		functions: { pxToRem },
		breakpoints,
	} = theme

	const route = findRouteByPath(pathname)

	const [darkMode] = useState(false) // TODO: darkMode setting
	const [mobileNavbar, setMobileNavbar] = useState(false)
	const [mobileView, setMobileView] = useState(false)

	const openMobileNavbar = ({ currentTarget }) => setMobileNavbar(currentTarget.parentNode)
	const closeMobileNavbar = () => setMobileNavbar(false)

	const { t } = useTranslation()
	const dispatch = useDispatch()

	const user = useUser()
	const activeStore = useStore()

	const [fetchAppUser, { data: appUserDataRes }] = appUserApi.useLazyGetAppUserQuery()
	const appUserData = appUserDataRes?.data
	const profilePicture = appUserData?.photos?.path || ""

	const userStoreRole = useUserStoreRole()

	const { setAnchorEl, ...anchor } = useAnchorElement()

	const handleOpenMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleCloseMenu = () => {
		setAnchorEl(null)
	}

	const handleEditProfileClick = () => {
		handleCloseMenu()
		dispatch(removeCheckedPassword())
	}

	useEffect(() => {
		// A function that sets the display state for the NavbarMobile.
		function displayMobileNavbar() {
			if (window.innerWidth < breakpoints.values.lg) {
				setMobileView(true)
				setMobileNavbar(false)
			} else {
				setMobileView(false)
				setMobileNavbar(false)
			}
		}
		window.addEventListener("resize", displayMobileNavbar)

		// Call the displayMobileNavbar function to set the state with the initial value.
		displayMobileNavbar()

		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", displayMobileNavbar)
	}, [])

	useEffect(() => {
		if (user.id !== null) {
			fetchAppUser()
		}
	}, [user.id])

	function renderProfileMenu() {
		return (
			<Menu
				anchorReference={null}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				{...anchor}
				onClose={handleCloseMenu}>
				<Box my={3}>
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						width={pxToRem(320)}
						my={3}
						gap={3}>
						<EditProfilePicture profilePicture={profilePicture} />
						<StoreSelector closeMenu={handleCloseMenu} />
					</Box>
					<Box px={2}>
						<NavbarLink
							icon={ICON_NAMES.EDIT}
							name={t("Personal information ")}
							route={ROUTE_PROFILE_RECONFIRM_PASSWORD}
							onClick={handleEditProfileClick}
						/>
						<NavbarLink
							icon={<HomeSvg fill="currentColor" />}
							name={t("Store information")}
							route={ROUTE_STORES_MANAGEMENT}
							onClick={handleCloseMenu}
						/>
						<NavbarLink
							icon={ICON_NAMES.GROUP}
							name={t("Staff information")}
							route={ROUTE_STAFF_MANAGEMENT}
							onClick={handleCloseMenu}
						/>

						<LogoutDesktop />
					</Box>
				</Box>
			</Menu>
		)
	}

	const TitleSection = () => {
		const isSidenavVisiblePage = route?.sidenavVisible && activeStore?.name

		if (isSidenavVisiblePage) {
			return (
				<Typography
					size="lg"
					fontWeight="bold"
					px={2}
					sx={({ palette }) => ({
						color: light ? palette.dark.main : palette.white.main,
						minWidth: pxToRem(220),
						backgroundColor: palette.navy.main,
						borderRadius: pxToRem(8),
						textAlign: "center",
						cursor: "default",
					})}>
					{activeStore.name}
				</Typography>
			)
		}

		return (
			<Box
				component={Link}
				to={ROUTE_DASHBOARD}
				py={transparent ? 1.5 : 0.75}
				lineHeight={1}
				pl={{ xs: 0, lg: 1 }}
				sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
				<VicxAdminLogoSvg />
			</Box>
		)
	}

	return (
		<FullWidthContainer
			sx={{ ownerProps: { isBordered: isBordered || route?.isTopnavBordered } }}>
			<Box
				py={1}
				px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 2 }}
				my={3}
				mx={3}
				color={light ? "white" : "dark"}
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				sx={({
					palette: { transparent: transparentColor, white, background },
					functions: { rgba },
				}) => ({
					backgroundColor: transparent
						? transparentColor.main
						: rgba(darkMode ? background.sidenav : white.main, 0.8),
					backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
					margin: "0 !important",
					height: "100%",
				})}>
				<TitleSection />

				<Box
					color="inherit"
					display={{ xs: "none", lg: "flex" }}
					m={0}
					p={0}
					sx={{ alignItems: "center" }}>
					{termsPaths.includes(pathname) ? null : user.id && userStoreRole.store_id ? (
						<>
							<Box sx={{ mr: 5, display: "flex", alignItems: "center" }}>
								<ReservationStatusCard />

								<Notification />

								{process.env.NODE_ENV === "development" && (
									<LangSwitch sx={{ ml: 2 }} />
								)}
							</Box>
							<Typography fontSize="size.lg" mr={1.2}>
								{user?.name}
							</Typography>
							<AppAvatar
								src={profilePicture}
								alt={user?.name}
								size="medium"
								onClickHandler={handleOpenMenu}
								hoverEffect
							/>
							{renderProfileMenu()}
						</>
					) : user.id ? (
						<LogoutDesktop />
					) : !hideLoginButtonPaths.includes(pathname) ? (
						<NavbarLink
							icon={ICON_NAMES.PERSON}
							name={t("Login")}
							route={ROUTE_LOGIN}
							light={light}
						/>
					) : null}
				</Box>

				{action &&
					(action.type === "internal" ? (
						<Box display={{ xs: "none", lg: "inline-block" }}>
							<AutoLoadingButton
								component={Link}
								to={action.route}
								variant="gradient"
								color={action.color ? action.color : "info"}
								size="small">
								{action.label}
							</AutoLoadingButton>
						</Box>
					) : (
						<Box display={{ xs: "none", lg: "inline-block" }}>
							<AutoLoadingButton
								component="a"
								href={action.route}
								target="_blank"
								rel="noreferrer"
								variant="gradient"
								color={action.color ? action.color : "info"}
								size="small"
								sx={{ mt: -0.3 }}>
								{action.label}
							</AutoLoadingButton>
						</Box>
					))}
				{!hideLoginButtonPaths.includes(pathname) && (
					<Box
						display={{ xs: "inline-block", lg: "none" }}
						lineHeight={0}
						py={1.5}
						pl={1.5}
						color="inherit"
						sx={{ cursor: "pointer" }}
						onClick={openMobileNavbar}>
						<Icon fontSize="default">{mobileNavbar ? "close" : "menu"}</Icon>
					</Box>
				)}
			</Box>
			{mobileView && (
				<LogoutMobile
					open={mobileNavbar}
					closeMobileNavbar={closeMobileNavbar}
					mobileNavbar={mobileNavbar}
				/>
			)}
		</FullWidthContainer>
	)
}

// Setting default values for the props of DefaultNavbar
AppTopnav.defaultProps = {
	transparent: false,
	light: false,
	action: false,
}

// Typechecking props for the DefaultNavbar
AppTopnav.propTypes = {
	transparent: PropTypes.bool,
	light: PropTypes.bool,
	action: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.shape({
			type: PropTypes.oneOf(["external", "internal"]).isRequired,
			route: PropTypes.string.isRequired,
			color: PropTypes.oneOf([
				"primary",
				"secondary",
				"info",
				"success",
				"warning",
				"error",
				"dark",
				"light",
			]),
			label: PropTypes.string.isRequired,
		}),
	]),
}

export default AppTopnav
