import { Box, Typography, styled } from "@mui/material"
import pxToRem from "assets/theme/functions/pxToRem"
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

const TabItem = styled(Box)(
	({ theme: { palette, spacing }, ownerState: { isActive, isReadOnly } }) => ({
		borderBottom: `3px solid ${isActive ? palette.primary.main : palette.grey[300]}`,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: `${spacing(4)} 0`,
		color: isActive ? palette.dark : palette.grey[300],
		transition: "all ease-in-out 0.3s",
		cursor: isReadOnly ? "auto" : "pointer",
		pointerEvents: isReadOnly ? "none" : "auto",
		zIndex: 10, // overlapping issue
		flex: 1, // In multiple tabs, each TabItem will occupy equal space.
	})
)

const TabContainer = styled(Box)(() => ({
	width: "100%",
	display: "flex",
}))

const AppTabs = ({ tabItems, activeTab = tabItems[0], setActiveTab, isReadOnly }) => {
	const { t } = useTranslation()

	return (
		<TabContainer>
			{tabItems.map((tabItem) => {
				const isActiveTab = activeTab.id === tabItem.id
				return (
					<TabItem
						ownerState={{
							isActive: isActiveTab,
							isReadOnly,
						}}
						key={tabItem.id}
						onClick={() => setActiveTab(tabItem)}>
						<Typography
							sx={{ maxWidth: pxToRem(230), textAlign: "center" }}
							variant="h4"
							color="inherit">
							{t(tabItem.name)}
						</Typography>
					</TabItem>
				)
			})}
		</TabContainer>
	)
}

AppTabs.defaultProps = {
	setActiveTab: new Function(),
	isReadOnly: false,
}

AppTabs.propTypes = {
	tabItems: PropTypes.arrayOf(PropTypes.object).isRequired,
	activeTab: PropTypes.object,
	setActiveTab: PropTypes.func,
	isReadOnly: PropTypes.bool,
}

export default AppTabs

// consumer guide
// ==============
// [1] if you only has one tab, you can just pass the props (tabItems, isReadOnly - with true value) is enough
// [2] but if you has multiple tabs,
// you need to pass the props (tabItems, activeTab, setActiveTab) - without isReadOnly (false by default)
