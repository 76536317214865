import { RESERVATION_STATUSES } from "constant"
import { api } from "services/api"
import {
	API_RESERVATION,
	API_RESERVATION_CHECK_CLUBS,
	API_RESERVATION_DAILY,
	API_RESERVATION_FITTING_TYPES,
	API_RESERVATION_MEMO,
	API_RESERVATION_MONTHLY,
	API_RESERVATION_SELECTABLE_DAY,
	API_RESERVATION_SELECTABLE_FITTERS,
	API_RESERVATION_SELECTABLE_MONTH,
	API_RESERVATION_SELECTABLE_ROOMS,
	API_RESERVATION_TODAY,
	API_RESERVATION_WEEKLY,
	API_SEND_RESERVATION_NOTIFICATION,
} from "services/endpoints"
import {
	TAG_RESERVATION,
	TAG_RESERVATION_FITTING_TYPES,
	TAG_RESERVATION_LIST,
	TAG_RESERVATION_SELECTABLE_DAY,
	TAG_RESERVATION_SELECTABLE_FITTERS,
	TAG_RESERVATION_SELECTABLE_ROOMS,
	TAG_RESERVATION_SELECTABLE_TIME_SLOTS,
} from "services/tags"

const selectableTags = [
	TAG_RESERVATION_SELECTABLE_TIME_SLOTS,
	TAG_RESERVATION_SELECTABLE_FITTERS,
	TAG_RESERVATION_SELECTABLE_ROOMS,
	TAG_RESERVATION_SELECTABLE_DAY,
]

// TODO: follow naming conventions - put the prefix 'get' (no more 'fetch' or 'alike') before any query | 'update' (no more 'modify' or 'alike') for mutation | no more like "weeklyReservationList" -> it should be "getWeeklyReservations" (preferred - shorter and intuitive) or "getWeeklyReservationList"
export default api.injectEndpoints({
	endpoints(build) {
		return {
			listByToday: build.query({
				query(date) {
					return {
						url: `${API_RESERVATION_TODAY}/${date}`,
					}
				},

				transformResponse: ({ data }) => {
					return data.filter(
						(item) =>
							![
								RESERVATION_STATUSES.CANCELLED.value,
								RESERVATION_STATUSES.NOSHOW.value,
							].includes(item.status)
					)
				},

				providesTags: [TAG_RESERVATION_LIST],
			}),

			getAllReservations: build.query({
				query(params) {
					return {
						url: API_RESERVATION,
						params,
					}
				},

				providesTags: [TAG_RESERVATION_LIST],
			}),

			getReservationHasClub: build.query({
				query(reservationID) {
					return {
						url: API_RESERVATION_CHECK_CLUBS(reservationID),
					}
				},
			}),

			monthlyReservationList: build.query({
				query({ date, ...params }) {
					return {
						url: `${API_RESERVATION_MONTHLY}/${date}`,
						params,
					}
				},

				providesTags: [TAG_RESERVATION_LIST],
			}),

			weeklyReservationList: build.query({
				query({ date, ...params }) {
					return {
						url: `${API_RESERVATION_WEEKLY}/${date}`,
						params,
					}
				},

				providesTags: [TAG_RESERVATION_LIST],
			}),

			dailyReservationList: build.query({
				query({ date, ...params }) {
					return {
						url: `${API_RESERVATION_DAILY}/${date}`,
						params,
					}
				},

				providesTags: [TAG_RESERVATION_LIST],
			}),

			getReservationFittingTypes: build.query({
				query() {
					return {
						url: API_RESERVATION_FITTING_TYPES,
					}
				},

				providesTags: [TAG_RESERVATION_FITTING_TYPES],
			}),

			getSelectableTimeSlotsPerMonth: build.query({
				query(params) {
					return {
						url: API_RESERVATION_SELECTABLE_MONTH,
						params,
					}
				},

				providesTags: [TAG_RESERVATION_SELECTABLE_TIME_SLOTS],
			}),

			getSelectableRooms: build.query({
				query(params) {
					return {
						url: API_RESERVATION_SELECTABLE_ROOMS,
						params,
					}
				},

				providesTags: [TAG_RESERVATION_SELECTABLE_ROOMS],
			}),

			getSelectableTimeSlotsPerDay: build.query({
				query(params) {
					return {
						url: API_RESERVATION_SELECTABLE_DAY,
						params,
					}
				},

				transformResponse({ data }) {
					return data.reduce(
						(init, current) => {
							if (Number(current.slice(0, 2)) < 12) {
								init[0].timeSlots.push(current)
							} else {
								init[1].timeSlots.push(current)
							}

							return init
						},
						[
							{
								label: "AM",
								timeSlots: [],
							},
							{
								label: "PM",
								timeSlots: [],
							},
						]
					)
				},

				providesTags: [TAG_RESERVATION_SELECTABLE_DAY],
			}),

			getSelectableFitters: build.query({
				query(params) {
					return {
						url: API_RESERVATION_SELECTABLE_FITTERS,
						params,
					}
				},

				providesTags: [TAG_RESERVATION_SELECTABLE_FITTERS],
			}),

			createReservation: build.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: API_RESERVATION,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_RESERVATION_LIST, ...selectableTags] : []),
			}),

			updateReservationMemo: build.mutation({
				query({ id, data }) {
					return {
						data,
						method: "PATCH",
						url: `${API_RESERVATION}/${id}`,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_RESERVATION_LIST, TAG_RESERVATION] : []),
			}),

			updateListViewReservationMemo: build.mutation({
				query({ id, data }) {
					return {
						data,
						method: "PUT",
						url: API_RESERVATION_MEMO(id),
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_RESERVATION_LIST, TAG_RESERVATION] : []),
			}),

			resendReservationNotification: build.mutation({
				query(reservationID) {
					return {
						method: "POST",
						url: API_SEND_RESERVATION_NOTIFICATION(reservationID),
					}
				},
			}),

			getReservationDetail: build.query({
				query(id) {
					return {
						method: "GET",
						url: `${API_RESERVATION}/${id}`,
					}
				},

				providesTags: [TAG_RESERVATION],
			}),

			updateReservation: build.mutation({
				query({ id, data }) {
					return {
						data,
						method: "PUT",
						url: `${API_RESERVATION}/${id}`,
					}
				},

				invalidatesTags: (ok) =>
					ok ? [TAG_RESERVATION_LIST, TAG_RESERVATION, ...selectableTags] : [],
			}),

			updateReservationClubs: build.mutation({
				query({ id, data }) {
					return {
						data,
						method: "PUT",
						url: `${API_RESERVATION}/${id}/clubs`,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_RESERVATION_LIST, TAG_RESERVATION] : []),
			}),
		}
	},
})
