import AppTextField, { AppTextFieldPropTypes } from "components/form/AppTextField"
import PropTypes from "prop-types"
import { Controller } from "react-hook-form"

const ControlledAppTextField = ({
	name,
	type,
	placeholder,
	error,
	control,
	extraControlProps,
	disabled,
	showCloseIconOnDisabledInput,
	...rest
}) => {
	return (
		<Controller
			render={({ field: { ref, value, ...field } }) => {
				return (
					<AppTextField
						size="large"
						type={type}
						placeholder={placeholder}
						fullWidth
						error={error}
						controlledRef={ref}
						disabled={disabled}
						showCloseIconOnDisabledInput={showCloseIconOnDisabledInput}
						value={value}
						{...field}
						{...rest}
					/>
				)
			}}
			name={name}
			control={control}
			{...extraControlProps}
		/>
	)
}

ControlledAppTextField.propTypes = {
	name: PropTypes.string.isRequired,
	control: PropTypes.object.isRequired,
	extraControlProps: PropTypes.object,
	inputProps: PropTypes.shape(AppTextFieldPropTypes),
}

export default ControlledAppTextField

// NOTE
// ====
// AppTextField controlled by RHF to ease the development (only compatible with the RHF lib)
