import { NOT_SURE_LABEL, NOT_SURE_VALUE } from "constant"
import { api } from "services/api"
import {
	API_DRIVER_CLUB_OPTIONS,
	API_IRON_NUMBER_OPTIONS,
	API_VALID_USER_COMMON,
} from "services/endpoints"
import { TAG_VALID_USER_COMMON } from "services/tags"

export default api.injectEndpoints({
	endpoints(build) {
		return {
			getClubOptions: build.query({
				query({ clubType }) {
					return {
						url: API_DRIVER_CLUB_OPTIONS(clubType),
					}
				},
			}),

			getIronNumbers: build.query({
				query() {
					return {
						url: API_IRON_NUMBER_OPTIONS,
					}
				},
			}),

			getCommonOption: build.query({
				query() {
					return {
						url: API_VALID_USER_COMMON,
					}
				},

				transformResponse: ({ glove_sizes, handicaps, experiences, ...data }) => {
					glove_sizes.unshift(NOT_SURE_VALUE)
					handicaps.unshift(NOT_SURE_VALUE)
					return {
						glove_sizes: glove_sizes.map(
							(item) =>
								item === NOT_SURE_VALUE
									? { value: item, label: NOT_SURE_LABEL }
									: { value: `${item}`, label: `${item}` } // this need to be string, otherwise the value will not be shown
						),
						handicaps: handicaps.map((item) =>
							item === NOT_SURE_VALUE
								? { value: item, label: NOT_SURE_LABEL }
								: item === 0
									? { value: "0", label: "0" } // for the value type number (0)
									: { value: item, label: item }
						),
						experiences: experiences.map((item) => ({
							value: item.value,
							label: item.display,
						})),
						...data,
					}
				},

				providesTags: [TAG_VALID_USER_COMMON],
			}),
		}
	},
})
