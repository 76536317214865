import { yupResolver } from "@hookform/resolvers/yup"
import { StyledAutoLoadingButton, StyledFlexBox } from "components/dialog/shared"
import { DATE_FORMATS, RESERVATION_STATUSES } from "constant"
import ReservationForm from "layouts/reservations/components/ReservationForm"
import ReservationFormCustomerInfo from "layouts/reservations/components/ReservationFormCustomerInfo"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { reservationApi } from "services/modules"
import { openSnackbar } from "stores/slice/snackbar"
import { ROUTE_MEMBERS } from "urls"
import { convertMinutesToHours, getMinuteDifference, stringToDate } from "utils/dateTime"
import { handleError } from "utils/helpers"
import { reservationSchema } from "validations"
import { STEPABLE } from "validations/reservationSchema"

const ReservationEditForm = ({ initialReservationDetails, handleDialogClose, openClubModal }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const {
		id,
		fitting_type,
		fitting_type_item_ids,
		reservation_date,
		start_time,
		end_time,
		memo,
		storeRoom,
		appUser,
		status,
		user,
		user_id,
		clubs,
	} = initialReservationDetails

	const [selectedRoomName, setSelectedRoomName] = useState(storeRoom.name)
	const [selectedFitterName, setSelectedFitterName] = useState(appUser.name)
	const [totalDuration, setTotalDuration] = useState(
		convertMinutesToHours(
			getMinuteDifference(
				stringToDate({
					dateString: end_time,
					formatFrom: DATE_FORMATS.BACKEND_RESERVATION_TF,
				}),
				stringToDate({
					dateString: start_time,
					formatFrom: DATE_FORMATS.BACKEND_RESERVATION_TF,
				})
			)
		)
	)

	const [updateReservation] = handleError(reservationApi.useUpdateReservationMemoMutation)
	const [updateReservationClubs] = handleError(reservationApi.useUpdateReservationClubsMutation)
	const [resendReservationNotification] = handleError(
		reservationApi.useResendReservationNotificationMutation
	)

	const {
		formState: { isValid, errors },
		setValue,
		watch,
		control,
		trigger,
		handleSubmit,
	} = useForm({
		resolver: yupResolver(reservationSchema(t)),
		defaultValues: {
			[STEPABLE]: {
				fitting_type_id: fitting_type.id,
				fitting_type_item_ids,
				reservation_date,
				start_time,
				app_user_id: appUser.id,
				room_id: storeRoom.id,
				items: clubs,
			},
			normal: {
				memo,
				status,
			},
		},
	})

	const isReserved = watch("normal.status") === RESERVATION_STATUSES.RESERVED.value

	const navigateToCustomerDetail = () => navigate(`${ROUTE_MEMBERS}/${user_id}`)

	const handleSaveClick = handleSubmit(async (vals) => {
		const { items, ...otherStepableVals } = vals[STEPABLE]

		await updateReservation({
			id,
			data: { ...otherStepableVals, ...vals.normal, available_time: totalDuration },
		})

		await updateReservationClubs({
			id,
			data: { items },
		})

		dispatch(
			openSnackbar({
				severity: "success",
				message: t("Saved successfully."),
			})
		)
	})

	useEffect(() => {
		trigger(STEPABLE) // validates the form on first render to get the error messages of stepable fields (which will be shown in toast) in advance
	}, [])

	return (
		<>
			<ReservationFormCustomerInfo
				customer={user}
				onNameClick={() => {
					navigateToCustomerDetail()
					handleDialogClose()
				}}
				editFormProps={{
					reservationID: id,
					resendButton: {
						onClick: async () => {
							await resendReservationNotification(id)
							dispatch(
								openSnackbar({
									severity: "success",
									message: t("Confirmation text has been resent."),
								})
							)
						},
						isDisabled: !isReserved,
					},
					control,
					statusFieldName: "normal.status",
				}}
			/>

			<ReservationForm
				customer={user}
				formProps={{ setValue, watch, errors, control }}
				totalDuration={totalDuration}
				setTotalDuration={setTotalDuration}
				selectedFitterNameState={[selectedFitterName, setSelectedFitterName]}
				selectedRoomNameState={[selectedRoomName, setSelectedRoomName]}
				isDisabled
				isReserved={isReserved}
				currentFitter={appUser}
				currentRoom={storeRoom}
				openClubModal={openClubModal}
			/>

			{/* action buttons */}
			<StyledFlexBox>
				<StyledAutoLoadingButton variant="outlined" onClick={handleDialogClose}>
					{t("Cancel")}
				</StyledAutoLoadingButton>
				<StyledAutoLoadingButton disabled={!isValid} onClick={handleSaveClick}>
					{t("Save")}
				</StyledAutoLoadingButton>
			</StyledFlexBox>
		</>
	)
}

export default ReservationEditForm
