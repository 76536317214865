import styled from "@emotion/styled"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft"
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight"
import { IconButton } from "@mui/material"
import Pagination from "@mui/material/Pagination"
import PaginationItem from "@mui/material/PaginationItem"

const PaginationWrapper = styled.div`
	.MuiPaginationItem-icon,
	.MuiPaginationItem-root {
		&:hover {
			color: #2f4bbc;
		}
	}
`

function PaginationChangeIcon({ Icon, ...props }) {
	return (innerProps) => (
		<IconButton size="small" {...innerProps} {...props}>
			<Icon fontSize="medium" />
		</IconButton>
	)
}

const ClubPagination = ({ pagesCount = 1, currentPage, setCurrentPage }) => {
	return (
		<PaginationWrapper>
			<Pagination
				sx={{
					mt: 2,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
				page={currentPage}
				count={pagesCount}
				showFirstButton
				showLastButton
				onChange={(_, page) => {
					setCurrentPage(page)
				}}
				boundaryCount={0}
				renderItem={(props) => {
					return (
						<PaginationItem
							component="div"
							size="large"
							sx={{
								padding: 0,
								color: props.selected ? "#2F4BBC" : "#878C9F",
								fontSize: "16px",
								fontWeight: "bold",
							}}
							style={{
								backgroundColor: "transparent",
							}}
							slots={{
								first: PaginationChangeIcon({
									onClick: () => setCurrentPage(1),
									disabled: currentPage === 1,
									Icon: KeyboardDoubleArrowLeftIcon,
								}),
								previous: PaginationChangeIcon({
									onClick: () => setCurrentPage(currentPage - 1),
									disabled: currentPage === 1,
									Icon: KeyboardArrowLeftIcon,
								}),
								next: PaginationChangeIcon({
									onClick: () => setCurrentPage(currentPage + 1),
									disabled: currentPage === pagesCount,
									Icon: KeyboardArrowRightIcon,
								}),
								last: PaginationChangeIcon({
									onClick: () => setCurrentPage(pagesCount),
									disabled: currentPage === pagesCount,
									Icon: KeyboardDoubleArrowRightIcon,
								}),
							}}
							{...props}
						/>
					)
				}}
			/>
		</PaginationWrapper>
	)
}

export default ClubPagination
