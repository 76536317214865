import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Skeleton, Typography } from "@mui/material"
import { ControlledAppSelect } from "components"
import AutoLoadingButton from "components/AutoLoadingButton"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { storeApi } from "services/modules"
import { updateStore } from "stores/slice/store"
import { updateUserAssignedStores, updateUserStoreRole } from "stores/slice/user"
import { ROUTE_DEFAULT } from "urls"
import { getUserStoreRole, handleApiErrors, handleError } from "utils/helpers"
import { selectStoreSchema } from "validations"

const SelectStore = ({ user }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const {
		formState: { isValid },
		control,
		setError,
		handleSubmit,
		setValue,
		trigger,
	} = useForm({
		resolver: yupResolver(selectStoreSchema),
	})

	const [getStore] = handleError(storeApi.useLazyGetStoreDetailQuery)

	const [changeUserStore] = handleError(storeApi.useChangeUserStoreMutation)

	const {
		data: assignedStores = [],
		isSuccess,
		isFetching,
	} = handleError(storeApi.useGetUserAssignedStoresQuery)

	useEffect(() => {
		if (isSuccess) {
			dispatch(updateUserAssignedStores(assignedStores))

			if (assignedStores.length === 1) {
				setValue("selected_store_id", assignedStores[0].store.id)
				trigger("selected_store_id")
			}
		}
	}, [isSuccess])

	return (
		<Box
			sx={{
				height: "100%",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
			}}>
			<Box px={4} py={3}>
				<Typography fontSize="size.3xl" fontWeight="fontWeightBold" mb={3}>
					{t("Select store.")}
				</Typography>

				{isFetching ? (
					<Skeleton height="100px" />
				) : (
					<ControlledAppSelect
						fullWidth
						placeholder={t("Select")}
						control={control}
						defaultValue=""
						size="normal"
						name="selected_store_id"
						options={assignedStores.map(({ store }) => ({
							label: store.name,
							value: store.id,
						}))}
						noMaxWidth
					/>
				)}
			</Box>

			<Box m={4}>
				<AutoLoadingButton
					disabled={!isValid}
					size="medium"
					onClick={handleSubmit(({ selected_store_id }) => {
						return getStore(selected_store_id).then(
							async ({ data }) => {
								if (data.id !== user.current_store.store_id) {
									await changeUserStore({ store_id: data.id })
								}

								dispatch(updateStore(data))
								const userRole = getUserStoreRole(data.storeMembers, user.id)
								dispatch(updateUserStoreRole({ ...userRole, store_id: data.id }))
								navigate(ROUTE_DEFAULT)
							},
							(error) => {
								handleApiErrors(error, dispatch, setError)
							}
						)
					})}
					variant="contained"
					color="primary"
					fullWidth>
					{t("Confirm")}
				</AutoLoadingButton>
			</Box>
		</Box>
	)
}

export default SelectStore
