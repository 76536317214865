import { Box } from "@mui/material"
import ClubSelectionButton from "layouts/reservations/components/ReservationForm/ClubSelectionButton"
import DateSelectionBox from "layouts/reservations/components/ReservationForm/DateSelectionBox"
import FitterSelectionBox from "layouts/reservations/components/ReservationForm/FitterSelectionBox"
import FittingTypeSelectionBox from "layouts/reservations/components/ReservationForm/FittingTypeSelectionBox"
import MemoBox from "layouts/reservations/components/ReservationForm/MemoBox"
import RoomSelectionBox from "layouts/reservations/components/ReservationForm/RoomSelectionBox"
import TimeSelectionBox from "layouts/reservations/components/ReservationForm/TimeSelectionBox"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { openSnackbar } from "stores/slice/snackbar"
import reservationSchema, { STEPABLE } from "validations/reservationSchema"

const ReservationForm = ({
	customer,
	formProps,
	totalDuration,
	setTotalDuration,
	selectedRoomNameState,
	selectedFitterNameState,
	isDisabled,
	isReserved,
	currentRoom,
	currentFitter,
	openClubModal,
}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const { setValue, watch, errors, control } = formProps

	const [activeSelectionBoxName, setActiveSelectionBoxName] = useState(null)

	// determine if an input field is settable
	// (1) get fields in order from the schema
	// (2) get previous fields of the field to be set
	// (3) check if there's any error in the previous fields (prioritized by their order in the schema)
	// (4) if error ? show error by toast : input value is set

	const stepableFields = Object.keys(reservationSchema(t).fields[STEPABLE].fields)

	const {
		fitting_type_id: selectedFittingTypeID,
		fitting_type_item_ids: selectedFittingTypeItemIDsInStr,
		reservation_date: selectedReservationDate,
		start_time: selectedStartTime,
		app_user_id: selectedFitterID,
		room_id: selectedRoomID,
		items: selectedClubs,
	} = watch(STEPABLE) || {}

	const stepableFieldNamesInOrder = Object.values(stepableFields)

	const getStepableFieldFullName = (name) => `${STEPABLE}.${name}` // concat the prefix "stepable"

	const setStepableValue = (fieldName, value) =>
		setValue(getStepableFieldFullName(fieldName), value, {
			shouldValidate: true,
			shouldDirty: true,
		})

	const runIfSettable = (fieldName, onSettable, isSubsequentFieldsResetNeeded) => {
		const fieldIndex = stepableFieldNamesInOrder.findIndex((name) => name === fieldName)
		const previousFieldNames = stepableFieldNamesInOrder.slice(0, fieldIndex)
		const subsequentFieldNames = stepableFieldNamesInOrder.slice(fieldIndex + 1) // to reset the subsequent fields

		const stepableErrors = errors[STEPABLE] || {}
		const erroneousFieldNames = Object.keys(stepableErrors)
		const prioritizedErrorFieldName = previousFieldNames.find((name) =>
			erroneousFieldNames.includes(name)
		)

		if (prioritizedErrorFieldName) {
			dispatch(
				openSnackbar({
					severity: "error",
					message: stepableErrors[prioritizedErrorFieldName].message,
				})
			)
		} else {
			onSettable()

			// reset subsequent field values since stepable field values are influenced by their previous field value so changing a field value should reset its subsequent field values
			isSubsequentFieldsResetNeeded &&
				subsequentFieldNames.map((fieldName) => setStepableValue(fieldName, null))
		}
	}

	const setStepableValueIfSettable = (fieldName, value) =>
		runIfSettable(
			fieldName,
			() => {
				setStepableValue(fieldName, value)
			},
			true
		)

	const toggleActiveSelectionBoxNameIfSettable = (fieldName) =>
		runIfSettable(fieldName, () =>
			setActiveSelectionBoxName((boxName) => (boxName === fieldName ? null : fieldName))
		)

	// closes any selection box opened on reservation 'disabled' state
	useEffect(() => {
		isDisabled && setActiveSelectionBoxName(null)
	}, [isDisabled])

	return (
		<Box sx={{ display: "flex", flexDirection: "column" }} gap={2}>
			<FittingTypeSelectionBox
				setValue={(...args) => {
					setStepableValueIfSettable(...args)
					setActiveSelectionBoxName(null) // close all the selection boxes if fitting type is changed
				}}
				setTotalDuration={setTotalDuration}
				selectedFittingTypeID={selectedFittingTypeID}
				selectedFittingTypeItemIDsInStr={selectedFittingTypeItemIDsInStr}
				isDisabled={isDisabled}
			/>

			<DateSelectionBox
				setValue={setStepableValueIfSettable}
				activeSelectionBoxName={activeSelectionBoxName}
				onClick={toggleActiveSelectionBoxNameIfSettable}
				selectedReservationDate={selectedReservationDate}
				isDisabled={isDisabled}
			/>

			<TimeSelectionBox
				setValue={setStepableValueIfSettable}
				activeSelectionBoxName={activeSelectionBoxName}
				onClick={toggleActiveSelectionBoxNameIfSettable}
				totalDuration={totalDuration}
				selectedReservationDate={selectedReservationDate}
				selectedStartTime={selectedStartTime}
				isDisabled={isDisabled}
			/>

			<FitterSelectionBox
				setValue={setStepableValueIfSettable}
				activeSelectionBoxName={activeSelectionBoxName}
				onClick={toggleActiveSelectionBoxNameIfSettable}
				selectedFitterID={selectedFitterID}
				selectedFitterNameState={selectedFitterNameState}
				isDisabled={!isReserved && isDisabled}
				totalDuration={totalDuration}
				selectedReservationDate={selectedReservationDate}
				selectedStartTime={selectedStartTime}
				currentFitter={currentFitter}
			/>

			<RoomSelectionBox
				setValue={setStepableValueIfSettable}
				activeSelectionBoxName={activeSelectionBoxName}
				onClick={toggleActiveSelectionBoxNameIfSettable}
				totalDuration={totalDuration}
				selectedRoomID={selectedRoomID}
				selectedReservationDate={selectedReservationDate}
				selectedStartTime={selectedStartTime}
				selectedRoomNameState={selectedRoomNameState}
				isDisabled={!isReserved && isDisabled}
				currentRoom={currentRoom}
			/>

			<ClubSelectionButton
				setValue={setStepableValueIfSettable}
				customer={customer}
				selectedClubs={selectedClubs}
				selectedRoomID={selectedRoomID}
				runIfSettable={runIfSettable}
				selectedFittingTypeID={selectedFittingTypeID}
				selectedFittingTypeItemIDsInStr={selectedFittingTypeItemIDsInStr}
				isDisabled={!isReserved && isDisabled}
				openClubModal={openClubModal}
			/>

			<MemoBox control={control} errors={errors} isDisabled={!isReserved && isDisabled} />
		</Box>
	)
}

export default ReservationForm
