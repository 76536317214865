import { Box, Typography, useTheme } from "@mui/material"
import { ClockHoverSvg, ClockSvg } from "assets/svgs"
import { DATE_FORMATS } from "constant"
import { useLocalizedDateUtils } from "hooks"
import {
	ClickableReservationInput,
	GridItemValue,
	SelectionBoxNoData,
	SelectionBoxPlaceholder,
	StyledDivider,
	StyledGridContainer,
	StyledGridItem,
} from "layouts/reservations/components/ReservationForm/shared"
import { isEmpty } from "lodash"
import { Fragment, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { reservationApi } from "services/modules"
import { useIsEnglish } from "stores/slice/app"
import { isEmptyArray } from "utils/array"
import { addHoursToDate, stringToDate } from "utils/dateTime"

const TimeShiftLabel = ({ children }) => (
	<Typography variant="h5" m={2}>
		{children}
	</Typography>
)

let readableStartTime
let readableEndTime

const TimeSelectionBox = ({
	setValue,
	activeSelectionBoxName,
	onClick,
	totalDuration = null,
	selectedReservationDate = null,
	selectedStartTime,
	isDisabled,
}) => {
	const { t } = useTranslation()
	const { formatDate, formatDateString, getAmPmFromDateStringResDataBox } =
		useLocalizedDateUtils()
	const {
		palette: { info, disabled },
	} = useTheme()
	const isEng = useIsEnglish()
	const timeFormat = isEng
		? DATE_FORMATS.SHOWN_RESERVATION_DATA_EN
		: DATE_FORMATS.SHOWN_RESERVATION_TF_KR

	const fieldName = "start_time"
	const isActiveSelectionBox = activeSelectionBoxName === fieldName

	const setSelectedStartTime = (selectedStartTime) => setValue(fieldName, selectedStartTime)

	const {
		data: timeShifts = [],
		isLoading,
		isSuccess,
	} = reservationApi.useGetSelectableTimeSlotsPerDayQuery({
		available_time: totalDuration,
		selected_date: selectedReservationDate,
	})

	useEffect(() => {
		if (selectedStartTime) {
			const rawEndTime = addHoursToDate({
				continuousHour: totalDuration,
				date: stringToDate({
					dateString: selectedStartTime,
					formatFrom: DATE_FORMATS.BACKEND_RESERVATION_TF,
				}),
			})

			readableStartTime = getAmPmFromDateStringResDataBox(selectedStartTime)
			// readableEndTime = getAmPmFromDateStringResDataBox(new Date(rawEndTime))
			readableEndTime = formatDate(rawEndTime, timeFormat).toLowerCase()
		}
	}, [selectedStartTime])

	return (
		<Box>
			<ClickableReservationInput
				label={t("Reservation time")}
				value={selectedStartTime && `${readableStartTime} - ${readableEndTime}`}
				onClick={() => onClick(fieldName)}
				Icon={isActiveSelectionBox ? ClockHoverSvg : ClockSvg}
				iconProps={{ stroke: isDisabled ? disabled.main : info.main }}
				mb={isActiveSelectionBox && isEmptyArray(timeShifts) ? 2 : 0}
				isDisabled={isDisabled}
			/>

			{isActiveSelectionBox &&
				(isLoading ? (
					<SelectionBoxPlaceholder />
				) : isSuccess && !isEmptyArray(timeShifts) ? (
					<>
						{/* renders time shifts - AM | PM */}
						{timeShifts.map(
							({ label, timeSlots }) =>
								!isEmpty(timeSlots) && (
									<Fragment key={label}>
										<TimeShiftLabel>{t(label)}</TimeShiftLabel>
										<StyledGridContainer>
											{timeSlots.map((timeSlot) => (
												<StyledGridItem
													key={timeSlot}
													ownerProps={{
														isSelected: timeSlot === selectedStartTime,
													}}
													onClick={() => setSelectedStartTime(timeSlot)}>
													<GridItemValue>
														{formatDateString({
															dateString: timeSlot,
															formatFrom:
																DATE_FORMATS.BACKEND_RESERVATION_TF,
															formatTo:
																DATE_FORMATS.SHOWN_RESERVATION_TF_EN,
														})}
													</GridItemValue>
												</StyledGridItem>
											))}
										</StyledGridContainer>
									</Fragment>
								)
						)}

						<StyledDivider mt={2} />
					</>
				) : (
					<SelectionBoxNoData />
				))}
		</Box>
	)
}

export default TimeSelectionBox
