import { Grid, Typography, useTheme } from "@mui/material"
import pxToRem from "assets/theme/functions/pxToRem"
import AppSwitch from "components/AppSwitch"
import { WEDGE } from "constant"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useIsEnglish } from "stores/slice/app"

function IronClubCardForEquipment({ customIronSet, setCustomIronSet }) {
	const {
		palette: { grey },
	} = useTheme()
	const { t } = useTranslation()
	const isEn = useIsEnglish()
	const [useStockShaft, setUseStockShaft] = useState(true)

	const numberList = customIronSet
		? Object.values(customIronSet)
				.map(({ head, clubType }) => {
					const number = head?.data?.type
					if (clubType === WEDGE || isEn) {
						return number
					}
					return t("No. {{number}}", { number })
				})
				.join(", ")
		: ""

	useEffect(() => {
		if (customIronSet) {
			const updateIronSet = Object.values(customIronSet).map((value) => {
				if (!useStockShaft) {
					delete value.shaft
				}
				return { ...value, isStockShaft: useStockShaft }
			})
			setCustomIronSet(
				Object.assign(
					{},
					updateIronSet.map((head) => head)
				)
			)
		}
	}, [useStockShaft])

	return (
		<Grid container border={`1px solid ${grey[300]}`} borderRadius={pxToRem(8)}>
			<Typography
				borderBottom={`1px solid ${grey[300]}`}
				paddingY={pxToRem(12)}
				flex={1}
				textAlign="center"
				fontSize="size.md">
				{numberList}
			</Typography>
			<Grid
				item
				container
				paddingX={pxToRem(14)}
				paddingY={pxToRem(12)}
				alignItems="center"
				justifyContent="space-around">
				<Typography fontSize="size.md">{t("Stock shaft")}</Typography>
				<AppSwitch
					disabled={false}
					defaultChecked={useStockShaft}
					onChange={() => {
						setUseStockShaft((prev) => !prev)
					}}
				/>
			</Grid>
		</Grid>
	)
}

export default IronClubCardForEquipment
