import pxToRem from "assets/theme/functions/pxToRem"
import { any, arrayOf, number, oneOf, oneOfType, shape, string } from "prop-types"
import { Controller } from "react-hook-form"

import AppSelect from "./AppSelect"

const ControlledAppSelect = ({
	name,
	size = "large",
	label,
	minWidth = 140,
	fullWidth = false,
	options = [],
	control,
	noBorder,
	disabled,
	onCustomValueChange = null,
	variant,
	type,
	...props
}) => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { ref, onChange, value, ...field } }) => {
				// mutate string value to object since AppSelect expect its value as an object for the placeholder feature (read more in AppSelect.js)
				// but ControlledAppSelect expect its value as a string (to ease api call)
				// so we have that twist here
				// value can be direct input and out of range from options
				const inputValue =
					Object.values(options).find((option) => option.value === value) || value || ""

				return (
					<AppSelect
						sx={{ minWidth: pxToRem(minWidth) }}
						noBorder={noBorder}
						variant={variant}
						size={size}
						label={label}
						disabled={disabled}
						options={options}
						fullWidth={fullWidth}
						ref={ref}
						type={type}
						onChange={(e) =>
							onCustomValueChange
								? onCustomValueChange(e.target.value.value)
								: onChange(e.target.value.value)
						}
						value={inputValue || ""}
						{...field}
						{...props}>
						{options.map((option) => {
							return (
								<AppSelect.MenuItem
									// natively mui put this 'Mui-select' class to the chosen menu item
									// but mui only assigns it along with the onChange event handler
									// so, for the default value (initial value) of RHF, we gotta programmatically set this class here
									// so that the menu item will have the 'Mui-selected' class which hides that menu item in terms of css
									className={`${option.value === value && "Mui-selected"}`}
									key={option.value}
									value={option}>
									{option.label}
								</AppSelect.MenuItem>
							)
						})}
					</AppSelect>
				)
			}}
		/>
	)
}

ControlledAppSelect.propTypes = {
	name: string,
	size: oneOf(["small", "large", "normal"]),
	label: string,
	minWidth: number,
	options: arrayOf(
		shape({
			label: oneOfType([string, number]),
			value: any,
		})
	),
	variant: oneOf(["sticky", "normal"]),
	control: any,
	type: string,
}

export default ControlledAppSelect
