import { Box, styled } from "@mui/material"

const DEFAULT_RED_DOT_SIZE_IN_PX = 6
const StyledRedDot = styled(Box)(({ theme, ownerProps }) => {
	const {
		palette,
		functions: { pxToRem },
	} = theme

	const sizeInPx = ownerProps?.sizeInPx

	const size = sizeInPx || DEFAULT_RED_DOT_SIZE_IN_PX

	return {
		width: pxToRem(size),
		height: pxToRem(size),
		backgroundColor: palette.error.main,
		borderRadius: "50%",
	}
})

export default StyledRedDot

/*

NOTE
====
To override the default size: pass this "ownerProps={{ sizeInPx: [your custom number in px] }}"

*/
