import { Box, Typography, useTheme } from "@mui/material"
import { ENGLISH_LANGUAGE } from "constant"
import { startOfWeek } from "date-fns"

import DatePickerWrapper from "./DatePickerComponents/DatePickerWrapper"

const WeekViewDatePicker = ({
	language,
	monthName,
	weekDays,
	year,
	month,
	week,
	days,
	setWeekStartDate = null,
	setSelectedDay,
	previousMonth,
	nextMonth,
	plusButton,
	selectedDay = null,
}) => {
	const { palette } = useTheme()

	const primaryBlue = palette.primary.main
	const lightBlue = palette.secondary.main
	const transparent = palette.transparent.main
	const white = palette.white.main
	const black = palette.black.main
	const f5Grey = palette.grey[200]
	const grey300 = palette.grey[300]
	const grey700 = palette.grey[700]

	const setSelectedWeek = (selectedDate) => {
		const firstDayOfWeek = startOfWeek(selectedDate)
		setWeekStartDate(firstDayOfWeek)
	}

	return (
		<Box
			sx={{
				flexGrow: 1,
				margin: 0,
				padding: "0 20px",
				border: `1px solid ${grey300}`,
				borderRadius: "8px",
				background: white,
				minWidth: "370px",
				width: "415px",
				maxWidth: "420px",
				minHeight: "800px",
				position: "relative",
			}}>
			<DatePickerWrapper
				border={false}
				padding="15px 0"
				width="100%"
				weekDayListMarginTop="10px"
				weekDayNameFontSize={language === ENGLISH_LANGUAGE ? "18px" : "20px"}
				monthName={monthName}
				weekDays={weekDays}
				year={year}
				month={month}
				previousMonth={previousMonth}
				nextMonth={nextMonth}
				datesView={days.map((d) => {
					const dateString = d.date.toLocaleDateString("en-GB")
					const currentDay = new Date()
					const today = currentDay.toLocaleDateString("en-GB") === dateString

					let dayWeekCheck
					let firstDayOfWeek
					let lastDayOfWeek

					if (selectedDay) {
						dayWeekCheck = dateString === selectedDay.date.toLocaleDateString("en-GB")
					} else {
						const weeksDateString = []
						firstDayOfWeek = dateString === week[0].date.toLocaleDateString("en-GB")
						lastDayOfWeek = dateString === week[6].date.toLocaleDateString("en-GB")

						week.map((d) => {
							weeksDateString.push(d.date.toLocaleDateString("en-GB"))
						})

						dayWeekCheck = weeksDateString.includes(dateString)
					}

					return (
						<Box
							onClick={() => {
								selectedDay ? setSelectedDay(d) : setSelectedWeek(d.date)
							}}
							key={dateString}
							sx={{
								backgroundColor: dayWeekCheck ? lightBlue : transparent,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								cursor: "pointer",
								borderRadius: selectedDay
									? "8px"
									: firstDayOfWeek
										? "8px 0 0 8px"
										: lastDayOfWeek
											? "0 8px 8px 0"
											: "none",
							}}>
							<Box
								sx={{
									margin: 0,
									padding: "0 3.2px",
									":hover": {
										outline: `1px solid ${primaryBlue}`,
										borderRadius: "8px",
									},
								}}>
								<Typography
									component="div"
									sx={{
										border: "none",
										outline: "none",
										borderRadius: "8px",
										textAlign: "center",
										padding: "12px 1.8px",
										transition: "border 0.3s ease",
									}}>
									<Typography
										sx={{
											fontSize: "14px",
											color:
												d.rsCount > 0
													? primaryBlue
													: dayWeekCheck
														? lightBlue
														: white,
											backgroundColor: d.rsCount > 0 ? f5Grey : transparent,
											width: "30px",
											height: "30px",
											margin: 0,
											marginLeft: "2.5px",
											padding: 0,
											paddingTop: "4px",
											borderRadius: "50%",
										}}>
										{d.rsCount}
									</Typography>
									<Typography
										sx={{
											marginTop: "10px",
											fontSize: "20px",
											width: "34px",
											height: "34px",
											paddingTop: "1px",
											borderRadius: "50%",
											backgroundColor: dayWeekCheck
												? transparent
												: today
													? primaryBlue
													: transparent,
											color: today
												? white
												: dayWeekCheck
													? white
													: d.date.getMonth() === month
														? black
														: grey700,
										}}>
										{d.day}
									</Typography>
								</Typography>
							</Box>
						</Box>
					)
				})}
			/>
			<Box sx={{ position: "absolute", bottom: "20px", right: "20px" }}>{plusButton}</Box>
		</Box>
	)
}

export default WeekViewDatePicker
