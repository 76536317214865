import { IconButton, InputAdornment, TextField, styled } from "@mui/material"
import { CloseBadge } from "assets/svgs"
import { SPECIAL_INPUT_TYPES } from "constant"
import { bool, func, number, oneOf, oneOfType, string } from "prop-types"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { formatDOB } from "utils/dateTime"
import {
	formatClubAnglesNoUnit,
	formatClubFlexNoUnit,
	formatClubLengthNoUnit,
	formatClubSwingWeightNoUnit,
	formatClubWeightNoUnit,
	formatHeight,
	formatPhoneNumber,
	formatSizeNoUnit,
	formatWeight,
	removeHyphens,
	removeNonNumeric,
	removeNonNumericExceptDot,
} from "utils/string"

import UnitAdornment from "./UnitAdornment"

export const StyledTextField = styled(TextField)(({
	theme,
	ownerState, // ownerState = public props + internal state
}) => {
	const {
		palette,
		typography,
		functions: { pxToRem },
		borders: { borderRadius },
		spacing,
	} = theme
	const { error, success, disabled, size, isPassword, showCloseIconOnDisabledInput } = ownerState
	const { grey, transparent, error: colorError, success: colorSuccess, black, skyBlue } = palette

	const sizeBasedStyles = {
		small: {
			padding: `${pxToRem(0)} ${pxToRem(12)}`,
			minHeight: pxToRem(40),
			fontSize: typography.size.sm,
		},
		normal: {
			padding: `${pxToRem(0)} ${pxToRem(20)}`,
			minHeight: pxToRem(50),
			fontSize: typography.size.md,
		},
		large: {
			padding: `${pxToRem(0)} ${pxToRem(20)}`,
			minHeight: pxToRem(60),
			fontSize: typography.size.md,
		},
	}

	const errorStyles = () => ({
		"& .MuiOutlinedInput-notchedOutline, &:after": {
			borderColor: colorError.main,
		},

		"& .Mui-focused": {
			"& .MuiOutlinedInput-notchedOutline, &:after": {
				borderColor: colorError.main,
			},
		},

		"& .MuiInputLabel-root.Mui-focused": {
			color: colorError.main,
		},
	})

	const successStyles = () => ({
		"& .Mui-focused": {
			"& .MuiOutlinedInput-notchedOutline, &:after": {
				borderColor: colorSuccess.main,
			},
		},

		"& .MuiInputLabel-root.Mui-focused": {
			color: colorSuccess.main,
		},
	})

	return {
		"& .MuiInputBase-root": {
			padding: sizeBasedStyles[size].padding,
			paddingRight: pxToRem(!showCloseIconOnDisabledInput && disabled ? 0 : 40),
		},

		"& .MuiInputBase-input": {
			borderRadius: borderRadius.lg,
			padding: 0,
			paddingRight: spacing(2),
			minHeight: sizeBasedStyles[size].minHeight,
			fontSize: sizeBasedStyles[size].fontSize,
			letterSpacing: isPassword ? "0.25em" : "normal", // enhance letter spacing for asterisks which is used to hide passwords
			color: disabled ? grey[700] : black.main,
			textOverflow: disabled ? "ellipsis" : "initial",

			"&::placeholder": {
				letterSpacing: "normal",
			},
		},

		"input:-webkit-autofill, .MuiInputBase-root:has(> input:-webkit-autofill) ": {
			backgroundColor: skyBlue.main,
		},

		"input:-webkit-autofill,input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active":
			{
				"box-shadow": "inset 0 0 20px 20px #E7F0FE",
			},

		"& .MuiOutlinedInput-notchedOutline": {
			padding: 0,
		},

		backgroundColor: disabled ? `${grey[200]} !important` : transparent.main,
		pointerEvents: disabled ? "none" : "auto",

		...(error && errorStyles()),
		...(success && successStyles()),
	}
})

const ClearButton = styled(IconButton)(({ theme: { spacing }, ownerState: { isShown } }) => ({
	visibility: isShown ? "visible" : "hidden",
	position: "absolute",
	right: spacing(2),
}))

// to auto-format special values like (phone-number, dob, ...)
const autoFormatSpecialValue = (type, value) => {
	switch (type) {
		case SPECIAL_INPUT_TYPES.TEL:
			return formatPhoneNumber(value)

		case SPECIAL_INPUT_TYPES.DOB:
			return formatDOB(value)

		case SPECIAL_INPUT_TYPES.HEIGHT:
			return formatHeight(value)

		case SPECIAL_INPUT_TYPES.WEIGHT:
			return formatWeight(value)

		case SPECIAL_INPUT_TYPES.SIZE:
			return formatSizeNoUnit(value)

		case SPECIAL_INPUT_TYPES.CLUB_WEIGHT:
			return formatClubWeightNoUnit(value)

		case SPECIAL_INPUT_TYPES.CLUB_SWING_WEIGHT:
			return formatClubSwingWeightNoUnit(value)

		case SPECIAL_INPUT_TYPES.CLUB_LENGTH:
			return formatClubLengthNoUnit(value)

		case SPECIAL_INPUT_TYPES.LOFT:
		case SPECIAL_INPUT_TYPES.LIE_ANGLE:
		case SPECIAL_INPUT_TYPES.BOUNCE_ANGLE:
			return formatClubAnglesNoUnit(value)

		case SPECIAL_INPUT_TYPES.FLEX:
			return formatClubFlexNoUnit(value)

		default:
			return value
	}
}

const getUnitForSpecialValue = (type, t) => {
	switch (type) {
		case SPECIAL_INPUT_TYPES.HEIGHT:
			return t("heightUnit")

		case SPECIAL_INPUT_TYPES.WEIGHT:
			return t("weightUnit")

		case SPECIAL_INPUT_TYPES.SIZE:
			return t("{{No}}")

		case SPECIAL_INPUT_TYPES.NUMBER:
			return t("{{Number}}")

		case SPECIAL_INPUT_TYPES.CLUB_WEIGHT:
			return "g"

		case SPECIAL_INPUT_TYPES.CLUB_SWING_WEIGHT:
			return "g"

		case SPECIAL_INPUT_TYPES.CLUB_LENGTH:
			return "inch"

		case SPECIAL_INPUT_TYPES.LOFT:
		case SPECIAL_INPUT_TYPES.LIE_ANGLE:
		case SPECIAL_INPUT_TYPES.BOUNCE_ANGLE:
			return "deg"

		case SPECIAL_INPUT_TYPES.FLEX:
			return "flex"

		default:
			return null
	}
}

// to get raw values of formatted special values (phone-number, dob, ...)
// formatted is still needed to be called for purposes like disabling the entry once the input length exceeds
const getRaw = (type, formattedValue) => {
	switch (type) {
		case SPECIAL_INPUT_TYPES.TEL:
			return removeHyphens(formattedValue)

		case SPECIAL_INPUT_TYPES.DOB:
			return formattedValue.replace(/-/g, ".") // replace '-' with '.' for dob // should be standardized in backend that should accept raw instead of '.' embedded string

		case SPECIAL_INPUT_TYPES.HEIGHT:
		case SPECIAL_INPUT_TYPES.WEIGHT:
		case SPECIAL_INPUT_TYPES.CLUB_WEIGHT:
		case SPECIAL_INPUT_TYPES.CLUB_LENGTH:
		case SPECIAL_INPUT_TYPES.LOFT:
		case SPECIAL_INPUT_TYPES.LIE_ANGLE:
		case SPECIAL_INPUT_TYPES.BOUNCE_ANGLE:
			return removeNonNumericExceptDot(formattedValue)

		case SPECIAL_INPUT_TYPES.CLUB_SWING_WEIGHT:
			return formattedValue.replace(/ g/g, "")

		case SPECIAL_INPUT_TYPES.FLEX:
			return formattedValue.replace(/ flex/g, "")

		case SPECIAL_INPUT_TYPES.SIZE:
			return removeNonNumeric(formattedValue)

		default:
			return formattedValue
	}
}

const AppTextField = ({
	size,
	type,
	value,
	success,
	error,
	disabled,
	withClearButton,
	onClear,
	onChange,
	readOnly,
	controlledRef, // ref function from the controller
	InputProps,
	showCloseIconOnDisabledInput,
	alignUnitToRight,
	...rest
}) => {
	const isSpecialType = Object.values(SPECIAL_INPUT_TYPES).includes(type)
	const isPassword = type === "password"
	const ownerState = { error, success, disabled, size, isPassword, showCloseIconOnDisabledInput }
	const { t } = useTranslation()

	// to auto-format special values like (phone-number, dob, ...)
	const [maskedVal, setMaskedVal] = useState(autoFormatSpecialValue(type, value))
	const inputRef = useRef(null)

	const handleInputChange = ({ target: { value } }) => {
		const formattedValue = autoFormatSpecialValue(type, value)

		onChange({
			target: {
				value: getRaw(type, formattedValue),
			},
		})

		isSpecialType && setMaskedVal(formattedValue)
	}

	const handleClear = (e) => {
		e.stopPropagation()
		inputRef.current?.focus()
		handleInputChange({ target: { value: "" } })
		onClear && onClear() // optional callback to run once input is cleared
	}

	useEffect(() => {
		isSpecialType && setMaskedVal(autoFormatSpecialValue(type, value)) // For reset flow
	}, [value])

	const shouldShown = alignUnitToRight
		? false
		: disabled
			? showCloseIconOnDisabledInput
			: value || (isSpecialType && maskedVal)

	const inputProps = {
		readOnly,
		onChange: handleInputChange,
		endAdornment: withClearButton ? (
			<InputAdornment position="end">
				{getUnitForSpecialValue(type, t) && (
					<span style={{ position: "absolute", right: shouldShown ? "45px" : "20px" }}>
						{getUnitForSpecialValue(type, t)}
					</span>
				)}
				<ClearButton
					sx={({ palette }) => ({
						color: disabled ? palette.baseColors.LIGHTGREY : palette.grey[700],
						"&:hover": { color: palette.primary.main },
					})}
					aria-label="clear input"
					onClick={handleClear}
					edge="end"
					ownerState={{
						isShown: shouldShown,
					}}>
					<CloseBadge fill="currentcolor" />
				</ClearButton>
			</InputAdornment>
		) : (
			getUnitForSpecialValue(type, t) && (
				<UnitAdornment>{getUnitForSpecialValue(type, t)}</UnitAdornment>
			)
			// <></>
		),
		...InputProps,
	}

	return (
		<StyledTextField
			size={size}
			inputRef={(e) => {
				controlledRef && controlledRef(e) // bind the input box with the controller so that inputBox can be controlled from the form level
				inputRef.current = e
			}}
			value={isSpecialType ? maskedVal : value}
			type={type}
			ownerState={ownerState}
			InputProps={inputProps}
			{...rest}
		/>
	)
}

AppTextField.defaultProps = {
	size: "large",
	type: "text",
	value: "",
	readOnly: false,
	success: false,
	error: false,
	disabled: false,
	withClearButton: true,
	showCloseIconOnDisabledInput: false,
	alignUnitToRight: false,
}

// export this to use that in ControlledAppTextField component
export const AppTextFieldPropTypes = {
	size: oneOf(["small", "normal", "large"]),
	type: string,
	name: string.isRequired,
	value: oneOfType([string, number]),
	success: bool,
	error: bool,
	disabled: bool,
	withClearButton: bool,
	onClear: func, // optional callback to run once input is cleared
	onChange: func.isRequired,
	readOnly: bool,
	controlledRef: func,
	showCloseIconOnDisabledInput: bool,
	alignUnitToRight: bool,
}

AppTextField.propTypes = AppTextFieldPropTypes

export default AppTextField
