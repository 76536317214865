import { Box, Typography, useTheme } from "@mui/material"
import { ArrowHeadDownSvg } from "assets/svgs"
import { AppTextField } from "components"
import { SPECIAL_INPUT_TYPES } from "constant"
import { isString } from "lodash"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
	formatClubAnglesNoUnit,
	formatClubFlexNoUnit,
	formatClubLengthNoUnit,
	formatClubSwingWeightNoUnit,
	formatClubWeightNoUnit,
	formatSizeNoUnit,
} from "utils/string"

import { MenuProps, StyledMenuItem, StyledSelect } from "./AppSelect"

function AppSelectTextField({
	name,
	size = "normal",
	type,
	placeholder = "Select",
	methods,
	error,
	disabled,
	options = [],
	variant = "sticky",
	borderColor,
	extraStyle,
	topInputDirect = true,
	...rest
}) {
	const { t } = useTranslation()
	const theme = useTheme()
	const {
		palette: { grey, black },
	} = theme
	const { control, setFocus, setValue } = methods

	const boxBorderColor = borderColor || grey[300]
	const isStickyVariant = variant === "sticky"
	const textColor = disabled ? grey[700] : black.main

	const autoFormatSpecialValue = (type, value) => {
		switch (type) {
			case SPECIAL_INPUT_TYPES.CLUB_WEIGHT:
				return formatClubWeightNoUnit(value)

			case SPECIAL_INPUT_TYPES.CLUB_SWING_WEIGHT:
				return formatClubSwingWeightNoUnit(value)

			case SPECIAL_INPUT_TYPES.CLUB_LENGTH:
				return formatClubLengthNoUnit(value)

			case SPECIAL_INPUT_TYPES.SIZE:
				return formatSizeNoUnit(value, t)

			case SPECIAL_INPUT_TYPES.LOFT:
			case SPECIAL_INPUT_TYPES.LIE_ANGLE:
			case SPECIAL_INPUT_TYPES.BOUNCE_ANGLE:
				return formatClubAnglesNoUnit(value)

			case SPECIAL_INPUT_TYPES.FLEX:
				return formatClubFlexNoUnit(value)

			default:
				return value
		}
	}

	const onSelectDirectInputHandler = () => {
		setTimeout(() => setFocus(name), 0)
		setValue(name, "")
	}

	const InputDirectMenuItem = (
		<StyledMenuItem
			ownerState={{ size }}
			value={{ label: "Enter directly", value: "Enter directly" }}
			onClick={onSelectDirectInputHandler}>
			{t("Enter directly.")}
		</StyledMenuItem>
	)

	return (
		<Box position="relative" width="100%">
			<Controller
				name={name}
				control={control}
				render={({ field: { value: renderValue, onChange, ref } }) => {
					const valueFromOption = options.find(
						(item) => item?.value === renderValue || item === renderValue
					)

					return (
						<>
							<AppTextField
								value={renderValue === null ? t("No information") : renderValue}
								placeholder={placeholder}
								error={error}
								controlledRef={ref}
								disabled={disabled}
								size={size}
								type={type}
								withClearButton={options.length > 0 && !disabled ? false : true}
								alignUnitToRight={options.length > 0 && !disabled ? false : true}
								onChange={onChange}
								sx={{
									...extraStyle,
									width: "100%",
									"& .MuiInputBase-input": {
										color: valueFromOption
											? "transparent !important"
											: "black.main",
									},
								}}
								{...rest}
							/>
							<StyledSelect
								disabled={disabled}
								MenuProps={MenuProps(theme, isStickyVariant)}
								defaultValue={renderValue} // need to use renderValue, cannot use valueFromOption directly
								value={renderValue === null ? t("No information") : renderValue}
								renderValue={(value) => {
									const theValue = value?.value || value
									const valueFromOption = options.find(
										(item) => item?.value === theValue || item === theValue
									)

									const shownValue = !valueFromOption
										? name === "total_weight"
											? t("No information")
											: valueFromOption?.label || theValue
										: valueFromOption?.label || theValue

									const formattedShownValue =
										Object.values(SPECIAL_INPUT_TYPES).includes(type) &&
										shownValue !== t("No information")
											? autoFormatSpecialValue(type, shownValue)
											: shownValue

									if (formattedShownValue) {
										if (isString(formattedShownValue)) {
											if (formattedShownValue === "Enter directly") {
												return renderValue
											}
											return isString(formattedShownValue) ? (
												<Typography noWrap fontSize="size.md">
													{formattedShownValue}
												</Typography>
											) : (
												formattedShownValue
											)
										}
									} else {
										return placeholder
									}
								}}
								ownerState={{
									size,
									noBorder: disabled ? false : true,
									placeholder,
									boxBorderColor,
									isStickyVariant: false,
									disabled,
									value: rest.value,
								}}
								sx={{
									position: "absolute",
									top: 0,
									bottom: 0,
									left: 0,
									right: 0,
									backgroundColor: "transparent",
									"& .MuiSelect-select": {
										color: valueFromOption
											? "black.main"
											: "transparent !important",
									},
								}}
								IconComponent={({ className }) => {
									return options.length > 0 && !disabled ? (
										<ArrowHeadDownSvg
											stroke={textColor}
											className={className}
										/>
									) : (
										<></>
									)
								}}
								{...rest}>
								{topInputDirect && InputDirectMenuItem}
								{options.map(({ label, value }) => {
									return renderValue === value ? (
										<></>
									) : (
										<StyledMenuItem
											ownerState={{ size }}
											key={label}
											value={{ label, value }}
											onClick={() => {
												onChange(value)
											}}>
											{t(label)}
										</StyledMenuItem>
									)
								})}
								{!topInputDirect && InputDirectMenuItem}
							</StyledSelect>
						</>
					)
				}}
			/>
		</Box>
	)
}

export default AppSelectTextField
