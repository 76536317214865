import { Box, Typography, useTheme } from "@mui/material"
import { RESERVATION_STATUSES } from "constant"
import { useTranslation } from "react-i18next"
import { useIsEnglish } from "stores/slice/app"

const StatusBubble = ({ status }) => {
	const {
		palette: { white, reservationStatusColors },
		typography,
	} = useTheme()

	const { t } = useTranslation()
	const isEn = useIsEnglish()

	return (
		<Box
			width={40}
			height={40}
			fontSize={12}
			borderRadius={"100%"}
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				margin: "0 auto",
				userSelect: "none",
			}}
			bgcolor={reservationStatusColors[status]}>
			<Typography color={white.main} fontSize={typography.size.xs}>
				{!isEn && status === RESERVATION_STATUSES.CANCELLED.value ? (
					<Box>
						<p>예약</p>
						<p>취소</p>
						{/* WIP on solution for line break to line break the korean text inside the bubble */}
					</Box>
				) : (
					t(status)
				)}
			</Typography>
		</Box>
	)
}

export default StatusBubble
