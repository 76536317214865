import { mixed, number, object, string } from "yup"

const customerRegistrationFormSchema = (t) =>
	object({
		name: string().required(t("Enter your name")),
		phone_number: string().required(t("Enter phone number")),
		gender: string().required(),
		height: number().required(),
		weight: number().required(),
		hand: string().nullable(),
		glove_size: mixed().nullable(),
		glove_size_manual: number().nullable(),
		experience: string().nullable(),
		handicap: mixed().nullable(),
		handicap_manual: number().nullable(),
	})

export default customerRegistrationFormSchema
