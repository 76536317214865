import { api } from "services/api"
import {
	API_ANALYZERS_OFFICIAL,
	API_APP_USER_ASSIGNED_STORES,
	API_STORE,
	API_STORE_FITTERS,
	API_STORE_HELP,
	API_STORE_WORKDAY,
	API_UPDATE_STORE,
} from "services/endpoints"
import * as tags from "services/tags"

const storeApi = api.injectEndpoints({
	endpoints(build) {
		return {
			getUserAssignedStores: build.query({
				query() {
					return {
						url: API_APP_USER_ASSIGNED_STORES,
					}
				},

				transformResponse: ({ data }) => data,

				providesTags: [tags.TAG_STORE_LIST],
			}),

			getStoreDetail: build.query({
				query(id) {
					return {
						url: `${API_STORE}/${id}`,
					}
				},

				transformResponse: ({ data }) => data,

				providesTags: [tags.TAG_STORE],
			}),

			updateStore: build.mutation({
				query({ id, data }) {
					return {
						data,
						method: "PUT",
						url: `${API_STORE}/${id}`,
					}
				},

				invalidatesTags: (ok) => (ok ? [tags.TAG_STORE] : []),
			}),

			changeUserStore: build.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: API_UPDATE_STORE,
					}
				},

				invalidatesTags: (ok) => (ok ? Object.values(tags) : []),
			}),

			getAnalyzers: build.query({
				query() {
					return {
						url: API_ANALYZERS_OFFICIAL,
					}
				},

				providesTags: [tags.TAG_ANALYZERS],
			}),

			getStoreFitters: build.query({
				query() {
					return {
						url: API_STORE_FITTERS,
					}
				},

				transformResponse: ({ data }) =>
					data.map((member) => ({ label: member.name, value: member.id })),

				providesTags: [tags.TAG_STORE_FITTERS],
			}),

			workdays: build.query({
				query() {
					return {
						url: API_STORE_WORKDAY,
					}
				},

				transformResponse: ({ data }) => {
					return data.reduce((i, j) => {
						i[j.code] = j
						return i
					}, {})
				},

				providesTags: [tags.TAG_STORE_WORKDAY],
			}),

			setWorkdays: build.mutation({
				query(data) {
					return {
						data: { items: Object.values(data) },
						method: "PUT",
						url: API_STORE_WORKDAY,
					}
				},

				// re-enable this as per needed
				invalidatesTags: (ok) => (ok ? [tags.TAG_STORE_WORKDAY] : []),
			}),

			getStoreHelpList: build.query({
				query(params) {
					return {
						params,
						url: API_STORE_HELP,
					}
				},

				providesTags: [tags.TAG_STORE_HELPS],
			}),

			getStoreHelpDetail: build.query({
				query(id) {
					return {
						url: `${API_STORE_HELP}/${id}`,
					}
				},

				providesTags: [tags.TAG_STORE_HELP],
			}),

			postStoreHelpDetail: build.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: API_STORE_HELP,
					}
				},

				invalidatesTags: (ok) => (ok ? [tags.TAG_STORE_HELPS] : []),
			}),

			deleteStoreHelpDetail: build.mutation({
				query(id) {
					return {
						method: "DELETE",
						url: `${API_STORE_HELP}/${id}`,
					}
				},

				invalidatesTags: (ok) => (ok ? [tags.TAG_STORE_HELPS] : []),
			}),
		}
	},
})

export const { useGetStoreFittersQuery } = storeApi

export default storeApi
