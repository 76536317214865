import { createSlice } from "@reduxjs/toolkit"
import { STORE_ROLES } from "constant"
import { useSelector } from "react-redux"
import { USER_SLICE } from "stores/slicekeys"

const initialState = {
	id: null,
	name: null,
	email: null,
	phone_number: null,
	store_ids: null,
	is_password_checked: false,
	assignedStores: [],
	store_role: {
		store_id: null,
		is_owner: false,
		role: null,
		role_display: null,
	},
}

const userSlice = createSlice({
	name: USER_SLICE,
	initialState,
	reducers: {
		addUser(state, action) {
			const { id, name, email, phone_number, store_ids } = action.payload
			state.id = id
			state.name = name
			state.email = email
			state.phone_number = phone_number
			state.store_ids = store_ids
			state.is_password_checked = false
		},

		updateUserStoreRole(state, action) {
			const { store_id, role, is_owner, role_display } = action.payload
			state.store_role.store_id = store_id
			state.store_role.role = role
			state.store_role.role_display = role_display
			state.store_role.is_owner = is_owner
		},

		removeUser() {
			return initialState
		},

		addCheckedPassword(state, action) {
			state.is_password_checked = action.payload
		},

		removeCheckedPassword(state) {
			state.is_password_checked = initialState.is_password_checked
		},

		updatePhoneNumber(state, action) {
			state.phone_number = action.payload
		},

		updateUserAssignedStores(state, action) {
			state.assignedStores = action.payload
		},
	},
})

export const {
	addUser,
	removeUser,
	updateUserStoreRole,
	addCheckedPassword,
	removeCheckedPassword,
	updatePhoneNumber,
	updateUserAssignedStores,
} = userSlice.actions
export default userSlice.reducer

export const useUser = () => {
	const user = useSelector((state) => state[USER_SLICE])

	return Object.assign(
		{
			get isStaff() {
				return this.store_role.role === STORE_ROLES.STAFF.value
			},

			get isAdmin() {
				return this.store_role.role === STORE_ROLES.ADMIN.value
			},
		},
		user
	)
}

export const useStoreMembers = () => useSelector((state) => state[USER_SLICE]?.storeMembers || [])

export const userStoreIds = () => useSelector((state) => state[USER_SLICE]?.store_ids || [])

export const useUserStoreRole = () => useSelector((state) => state[USER_SLICE].store_role)

export const useUserAssignedStores = () => useSelector((state) => state[USER_SLICE].assignedStores)
