import { Box, Typography, styled, useTheme } from "@mui/material"
import { ReloadWhiteSvg } from "assets/svgs"
import { DEFAULT_ENABLED_RESERVATION_FILTERS, KOREA_LANGUAGE, RESERVATION_STATUSES } from "constant"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useLanguage } from "stores/slice/app"

import CheckView from "./CheckView"

const ViewButton = ({ onClick, width, backgroundColor, label }) => {
	return (
		<button
			onClick={onClick}
			style={{
				width: width,
				height: "34px",
				backgroundColor: backgroundColor,
				border: "none",
				borderRadius: "8px",
				margin: 0,
				cursor: "pointer",
			}}>
			{label}
		</button>
	)
}

const StyledFilterContainer = styled(Box)(() => ({
	flexGrow: 2,
	display: "flex",
	alignItems: "flex-start",
	justifyContent: "flex-end",
	"@media (max-width: 1087px)": {
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
	gap: "10px",
}))

const StyledFilterLabel = styled(Typography)(({ theme: { palette } }) => ({
	margin: 0,
	padding: 0,
	color: palette.white.main,
	fontWeight: 500,
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	gap: "3px",
}))

const FilterView = ({ handleToday, filters, setFilters, reset }) => {
	const { t } = useTranslation()
	const language = useLanguage()

	const [defaultSort, setDefaultSort] = useState(true)

	const {
		palette: { primary, white, grey, reservationStatusColors },
		typography,
	} = useTheme()

	return (
		<StyledFilterContainer>
			{handleToday && (
				<ViewButton
					width="90px"
					onClick={handleToday}
					backgroundColor={primary.main}
					label={
						<StyledFilterLabel
							fontSize={
								language === KOREA_LANGUAGE
									? typography.size.md
									: typography.size.sm
							}>
							{t("Today")}
						</StyledFilterLabel>
					}
				/>
			)}
			<Box
				sx={{
					height: "35px",
					backgroundColor: white.main,
					border: `1px solid ${grey[300]}`,
					borderRadius: "8px",
					margin: 0,
					paddingLeft: "10px",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}>
				{Object.values(RESERVATION_STATUSES).map(({ name, value }) => (
					<CheckView
						key={value}
						checkedColor={reservationStatusColors[value]}
						language={language}
						label={name}
						value={value}
						defaultSort={defaultSort}
						onToggleIsChecked={() => {
							setDefaultSort(false)

							const updatedFilters = filters.includes(value)
								? filters.filter((filter) => filter !== value)
								: [...filters, value]

							setFilters(updatedFilters)
						}}
					/>
				))}
			</Box>
			<ViewButton
				width="140px"
				onClick={() => {
					reset()
					setFilters(DEFAULT_ENABLED_RESERVATION_FILTERS)
					setDefaultSort(true)
				}}
				backgroundColor={grey[700]}
				label={
					<StyledFilterLabel
						fontSize={
							language === KOREA_LANGUAGE ? typography.size.md : typography.size.sm
						}>
						<ReloadWhiteSvg />
						{t("Reset filter")}
					</StyledFilterLabel>
				}
			/>
		</StyledFilterContainer>
	)
}

export default FilterView
