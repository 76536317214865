import { RESERVATION_STATUSES } from "constant"

const BASE_COLORS = {
	BLACK: "#000000",
	BLUES: {
		navy: "#192C78",
		blue: "#2F4BBC",
		lightblue: "#6F8BFD",
		"lightblue:50": "#678BFD40",
		superLightBlue: "#DCE3FF",
		skyBlue: "#E7F0FE",
	},
	GREY: "#878C9F",
	LIGHTGREY: "#D5D5D5",
	WHITESMOKE: "#F5F5F5",
	WHITE: "#ffffff",
	RED: "#FF6760",
	GREEN: "#00C19F",
	LAVENDAR: "#DCE3FF",
	SELECTED: "#7892FD",
	CANCEL: "#FFD363",
}

export default {
	background: {
		default: BASE_COLORS.WHITE,
		paper: BASE_COLORS.WHITE,
	},

	text: {
		main: BASE_COLORS.WHITE,
		focus: BASE_COLORS.WHITE,
	},

	transparent: {
		main: "transparent",
	},

	white: {
		main: BASE_COLORS.WHITE,
		focus: BASE_COLORS.WHITE,
	},

	black: {
		light: BASE_COLORS.BLACK,
		main: BASE_COLORS.BLACK,
		focus: BASE_COLORS.BLACK,
	},

	primary: {
		main: BASE_COLORS.BLUES.blue,
		focus: BASE_COLORS.BLUES.blue,
	},

	green: {
		main: BASE_COLORS.GREEN,
		focus: BASE_COLORS.GREEN,
	},

	secondary: {
		main: BASE_COLORS.BLUES.lightblue,
		focus: BASE_COLORS.BLUES.lightblue,
		light: BASE_COLORS.BLUES.superLightBlue,
	},

	info: {
		main: BASE_COLORS.GREY,
		focus: BASE_COLORS.LIGHTGREY,
	},

	success: {
		main: BASE_COLORS.BLUES.lightblue,
		focus: BASE_COLORS.BLUES.lightblue,
	},

	warning: {
		main: BASE_COLORS.RED,
		focus: BASE_COLORS.RED,
	},

	error: {
		main: BASE_COLORS.RED,
		focus: BASE_COLORS.RED,
	},

	light: {
		main: BASE_COLORS.WHITESMOKE,
		focus: BASE_COLORS.WHITESMOKE,
	},

	dark: {
		main: BASE_COLORS.BLACK,
		focus: BASE_COLORS.BLACK,
	},

	disabled: {
		main: BASE_COLORS.LIGHTGREY,
		focus: BASE_COLORS.LIGHTGREY,
	},

	dashboard: {
		main: BASE_COLORS.LAVENDAR,
		focus: BASE_COLORS.LAVENDAR,
	},

	calendar: {
		main: BASE_COLORS.BLUES.lightblue,
		yellow: BASE_COLORS.CANCEL,
	},

	navy: {
		main: BASE_COLORS.BLUES.navy,
		focus: BASE_COLORS.BLUES.navy,
	},

	skyBlue: {
		main: BASE_COLORS.BLUES.skyBlue,
		focus: BASE_COLORS.BLUES.skyBlue,
	},

	focusOut: {
		main: BASE_COLORS.LIGHTGREY,
		focus: BASE_COLORS.LIGHTGREY,
	},

	grey: {
		100: "#f8f9fa",
		200: BASE_COLORS.WHITESMOKE,
		300: BASE_COLORS.LIGHTGREY,
		400: "#ced4da",
		500: "#adb5bd",
		600: "#6c757d",
		700: BASE_COLORS.GREY,
		800: "#343a40",
		900: BASE_COLORS.BLACK,
	},

	yellow: {
		main: "#FFD363;",
	},

	gradients: {
		primary: {
			main: BASE_COLORS.BLUES.blue,
			state: BASE_COLORS.BLUES.blue,
		},

		secondary: {
			main: BASE_COLORS.BLUES.lightblue,
			state: BASE_COLORS.BLUES.lightblue,
		},

		avatar: {
			main: BASE_COLORS.BLUES.superLightBlue,
		},

		info: {
			main: BASE_COLORS.BLUES.lightblue,
			state: BASE_COLORS.BLUES.lightblue,
		},

		success: {
			main: BASE_COLORS.BLUES.blue,
			state: BASE_COLORS.BLUES.blue,
		},

		warning: {
			main: "#FFA726",
			state: "#FB8C00",
		},

		error: {
			main: "#EF5350",
			state: "#E53935",
		},

		light: {
			main: "#EBEFF4",
			state: "#CED4DA",
		},

		dark: {
			main: "#2A5FD1",
			state: "#2A5FD1",
		},
	},

	socialMediaColors: {
		facebook: {
			main: "#3b5998",
			dark: "#344e86",
		},

		twitter: {
			main: "#55acee",
			dark: "#3ea1ec",
		},

		instagram: {
			main: "#125688",
			dark: "#0e456d",
		},

		linkedin: {
			main: "#0077b5",
			dark: "#00669c",
		},

		pinterest: {
			main: "#cc2127",
			dark: "#b21d22",
		},

		youtube: {
			main: "#e52d27",
			dark: "#d41f1a",
		},

		vimeo: {
			main: "#1ab7ea",
			dark: "#13a3d2",
		},

		slack: {
			main: "#3aaf85",
			dark: "#329874",
		},

		dribbble: {
			main: "#ea4c89",
			dark: "#e73177",
		},

		github: {
			main: "#24292e",
			dark: "#171a1d",
		},

		reddit: {
			main: "#ff4500",
			dark: "#e03d00",
		},

		tumblr: {
			main: "#35465c",
			dark: "#2a3749",
		},
	},

	badgeColors: {
		primary: {
			background: "#f8b3ca",
			text: "#cc084b",
		},

		secondary: {
			background: "#d7d9e1",
			text: "#6c757d",
		},

		info: {
			background: "#aecef7",
			text: "#095bc6",
		},

		success: {
			background: "#bce2be",
			text: "#339537",
		},

		warning: {
			background: "#ffd59f",
			text: "#c87000",
		},

		error: {
			background: "#fcd3d0",
			text: "#f61200",
		},

		light: {
			background: "#ffffff",
			text: "#c7d3de",
		},

		dark: {
			background: "#8097bf",
			text: "#1e2e4a",
		},
	},

	coloredShadows: {
		primary: "#e91e62",
		secondary: "#110e0e",
		info: "#00bbd4",
		success: "#4caf4f",
		warning: "#ff9900",
		error: "#f44336",
		light: "#adb5bd",
		dark: "#404040",
	},

	reservationStatusColors: {
		// [RESERVATION_STATUSES.WAITING.value]: BASE_COLORS.GREY,
		[RESERVATION_STATUSES.RESERVED.value]: BASE_COLORS.BLACK,
		[RESERVATION_STATUSES.IN_PROGRESS.value]: BASE_COLORS.BLUES.lightblue,
		[RESERVATION_STATUSES.COMPLETED.value]: BASE_COLORS.BLUES.blue,
		[RESERVATION_STATUSES.NOSHOW.value]: BASE_COLORS.RED,
		[RESERVATION_STATUSES.CANCELLED.value]: BASE_COLORS.CANCEL,
	},

	inputBorderColor: BASE_COLORS.LIGHTGREY,

	tabs: {
		indicator: { boxShadow: "#ddd" },
	},

	baseColors: { ...BASE_COLORS },
}
