import { useTheme } from "@emotion/react"
import { Tooltip, Typography } from "@mui/material"
import pxToRem from "assets/theme/functions/pxToRem"
import PropTypes from "prop-types"
import { useState } from "react"

function AppCopyTooltip({ children, title, textToBeCopied }) {
	const {
		palette: { navy, secondary },
	} = useTheme()

	const [copied, setCopied] = useState(false)

	return (
		<Tooltip
			onClick={() => {
				setCopied(true)
				navigator.clipboard.writeText(textToBeCopied)
			}}
			componentsProps={{
				tooltip: {
					sx: {
						bgcolor: secondary.light,
						padding: pxToRem(10),
						borderRadius: pxToRem(8),
						maxWidth: pxToRem(240),
					},
				},
			}}
			placement="left-start"
			title={
				<Typography fontSize="size.sm" fontWeight="bold" color={navy.main}>
					{copied ? "Copied!" : title}
				</Typography>
			}
			followCursor>
			{children}
		</Tooltip>
	)
}

AppCopyTooltip.defaultProps = {
	title: "",
	textToBeCopied: "",
}

AppCopyTooltip.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string,
	textToBeCopied: PropTypes.string,
}

export default AppCopyTooltip
