import { api } from "services/api"
import { API_FITTING_MANAGEMENT } from "services/endpoints"
import { TAG_FITTING_MANAGEMENT } from "services/tags"

const fittingManagementApi = api.injectEndpoints({
	endpoints(build) {
		return {
			getFittingList: build.query({
				query(params) {
					return {
						url: API_FITTING_MANAGEMENT,
						params,
					}
				},

				providesTags: [TAG_FITTING_MANAGEMENT],
			}),

			getFittingDetail: build.query({
				query(id) {
					return {
						url: `${API_FITTING_MANAGEMENT}/${id}`,
						method: "GET",
					}
				},

				providesTags: [TAG_FITTING_MANAGEMENT],
			}),
		}
	},
})

export default fittingManagementApi
