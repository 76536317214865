import { Box, Typography } from "@mui/material"
import { useLocalizedDateUtils } from "hooks"

import DataBox from "./DataBox"

const ReservationsListCard = ({ palette, typography, selectedDay, plusButton, handleDataBox }) => {
	const { formatCalendarDay } = useLocalizedDateUtils()

	return (
		selectedDay && (
			<Box
				sx={{
					flexGrow: 1,
					minWidth: "300px",
					// maxWidth: "300px",
					minHeight: "550px",
					maxHeight: "920px",
					backgroundColor: palette.white.main,
					border: `1px solid ${palette.grey[300]}`,
					borderRadius: "8px",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "flex-start",
					position: "relative",
					"@media (max-width: 1400px)": {
						minWidth: "300px",
						maxWidth: "500px",
					},
				}}>
				<Typography
					sx={{
						fontSize: typography.size.xl,
						fontWeight: 700,
						paddingY: "15px",
						margint: 0,
					}}>
					{formatCalendarDay(selectedDay.date)}
				</Typography>
				<Box
					width="100%"
					marginBottom="10px"
					borderBottom={`1px solid ${palette.grey[300]}`}></Box>
				<Box
					sx={{
						// display: "flex",
						// flexDirection: "column",
						// justifyContent: "center",
						// alignItems: "center",
						marginBottom: "25px",
						overflowY: "auto",
						scrollBehavior: "smooth",
					}}>
					{selectedDay.reservations?.map((reservation, index) => {
						return (
							<DataBox
								key={reservation.id}
								marginTop={index === 0 ? "5px" : "10px"}
								reservation={reservation}
								isBig={true}
								handleDataBox={handleDataBox}
							/>
						)
					})}
				</Box>
				<Box sx={{ position: "absolute", bottom: "20px", right: "20px" }}>{plusButton}</Box>
			</Box>
		)
	)
}

export default ReservationsListCard
