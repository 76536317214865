import { Box, Typography } from "@mui/material"
import Icon from "@mui/material/Icon"
import pxToRem from "assets/theme/functions/pxToRem"
import { useNavigate } from "react-router-dom"

function DefaultNavbarLink({ icon, name, light, route, onClick }) {
	const navigate = useNavigate()
	return (
		<Box
			component="a"
			mx={1}
			my={1}
			p={1}
			display="flex"
			alignItems="center"
			onClick={() => {
				onClick?.()
				route && navigate(route)
			}}
			sx={(theme) => {
				const { white, dark, grey, primary } = theme.palette
				return {
					cursor: "pointer",
					userSelect: "text",
					color: light ? white.main : grey[700],
					"&:hover": {
						color: light ? dark.main : primary.main,
					},
				}
			}}>
			<Icon
				sx={{
					color: "inherit",
					verticalAlign: "middle",
				}}>
				{icon}
			</Icon>
			<Typography
				variant="h6"
				fontWeight="regular"
				color={light ? "white" : "inherit"}
				textTransform="capitalize"
				sx={{ width: "100%", lineHeight: 0, ml: pxToRem(5) }}>
				&nbsp;{name}
			</Typography>
		</Box>
	)
}

export default DefaultNavbarLink
