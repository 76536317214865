import { Box, Tooltip } from "@mui/material"
import { GolfHoverSvg, GolfSvg } from "assets/svgs"
import {
	ClickableReservationInput,
	GridItemValue,
	SelectionBoxNoData,
	SelectionBoxPlaceholder,
	StyledDivider,
	StyledGridContainer,
	StyledGridItem,
} from "layouts/reservations/components/ReservationForm/shared"
import { useTranslation } from "react-i18next"
import { reservationApi } from "services/modules"
import { isEmptyArray } from "utils/array"

const RoomSelectionBox = ({
	setValue,
	activeSelectionBoxName,
	onClick,
	totalDuration = null,
	selectedRoomID,
	selectedReservationDate = null,
	selectedStartTime = null,
	selectedRoomNameState,
	isDisabled,
	currentRoom,
}) => {
	const { t } = useTranslation()

	const fieldName = "room_id"
	const isActiveSelectionBox = activeSelectionBoxName === fieldName

	const [selectedRoomName, setSelectedRoomName] = selectedRoomNameState

	const params = {
		available_time: totalDuration,
		reservation_date: selectedReservationDate,
		start_time: selectedStartTime,
	}

	const {
		data: { data: rooms } = { data: [] },
		isLoading,
		isSuccess,
	} = reservationApi.useGetSelectableRoomsQuery(params, {
		skip: Object.values(params).some((param) => param === undefined || param === null),
	})

	const setSelectedRoomID = (room) => {
		setValue(fieldName, room.id)
		setSelectedRoomName(room.name)
	}

	const withCurrentRoom = (rooms) => {
		if (!currentRoom || rooms.find((room) => room.id === currentRoom.id)) {
			return rooms
		}

		return [currentRoom, ...rooms]
	}
	const selectableRooms = withCurrentRoom(rooms)

	return (
		<Box>
			<ClickableReservationInput
				label={t("Hitting bay")}
				value={selectedRoomID && selectedRoomName}
				onClick={() => onClick(fieldName)}
				Icon={isActiveSelectionBox ? GolfHoverSvg : GolfSvg}
				mb={isActiveSelectionBox ? 2 : 0}
				isDisabled={isDisabled}
			/>

			{isActiveSelectionBox &&
				(isLoading ? (
					<SelectionBoxPlaceholder />
				) : isSuccess && !isEmptyArray(selectableRooms) ? (
					<Box mt={2}>
						<StyledGridContainer>
							{selectableRooms.map((room) => (
								<Tooltip
									key={room.id}
									title={room.name.length > 6 ? room.name : ""}
									PopperProps={{
										sx: {
											"& .MuiTooltip-tooltip": {
												bgcolor: "secondary.light",
												color: "navy.main",
											},
										},
									}}
									placement="bottom">
									<StyledGridItem
										ownerProps={{
											isSelected: room.id === selectedRoomID,
										}}
										onClick={() => setSelectedRoomID(room)}>
										<GridItemValue>{room.name}</GridItemValue>
									</StyledGridItem>
								</Tooltip>
							))}
						</StyledGridContainer>

						<StyledDivider mt={2} />
					</Box>
				) : (
					<SelectionBoxNoData />
				))}
		</Box>
	)
}

export default RoomSelectionBox
