import { Box } from "@mui/material"
import { TOPNAV_HEIGHT_IN_PX } from "constant"
import PropTypes from "prop-types"

const PageLayout = ({ background, children, sx, ...props }) => {
	return (
		<Box
			width="100%"
			bgcolor={background}
			sx={({ functions: { pxToRem } }) => ({
				overflowX: "hidden",
				position: "relative",
				height: `calc(100vh - ${pxToRem(TOPNAV_HEIGHT_IN_PX)})`,
				...sx,
			})}
			{...props}>
			{children}
		</Box>
	)
}

PageLayout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default PageLayout
