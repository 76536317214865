import { Badge, Box } from "@mui/material"
import { CameraSvg } from "assets/svgs"
import AppAvatar from "components/AppAvatar"
import { useTopbarLoading } from "hooks"
import { useRef } from "react"
import { appUserApi } from "services/modules"
import { useUser } from "stores/slice/user"
import { handleError } from "utils/helpers"

export default function EditProfilePicture({ profilePicture }) {
	const fileInputRef = useRef(null)

	const [changeProfile, { isLoading }] = handleError(appUserApi.useChangeAppUserProfileMutation)

	useTopbarLoading(isLoading)
	const user = useUser()

	return (
		<Badge
			overlap="circular"
			anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
			badgeContent={
				<Box
					onClick={() => {
						fileInputRef.current.click()
					}}
					sx={({ palette: { grey }, functions: { pxToRem } }) => ({
						marginBottom: pxToRem(15),
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						width: pxToRem(32),
						height: pxToRem(32),
						borderRadius: "50%",
						border: `3px solid ${grey[700]}`,
						boxSizing: "border-box",
						bgcolor: "background.default",
						color: "grey.700",
						"&:hover": {
							borderColor: "primary.main",
							color: "primary.main",
						},
					})}>
					<CameraSvg fill="currentcolor" />
				</Box>
			}
			sx={{
				cursor: "pointer",
				".MuiBadge-badge": {
					padding: 0,
				},
			}}
			variant="contained">
			<input
				type="file"
				accept="image/*"
				style={{ display: "none" }}
				onChange={(e) => {
					const [file] = e.target.files

					if (file) {
						const formData = new FormData()
						formData.append("profile_image", file)

						changeProfile(formData)
					}
				}}
				ref={fileInputRef}
			/>
			<AppAvatar
				alt={user?.name}
				src={profilePicture}
				size="large"
				onClickHandler={() => {
					fileInputRef.current.click()
				}}
			/>
		</Badge>
	)
}
