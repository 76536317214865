import { createContext } from "react"

export const CartContext = createContext({
	cart: { items: null, type: null, equipment: null },
	setCart: null,
	clubType: null,
	clubComponent: null,
	currentCartIndex: null,
	setCurrentCartIndex: null,
	alwaysShowPagination: false,
	onCartConfirm: null,
	cartBtnText: null,
	isLoading: false,
})
