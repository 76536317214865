import { CS_TYPES } from "constant"
import { array, object, string } from "yup"

const helpFormSchema = (t) =>
	object({
		title: string().required(t("Enter the title.")),
		category: string()
			.oneOf(CS_TYPES.map(({ value }) => value))
			.required(t("Enter the type of inquiry.")),
		contents: string().required(t("Enter the inquiry details.")),
		photo_list: array().of(string()),
	}).required()

export default helpFormSchema
