import { Box, Typography, useTheme } from "@mui/material"
import pxToRem from "assets/theme/functions/pxToRem"
import { ConfirmDialog } from "components"
import AppSwitch from "components/AppSwitch"
import { CartContext } from "context/cartContext"
import { useContext } from "react"
import { Trans, useTranslation } from "react-i18next"

import DefaultShaftImg from "../../assets/images/default_shaft.png"

function CommonClubDialog({
	selectedItem,
	setSelectedItem,
	useStockShaft,
	setUseStockShaft,
	clubType,
	equipment,
}) {
	const {
		palette: { grey },
	} = useTheme()
	const { t } = useTranslation()
	const { cart, setCart, setCurrentCartIndex } = useContext(CartContext)

	const onDialogConfirm = () => {
		const addItem = {
			name: selectedItem?.name,
			brand: selectedItem?.brand?.name,
			image: selectedItem?.photos?.path,
			data: selectedItem,
		}
		if (useStockShaft) {
			let item = {
				head: addItem,
				head_id: selectedItem?.id,
				shaft: {
					name:
						selectedItem?.stock_shafts?.length > 0
							? selectedItem?.stock_shafts[0]?.shaft?.shaft_name
							: "",
					model:
						selectedItem?.stock_shafts?.length > 0
							? selectedItem?.stock_shafts[0]?.shaft?.shaft_name
							: "",
					brand:
						selectedItem?.stock_shafts?.length > 0
							? selectedItem?.stock_shafts[0]?.shaft?.brand?.name
							: "",
					image: DefaultShaftImg,
					data: null,
				},
				shaft_id: null,
				isStockShaft: useStockShaft,
			}
			setCart({ ...cart, items: [item], type: clubType, equipment: equipment })
		} else
			setCart({
				...cart,
				items: [{ head: addItem, head_id: selectedItem?.id }],
				type: clubType,
				equipment: equipment,
			})
		setCurrentCartIndex(0)
		setSelectedItem()
	}

	const onDialogClose = () => {
		setSelectedItem(null)
	}

	return (
		<ConfirmDialog
			open={selectedItem}
			onClose={onDialogClose}
			onCancel={onDialogClose}
			cancelLabel={t("Cancel")}
			confirmLabel={t("Confirm")}
			onConfirm={onDialogConfirm}>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="center"
				flexDirection="column"
				marginTop={pxToRem(15)}
				marginBottom={pxToRem(30)}>
				<Typography textAlign="center" marginBottom={pxToRem(30)} fontSize="size.md">
					<Trans>{t("Select if you're using a <strong>stock shaft.</strong>")}</Trans>
				</Typography>
				<Box
					width="100%"
					paddingY={pxToRem(16)}
					paddingX={pxToRem(40)}
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					borderRadius={pxToRem(8)}
					border={`1px solid ${grey[300]}`}>
					<Typography fontSize="size.md" mr={pxToRem(20)}>
						<Trans>{t("Stock shaft")}</Trans>
					</Typography>
					<AppSwitch
						defaultChecked={useStockShaft}
						onChange={() => setUseStockShaft((prev) => !prev)}
					/>
				</Box>
			</Box>
		</ConfirmDialog>
	)
}

export default CommonClubDialog
