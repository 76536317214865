import { Box } from "@mui/material"
import WeekViewDatePicker from "components/DatePicker/WeekViewDatePicker"
import { ENGLISH_LANGUAGE } from "constant"
import { useCalendar, useTopbarLoading } from "hooks"
import { useEffect } from "react"
import { reservationApi } from "services/modules"

import { FilterView, WeekCalendarView, WeekSelector } from "./CalenderComponents"

const WeekView = ({ calendarViewProps }) => {
	const {
		language,
		palette,
		typography,
		reservationOptions,
		filters,
		setFilters,
		setResponse,
		reset,
		handleDataBox,
		plusButton,
		holidays,
	} = calendarViewProps

	const {
		monthName,
		weekDays,
		year,
		month,
		days,
		week,
		setWeekStartDate,
		previousWeek,
		nextWeek,
		thisWeek,
		previousMonth,
		nextMonth,
		thisMonth,
		thisYear,
		zeroPad,
	} = useCalendar()

	const [weeklyReservation, { data: weeklyReservationListRes, isFetching }] =
		reservationApi.useLazyWeeklyReservationListQuery()

	const [monthlyReservation, { data: monthlyReservationListRes }] =
		reservationApi.useLazyMonthlyReservationListQuery()

	useTopbarLoading(isFetching)

	const startWeekDate = week[0].date.toString()

	useEffect(() => {
		weeklyReservation({
			...reservationOptions,
			date: week[0].formatted,
			status: filters.join(","),
		})
		monthlyReservation({
			...reservationOptions,
			date: `${year}${zeroPad(month + 1)}`,
			status: filters.join(","),
		})
	}, [filters, startWeekDate, month, year, reservationOptions.app_user_id, reservationOptions.q])

	useEffect(() => {
		setResponse(weeklyReservationListRes)
	}, [weeklyReservationListRes?.data])

	const handleToday = () => {
		thisYear()
		thisMonth()
		thisWeek()
	}

	week.map((day) => {
		day.reservations = []
		weeklyReservationListRes?.data?.map((reservation) => {
			if (day.formatted === reservation.reservation_date) {
				day.reservations.push(reservation)
			}
		})
		return day
	})

	days.map((day) => {
		day.rsCount = 0
		monthlyReservationListRes?.data?.map((reservation) => {
			if (day.formatted === reservation.reservation_date) {
				day.rsCount++
			}
		})
		return day
	})

	holidays.forEach((holiday) => {
		const index = week.findIndex(({ formatted }) => formatted === holiday.date)

		if (index !== -1) {
			week[index].holiday = holiday
		}
	})

	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "flex-end",
					"@media (max-width: 1574px)": language === ENGLISH_LANGUAGE && {
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						gap: "5px",
					},
					"@media (max-width: 1476px)": language !== ENGLISH_LANGUAGE && {
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						gap: "5px",
					},
				}}>
				<WeekSelector week={week} previousWeek={previousWeek} nextWeek={nextWeek} />
				<Box
					sx={{
						"@media (max-width: 1400px)": {
							marginTop: "15px",
						},
					}}
				/>
				<FilterView
					handleToday={handleToday}
					filters={filters}
					setFilters={setFilters}
					reset={reset}
				/>
			</Box>
			<Box
				marginTop="20px"
				sx={{
					display: "flex",
					flexDirection: "row",
					gap: "20px",
					overflowX: "auto",
					scrollBehavior: "smooth",
					// "@media (max-width: 1560px)": {
					// 	flexDirection: "column",
					// 	justifyContent: "center",
					// 	alignItems: "center",
					// },
				}}>
				<WeekCalendarView
					week={week}
					palette={palette}
					typography={typography}
					isLoading={isFetching}
					handleDataBox={handleDataBox}
				/>
				<WeekViewDatePicker
					language={language}
					monthName={monthName}
					weekDays={weekDays}
					year={year}
					month={month}
					week={week}
					days={days}
					setWeekStartDate={setWeekStartDate}
					previousMonth={previousMonth}
					nextMonth={nextMonth}
					plusButton={plusButton}
				/>
			</Box>
		</Box>
	)
}

export default WeekView
