import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import { Checkbox, FormControlLabel, Typography, useTheme } from "@mui/material"
import { DEFAULT_ENABLED_RESERVATION_FILTERS, KOREA_LANGUAGE } from "constant"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const CheckView = ({ checkedColor, language, label, value, defaultSort, onToggleIsChecked }) => {
	const [checked, setChecked] = useState(false)

	const { t } = useTranslation()
	const { typography, palette } = useTheme()

	const getDefaultCheck = (value) =>
		defaultSort && DEFAULT_ENABLED_RESERVATION_FILTERS.includes(value)

	useEffect(() => {
		defaultSort && setChecked(getDefaultCheck(value))
	}, [defaultSort])

	const handleCheckboxChange = (event) => {
		onToggleIsChecked()
		setChecked(event.target.checked)
	}

	return (
		<FormControlLabel
			control={
				<Checkbox
					sx={{ margin: "0 6px 0 14px", padding: 0 }}
					disableRipple={true}
					checked={checked}
					onChange={handleCheckboxChange}
					size="small"
					icon={
						<CheckCircleOutlineIcon
							fontSize="medium"
							sx={{ color: palette.grey[300] }}
						/>
					}
					checkedIcon={<CheckCircleIcon fontSize="medium" sx={{ color: checkedColor }} />}
				/>
			}
			label={
				<Typography
					sx={{
						margin: 0,
						padding: 0,
						marginTop: "2px",
						fontSize:
							language === KOREA_LANGUAGE ? typography.size.md : typography.size.xs,
						fontWeight: 500,
					}}
					color={checked ? checkedColor : palette.grey[300]}>
					{t(label)}
				</Typography>
			}
		/>
	)
}

export default CheckView
