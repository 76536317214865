import { COMMON_REGEXES, OTP_INPUT_LENGTH } from "constant"
import { boolean, object, string } from "yup"

const signupSchema = (t) =>
	object({
		personalInfo: object({
			name: string(),
			email: string().emailExtended(t),
			password: string()
				.min(8, t("Password must be at least 8 characters"))
				.matches(
					/^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\\[\]:;<>,.?~\\/-])[^ ]*$/,
					t(
						"Combination of English letters + numbers + special characters (excluding spaces)"
					)
				)
				.test(
					"no-more-than-3-identical-numbers-in-a-row",
					t("Cannot use more than 3 identical numbers in a row"),
					(pwd) => {
						return !/(.)\1\1\1/.test(pwd)
					}
				)
				.required(t("Please enter your password")),
			passwordCheck: string()
				.test("passwords-match", t("Passwords must match"), function (value) {
					return this.parent.password === value
				})
				.required(t("Please enter your password again")),
			phone_number: string()
				.required(t("Enter phone number"))
				.test("KR_PHONE_FORMAT", t("Invalid phone number"), (value) => {
					if (!value) return false
					const numericValue = value.replace(/-/g, "") // remove '-'
					return COMMON_REGEXES.KR_PHONE_FORMAT.test(numericValue)
				}),
		}),
		code: string()
			.test("length", t("Please enter 6 digits."), (value) => {
				return value && value.length === OTP_INPUT_LENGTH
			})
			.required(t("OTP input")),
		terms: object({
			terms_of_use: boolean().oneOf([true], t("Please accept the required terms")).required(),
			privacy_policy: boolean()
				.oneOf([true], t("Please accept the required terms"))
				.required(),
			accept_marketing_SMS: boolean().oneOf([true, false]),
			accept_marketing_Email: boolean().oneOf([true, false]),
		}),
	}).required()

export default signupSchema
