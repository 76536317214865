export const formatNumberWithLeadingZeros = (number, desiredLength) => {
	return String(number).padStart(desiredLength, "0")
}

export const removeNonNumeric = (value) => value.replace(/[^0-9]/g, "")

export const removeNonNumericExceptDot = (value) => value.replace(/[^0-9.]|\.(?=.*\.)/g, "")

export function autoFillDashes(indexToBeFilled, value) {
	return value.split("").reduce((init, current, index) => {
		if (indexToBeFilled.includes(index)) {
			init += "-"
		}

		init += current

		return init
	}, "")
}

/**
 * capitalizeAndSnake("hello world") // "HELLO_WORLD"
 */
export const capitalizeAndSnake = (text) => {
	const formattedString = text.toUpperCase().replace(/\s+/g, "_")

	return formattedString
}

// todo(@oak)
export const toLabelString = (text) => {
	const words = text.split("_")
	const firstWordCapitalized = words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase()
	const restOfWords = words
		.slice(1)
		.map((word) => word.toLowerCase())
		.join(" ")

	return firstWordCapitalized + " " + restOfWords
}

export const formatPhoneNumber = (input = "") => {
	if (!input) return input
	const numericValue = removeNonNumeric(input)
	const limitedLengthValue = numericValue.slice(0, 11)

	return autoFillDashes([3, 7], limitedLengthValue)
}

export const removeHyphens = (str = "") => {
	return str.replace(/-/g, "")
}

export const isEmpty = (str) => {
	return str === null || str === undefined || str.trim() === ""
}

export const isTrue = (flag) => {
	return flag === "true" || flag === true
}

export const formatHeight = (input = "") => {
	if (!input) return input
	const numericValue = removeNonNumericExceptDot(String(input))
	return numericValue
}

export const formatWeight = (input = "") => {
	if (!input) return input
	const numericValue = removeNonNumericExceptDot(String(input))
	return numericValue
}

export const formatSize = (input = "", t) => {
	if (!input) return input
	const numericValue = removeNonNumeric(String(input))
	return t("{{No}}", { No: numericValue })
}

export const formatSizeNoUnit = (input = "") => {
	if (!input) return input
	const numericValue = removeNonNumeric(String(input))
	return numericValue
}

export const formatClubWeight = (input = "") => {
	if (!input) return input
	const numericValue = removeNonNumericExceptDot(String(input))
	return numericValue + " g"
}

export const formatClubWeightNoUnit = (input = "") => {
	if (!input) return input
	const numericValue = removeNonNumericExceptDot(String(input))
	return numericValue
}

export const formatClubSwingWeight = (input = "") => {
	if (!input) return input
	return String(input) + " g"
}

export const formatClubSwingWeightNoUnit = (input = "") => {
	if (!input) return input
	return String(input)
}

export const formatClubLength = (input = "") => {
	if (!input) return input
	const numericValue = removeNonNumericExceptDot(String(input))
	return numericValue + " inch"
}

export const formatClubLengthNoUnit = (input = "") => {
	if (!input) return input
	const numericValue = removeNonNumericExceptDot(String(input))
	return numericValue
}

export const formatClubAngles = (input = "") => {
	if (!input) return input
	const numericValue = removeNonNumericExceptDot(String(input))
	return numericValue + " deg"
}

export const formatClubAnglesNoUnit = (input = "") => {
	if (!input) return input
	const numericValue = removeNonNumericExceptDot(String(input))
	return numericValue
}

export const formatClubFlex = (input = "") => {
	if (!input) return input
	return String(input) + " flex"
}

export const formatClubFlexNoUnit = (input = "") => {
	if (!input) return input
	return String(input)
}

export const replaceWithDash = (text, pattern) => text.replace(pattern, "-")

export const isEqualString = (str1, str2) => {
	return str1.toLowerCase() === str2.toLowerCase()
}

export const truncateString = (inputString = "", maxLength = 10) => {
	if (inputString.length > maxLength) {
		return inputString.substring(0, maxLength) + "..."
	}
	return inputString
}
