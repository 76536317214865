import { DATE_DEFAULT_FORMAT, DATE_FORMATS } from "constant"
import {
	addDays,
	format,
	formatDuration,
	getHours,
	getMinutes,
	parse,
	startOfToday,
	subDays,
	subMonths,
	subWeeks,
} from "date-fns"
import { enUS, ko } from "date-fns/locale"
import { useIsEnglish } from "stores/slice/app"
import { getDiscreteMinsAndHrs } from "utils/dateTime"

const useLocalizedDateUtils = () => {
	const isEnglish = useIsEnglish()
	const locale = isEnglish ? enUS : ko

	const formatDateString = ({ dateString, formatFrom = DATE_DEFAULT_FORMAT, formatTo }) => {
		const date = parse(dateString, formatFrom, new Date())
		return format(date, formatTo, { locale })
	}

	const formatDate = (date, output = DATE_DEFAULT_FORMAT) => {
		try {
			return format(new Date(date), output, { locale })
		} catch (err) {
			return date
		}
	}

	const getAmPmFromDateString = (dateString) => {
		const parsedTime = parse(dateString, DATE_FORMATS.BACKEND_RESERVATION_TF, new Date())
		const formattedTime = format(
			parsedTime,
			isEnglish ? DATE_FORMATS.SHOWN_RESERVATION_TF_EN : DATE_FORMATS.SHOWN_RESERVATION_TF_KR,
			{ locale }
		)
		return formattedTime.toLowerCase()
	}

	const getAmPmFromDateStringResDataBox = (dateString) => {
		// temporarily catch error with "try catch",
		// the error cause because of the dateString which come from backend
		try {
			const parsedTime = parse(dateString, DATE_FORMATS.BACKEND_RESERVATION_TF, new Date())
			const formattedTime = format(
				parsedTime,
				isEnglish
					? DATE_FORMATS.SHOWN_RESERVATION_DATA_EN
					: DATE_FORMATS.SHOWN_RESERVATION_DATA_KR,
				{ locale }
			)
			return formattedTime.toLowerCase()
		} catch (error) {
			console.log("the date string: ", dateString)
			console.log("the date string: error: ", error)
			return ""
		}
	}

	const formatCalendarDay = (selectedDay, justDayName = false) => {
		const options = {
			month: "short",
			day: "numeric",
		}

		const formattedDate = selectedDay.toLocaleDateString(isEnglish ? "en-US" : "ko-KR", options)

		if (justDayName) {
			return formattedDate // Return only the day name if justDayName is true
		} else {
			const dayName = selectedDay.toLocaleDateString(isEnglish ? "en-US" : "ko-KR", {
				weekday: "short",
			})
			return `${formattedDate} (${dayName})`
		}
	}

	const formatWeekCalendarDay = (selectedDay, t, justDayName = true) => {
		const options = {
			weekday: "short",
		}

		const formattedDate = selectedDay.toLocaleDateString(isEnglish ? "en-US" : "ko-KR", options)

		if (justDayName) {
			return t(formattedDate)
		} else {
			const day = selectedDay.toLocaleDateString(isEnglish ? "en-US" : "ko-KR", {
				month: "short",
				day: "numeric",
			})
			return `${day} (${t(formattedDate)})`
		}
	}

	const subtractDays = (dateString, amount = 1) => {
		const date = parse(dateString, DATE_DEFAULT_FORMAT, new Date())
		return format(subDays(date, amount), DATE_DEFAULT_FORMAT)
	}

	const formatLocalizedDuration = (durationObj) => formatDuration(durationObj, { locale })

	const getDiscreteTimeFromContinuousHour = (hourInFloat) => {
		const { hours, minutes } = getDiscreteMinsAndHrs(hourInFloat)
		return formatLocalizedDuration({ hours, minutes })
	}

	const formatTimeDurationWithShift = (date) => {
		const dateObj = new Date(date)
		const timeShift = formatDate(dateObj, "a", { locale }) // AM | PM
		const hours = getHours(dateObj)
		const minutes = getMinutes(dateObj)
		return `${timeShift} ${formatLocalizedDuration({ hours, minutes })}`
	}

	const formatTimeWithShift = (date) => {
		const dateObj = new Date(date)
		const minutes = getMinutes(dateObj)
		return locale === ko
			? minutes === 0
				? format(dateObj, "a h시", { locale })
				: format(dateObj, "a h시 mm분", { locale })
			: format(dateObj, "h:mm a", { locale })
	}

	const getPeriodOfStartAndEndDates = (period) => {
		const addedDay = addDays(startOfToday(), 1)
		let endDate = format(startOfToday(), "yyyy-MM-dd")
		let startDate

		switch (period) {
			case "today":
				startDate = endDate
				break
			case "1week":
				startDate = format(subWeeks(addedDay, 1), "yyyy-MM-dd")
				break
			case "3months":
				startDate = format(subMonths(addedDay, 3), "yyyy-MM-dd")
				break
			default:
				startDate = ""
				endDate = ""
		}

		return { startDate, endDate }
	}

	return {
		formatDateString,
		formatDate,
		formatCalendarDay,
		formatWeekCalendarDay,
		getAmPmFromDateString,
		getAmPmFromDateStringResDataBox,
		getDiscreteTimeFromContinuousHour,
		formatTimeDurationWithShift,
		formatTimeWithShift,
		subtractDays,
		getPeriodOfStartAndEndDates,
	}
}

export default useLocalizedDateUtils
