import { Switch, styled } from "@mui/material"

const StyledSwitch = styled(Switch)(
	({
		theme: {
			palette: { grey, primary, background },
			transitions,
		},
	}) => ({
		width: 38,
		height: 18,
		padding: 0,
		display: "flex",
		"&:active": {
			"& .MuiSwitch-thumb": {
				width: 15,
			},
			"& .MuiSwitch-switchBase.Mui-checked": {
				transform: "translateX(20px)",
			},
		},
		"& .MuiSwitch-switchBase": {
			padding: 3,
			"&.Mui-checked": {
				transform: "translateX(20px)",
				color: background.paper,
				"& + .MuiSwitch-track": {
					opacity: 1,
					backgroundColor: primary.main,
				},
			},
			"&.Mui-disabled": {
				"& + .MuiSwitch-track": {
					opacity: 1,
					backgroundColor: grey[300],
				},
			},
		},
		"& .MuiSwitch-thumb": {
			boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
			width: 12,
			height: 12,
			borderRadius: 100,
			transition: transitions.create(["width"], {
				duration: 200,
			}),
		},
		"& .MuiSwitch-track": {
			borderRadius: 20 / 2,
			opacity: 1,
			backgroundColor: grey[300],
			boxSizing: "border-box",
		},
	})
)

function AppSwitch({ onChange, disabled, defaultChecked }) {
	return <StyledSwitch disabled={disabled} checked={defaultChecked} onChange={onChange} />
}

export default AppSwitch
