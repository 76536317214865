import { Box, IconButton, Typography, styled } from "@mui/material"
import {
	ArrowHeadLeftSvg,
	ArrowHeadRightSvg,
	SmallArrowLeftSvg,
	SmallArrowRightSvg,
} from "assets/svgs"
import pxToRem from "assets/theme/functions/pxToRem"

const StyledArrowButton = styled(IconButton)(
	({
		theme: {
			palette: { transparent, primary, black, info },
		},
		isSmall,
	}) => ({
		padding: 0,
		margin: 0,
		backgroundColor: transparent.main,
		border: "none",
		outline: "none",
		color: isSmall ? info.main : black.main,
		"&:hover": {
			color: primary.main,
		},
	})
)

const SelectorWrapper = ({
	onClickPrevious,
	onClickNext,
	margin,
	label,
	isSmall,
	maxWidth = null,
}) => {
	return (
		<Box
			sx={{
				maxWidth: pxToRem(maxWidth || 320),
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}>
			<ArrowButton onClick={onClickPrevious} isLeft={true} isSmall={isSmall} />
			{label ? (
				<Typography variant="h6" fontSize={22} sx={{ margin }}>
					{label}
				</Typography>
			) : (
				<Box mx={margin} />
			)}
			<ArrowButton onClick={onClickNext} isSmall={isSmall} />
		</Box>
	)
}

export default SelectorWrapper

const ArrowButton = ({ onClick, isLeft = false, isSmall = false }) => {
	return (
		<StyledArrowButton disableRipple={true} onClick={onClick} isSmall={isSmall}>
			{isSmall ? <SmallArrow isLeft={isLeft} /> : <NormalArrow isLeft={isLeft} />}
		</StyledArrowButton>
	)
}

const NormalArrow = ({ isLeft }) => {
	return isLeft ? (
		<ArrowHeadLeftSvg stroke="currentcolor" />
	) : (
		<ArrowHeadRightSvg stroke="currentcolor" />
	)
}

const SmallArrow = ({ isLeft }) => {
	return isLeft ? (
		<SmallArrowLeftSvg fill="currentcolor" />
	) : (
		<SmallArrowRightSvg fill="currentcolor" />
	)
}
