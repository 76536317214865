import { Box, Typography, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useIsEnglish } from "stores/slice/app"

const views = ["M", "W", "D", "List"]

const ReservationViewSelect = ({ view, setView }) => {
	const { palette } = useTheme()
	const { t } = useTranslation()
	const isEn = useIsEnglish()

	return (
		<Box
			sx={{
				width: isEn ? "215px" : "215px",
				"@media (min-width: 1400px)": {
					width: isEn ? "215px" : "219px",
				},
				backgroundColor: palette.grey[300],
				borderRadius: "8px",
				display: "flex",
				gap: 1,
				justifyContent: "center",
				alignItems: "center",
			}}>
			{views.map((_view) => (
				<button
					key={_view}
					style={{
						height: "44px",
						width: isEn ? "46px" : _view !== "List" ? "44px" : "70px",
						margin: 0,
						padding: 0,
						outline: "none",
						border: "none",
						borderRadius: "8px",
						backgroundColor:
							_view === view ? palette.primary.main : palette.transparent.main,
						cursor: "pointer",
					}}
					onClick={() => setView(_view)}>
					<Typography
						sx={{
							margin: 0,
							padding: 0,
							paddingX: "1px",
							color: palette.white.main,
							fontSize: "16px",
							fontWeight: 700,
							"&:hover": {
								padding: isEn ? "7.5px 6.62px" : "7.5px 0px",
								outline: `1px solid ${palette.primary.main}`,
								borderRadius: "8px",
							},
						}}>
						{t(_view)}
					</Typography>
				</button>
			))}
		</Box>
	)
}

export default ReservationViewSelect
