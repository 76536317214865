import { Box, styled, useTheme } from "@mui/material"
import { TickSvg } from "assets/svgs"
import PropTypes from "prop-types"
import React from "react"

const SVG_SIZE = {
	small: 16,
	normal: 18,
}

const BOX_SIZE = {
	small: 18,
	normal: 24,
}

const CheckBoxContainer = styled(Box)(({
	theme: { palette, borders },
	size,
	ownerState: { isChecked, isDisabled },
	variant,
}) => {
	const {
		grey: { 300: disabledColor },
		primary,
	} = palette

	const unCheckedBorderColor = disabledColor

	const borderColor = isChecked
		? isDisabled
			? disabledColor
			: primary.main
		: unCheckedBorderColor

	const backgroundColor = isChecked ? (isDisabled ? disabledColor : primary.main) : "transparent"

	return {
		width: BOX_SIZE[size],
		height: BOX_SIZE[size],
		backgroundColor,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		border: `1.5px solid ${borderColor}`,
		borderRadius: variant === "round" ? 100 : borders.borderRadius.xs,
		cursor: isDisabled ? "not-allowed" : "pointer",
	}
})

const AppCheckBox = ({ size, isChecked, variant, onToggle, isDisabled }) => {
	const { palette } = useTheme()

	return (
		<CheckBoxContainer
			onClick={onToggle}
			ownerState={{
				isChecked,
				isDisabled,
			}}
			size={size}
			variant={variant}
			disabled={isDisabled}
			component="button">
			<TickSvg
				width={SVG_SIZE[size]}
				height={SVG_SIZE[size]}
				stroke={isChecked ? palette.white.main : palette.baseColors.LIGHTGREY}
			/>
		</CheckBoxContainer>
	)
}

AppCheckBox.defaultProps = {
	size: "normal",
	isChecked: false,
	variant: "square",
	disabled: false,
}

AppCheckBox.propTypes = {
	size: PropTypes.oneOf(["small", "normal"]),
	isChecked: PropTypes.bool,
	variant: PropTypes.oneOf(["round", "square"]),
	onToggle: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
}

export default AppCheckBox
