import { Box, styled } from "@mui/material"
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table"
import { useLocalizedDateUtils } from "hooks"
import columns from "layouts/reservations/components/ReservationForm/TimeDurationPopOver/DurationTable/columns"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

const StyledTable = styled(Box)(({ theme }) => {
	const { grey, black, secondary } = theme.palette
	const { borderRadius } = theme.borders
	const { size: fontSize } = theme.typography

	return {
		textAlign: "center",
		color: black.main,
		width: "100%",
		borderSpacing: 0,
		border: `1px solid ${grey[300]}`,
		borderRadius: borderRadius.lg,
		overflow: "hidden",
		fontSize: fontSize.sm,

		thead: {
			backgroundColor: secondary.light,
			color: black.main,
		},

		tr: {
			height: "50px",

			"& > th, td": {
				borderBottomWidth: "1px",
				"&:not(:last-child)": {
					borderRightWidth: "1px",
				},
			},

			"&:last-child > td": {
				borderBottomWidth: 0,
			},
		},

		"th, td": {
			padding: 2,
			borderColor: grey[300],
			borderStyle: "solid",
			borderWidth: 0,
			minWidth: "60px",
		},

		th: {
			backgroundColor: grey[200],
			fontWeight: "bold",
		},

		td: {
			fontWeight: "regular",
			"&:first-of-type": {
				backgroundColor: secondary.light,
				fontWeight: "bold",
			},
		},
	}
})

const DurationTable = () => {
	const { t } = useTranslation()
	const { getDiscreteTimeFromContinuousHour } = useLocalizedDateUtils()

	const DURATIONS = useMemo(
		() => [
			{
				id: 1,
				"fitting-type": t("INDIVIDUAL_CLUB_FITTING"),
				individual: getDiscreteTimeFromContinuousHour(0.5),
				all: "-",
			},
			{
				id: 2,
				"fitting-type": t("FULL_CLUB_FITTING"),
				individual: `${getDiscreteTimeFromContinuousHour(
					0.5
				)} ~ ${getDiscreteTimeFromContinuousHour(1)}`,
				all: getDiscreteTimeFromContinuousHour(2),
			},
		],
		[]
	)

	const table = useReactTable({
		columns: columns(t),
		data: DURATIONS,
		getCoreRowModel: getCoreRowModel(),
	})

	return (
		<Box p={2}>
			<StyledTable component="table">
				<thead>
					<Box component="tr" sx={{ "&.MuiBox-root": { height: "25px" } }}>
						<Box component="th" rowSpan="2">
							{t("Fitting type")}
						</Box>
						<Box component="th" colspan="2">
							{t("Detail")}
						</Box>
					</Box>

					<Box component="tr" sx={{ "&.MuiBox-root": { height: "25px" } }}>
						<Box component="th">{t("Each")}</Box>
						<Box component="th">{t("All")}</Box>
					</Box>
				</thead>

				<tbody>
					{table.getRowModel().rows.map((row) => {
						return (
							<tr key={row.id}>
								{row.getVisibleCells().map((cell) => {
									return (
										<td key={cell.id}>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext()
											)}
										</td>
									)
								})}
							</tr>
						)
					})}
				</tbody>
			</StyledTable>
		</Box>
	)
}

export default DurationTable
