import { Box, Typography, useTheme } from "@mui/material"
import pxToRem from "assets/theme/functions/pxToRem"
import React from "react"
import { useTranslation } from "react-i18next"

const ClubFormHeaderText = ({
	label = "Club",
	required = false,
	showTip = false,
	onlyShaft = false,
}) => {
	const { t } = useTranslation()
	const {
		palette: { grey, warning },
		typography,
	} = useTheme()

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				marginTop: !showTip || onlyShaft ? pxToRem(40) : 0,
				marginBottom: showTip ? pxToRem(30) : 0,
			}}>
			<Box display="flex" alignItems="center" justifyContent="end" variant="caption">
				<Typography color="#000" fontSize={typography.size.md} fontWeight={700}>
					{t(label)}
				</Typography>
				{required && (
					<Box
						width={pxToRem(6)}
						height={pxToRem(6)}
						bgcolor={warning.main}
						marginLeft={pxToRem(4)}
						borderRadius={pxToRem(6)}
					/>
				)}
			</Box>
			{showTip && (
				<Box
					display="flex"
					alignItems="center"
					justifyContent="end"
					variant="caption"
					fontWeight="regular">
					<Box
						width={pxToRem(6)}
						height={pxToRem(6)}
						bgcolor={warning.main}
						marginRight={pxToRem(4)}
						borderRadius={pxToRem(6)}
					/>
					<Typography color={grey[700]} fontSize={typography.size.xs}>
						{t("Required fields")}
					</Typography>
				</Box>
			)}
		</Box>
	)
}

export default ClubFormHeaderText
