import { Box, Typography, useTheme } from "@mui/material"
import pxToRem from "assets/theme/functions/pxToRem"
import { ConfirmDialog } from "components"
import { IRON, WEDGE } from "constant"
import { CartContext } from "context/cartContext"
import { isUndefined } from "lodash"
import { useContext, useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"

import DefaultIronImg from "../../assets/images/default_iron.png"
import DefaultShaftImg from "../../assets/images/default_shaft.png"
import DefaultWedgeImg from "../../assets/images/default_wedge.png"
import IronClubCard from "./IronClubCard"
import IronClubCardForEquipment from "./IronClubCardForEquipment"

function IronClubDialog({ selectedItem, setSelectedItem, equipment, isForEquipment = false }) {
	const {
		palette: { grey },
	} = useTheme()
	const { t } = useTranslation()
	const [customIronSet, setCustomIronSet] = useState(null)
	const { cart, setCart, setCurrentCartIndex } = useContext(CartContext)

	let { heads, wedgeHeads } = selectedItem || {}

	const onIronDialogConfirm = () => {
		setCart({
			...cart,
			items: Object.values(customIronSet).filter((value) => value.is_use),
			type: IRON,
			equipment,
		})
		setCurrentCartIndex(0)
		setCustomIronSet(null)
		setSelectedItem()
	}

	const onDialogClose = () => {
		setCustomIronSet(null)
		setSelectedItem()
	}

	useEffect(() => {
		let shaft = {
			name: "",
			brand: "",
			image: DefaultShaftImg,
			data: null,
		}

		if (customIronSet) {
			setCustomIronSet(
				Object.assign(
					{},
					Object.values(customIronSet).map((ironSet) => ({
						head: {
							name: ironSet?.head?.name,
							brand: ironSet?.head?.brand,
							image: ironSet?.head?.image,
							data: ironSet?.head?.data,
						},
						clubType: ironSet.clubType,
						shaft,
						set_id: ironSet?.set_id,
						head_id: ironSet?.id,
						shaft_id: shaft?.id,
						is_use: ironSet.is_use,
						isStockShaft: ironSet.isStockShaft,
					}))
				)
			)
		} else {
			heads = heads.map((item) => {
				const headItem = { ...item }
				headItem.clubType = IRON
				if (isUndefined(headItem.photos?.path)) {
					headItem.photos = { path: DefaultIronImg }
				}
				return headItem
			})
			wedgeHeads = wedgeHeads.map((item) => {
				const wedgeItem = { ...item }
				wedgeItem.clubType = WEDGE
				if (isUndefined(wedgeItem.photos?.path)) {
					wedgeItem.photos = { path: DefaultWedgeImg }
				}
				return wedgeItem
			})
			const headList = Array().concat(heads, wedgeHeads)
			setCustomIronSet(
				Object.assign(
					{},
					headList.map((head) => {
						return {
							head: {
								name: head?.name,
								brand: head?.brand?.name,
								image: head?.photos?.path,
								data: head,
							},
							clubType: head.clubType,
							shaft,
							set_id: head?.set_id,
							head_id: head?.id,
							shaft_id: shaft?.id,
							is_use: true,
							isStockShaft: true,
						}
					})
				)
			)
		}
	}, [])

	return (
		<ConfirmDialog
			open={selectedItem}
			confirmDisabled={false}
			onClose={onDialogClose}
			onCancel={onDialogClose}
			cancelLabel={t("Cancel")}
			confirmLabel={t("Confirm")}
			onConfirm={onIronDialogConfirm}>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="center"
				flexDirection="column"
				marginTop={pxToRem(15)}
				marginBottom={pxToRem(30)}>
				<Typography textAlign="center" marginBottom={pxToRem(30)} fontSize="size.md">
					<Trans>
						{t(
							"After selecting the iron set's <strong>head number</strong>, please select if you are using a stock shaft and the <strong>model.</strong>"
						)}
					</Trans>
				</Typography>
				{isForEquipment ? (
					<IronClubCardForEquipment
						customIronSet={customIronSet}
						setCustomIronSet={setCustomIronSet}
					/>
				) : (
					selectedItem &&
					customIronSet && (
						<Box
							width="100%"
							borderRadius={pxToRem(8)}
							border={`1px solid ${grey[300]}`}>
							{Object.entries(customIronSet).map(([, value], index) => {
								return (
									<IronClubCard
										key={value?.head_id}
										name={`${index}_ironStockShaft`}
										index={index}
										customIronSet={customIronSet}
										setCustomIronSet={setCustomIronSet}
										isLastItem={
											Object.values(customIronSet)?.length - 1 === index
										}
										head={value?.head?.data}
									/>
								)
							})}
						</Box>
					)
				)}
			</Box>
		</ConfirmDialog>
	)
}

export default IronClubDialog
