import { Box } from "@mui/material"
import WeekViewDatePicker from "components/DatePicker/WeekViewDatePicker"
import { ENGLISH_LANGUAGE } from "constant"
import { useCalendar, useLocalizedDateUtils, useTopbarLoading } from "hooks"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { reservationApi } from "services/modules"

import { DayCalendarView, DaySelector, FilterView } from "./CalenderComponents"

const DayView = ({ calendarViewProps }) => {
	const { t } = useTranslation()

	const { getAmPmFromDateStringResDataBox } = useLocalizedDateUtils()

	const {
		language,
		palette,
		typography,
		reservationOptions,
		filters,
		setFilters,
		setResponse,
		reset,
		handleDataBox,
		plusButton,
		holidays,
	} = calendarViewProps

	const {
		monthName,
		weekDays,
		year,
		month,
		days,
		selectedDay,
		setSelectedDay,
		previousDay,
		thisDay,
		nextDay,
		thisWeek,
		previousMonth,
		nextMonth,
		thisMonth,
		thisYear,
		zeroPad,
	} = useCalendar()

	const [dailyReservation, { data: dailyReservationListRes }] =
		reservationApi.useLazyDailyReservationListQuery()

	const [monthlyReservation, { data: monthlyReservationListRes, isFetching }] =
		reservationApi.useLazyMonthlyReservationListQuery()

	useTopbarLoading(isFetching)

	useEffect(() => {
		dailyReservation({
			...reservationOptions,
			date: selectedDay.formatted,
			status: filters.join(","),
		})
		monthlyReservation({
			...reservationOptions,
			date: `${year}${zeroPad(month + 1)}`,
			status: filters.join(","),
		})
	}, [filters, selectedDay, month, year, reservationOptions.app_user_id, reservationOptions.q])

	useEffect(() => {
		setResponse(dailyReservationListRes)
	}, [dailyReservationListRes?.data])

	const handleToday = () => {
		thisYear()
		thisMonth()
		thisWeek()
		thisDay()
	}

	selectedDay.morningReservations = []
	selectedDay.eveningReservations = []
	dailyReservationListRes?.data?.map((reservation) => {
		const startTime = getAmPmFromDateStringResDataBox(reservation.start_time)
		const starts = startTime.split(" ")
		const toCheck = language === ENGLISH_LANGUAGE ? starts[1] : starts[0]
		if (toCheck === t("AM")) {
			selectedDay.morningReservations.push(reservation)
		} else {
			selectedDay.eveningReservations.push(reservation)
		}
	})

	days.map((day) => {
		day.rsCount = 0
		monthlyReservationListRes?.data?.map((reservation) => {
			if (day.formatted === reservation.reservation_date) {
				day.rsCount++
			}
		})
		return day
	})

	const holiday = holidays.find(({ date }) => selectedDay.formatted === date)

	if (holiday) {
		selectedDay.holiday = holiday
	}

	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "flex-end",
					"@media (max-width: 1455px)": {
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						gap: "5px",
					},
				}}>
				<DaySelector
					selectedDay={selectedDay}
					previousDay={previousDay}
					nextDay={nextDay}
				/>
				<Box
					sx={{
						"@media (max-width: 1400px)": {
							marginTop: "15px",
						},
					}}
				/>
				<FilterView
					handleToday={handleToday}
					filters={filters}
					setFilters={setFilters}
					reset={reset}
				/>
			</Box>
			<Box
				marginTop="20px"
				sx={{
					display: "flex",
					flexDirection: "row",
					gap: "20px",
					overflowX: "auto",
					scrollBehavior: "smooth",
					// "@media (max-width: 1500px)": {
					// 	flexDirection: "column",
					// 	justifyContent: "center",
					// 	alignItems: "center",
					// },
				}}>
				<DayCalendarView
					selectedDay={selectedDay}
					t={t}
					palette={palette}
					typography={typography}
					isLoading={isFetching}
					handleDataBox={handleDataBox}
				/>
				<WeekViewDatePicker
					language={language}
					monthName={monthName}
					weekDays={weekDays}
					year={year}
					month={month}
					week={[]}
					days={days}
					setSelectedDay={setSelectedDay}
					previousMonth={previousMonth}
					nextMonth={nextMonth}
					plusButton={plusButton}
					selectedDay={selectedDay}
				/>
			</Box>
		</Box>
	)
}

export default DayView
