import { Box, Typography, styled } from "@mui/material"
import { NavLink } from "react-router-dom"

const NAV_ITEM_HEIGHT_IN_PX = 58

export const StyledNavItem = styled(NavLink)(({ theme, ownerState }) => {
	const {
		functions: { pxToRem },
		spacing,
		palette,
		borders: { borderRadius },
	} = theme

	const { isSelected, isSmall } = ownerState

	return {
		display: "flex",
		position: "relative",
		width: isSmall ? "auto" : "100%",
		overflow: "hidden",
		justifyContent: isSmall ? "center" : "flex-start",
		alignItems: "center",
		height: pxToRem(NAV_ITEM_HEIGHT_IN_PX),
		padding: isSmall ? spacing(0.5) : spacing(1, 2),
		backgroundColor: isSelected
			? palette.baseColors.BLUES.superLightBlue
			: palette.transparent.main,
		borderRadius: borderRadius.lg,
		cursor: "pointer",

		"&:hover": {
			backgroundColor: isSelected
				? `${palette.baseColors.BLUES.superLightBlue}80`
				: palette.grey[200],
		},
	}
})

// NOTE: actually, just StyledNavItemLabel is enough but it needs a relative positioned wrapper for position animations - so here's StyledNavItemLabelWrap
export const StyledNavItemLabelWrap = styled(Box)(({ theme, ownerState }) => {
	const { spacing } = theme
	const { isSmall } = ownerState

	return {
		position: "relative",
		flexGrow: isSmall ? 0 : 1,
		marginLeft: isSmall ? 0 : spacing(1),
		height: "100%",
		display: "flex",
		alignItems: "center",
		overflow: "hidden",
	}
})

export const StyledNavItemLabel = styled(Typography)(({ theme, ownerState }) => {
	const { transitions, typography } = theme
	const { isSmall } = ownerState

	return {
		position: "absolute",
		left: isSmall ? 50 : 0,
		fontSize: typography.size.lg,
		transition: transitions.create("left", {
			easing: transitions.easing.easeOut,
			duration: transitions.duration.enteringScreen,
		}),
	}
})

StyledNavItemLabel.defaultProps = {
	noWrap: true,
}
