import { DIRECT_INPUT_VALUE } from "constant"
import { array, object, string } from "yup"

const ironSetFormSchema = (t, register) =>
	object({
		club: string().required(t("Select the club")),
		number: array().required(),
		head: object({
			brand: string().required(t("Enter the brand")),
			model: string().required(t("Enter the model")),
		}),
		shaft: object({
			brand: string().required(t("Enter the brand")),
			model: string().required(t("Enter the model")),
			material:
				register === "customer"
					? string().nullable()
					: string().required(t("Enter the material")),
			flex:
				register === "customer"
					? string().nullable()
					: string().required(t("Select the flex")),
			weight:
				register === "customer"
					? string().nullable()
					: string().required(t("Select the weight")),
			weight_direct_input: string().when("weight", {
				is: (value) => value === DIRECT_INPUT_VALUE,
				then: (rule) => rule.required(t("Select the number")),
			}),
			length:
				register === "customer"
					? string().nullable()
					: string().required(t("Select the length")),
			length_direct_input: string().when("length", {
				is: (value) => value === DIRECT_INPUT_VALUE,
				then: (rule) => rule.required(t("Select the number")),
			}),
		}),
	})

export default ironSetFormSchema
