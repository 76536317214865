import { Button, Typography, useTheme } from "@mui/material"
import { Close, Ellipse } from "assets/svgs"
import DataTable from "components/DataTable/stateful"
import StatusBubble from "components/StatusBubble"
import { showModal } from "components/dialog"
import MemoDialog from "components/dialog/MemoDialog"
import { DATE_DEFAULT_FORMAT, RESERVATION_STATUSES, STRING_DATE_FORMAT } from "constant"
import { useLocalizedDateUtils, useTopbarLoading } from "hooks"
import { useTranslation } from "react-i18next"
import { reservationApi } from "services/modules"
import { useIsEnglish } from "stores/slice/app"
import { handleError } from "utils/helpers"

import { FilterView } from "./CalenderComponents"

const ListView = ({
	filters,
	setFilters,
	setResponse,
	reservationOptions,
	reset,
	handleOnRowClick,
	handleToday,
}) => {
	const { palette, typography } = useTheme()
	const { t } = useTranslation()
	const isEn = useIsEnglish()

	const { formatDateString, getAmPmFromDateStringResDataBox } = useLocalizedDateUtils()

	const [updateReservationMemo] = handleError(
		reservationApi.useUpdateListViewReservationMemoMutation
	)

	const columns = [
		{
			id: "user.name",
			accessorKey: "user.name",
			header: t("Name"),
			cell: ({ getValue, row }) => (
				<Typography
					minWidth={isEn ? 200 : ""}
					onClick={() => handleOnRowClick(row)}
					color={
						row.original.status !== RESERVATION_STATUSES.CANCELLED.value &&
						palette.primary.main
					}
					fontSize={typography.size.md}>
					{getValue()}
				</Typography>
			),
		},
		{
			enableSorting: false,
			accessorKey: "status",
			header: t("R_status"),
			cell: (data) => <StatusBubble status={data.getValue()} />,
		},
		{
			enableSorting: false,
			accessorKey: "fitting_type",
			header: t("Fitting type"),
			cell: (data) => t(data.getValue().name),
		},
		{
			enableSorting: false,
			id: "appUser.name",
			accessorKey: "appUser.name",
			header: t("Fitter"),
		},
		{
			enableSorting: false,
			accessorKey: "start_time",
			header: t("Time"),
			cell: (data) =>
				`${getAmPmFromDateStringResDataBox(
					data.getValue()
				)} - ${getAmPmFromDateStringResDataBox(data.row.original.end_time)}`,
		},
		{
			accessorKey: "reservation_date",
			header: t("Date"),
			cell: (data) =>
				formatDateString({
					dateString: data.getValue(),
					formatFrom: STRING_DATE_FORMAT,
					formatTo: DATE_DEFAULT_FORMAT,
				}),
		},
		{
			enableSorting: false,
			accessorKey: "memo",
			header: t("Memo"),
			cell: ({ getValue, row: { original } }) => {
				const value = getValue()

				const isCancelled = original.status === RESERVATION_STATUSES.CANCELLED.value

				const Icon = value !== "" ? Ellipse : Close

				return (
					<Button
						sx={{ px: 2 }}
						disabled={isCancelled}
						onClick={() => {
							showModal(({ closeModal }) => {
								return (
									<MemoDialog
										isOpen
										memoContent={value}
										confirmBtnLabel={t("Save")}
										onClose={closeModal}
										onConfirm={async (memo) => {
											await updateReservationMemo({
												id: original.id,
												data: { id: original.id, memo },
											})
												.unwrap()
												.finally(() => closeModal())
										}}
									/>
								)
							})
						}}>
						<Icon stroke={isCancelled ? palette.info.main : palette.primary.main} />
					</Button>
				)
			},
		},
	]

	return (
		<DataTable
			columns={columns}
			formFields={{
				...reservationOptions,
				status: filters.join(","),
			}}
			lazyFetcher={reservationApi.useLazyGetAllReservationsQuery}
			renderFilters={(response) => {
				useTopbarLoading(response?.isFetching)
				setResponse(response?.data)

				return (
					<FilterView
						handleToday={handleToday}
						filters={filters}
						setFilters={setFilters}
						reset={reset}
					/>
				)
			}}
		/>
	)
}

export default ListView
