import { Box, Dialog, Typography } from "@mui/material"
import pxToRem from "assets/theme/functions/pxToRem"
import AutoLoadingButton from "components/AutoLoadingButton"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useIsEnglish } from "stores/slice/app"

const MAX_WIDTH = 320

const ConfirmDialog = ({
	children,
	onConfirm,
	confirmDisabled = false,
	onCancel = null,
	onClose,
	confirmLabel,
	cancelLabel,
	open,
}) => {
	const { t } = useTranslation()
	const isEnglish = useIsEnglish()

	const buttonStyles = {
		textWrap: "nowrap",
		// flex: 1,
		height: pxToRem(50),
		width: pxToRem(135),
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="confirmation-dialog"
			sx={({ spacing, boxShadows, borders }) => ({
				"& .MuiDialog-paper": {
					width: "100%",
					maxWidth: MAX_WIDTH,
					maxHeight: pxToRem(800),
					minHeight: pxToRem(175),
					overflowY: "auto",
					padding: spacing(2),
					paddingY: spacing(isEnglish ? 3 : 4),
					boxShadow: boxShadows.md,
					borderRadius: borders.borderRadius.lg,
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					alignItems: "center",
				},
			})}>
			{children}

			<Box gap={1} sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
				<AutoLoadingButton
					variant="outlined"
					color="primary"
					sx={buttonStyles}
					onClick={onCancel || onClose}>
					{cancelLabel || t("Cancel")}
				</AutoLoadingButton>

				<AutoLoadingButton
					disabled={confirmDisabled}
					variant="contained"
					color="primary"
					sx={buttonStyles}
					onClick={onConfirm}>
					{confirmLabel || t("Confirm")}
				</AutoLoadingButton>
			</Box>
		</Dialog>
	)
}

const Title = ({ children, ...rest }) => {
	return (
		<Typography textAlign="center" pb={4} pt={2} variant="body2" {...rest}>
			{children}
		</Typography>
	)
}

export default ConfirmDialog
ConfirmDialog.Title = Title

ConfirmDialog.propTypes = PropTypes.shape({
	children: PropTypes.object.isRequired,
	open: PropTypes.bool.isRequired,
	onConfirm: PropTypes.func.isRequired,
	onCancel: PropTypes.func,
	onClose: PropTypes.func.isRequired,
	confirmLabel: PropTypes.string,
	cancelLabel: PropTypes.string,
})

Title.propTypes = PropTypes.shape({
	children: PropTypes.node.isRequired,
})

/*
USAGE
=====
<ConfirmDialog
    open={isDialogOpen}
    onConfirm={() => console.log("confirmed!")}
    onClose={() => console.log("closed!")}
    confirmLabel="yes plz">
    <ConfirmDialog.Title>
        Hello <b>world ?</b>
    </ConfirmDialog.Title>
</ConfirmDialog>
*/
