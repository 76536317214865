import { ArrowForwardIos as ArrowForwardIosIcon } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import { ControlledAppSelect } from "components"
import { StyledAutoLoadingButton, StyledFlexBox } from "components/dialog/shared"
import { RESERVATION_STATUSES } from "constant"
import { Splitter } from "layouts/common/Splitter"
import { memo } from "react"
import { useTranslation } from "react-i18next"
import { useIsEnglish } from "stores/slice/app"
import { formatPhoneNumber } from "utils/string"

const ReservationStatusLabelBox = ({ label, value }) => {
	const { t } = useTranslation()

	const ColorBall = () => (
		<Box
			sx={({ palette }) => ({
				width: 12,
				height: 12,
				backgroundColor: palette.reservationStatusColors[value],
				borderRadius: "50%",
			})}
		/>
	)

	return (
		<Box sx={{ display: "flex", alignItems: "center", maxWidth: "100%" }} gap={1} mr={1}>
			<ColorBall />
			<Typography noWrap fontSize="size.md" sx={{ display: "inline-block" }}>
				{t(label)}
			</Typography>
		</Box>
	)
}

const ReservationStatusOptions = Object.values(RESERVATION_STATUSES).map(({ name, value }) => ({
	label: <ReservationStatusLabelBox label={name} value={value} />,
	value,
}))

const ReservationFormCustomerInfo = ({ onNameClick, customer, editFormProps }) => {
	const { t } = useTranslation()
	const isEnglish = useIsEnglish()

	const { reservationID, resendButton, control, statusFieldName } = editFormProps || {}

	return (
		<Box gap={3} mb={3} sx={{ display: "flex", flexDirection: "column" }}>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<Typography variant="body2">{t("Name")}</Typography>

					{/* customer name + arrow icon (to navigate to detail page) */}
					<Box
						ml={2}
						component="a"
						gap={0.5}
						onClick={onNameClick}
						sx={({ palette, transitions }) => ({
							display: "flex",
							alignItems: "center",
							cursor: "pointer",
							transition: transitions.create("color", {
								easing: transitions.easing.easeOut,
								duration: transitions.duration.enteringScreen,
							}),
							"&:hover": {
								color: palette.primary.main,
							},
						})}>
						<Typography variant="h5" color="inherit">
							{customer?.name}
						</Typography>
						<ArrowForwardIosIcon color="inherit" />
					</Box>
				</Box>
			</Box>

			<Box
				sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
				gap={1}>
				<StyledFlexBox gap={2} sx={{ justifyContent: "flex-start" }}>
					<Typography variant="body2">{t("Phone number")}</Typography>
					<Typography variant="body2">
						{formatPhoneNumber(customer?.phone_number)}
					</Typography>
				</StyledFlexBox>

				{editFormProps && (
					// reservation status select box
					<ControlledAppSelect
						control={control}
						sx={{ minWidth: isEnglish ? 130 : 200, flexGrow: 1 }}
						placeholder="select"
						size="normal"
						name={statusFieldName}
						options={ReservationStatusOptions}
					/>
				)}
			</Box>

			{editFormProps && (
				<>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<Typography variant="body2">{t("Reservation number")}</Typography>
						<Typography ml={2} variant="body2">
							{reservationID}
						</Typography>
					</Box>

					<StyledAutoLoadingButton
						color="secondary"
						onClick={resendButton.onClick}
						disabled={resendButton.isDisabled}>
						{t("Resend confirmation text")}
					</StyledAutoLoadingButton>
				</>
			)}

			<Splitter sx={{ mt: 1 }} />
		</Box>
	)
}

export default memo(ReservationFormCustomerInfo)
