import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useIsEnglish } from "stores/slice/app"
import { ROUTE_LOGIN, ROUTE_SIGN_UP } from "urls"
import { isTrue } from "utils/string"

import { ConfirmPage } from "./layout"
import SignupForm from "./signupForm"

const TYPE_FORM = "form"

function SignupHome({ data }) {
	return <>{data.type === TYPE_FORM ? <SignupForm data={data} /> : <LandingPage data={data} />}</>
}

function LandingPage({ data }) {
	const { name, email, is_new, store_name, store_id, app_user_id } = data
	const isNew = isTrue(is_new)

	const { t } = useTranslation()
	const navigate = useNavigate()
	const isEnglish = useIsEnglish()

	const onClickHandler = () => {
		if (isNew) {
			navigate(ROUTE_SIGN_UP, {
				state: {
					name,
					email,
					is_new,
					type: TYPE_FORM,
					app_user_id: app_user_id,
					store_id: store_id,
				},
			})
		} else {
			navigate(ROUTE_LOGIN)
		}
	}

	const getConfirmMessage = () => {
		const getNewMemberMessage = () =>
			isEnglish
				? `Welcome to ${store_name} Admin.\nSign up to get involved with your store.`
				: t("Welcome to Admin.\nSign up to get involved with your store.", {
						value: store_name,
					})

		const getExistingMemberMessage = () =>
			isEnglish
				? `Welcome to ${store_name} Admin.\nYour participation in the store has been completed, so please log in with your existing ID and PW and use Admin`
				: t(
						"Welcome to ABCD Golf Center Admin.\nYour participation in the store has been completed, so please log in with your existing ID and PW and use Admin",
						{
							value: store_name,
						}
					)

		return isNew ? getNewMemberMessage() : getExistingMemberMessage()
	}

	return (
		<ConfirmPage
			title={t("Welcome")}
			content={getConfirmMessage()}
			onClick={onClickHandler}
			buttonName={isNew ? t("Involve") : t("Login")}
		/>
	)
}

export default SignupHome
