import { Box } from "@mui/material"
import { AppSelect } from "components"
import { useTopbarLoading } from "hooks"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { storeApi } from "services/modules"
import { updateStore, useStore } from "stores/slice/store"
import { updateUserStoreRole, useUser } from "stores/slice/user"
import { ROUTE_DASHBOARD } from "urls"
import { getUserStoreRole, handleError } from "utils/helpers"

function useSwitchStore(userId) {
	const dispatch = useDispatch()

	const [fetchStore, { isFetching }] = handleError(storeApi.useLazyGetStoreDetailQuery)

	const [changeStore, { isLoading }] = handleError(storeApi.useChangeUserStoreMutation)

	useTopbarLoading(isFetching || isLoading)

	return async (id) => {
		const { data: store } = await fetchStore(id)

		await changeStore({ store_id: store.id })

		dispatch(updateStore(store))

		const userRole = getUserStoreRole(store.storeMembers, userId)

		dispatch(updateUserStoreRole({ ...userRole, store_id: store.id }))
	}
}

export default function StoreSelector({ closeMenu }) {
	const user = useUser()
	const navigate = useNavigate()

	const activeStore = useStore()

	const { data: assignedStores = [] } = handleError(() =>
		storeApi.useGetUserAssignedStoresQuery(null, { skip: !user.id })
	)

	const stores = assignedStores.map((store) => store.store)

	const switchStore = useSwitchStore(user.id)

	return (
		<Box
			sx={{
				width: "100%",
				padding: ({ functions: { pxToRem } }) => `0 ${pxToRem(30)}`,
			}}>
			<AppSelect
				fullWidth
				size="small"
				defaultValue=""
				displayEmpty
				renderValue={(selected) => (!selected ? activeStore?.name : selected?.label)}
				onChange={async ({ target: { value } }) => {
					try {
						await switchStore(value.value)
						navigate(ROUTE_DASHBOARD)
					} finally {
						closeMenu()
					}
				}}
				options={stores}>
				{stores.map((item) => (
					<AppSelect.MenuItem
						className={item.id === activeStore.id && "Mui-selected"}
						key={item.id}
						value={{
							label: item.name,
							value: item.id,
						}}>
						{item.name}
					</AppSelect.MenuItem>
				))}
			</AppSelect>
		</Box>
	)
}
