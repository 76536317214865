import breakpoints from "assets/theme/base/breakpoints"
import colors from "assets/theme/base/palette"
import { STANDARD_FONT_SIZE } from "constant"

const { info, dark } = colors

export default {
	html: {
		scrollBehavior: "smooth",
	},

	"*, *::before, *::after": {
		margin: 0,
		padding: 0,
	},

	"a, a:link, a:visited": {
		textDecoration: "none !important",
	},

	"a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
		color: `${dark.main} !important`,
		transition: "color 150ms ease-in !important",
	},

	"a.link:hover, .link:hover, a.link:focus, .link:focus": {
		color: `${info.main} !important`,
	},

	// responsive font sizes
	":root": {
		fontSize: `${STANDARD_FONT_SIZE - 4}px`,
	},

	[`@media (min-width:${breakpoints.values.lg}px)`]: {
		":root": {
			fontSize: `${STANDARD_FONT_SIZE - 2}px`,
		},
	},

	[`@media (min-width:${breakpoints.values.xxl}px)`]: {
		":root": {
			fontSize: `${STANDARD_FONT_SIZE}px`,
		},
	},

	u: {
		textUnderlineOffset: "4px",
	},

	"span, p": {
		whiteSpace: "pre-wrap",
	},

	/* Hide scrollbar for Chrome, Safari and Opera */
	".hiddenScrollbar::-webkit-scrollbar": {
		display: "none",
	},

	/* Hide scrollbar for IE, Edge and Firefox */
	".hiddenScrollbar": {
		msOverflowStyle: "none" /* IE and Edge */,
		scrollbarWidth: "none" /* Firefox */,
	},
}
