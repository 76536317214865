import { COMMON_REGEXES } from "constant"
import { object, string } from "yup"

const deliveryAddressSchema = (t) =>
	object({
		recipient_name: string().required(),
		address: string().required(),
		receipt_method: string().required(),
		recipient_phone_number: string()
			.required(t("Enter phone number"))
			.test("KR_PHONE_FORMAT", t("Invalid phone number"), (value) => {
				if (!value) return false
				const numericValue = value.replace(/-/g, "") // remove '-'
				return COMMON_REGEXES.KR_PHONE_FORMAT.test(numericValue)
			}),
		shipping_destination: string().required(),
		address_detail: string().required(),
		delivery_info: string(),
	})

export const emptyForm = {
	recipient_name: "",
	address: "",
	receipt_method: "",
	recipient_phone_number: "",
	shipping_destination: "",
	address_detail: "",
	delivery_info: "",
}

export default deliveryAddressSchema
