import { Box, Typography, useTheme } from "@mui/material"
import { useLocalizedDateUtils } from "hooks"
import { useTranslation } from "react-i18next"
import { useIsEnglish } from "stores/slice/app"
import { formatLongText } from "utils/helpers"

const DataBoxForDayView = ({ marginTop, reservation, handleDataBox }) => {
	const { t } = useTranslation()
	const { palette } = useTheme()
	const { getAmPmFromDateStringResDataBox } = useLocalizedDateUtils()

	const isEn = useIsEnglish()

	const startTime = getAmPmFromDateStringResDataBox(reservation.start_time)
	const endTime = getAmPmFromDateStringResDataBox(reservation.end_time)
	const starts = startTime.split(" ")
	const ends = endTime.split(" ")

	return (
		<Box
			onClick={() => handleDataBox(reservation)}
			sx={{
				minWidth: "368px",
				width: "90%",
				maxWidth: "90%",
				"@media (max-width: 1020px)": {
					minWidth: "270px",
					width: "85%",
					maxWidth: "85%",
				},
				minHeight: "60px",
				marginTop: marginTop,
				px: "20px",
				backgroundColor: palette.grey[200],
				borderRadius: "8px",
				cursor: "pointer",
				"&:hover": {
					outline: `1px solid ${palette.primary.main}`,
				},
			}}>
			<Box
				sx={{
					display: "flex",
					alignItems: "flex-end",
					marginTop: "3px",
					gap: "10px",
				}}>
				<Box
					sx={({ palette }) => ({
						minWidth: "10px",
						width: "10px",
						height: "10px",
						borderRadius: "10px",
						backgroundColor: palette.reservationStatusColors[reservation.status],
						marginBottom: "34.7px",
					})}
				/>
				<Box
					sx={{
						width: "100%",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "flex-end",
					}}>
					<Typography fontSize={16}>
						{isEn ? (
							<>
								{startTime} - {endTime}
							</>
						) : (
							<>
								{starts[0]} {starts[1]}-{ends[1]}
							</>
						)}
						<br />
						{formatLongText(reservation?.user?.name, 12)}
					</Typography>
					<Typography fontSize={isEn ? 14 : 16}>
						{formatLongText(t(reservation?.fitting_type?.name), 15)}
					</Typography>
				</Box>
			</Box>
		</Box>
	)
}

export default DataBoxForDayView
