import CloseIcon from "@mui/icons-material/Close"
import DoneIcon from "@mui/icons-material/Done"
import { Box, Typography } from "@mui/material"
import { styled } from "@mui/styles"
import pxToRem from "assets/theme/functions/pxToRem"
import { ControlledAppTextField } from "components"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { isEmpty } from "utils/string"

const IconWrapper = styled(Typography)({
	fontSize: pxToRem(18),
})

const CloseSmallIcon = styled(CloseIcon)({
	fontSize: pxToRem(16),
})

const DoneSmallIcon = styled(DoneIcon)({
	fontSize: pxToRem(16),
})

const IconBox = styled(Box)({
	width: pxToRem(22),
	height: pxToRem(22),
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
})

const ValidationIcon = ({ value, isValid }) => {
	if (!value) {
		return <IconWrapper>&bull;</IconWrapper>
	}

	if (isValid) {
		return <DoneSmallIcon color="primary" />
	} else {
		return <CloseSmallIcon color="error" />
	}
}

const ValidationMessage = ({ value, isValid, message }) => {
	let color = "dark"

	if (value && isValid) {
		color = "primary"
	} else if (value) {
		color = "error"
	}

	return (
		<Box display={"flex"} alignItems={"center"} mt={1}>
			<IconBox>
				<ValidationIcon value={value} isValid={isValid} />
			</IconBox>
			<Typography variant="body3" color={color}>
				{message}
			</Typography>
		</Box>
	)
}

const ValidationField = ({ name, placeholder, error, control, watchValue, validations }) => {
	const isPasswordEmpty = isEmpty(watchValue)
	const [passwordIsOnFocus, setPasswordIsOnFocus] = useState(false)

	return (
		<Box>
			<ControlledAppTextField
				name={name}
				type="password"
				error={Boolean(error)}
				placeholder={placeholder}
				control={control}
				onFocus={() => setPasswordIsOnFocus(true)}
			/>
			{(!isPasswordEmpty || passwordIsOnFocus) &&
				validations.map((v, index) => (
					<ValidationMessage
						key={index}
						value={watchValue}
						isValid={v.test(watchValue)}
						message={v.message}
					/>
				))}
		</Box>
	)
}

const ValidatePasswordForm = ({ errors, control, watchPassword, watchPasswordCheck }) => {
	const { t } = useTranslation()

	const validations = [
		{
			test: (pwd) => /.{8,}/.test(pwd),
			message: t("Enter at least 8 characters"),
		},
		{
			test: (pwd) =>
				/^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\\[\]:;<>,.?~\\/-])[^ ]*$/.test(pwd),
			message: t(
				"Combination of English letters + numbers + special characters (excluding spaces)"
			),
		},
		{
			test: (pwd) => !/(.)\1\1\1/.test(pwd),
			message: t("Cannot use more than 3 identical numbers in a row"),
		},
	]

	return (
		<Box>
			<ValidationField
				name="personalInfo.password"
				placeholder={t("Enter password")}
				error={errors.personalInfo?.password}
				control={control}
				watchValue={watchPassword}
				validations={validations}
			/>
			<Box mt={1.5}>
				<ValidationField
					name="personalInfo.passwordCheck"
					placeholder={t("Enter password again")}
					error={errors.personalInfo?.passwordCheck}
					control={control}
					watchValue={watchPasswordCheck}
					validations={[
						{
							test: (pwdCheck) => pwdCheck === watchPassword,
							message: t("Please enter the same password."),
						},
					]}
				/>
			</Box>
		</Box>
	)
}

export default ValidatePasswordForm
