import { Box, Breadcrumbs, Divider, Grid, Link, Typography } from "@mui/material"
import pxToRem from "assets/theme/functions/pxToRem"
import { ABOUT } from "constant"
import { useTranslation } from "react-i18next"
import { Link as RouterLink, useLocation } from "react-router-dom"

function Segments({ items, ...props }) {
	const { t } = useTranslation()
	const { pathname } = useLocation()

	const Separator = () => (
		<Typography
			color="grey.300"
			sx={{
				lineHeight: 1,
				fontSize: pxToRem(10),
				display: "inline-block",
				verticalAlign: "middle",
			}}>
			|
		</Typography>
	)

	return (
		<Grid container>
			<Breadcrumbs
				separator={<Separator />}
				{...props}
				sx={{
					"& .MuiBreadcrumbs-separator": {
						margin: 0,
					},
				}}>
				{items.map(({ label, to }, index) => {
					const isFirstItem = index === 0
					const sx = {
						px: isFirstItem ? 0 : 3,
						pr: 3,
						fontSize: pxToRem(12),
						verticalAlign: "middle",
						color: "grey.700",
						fontWeight: "400",
					}

					if (!to) {
						sx.cursor = "unset"
						return (
							<Grid item key={label}>
								<Typography sx={sx}>{t(label)}</Typography>
							</Grid>
						)
					} else {
						sx["&:hover"] = { fontWeight: "bold" }
					}

					if (to === pathname) {
						sx.fontWeight = "bold"
					}

					return (
						<Grid item key={label}>
							<Link to={to} component={RouterLink} sx={sx}>
								{t(label)}
							</Link>
						</Grid>
					)
				})}
			</Breadcrumbs>
		</Grid>
	)
}

export default function WithFooter({ children }) {
	const { t } = useTranslation()

	return (
		<Box sx={{ p: 1 }}>
			{children}
			<Box position="relative" bottom="5%" component="footer" height={pxToRem(140)}>
				<Divider sx={{ mb: 2, mt: 2 }} />

				<Box mx={20} display="flex" justifyContent="space-between">
					<Box
						flex="1 0 auto"
						display="flex"
						flexDirection="column"
						justifyContent="space-between">
						<Segments
							items={Object.entries(ABOUT).map(([to, label]) => ({ label, to }))}
						/>

						<Segments
							items={[
								{ label: "VICX Corporation Co., Ltd" },
								{ label: "201, 86 Banpo-daero, Seocho-gu, Seoul, South Korea" },
								{ label: "Registration number 635-81-02953" },
							]}
						/>
					</Box>

					<Box>
						<Typography variant="guideText" fontWeight={700} lineHeight={pxToRem(19.2)}>
							{`${t("Customer center")} 032-832-8128`}
						</Typography>

						<Box display="flex" lineHeight="normal">
							<Box>
								<Typography variant="guideText" noWrap>
									{`${t("Mon ~ Fri")}`}
								</Typography>
								<br />
								<Typography variant="guideText" noWrap>
									{`${t("Lunch hours")}`}
								</Typography>
							</Box>

							<Box ml={2}>
								<Typography variant="guideText" noWrap>
									{`
										(${t("Time am", { time: "10:00" })}
										~
										${t("Time pm", { time: "5:00" })})
									`}
								</Typography>
								<br />
								<Typography variant="guideText" noWrap>
									{/* {`(${"PM 12:00 ~ PM 2:00"})`} */}
									{`
										(${t("Time pm", { time: "12:00" })}
										~
										${t("Time pm", { time: "2:00" })})
									`}
								</Typography>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}
