import { STORE_ROLES } from "constant"
import { boolean, object, string } from "yup"

const inviteNewStaffSchema = (t) =>
	object({
		name: string().required(t("Please enter your name")),
		email: string().emailExtended(t).required(t("Please enter your email")),
		role: string().required().oneOf([STORE_ROLES.ADMIN.value, STORE_ROLES.STAFF.value]),
		phone_number: string().required(t("Please enter your phone number")),
		is_fitter: boolean().required(),
	}).required()

export default inviteNewStaffSchema
