import { Popover, Typography, styled } from "@mui/material"
import { ExclamationSvg } from "assets/svgs"
import { useAnchorElement } from "hooks"
import DurationTable from "layouts/reservations/components/ReservationForm/TimeDurationPopOver/DurationTable"
import { useTranslation } from "react-i18next"

const StyledExclamationSvg = styled(ExclamationSvg)(({ theme: { palette }, ownerProps }) => {
	const { isActive } = ownerProps

	return {
		cursor: "pointer",

		"& .background": {
			fill: isActive ? palette.primary.main : palette.grey[700],
		},

		"& .icon": {
			fill: palette.white.main,
		},

		"&:hover": {
			"& .background": {
				fill: palette.primary.main,
			},
		},
	}
})

const TimeDurationPopOver = () => {
	const { t } = useTranslation()
	const { id, anchorEl, setAnchorEl, open } = useAnchorElement("time-duration-pop-over")

	const handleMouseOver = (event) => {
		setAnchorEl(event.currentTarget)
	}

	return (
		<>
			<StyledExclamationSvg
				onMouseOver={handleMouseOver}
				// onMouseLeave={() => setAnchorEl(null)}
				ownerProps={{ isActive: open }}
			/>

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				sx={({ borders: { borderRadius } }) => ({
					".MuiPopover-paper": {
						width: "100%",
						maxWidth: "420px",
						borderRadius: borderRadius.lg,
					},
				})}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}>
				<Typography p={3} pb={1} variant="h6">
					{t("Time required by fitting type")}
				</Typography>

				<DurationTable />
			</Popover>
		</>
	)
}

export default TimeDurationPopOver
