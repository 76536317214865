import { Grid, TextField } from "@mui/material"
import { AutoLoadingButton, FormDialog } from "components"
import { useRef } from "react"
import { useTranslation } from "react-i18next"

const MemoDialog = ({ onClose, onConfirm, isOpen, confirmBtnLabel, memoContent }) => {
	const { t } = useTranslation()
	const memoRef = useRef(memoContent)

	return (
		<FormDialog onClose={onClose} open={isOpen} width={580} padding={4}>
			<FormDialog.Header paddingTop={4}>{t("Memo")}</FormDialog.Header>
			<FormDialog.Content>
				<TextField inputRef={memoRef} p={2} multiline rows={8} defaultValue={memoContent} />
				<Grid container spacing={1} mt={5}>
					<Grid item xs={6}>
						<AutoLoadingButton
							variant="outlined"
							color="primary"
							fullWidth
							onClick={onClose}>
							{t("Cancel")}
						</AutoLoadingButton>
					</Grid>
					<Grid item xs={6}>
						<AutoLoadingButton
							onClick={() => onConfirm(memoRef.current.value)}
							fullWidth>
							{confirmBtnLabel}
						</AutoLoadingButton>
					</Grid>
				</Grid>
			</FormDialog.Content>
		</FormDialog>
	)
}

export default MemoDialog
