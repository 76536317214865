/**
 * @param {Object} params
 * @param {import("react-hook-form").UseFormReturn} params.hookFormProps - react-hook-form
 * @param {boolean} params.isEdit
 * @returns  {JSX.Element}
 */
import { Box, Button, Typography, useTheme } from "@mui/material"
import pxToRem from "assets/theme/functions/pxToRem"
import FormLoadingSkeleton from "components/FormLoadingSkeleton"
import { AppSelectTextField } from "components/form"
import { DIRECT_INPUT_VALUE, IRON, SPECIAL_INPUT_TYPES } from "constant"
import { useCallback } from "react"
import { useFieldArray } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { clubsApi } from "services/modules"
import validApi from "services/modules/valid"
import { useUser } from "stores/slice/user"
import { arrayToSelectOptions, objectToSelectOptions } from "utils/helpers"

import { ClubFormHeaderText, ClubFormLabel } from "./components"
import {
	HeadBrandInput,
	HeadModelInput,
	ShaftBrandInput,
	ShaftFlexInput,
	ShaftLengthInput,
	ShaftMaterialInput,
	ShaftModelInput,
	ShaftWeightInput,
} from "./components/ClubFormInputs"

function IronSetForm({ hookFormProps, isEdit, register = "store", readOnly = false }) {
	const {
		palette: { primary, background },
	} = useTheme()
	const { t } = useTranslation()
	const user = useUser()
	const {
		formState: { errors },
		control,
		watch,
	} = hookFormProps

	const { fields, append, remove } = useFieldArray({
		control,
		name: "number",
	})

	const inputProps = {
		t,
		methods: hookFormProps,
		control,
		errors,
		disabled: (user.isStaff && register === "store") || readOnly,
	}

	const { data, isFetching } = validApi.useGetClubOptionsQuery({ clubType: IRON })
	const { data: ironNumbers, isFetching: ironNumbersFetching } = validApi.useGetIronNumbersQuery()
	const { data: brands, isFetching: brandFetching } = clubsApi.useGetAllBrandsQuery()
	const { data: shaftModels, isFetching: shaftModelFetching } = clubsApi.useGetClubModelsQuery({
		clubType: "Iron",
		filter: "shaft",
	})
	const { data: headModels, isFetching: headModelFetching } = clubsApi.useGetClubModelsQuery({
		clubType: "Iron",
		filter: "head",
	})

	const { shaft } = data?.data || {}

	const values = watch()

	const brandOptions = brands?.data?.map(({ name }) => ({ label: name, value: name }))
	const headModelOptions = headModels?.data?.map(({ name }) => ({ label: name, value: name }))
	const shaftModelOptions = shaftModels?.data?.map(({ name }) => ({ label: name, value: name }))

	const shaftMaterialOptions = shaft?.material && objectToSelectOptions(shaft?.material, t)
	const ironNumberOptions =
		ironNumbers &&
		Object.entries(ironNumbers.data.number).map(([key, value]) => ({
			label: t ? t(key) : key,
			value: value,
		}))

	const shaftFlexOptions = useCallback(() => {
		if (values?.shaft?.material) {
			const flexes = shaft?.material?.[values.shaft.material]?.shaft_flexes
			return objectToSelectOptions(flexes)
		}
	}, [values.shaft.material])

	const shaftWeightOptions = useCallback(() => {
		if (values.shaft.flex && values.shaft.flex !== DIRECT_INPUT_VALUE) {
			const weights =
				shaft?.material?.[values.shaft.material]?.shaft_flexes?.[values.shaft.flex]?.weights
			if (weights) {
				return arrayToSelectOptions(weights)
			}
		}
	}, [values.shaft.flex])

	const shaftLengthOptions = shaft?.lengths && arrayToSelectOptions(shaft.lengths)

	if (
		isFetching ||
		brandFetching ||
		ironNumbersFetching ||
		shaftModelFetching ||
		headModelFetching
	) {
		return <FormLoadingSkeleton marginTop={5} />
	}

	return (
		<>
			<ClubFormHeaderText label="Head" />

			<HeadBrandInput
				name="head.brand"
				{...inputProps}
				options={brandOptions}
				disabled={isEdit || inputProps.disabled}
			/>

			<HeadModelInput
				name="head.model"
				{...inputProps}
				disabled={isEdit || inputProps.disabled}
				options={headModelOptions}
			/>

			<Box display="flex" flexDirection="column">
				<ClubFormLabel label="Number" errorMsg={errors?.head?.model?.message} />
				{fields.map((field, index) => (
					<Box
						key={field.id}
						marginBottom={pxToRem(20)}
						display="flex"
						alignItems="end"
						gap={1}>
						<Box display="flex" width="100%" flexDirection="column">
							<AppSelectTextField
								name={`number.${index}.value`}
								type={SPECIAL_INPUT_TYPES.NUMBER}
								size="normal"
								variant="normal"
								options={ironNumberOptions}
								placeholder={t("Select number")}
								methods={hookFormProps}
								disabled={inputProps.disabled}
							/>
						</Box>
						{index > 0 && (
							<Button
								sx={{
									width: pxToRem(100),
									height: pxToRem(50),
								}}
								variant="outlined"
								onClick={() => remove(index)}>
								{t("Delete")}
							</Button>
						)}
					</Box>
				))}
				<Box
					display="flex"
					alignSelf="flex-end"
					sx={{ cursor: "pointer" }}
					alignItems="center"
					justifyContent="center"
					bgcolor={primary.main}
					width={pxToRem(50)}
					height={pxToRem(50)}
					marginTop={pxToRem(20)}
					borderRadius={pxToRem(8)}
					onClick={() => append({ number: "" })}>
					<Typography color={background.paper} fontWeight="bold">
						+
					</Typography>
				</Box>
			</Box>

			<ClubFormHeaderText label="Shaft" />

			<ShaftBrandInput
				name="shaft.brand"
				{...inputProps}
				disabled={isEdit || inputProps.disabled}
				options={brandOptions}
			/>

			<ShaftModelInput
				name="shaft.model"
				{...inputProps}
				disabled={isEdit || inputProps.disabled}
				options={shaftModelOptions}
			/>

			<ShaftMaterialInput
				name="shaft.material"
				required={register === "store"}
				{...inputProps}
				options={shaftMaterialOptions}
			/>

			<ShaftFlexInput
				name="shaft.flex"
				required={register === "store"}
				{...inputProps}
				options={shaftFlexOptions()}
			/>

			<ShaftWeightInput
				name="shaft.weight"
				required={register === "store"}
				{...inputProps}
				options={shaftWeightOptions()}
			/>

			<ShaftLengthInput
				name="shaft.length"
				required={register === "store"}
				{...inputProps}
				options={shaftLengthOptions}
			/>
		</>
	)
}

export default IronSetForm
