import { ANALYZER, COMMON_REGEXES } from "constant"
import { mixed, object, string } from "yup"

const storeManagementSchema = (t) =>
	object({
		phoneNumber: string()
			.required(t("Please enter your mobile phone number."))
			.test("KR_PHONE_FORMAT", t("Invalid phone number"), (value) => {
				if (!value) return false
				const numericValue = value.replace(/-/g, "") // remove '-'
				return COMMON_REGEXES.KR_PHONE_FORMAT.test(numericValue)
			}),
		baseAddress: string().required(t("Please enter your address")),
		extraAddress: string().required(t("Please enter your detail address")),
		analyzer: mixed()
			.required(t("Enter launch monitor."))
			.test("analyzer check", t("Enter launch monitor."), function (value) {
				if (typeof value === "number") {
					// Check if the number is included in the allowed numbers array
					return Object.values(ANALYZER)
						.map((item) => item.id)
						.includes(value)
				} else if (typeof value === "string") {
					// Accept any string
					if (value === "") {
						return false
					}
					return true
				} else {
					// Reject any other type
					return false
				}
			}),
	}).required()

export default storeManagementSchema
