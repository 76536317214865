import { createTheme } from "@mui/material/styles"
import borders from "assets/theme/base/borders"
import boxShadows from "assets/theme/base/boxShadows"
import breakpoints from "assets/theme/base/breakpoints"
import globals from "assets/theme/base/globals"
import palette from "assets/theme/base/palette"
import spacing from "assets/theme/base/spacing"
import typography from "assets/theme/base/typography"
import button from "assets/theme/components/button"
import card from "assets/theme/components/card"
import cardContent from "assets/theme/components/card/cardContent"
import cardMedia from "assets/theme/components/card/cardMedia"
import input from "assets/theme/components/form/input"
import inputLabel from "assets/theme/components/form/inputLabel"
import inputOutlined from "assets/theme/components/form/inputOutlined"
import textField from "assets/theme/components/form/textField"
import link from "assets/theme/components/link"
import boxShadow from "assets/theme/functions/boxShadow"
import hexToRgb from "assets/theme/functions/hexToRgb"
import linearGradient from "assets/theme/functions/linearGradient"
import pxToRem from "assets/theme/functions/pxToRem"
import rgba from "assets/theme/functions/rgba"

export default createTheme({
	breakpoints,
	palette,
	typography,
	boxShadows,
	borders,
	spacing,
	functions: {
		boxShadow,
		hexToRgb,
		pxToRem,
		rgba,
		linearGradient,
	},

	components: {
		MuiCssBaseline: {
			styleOverrides: {
				...globals,
			},
		},
		MuiCard: { ...card },
		MuiCardMedia: { ...cardMedia },
		MuiCardContent: { ...cardContent },
		MuiButton: { ...button },
		MuiInput: { ...input },
		MuiInputLabel: { ...inputLabel },
		MuiOutlinedInput: { ...inputOutlined },
		MuiLink: { ...link },
		MuiTextField: { ...textField },
	},
})
