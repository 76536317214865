import { Box, Icon, styled } from "@mui/material"

export const StyledHeader = styled(Box)(({ theme, ownerState }) => {
	const { spacing, palette } = theme
	const { isSmall } = ownerState

	return {
		display: "flex",
		width: "100%",
		justifyContent: isSmall ? "center" : "space-between",
		alignItems: "center",
		padding: spacing(3),
		paddingBottom: spacing(7),
		position: "sticky",
		top: 0,
		backgroundColor: palette.white.main,
	}
})

// NOTE: actually, just StyledHeaderTitle is enough but it needs a relative positioned wrapper for position animations - so here's StyledHeaderTitleWrap
export const StyledHeaderTitleWrap = styled(Box)(({ ownerState }) => {
	const { isSmall } = ownerState

	return {
		position: "relative",
		flexGrow: isSmall ? 0 : 1,
		paddingLeft: 0,
		height: "100%",
	}
})

export const StyledHeaderTitle = styled(Box)(({ theme, ownerState }) => {
	const { transitions, spacing } = theme
	const { isSmall } = ownerState

	return {
		position: "absolute",
		top: spacing(0.01),
		left: isSmall ? 100 : spacing(1), // for position-animation
		transition: transitions.create("left", {
			easing: transitions.easing.easeOut,
			duration: transitions.duration.enteringScreen,
		}),
	}
})

StyledHeaderTitle.defaultProps = {
	variant: "h3",
	color: "baseColors.BLUES.navy",
}

export const StyledHamburgerMenu = styled(Icon)(({ theme }) => {
	const {
		functions: { pxToRem },
		spacing,
	} = theme

	return {
		width: pxToRem(30),
		fontSize: `${pxToRem(30)} `,
		cursor: "pointer",
		overflow: "visible",
		paddingTop: spacing(1.5),
	}
})
