import { api } from "services/api"
import { API_VERIFY_TOKEN } from "services/endpoints"

const commonApi = api.injectEndpoints({
	endpoints(build) {
		return {
			verifyToken: build.mutation({
				query(token) {
					return {
						data: { token },
						method: "POST",
						url: API_VERIFY_TOKEN,
					}
				},
			}),
		}
	},
})

export const { useVerifyTokenMutation } = commonApi

export default commonApi
