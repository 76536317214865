import { api } from "services/api"
import { API_STORE_ROOMS } from "services/endpoints"
import { TAG_RESERVATION_SELECTABLE_ROOMS, TAG_STORE_ROOMS } from "services/tags"

const storeRoomApi = api.injectEndpoints({
	endpoints(build) {
		return {
			getStoreRooms: build.query({
				query() {
					return {
						url: API_STORE_ROOMS,
					}
				},

				transformResponse: ({ data }) =>
					data.map((item) => ({
						name: item.name,
						is_active: item.is_active,
						room_id: item.id,
					})),

				providesTags: [TAG_STORE_ROOMS],
			}),

			createStoreRoom: build.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: API_STORE_ROOMS,
					}
				},

				invalidatesTags: (ok) =>
					ok ? [TAG_STORE_ROOMS, TAG_RESERVATION_SELECTABLE_ROOMS] : [],
			}),

			updateStoreRoom: build.mutation({
				query({ id, data }) {
					return {
						data,
						method: "PUT",
						url: `${API_STORE_ROOMS}/${id}`,
					}
				},

				invalidatesTags: (ok) =>
					ok ? [TAG_STORE_ROOMS, TAG_RESERVATION_SELECTABLE_ROOMS] : [],
			}),

			deleteStoreRoom: build.mutation({
				query(id) {
					return {
						method: "DELETE",
						url: `${API_STORE_ROOMS}/${id}`,
					}
				},

				invalidatesTags: (ok) =>
					ok ? [TAG_STORE_ROOMS, TAG_RESERVATION_SELECTABLE_ROOMS] : [],
			}),
		}
	},
})

export default storeRoomApi
