import { COMMON_REGEXES, IRON, YUP_REQUIRED_CHECK_TEST_TYPES } from "constant"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { openSnackbar } from "stores/slice/snackbar"
import { isRequired } from "utils/general"
import {
	driverClubFormSchema,
	hybridClubFormSchema,
	ironClubFormSchema,
	putterClubFormSchema,
	wedgeClubFormSchema,
	woodClubFormSchema,
} from "validations"
import { clubOnlyShaftSchema } from "validations/clubOnlyShaftSchema"
import { putterOnlyShaftSchema } from "validations/putterClubFormSchema"

export const formatNumber = (number) =>
	Number(number).toLocaleString(undefined, { maximumFractionDigits: 2 })

export const removeFileExtension = (fileName, extension) => fileName.replace(extension, "")

export const getErrorMessage = (error) => {
	if (error.errors) {
		return Object.entries(error.errors).reduce((errors, [key, [value]]) => {
			errors[key] = { type: "server", message: value }

			return errors
		}, {})
	}

	return error.message
}

export const getErrorMessageForSimpleForm = (error) => {
	return Object.entries(error.errors).reduce((init, [k, c]) => {
		init[k] = c[0]
		return init
	}, {})
}

export const formatLongText = (text, limit) => {
	if (text.length > limit) {
		return `${text.slice(0, limit)}...`
	}
	return text
}

export const scrollToElement = (ref) => {
	ref.current.scrollIntoView({ behavior: "smooth" })
}

export const handleApiErrors = (err, dispatch, setError, errorMessage) => {
	if (process.env.NODE_ENV === "development") {
		console.error({ err })
	}

	dispatch(
		openSnackbar({
			severity: "error",
			message: errorMessage ? errorMessage : err.response?.data?.message,
		})
	)

	const errors = getErrorMessage(err)
	if (typeof errors !== "string") {
		Object.entries(errors).forEach(([key, error]) => {
			setError(key, error)
		})
	}
}

export const extractFieldTestOptionsFromSchema = (
	schema = isRequired(),
	fieldName = isRequired()
) => schema.fields[fieldName].tests.map((testFunc) => testFunc.OPTIONS)

export function handlePromise(
	fn,
	successMsg = "Successfully fulfilled the action",
	errorMsg = "Unable to fulfill the action"
) {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const ret = fn()

	const { error, isSuccess } = Array.isArray(ret) ? ret[1] : ret

	useEffect(() => {
		if (isSuccess) {
			const successkeys = typeof successMsg === "string" ? [successMsg] : successMsg
			dispatch(openSnackbar({ severity: "success", message: t(...successkeys) }))
		}
	}, [isSuccess])

	useEffect(() => {
		if (error) {
			const errorkeys =
				typeof errorMsg === "string"
					? [errorMsg]
					: [error.response?.data?.message || errorMsg]

			dispatch(openSnackbar({ severity: "error", message: t(...errorkeys) }))
		}
	}, [error])

	return ret
}

export function handleError(fn, ...failMsgKey) {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const ret = fn()

	const { error } = Array.isArray(ret) ? ret[1] : ret

	useEffect(() => {
		if (error) {
			const keys =
				failMsgKey.length === 0
					? [error.response?.data?.message || error.message]
					: failMsgKey

			dispatch(openSnackbar({ severity: "error", message: t(...keys) }))
		}
	}, [error])

	return ret
}

export function isKrText(text) {
	return COMMON_REGEXES.KR_LANG.test(text)
}

/**
 * Pass current  store members and current userId to get selected store role of user.
 * * Pass return value of this function to updateUserStoreRole method to update current user's store role.
 * * Null will return if user role not found in store.
 * @param {Array} storesMembers
 * @param {string} userId
 */
export function getUserStoreRole(storesMembers, userId) {
	if (storesMembers.length > 0) return storesMembers.find(({ appUser }) => appUser.id === userId)
	return null
}

/**
 * Checks if the given yupFieldErrorObj has an error excluding the required check.
 *
 * @param {object} yupFieldErrorObj - The yup field error object to check.
 * @return {boolean} Returns true if the yupFieldErrorObj has an error excluding the required check, otherwise false.
 */
export const isErrorExcludingRequiredCheck = (yupFieldErrorObj) =>
	Boolean(yupFieldErrorObj && !YUP_REQUIRED_CHECK_TEST_TYPES.includes(yupFieldErrorObj?.type))

/**
 * @param {{head: object, shaft: object, club: string}} item - should be object value that contains head shaft and club
 * @param {boolean} isEdit - check club form is for combination or edit club
 */
export const clubDefaultValuesMapper = (item, isEdit = false) => {
	if (isEdit)
		return {
			club: item?.club || "",
			number: item?.head?.number || "",
			swing_weight: item?.head?.swing_weight || "",
			total_weight: item?.total_weight || "",
			head: {
				brand: item?.head?.brand?.name || "",
				model: item?.head?.name || "",
				loft: item?.head?.loft_angle || "",
				lie_angle: item?.head?.lie_angle || "",
				bounce_angle: item?.head?.bounce_angle || "",
			},
			shaft: {
				brand: item?.shaft?.brand?.name || "",
				model: item?.shaft?.shaft_name || "",
				material: item?.shaft?.material || "",
				weight: item?.shaft?.weight || "",
				flex: item?.shaft?.flex || "",
				length: item?.shaft?.length || "",
			},
		}

	return {
		club: item?.club || "",
		number: "",
		swing_weight: "",
		head: {
			brand: item?.head?.brand?.name || "",
			model: item?.head?.name || "",
			loft: "",
			lie_angle: "",
			bounce_angle: "",
		},
		shaft: {
			brand: item?.shaft?.brand?.name || item?.shaft_brand_name || "",
			model: item?.shaft?.shaft_name || item?.shaft_name || "",
			material: "",
			weight: "",
			flex: "",
			length: "",
		},
	}
}

/**
 * Append options to club form especially direct input and no information
 * @param {Function} t - react-i18next translation
 * @param {Array<{label: string, value}>} options
 * @param {boolean} isDirectInput if true "Enter directly" will be available in option
 * @param {boolean} isNoInfo if true No information will be available in option
 * @returns
 */
export const appendClubAdditionalOptions = (t, options, isNoInfo = true) => {
	const noInfoField = { label: t("No information"), value: null }
	if (isNoInfo) options.unshift(noInfoField)
	return options
}

/**
 * convert array values to select option object array
 * @param {Array} values
 * @param {Function} t - react-i18next useTranslation
 * @returns {Array<{label: string, value: any}>}
 */
export const arrayToSelectOptions = (values, t) => {
	if (values)
		return values.map((value) => {
			const stringValue = String(value)
			return { label: t ? t(value) : stringValue, value: stringValue }
		})
}

/**
 * convert object to select option object array
 * @param {Object} object
 * @param {Function} t - react-i18next useTranslation
 * @returns {Array<{label: string, value: any}>}
 */
export const objectToSelectOptions = (object, t) => {
	if (object)
		return Object.keys(object).map((key) => ({
			label: t ? t(key) : key,
			value: key,
		}))
}

export const clubFormDataToPayload = (data, room_id, set_id) => {
	const {
		head,
		swing_weight_direct_input,
		total_weight,
		total_weight_direct_input,
		head_id,
		shaft,
		shaft_id,
		swing_weight,
		number,
		number_direct_input,
	} = data
	const payload = {
		clubType: data.clubType,
		type: number_direct_input || number,
		head_id: head_id,
		head_name: head?.model,
		head_brand_name: head?.brand,
		loft_angle: parseFloat(head?.loft_direct_input) || head?.loft,
		lie_angle: parseFloat(head?.lie_angle_direct_input) || head?.lie_angle,
		bounce_angle: parseFloat(head?.bounce_angle_direct_input) || head?.bounce_angle,
		shaft_id: shaft_id,
		shaft_name: shaft?.model,
		shaft_brand_name: shaft?.brand,
		shaft_material: shaft?.material ? shaft.material : null,
		shaft_flex: shaft?.flex,
		shaft_weight: parseFloat(shaft?.weight_direct_input) || shaft?.weight,
		shaft_length: parseFloat(shaft?.length_direct_input) || shaft?.length,
		total_weight: total_weight_direct_input || total_weight,
		swing_weight: swing_weight_direct_input || swing_weight,
		kick_point: shaft?.kick_point,
		torque: shaft?.torque,
		grip_size: shaft?.grip_size,
	}
	if (set_id) payload.set_id = set_id
	if (room_id) payload.store_room_id = set_id
	return payload
}

export const cartItemsToPayload = (items, room_id, set_id) => {
	return items.map((object) => {
		object = object?.formData || object
		return clubFormDataToPayload(object, room_id, set_id)
	})
}

export const clubFormDefaultValues = (type, isIronSet = false) => ({
	club: type.toLowerCase(),
	number: type === IRON && isIronSet ? [{ number: "" }] : "",
	swing_weight: "",
	total_weight: "",
	head: {
		brand: "",
		model: "",
		loft: "",
		lie_angle: "",
	},
	shaft: {
		brand: "",
		model: "",
		weight: "",
		flex: "",
		length: "",
	},
})

export const Schemas = {
	Wood: woodClubFormSchema,
	Iron: ironClubFormSchema,
	Wedge: wedgeClubFormSchema,
	Hybrid: hybridClubFormSchema,
	Driver: driverClubFormSchema,
	Putter: putterClubFormSchema,
}

// This is for schema of instock shaft form
export const ClubShaftSchemas = {
	Wood: clubOnlyShaftSchema,
	Iron: clubOnlyShaftSchema,
	Wedge: clubOnlyShaftSchema,
	Hybrid: clubOnlyShaftSchema,
	Driver: clubOnlyShaftSchema,
	Putter: putterOnlyShaftSchema, // putter fields for instock shaft is not same
}

export const convertCmtoFeet = (value) => (value / 30.48).toFixed(1)

export const convertFeetToCm = (value) => (value * 30.48).toFixed(0)

export const convertPoundToKg = (value) => (value / 2.204).toFixed(1)

export const convertKgToPound = (value) => (value * 2.204).toFixed(0)
