import { Box, FormControlLabel, Grid, Typography } from "@mui/material"
import pxToRem from "assets/theme/functions/pxToRem"
import { AppCheckBox } from "components"
import AppIconButton from "components/AppIconButton"
import { ICON_NAMES } from "constant"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import { TermsDialog } from "./layout"

function Terms({ setValue }) {
	const { t } = useTranslation()
	const [open, setOpen] = useState(false)
	const [dialogProps, setDialogProps] = useState({})

	const [checked, setChecked] = useState([false, false, false])
	const [subChecks, setSubChecks] = useState({
		Email: false,
		SMS: false,
	})

	const terms = [
		{
			id: 1,
			label: t("Accept terms and conditions"),
			content: t("Accept terms and conditions contents"),
			name: "terms.terms_of_use",
			required: true,
		},
		{
			id: 2,
			label: t("Personal information collection and use agreement"),
			content: t("Personal information collection and use agreement contents"),
			name: "terms.privacy_policy",
			required: true,
		},
		{
			id: 3,
			label: t("Consent to receive promotional information"),
			content: t("Consent to receive promotional information contents"),
			name: "marketing",
			required: false,
		},
	]

	const handleCheckAll = (isChecked) => {
		setChecked([isChecked, isChecked, isChecked])
		setSubChecks({
			Email: isChecked,
			SMS: isChecked,
		})

		setValue("terms.terms_of_use", isChecked)
		setValue("terms.privacy_policy", isChecked)
		setValue("terms.accept_marketing_Email", isChecked)
		setValue("terms.accept_marketing_SMS", isChecked)
	}

	const handleIndividualCheck = (index, isChecked) => {
		const newChecked = [...checked]
		newChecked[index] = isChecked
		setChecked(newChecked)

		if (index === 2) {
			setSubChecks({
				Email: isChecked,
				SMS: isChecked,
			})

			setValue("terms.accept_marketing_Email", isChecked)
			setValue("terms.accept_marketing_SMS", isChecked)
		}
	}

	const handleSubCheckChange = (name, isChecked) => {
		const newSubChecks = {
			...subChecks,
			[name]: isChecked,
		}

		if (newSubChecks.Email && newSubChecks.SMS) {
			handleIndividualCheck(2, true)
		} else {
			handleIndividualCheck(2, false)
		}

		setSubChecks(newSubChecks)

		setValue(`terms.accept_marketing_${name}`, isChecked)
	}

	const onClickOpenModal = (id) => {
		const targetTerms = terms.find((term) => term.id === id)
		const dialogProps = {
			open,
			setOpen,
			onClickHandler: () => console.log("ok"),
			title: targetTerms.label,
			content: targetTerms.content,
		}

		setDialogProps(dialogProps)
		setOpen(true)
	}

	const TermItem = ({ term, index }) => {
		const { id, label, required, name } = term
		return (
			<Box position="relative" display="flex" width="100%" pb={2} pr={2} pl={1}>
				<Box display="flex" alignItems="center">
					<FormControlLabel
						label={
							<Typography
								fontSize={pxToRem(18)}
								sx={{
									color: checked[index] ? "dark.main" : "grey.700",
								}}>{`${label} [${
								required ? t("Required") : t("Optional")
							}]`}</Typography>
						}
						control={
							<Box flex="1 0 auto" pr={1}>
								<AppCheckBox
									variant="round"
									isChecked={checked[index]}
									onToggle={() => {
										setValue(name, !checked[index])
										handleIndividualCheck(index, !checked[index])
									}}
								/>
							</Box>
						}
						onClick={() => {
							setValue(name, !checked[index])
							handleIndividualCheck(index, !checked[index])
						}}
						sx={{ display: "flex", "& p": { mx: pxToRem(4) } }}
					/>
				</Box>
				<AppIconButton
					iconName={ICON_NAMES.ARROW_FORWARD_IOS}
					color={checked[index] ? "primary" : "grey.700"}
					onClick={() => onClickOpenModal(id)}
					sx={{ position: "absolute", right: 0, top: -6 }}
				/>
			</Box>
		)
	}

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "flex-start",
					justifyContent: "center",
				}}>
				<Box mb={3} pl={1}>
					<FormControlLabel
						label={
							<Typography
								fontSize={pxToRem(18)}
								sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
								{t("All agree")}
							</Typography>
						}
						control={
							<Box pr={1}>
								<AppCheckBox
									variant="round"
									isChecked={checked.every(Boolean)}
									indeterminate={checked.some(Boolean) && !checked.every(Boolean)}
									onToggle={() => handleCheckAll(!checked.every(Boolean))}
								/>
							</Box>
						}
						onClick={() => handleCheckAll(!checked.every(Boolean))}
						sx={{
							width: "100%",
							display: "flex",
							alignItems: "center",
							"& p": { ml: pxToRem(4) },
						}}
					/>
				</Box>
				{terms.map((item, index) => (
					<TermItem term={item} index={index} key={`Terms_${index}`} />
				))}
				<Grid container spacing={1} pl={6}>
					{["Email", "SMS"].map((type) => (
						<Grid item xs={6} key={type}>
							<FormControlLabel
								label={
									<Typography
										variant="notificationMessage"
										sx={{ color: subChecks[type] ? "dark.main" : "grey.700" }}>
										{type}
									</Typography>
								}
								control={
									<Box pr={1}>
										<AppCheckBox
											isChecked={subChecks[type]}
											onToggle={() =>
												handleSubCheckChange(type, !subChecks[type])
											}
										/>
									</Box>
								}
								onClick={() => handleSubCheckChange(type, !subChecks[type])}
								sx={{
									width: "100%",
									display: "flex",
									alignItems: "center",
									"& p": { ml: pxToRem(4) },
								}}
							/>
						</Grid>
					))}
				</Grid>
			</Box>
			<TermsDialog {...dialogProps} open={open} setOpen={setOpen} />
		</>
	)
}

export default Terms
