import { Box, Skeleton, Typography, styled } from "@mui/material"
import { FormDialogInputLabel } from "components"
import { useTranslation } from "react-i18next"

export const ClickableReservationInput = ({
	label,
	value,
	onClick,
	Icon,
	isDisabled,
	iconProps,
	...rest
}) => {
	return (
		<Box
			sx={({ palette }) => ({
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				cursor: isDisabled ? "not-allowed" : "pointer",
				backgroundColor: palette.white.main,
				width: "100%",
				border: "none",
				borderBottom: `1px solid ${palette.grey[300]}`,
				"&:disabled": {
					color: palette.black.main, // to bypass default behaviour of html disabled color override
				},
			})}
			py={2}
			onClick={onClick}
			disabled={isDisabled}
			component="button"
			{...rest}>
			<Box sx={{ display: "flex", alignItems: "center" }} gap={2}>
				<FormDialogInputLabel isRequired label={label} />
				<Typography size="size.xl" color={isDisabled ? "grey.700" : "black.main"}>
					{value}
				</Typography>
			</Box>

			<Icon {...iconProps} />
		</Box>
	)
}

export const SelectionBoxPlaceholder = () => (
	<Skeleton variant="rounded" height={50} sx={{ marginBottom: 2 }} />
)

export const StyledDivider = styled(Box)(({ theme: { palette } }) => ({
	borderBottom: `1px solid ${palette.grey[300]}`,
}))

export const StyledGridContainer = styled(Box)(() => ({
	display: "grid",
	gridTemplateColumns: "repeat(auto-fill, minmax(20%, 1fr))",
}))

StyledGridContainer.defaultProps = {
	gap: 1,
	px: 1,
}

export const StyledGridItem = styled(Box)(({ theme, ownerProps }) => {
	const {
		borders: { borderRadius },
		palette,
		spacing,
	} = theme

	const { isSelected } = ownerProps

	return {
		backgroundColor: isSelected ? palette.baseColors.SELECTED : "transparent",
		color: isSelected ? palette.white.main : palette.black.main,
		height: "50px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexGrow: 1,
		flexBasis: "20%",
		borderRadius: borderRadius.lg,
		border: `1px solid ${palette.grey[300]}`,
		transition: "all ease-in 0.3s",
		cursor: "pointer",
		pointerEvents: isSelected ? "none" : "auto",
		"&:hover": {
			borderColor: isSelected ? palette.baseColors.SELECTED : palette.primary.main,
		},
		padding: spacing(0, 1),
	}
})

export const GridItemValue = ({ children }) => (
	<Typography variant="body3" noWrap>
		{children}
	</Typography>
)

export const SelectionBoxNoData = () => {
	const { t } = useTranslation()
	return (
		<Typography fontSize="size.sm" mt={1} color="error" mb={4}>
			{t("No data")}
		</Typography>
	)
}
