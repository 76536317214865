import { NOT_SURE_VALUE } from "constant"
import { mixed, object, string } from "yup"

const golfInfoCarrerSchema = () =>
	object({
		golf_carrer: string().required(),
		handi: mixed().test(
			"is-string-or-number",
			'Must be a number or the string "like"',
			(value) => {
				if (typeof value === "number") {
					return true // Allow numbers
				} else if (typeof value === "string") {
					return value === NOT_SURE_VALUE || !isNaN(Number(value)) // Only allow the string "Not Sure" || "0" || the string that can parse into number
				}
				return false // Reject other types
			}
		),
	})

export default golfInfoCarrerSchema
