import { Skeleton, Stack } from "@mui/material"
import pxToRem from "assets/theme/functions/pxToRem"

/**
 * Skeleton Loading for Form
 * @param {import("@mui/material").StackProps} props
 * @returns JSX.Element
 */
function FormLoadingSkeleton(props) {
	return (
		<Stack spacing={2} {...props}>
			<Skeleton variant="rounded" width="100%" height={pxToRem(50)} />
			<Skeleton variant="rounded" width="100%" height={pxToRem(50)} />
			<Skeleton variant="rounded" width="100%" height={pxToRem(50)} />
		</Stack>
	)
}

export default FormLoadingSkeleton
