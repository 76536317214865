import { api } from "services/api"
import {
	API_STORE_FITTERS,
	API_STORE_MEMBERS,
	API_STORE_MEMBERS_CHANGE_ROLE,
	API_STORE_MEMBERS_FITTER,
	API_STORE_MEMBERS_RESIGN,
	API_STORE_MEMBERS_SEARCH,
} from "services/endpoints"
import { TAG_STORE_FITTERS, TAG_STORE_MEMBER } from "services/tags"

export default api.injectEndpoints({
	endpoints(build) {
		return {
			fitterList: build.query({
				query() {
					return {
						url: API_STORE_FITTERS,
					}
				},

				providesTags: [TAG_STORE_FITTERS],
			}),

			memberList: build.query({
				query() {
					return {
						url: API_STORE_MEMBERS,
					}
				},

				providesTags: [TAG_STORE_FITTERS],
			}),

			searchMember: build.query({
				query(params) {
					return {
						params,
						url: API_STORE_MEMBERS_SEARCH,
					}
				},

				providesTags: [TAG_STORE_MEMBER],
			}),

			addMember: build.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: API_STORE_MEMBERS,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_STORE_FITTERS, API_STORE_MEMBERS] : []),
			}),

			resignMember: build.mutation({
				query(data) {
					return {
						data,
						method: "PUT",
						url: API_STORE_MEMBERS_RESIGN,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_STORE_FITTERS] : []),
			}),

			changeMemberRole: build.mutation({
				query({ store_id, app_user_id, role }) {
					return {
						data: { store_id, app_user_id, role },
						method: "PUT",
						url: API_STORE_MEMBERS_CHANGE_ROLE,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_STORE_FITTERS] : []),
			}),

			changeMemberFitter: build.mutation({
				query({ app_user_id, is_fitter }) {
					return {
						data: { is_fitter },
						method: "PUT",
						url: API_STORE_MEMBERS_FITTER(app_user_id),
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_STORE_FITTERS] : []),
			}),
		}
	},
})
