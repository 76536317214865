import { combineReducers } from "redux"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import * as servicesModules from "services/modules"
import * as sliceKeys from "stores/slicekeys"

import appReducer from "./slice/app"
import snackbarReducer from "./slice/snackbar"
import storeReducer from "./slice/store"
import userReducer from "./slice/user"

const persistConfig = {
	key: "root",
	storage,
	whitelist: [sliceKeys.APP_SLICE, sliceKeys.USER_SLICE, sliceKeys.STORE_SLICE],
}

export const services = Object.values(servicesModules)

const combineReducer = combineReducers({
	[sliceKeys.APP_SLICE]: appReducer,
	[sliceKeys.USER_SLICE]: userReducer,
	[sliceKeys.STORE_SLICE]: storeReducer,
	[sliceKeys.SNACKBAR_SLICE]: snackbarReducer,
	...services.reduce(
		(init, module) => ({
			...init,
			[module.reducerPath]: module.reducer,
		}),
		{}
	),
})

const persistedReducer = persistReducer(persistConfig, combineReducer)

export default persistedReducer
