import { Box, styled } from "@mui/material"
import AutoLoadingButton from "components/AutoLoadingButton"

export const StyledAutoLoadingButton = styled(AutoLoadingButton)(() => ({
	flexGrow: 1,
}))

StyledAutoLoadingButton.defaultProps = {
	color: "primary",
	fullWidth: true,
}

export const StyledFlexBox = styled(Box)(() => ({
	display: "flex",
}))

StyledFlexBox.defaultProps = {
	gap: 1,
}
