import CancelIcon from "@mui/icons-material/Cancel"
import { Autocomplete, Paper, TextField, styled, useTheme } from "@mui/material"
import pxToRem from "assets/theme/functions/pxToRem"
import { forwardRef } from "react"
import { Controller } from "react-hook-form"

const ClearIcon = styled(CancelIcon)(({ theme: { palette } }) => ({
	color: palette.baseColors.GREY,
}))

const AutocompletePaper = (props) => {
	const { palette } = useTheme()

	return (
		<Paper
			elevation={0}
			sx={{
				marginTop: "4px",
				background: palette.white.main,
				border: `1px solid ${palette.grey[300]}`,
				"& .MuiAutocomplete-listbox": {
					padding: "0 !important",
					maxHeight: pxToRem(240),
				},
				"& .MuiAutocomplete-option": {
					color: palette.grey[700],
					fontSize: pxToRem(16),
					paddingX: `${pxToRem(20)} !important`,
					paddingY: `${pxToRem(16)} !important`,
				},
			}}
			{...props}
		/>
	)
}

const AppAutocomplete = forwardRef(({ placeholder, options, disabled, value, onChange }, ref) => {
	const {
		palette: { grey, black, transparent },
		typography,
		borders: { borderRadius },
	} = useTheme()

	return (
		<Autocomplete
			freeSolo
			value={value}
			defaultValue=""
			ref={(e) => ref(e)}
			disabled={disabled}
			disablePortal={true}
			options={options}
			onInputChange={(_, value) => {
				onChange(value)
			}}
			onChange={(e, value) => {
				onChange(value?.value || value)
			}}
			fullWidth
			PaperComponent={AutocompletePaper}
			clearIcon={<ClearIcon />}
			renderInput={(params) => (
				<TextField
					{...params}
					placeholder={placeholder}
					sx={{
						"& .MuiInputBase-root": {
							padding: `${pxToRem(0)} ${pxToRem(16)}`,
							paddingRight: pxToRem(disabled ? 0 : 40),
							backgroundColor: disabled
								? `${grey[200]} !important`
								: transparent.main,
						},

						"& .MuiAutocomplete-input": {
							borderRadius: borderRadius.lg,
							padding: 0,
							minHeight: pxToRem(35),
							fontSize: typography.size.md,
							letterSpacing: "normal",
							color: disabled ? grey[700] : black.main,

							"&::placeholder": {
								letterSpacing: "normal",
							},
						},

						"& .MuiOutlinedInput-notchedOutline": {
							padding: 0,
						},
						"& .MuiAutocomplete-clearIndicator": {
							visibility: `${value !== "" ? "visible" : "hidden"}  !important`,
						},
						"& .MuiAutocomplete-endAdornment": {
							top: `calc(50% - ${pxToRem(14)})`,
							right: `${pxToRem(14)} !important`,
						},
					}}
					InputProps={{
						...params.InputProps,
					}}
				/>
			)}
		/>
	)
})

export default AppAutocomplete

export function ControlledAppAutocomplete({ name, placeholder, control, disabled, options }) {
	return (
		<Controller
			control={control}
			name={name}
			rules={{ required: true }}
			render={({ field: { ref, onChange, value, ...field } }) => {
				return (
					<AppAutocomplete
						placeholder={placeholder}
						options={options}
						disabled={disabled}
						onChange={onChange}
						value={value}
						ref={ref}
						{...field}
					/>
				)
			}}
		/>
	)
}
