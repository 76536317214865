import { Box, Typography, useTheme } from "@mui/material"
import pxToRem from "assets/theme/functions/pxToRem"
import { formatLongText } from "utils/helpers"

const ClubAccordionMiddle = ({ item, noShaftInfo = false, noRightBorder = false }) => {
	const { palette } = useTheme()

	return (
		<Box
			sx={{
				height: "100%",
				width: "100%",
				borderRight: !noRightBorder && `1px solid ${palette.grey[300]}`,
			}}>
			<Box
				sx={{
					height: noShaftInfo ? "100%" : "50%",
					paddingLeft: "20px",
					paddingTop: noShaftInfo ? "33px" : "4px",
					borderBottom: !noShaftInfo && `1px solid ${palette.grey[300]}`,
				}}>
				<Typography sx={{ fontSize: pxToRem(16), fontWeight: 700 }}>
					{item.head_brand_name}
				</Typography>
				<Typography sx={{ fontSize: pxToRem(16), fontWeight: 500 }}>
					{item.head_name && formatLongText(item.head_name, 27)}
				</Typography>
			</Box>
			{!noShaftInfo && (
				<Box sx={{ height: "50%", paddingLeft: "20px", paddingTop: "4px" }}>
					<Typography sx={{ fontSize: pxToRem(16), fontWeight: 700 }}>
						{item.shaft_brand_name}
					</Typography>
					<Typography sx={{ fontSize: pxToRem(16), fontWeight: 500 }}>
						{item.shaft_name && formatLongText(item.shaft_name, 27)}
					</Typography>
				</Box>
			)}
		</Box>
	)
}

export default ClubAccordionMiddle
