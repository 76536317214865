import { Box, Typography } from "@mui/material"
import pxToRem from "assets/theme/functions/pxToRem"
import { ControlledAppAutocomplete } from "components/AppAutocomplete"
import { AppSelectTextField, ControlledAppSelect, ControlledAppTextField } from "components/form"
import { CLUB_TYPES, DIRECT_INPUT_VALUE, SPECIAL_INPUT_TYPES } from "constant"
import { t } from "i18next"
import React from "react"

import ClubFormLabel from "./ClubFormLabel"

export const ClubInput = ({ t, name, control, errors, disabled }) => {
	return (
		<Box display="flex" flexDirection="column">
			<ControlledAppSelect
				name={name}
				size="normal"
				options={CLUB_TYPES(t)}
				control={control}
				placeholder={t("Select")}
				disabled={disabled}
				sx={{ width: "100%" }}
			/>
			{errors?.club?.message && (
				<Typography mt={1} color="error" sx={{ fontSize: pxToRem(14) }}>
					{t(errors?.club?.message)}
				</Typography>
			)}
		</Box>
	)
}

export const NumberInput = ({ t, name, methods, errors, disabled, options }) => {
	return (
		<ClubFormLabel label="Number" errorMsg={errors?.number?.message}>
			<Box display="flex" flexDirection="column">
				<AppSelectTextField
					name={name}
					type={SPECIAL_INPUT_TYPES.NUMBER}
					placeholder={t("Select")}
					size="normal"
					methods={methods}
					disabled={disabled}
					options={options}
				/>
			</Box>
		</ClubFormLabel>
	)
}

export const TotalWeightInput = ({ t, name, required, methods, errors, disabled, options }) => {
	return (
		<ClubFormLabel
			label="Total weight"
			required={required}
			errorMsg={errors?.total_weight?.message}>
			<Box display="flex" flexDirection="column">
				<AppSelectTextField
					variant="normal"
					name={name}
					placeholder={t("Select")}
					size="normal"
					methods={methods}
					disabled={disabled}
					options={options}
				/>
			</Box>
		</ClubFormLabel>
	)
}

export const SwingWeightInput = ({ t, name, required, methods, errors, disabled, options }) => {
	return (
		<ClubFormLabel
			label="Swing weight"
			required={required}
			errorMsg={errors?.swing_weight?.message}>
			<Box display="flex" flexDirection="column">
				<AppSelectTextField
					variant="normal"
					name={name}
					placeholder={t("Select")}
					size="normal"
					methods={methods}
					disabled={disabled}
					options={options}
				/>
			</Box>
		</ClubFormLabel>
	)
}

export const HeadBrandInput = ({ name, control, errors, disabled, options }) => {
	return (
		<ClubFormLabel label="Brand" errorMsg={errors?.head?.brand?.message}>
			<ControlledAppAutocomplete
				name={name}
				placeholder={t("Enter Brand")}
				disabled={disabled}
				control={control}
				options={options}
			/>
		</ClubFormLabel>
	)
}

export const HeadModelInput = ({ name, control, errors, disabled, options }) => {
	return (
		<ClubFormLabel label="Model" errorMsg={errors?.head?.model?.message}>
			<ControlledAppAutocomplete
				placeholder={t("Enter Model")}
				name={name}
				disabled={disabled}
				control={control}
				options={options || []}
			/>
		</ClubFormLabel>
	)
}

export const HeadLoftInput = ({ t, name, required, methods, errors, disabled, options }) => {
	return (
		<ClubFormLabel label="Loft" required={required} errorMsg={errors?.head?.loft?.message}>
			<Box display="flex" flexDirection="column">
				{/* need to add special type */}
				<AppSelectTextField
					variant="normal"
					name={name}
					type={SPECIAL_INPUT_TYPES.LOFT}
					placeholder={t("Select")}
					size="normal"
					methods={methods}
					disabled={disabled}
					options={options}
				/>
			</Box>
		</ClubFormLabel>
	)
}

export const HeadLieAngleInput = ({ t, name, required, errors, disabled, options, methods }) => {
	return (
		<ClubFormLabel
			label="Lie angle"
			required={required}
			errorMsg={errors?.head?.lie_angle?.message}>
			<Box display="flex" flexDirection="column">
				{/* need to add special type */}
				<AppSelectTextField
					variant="normal"
					name={name}
					type={SPECIAL_INPUT_TYPES.LIE_ANGLE}
					placeholder={t("Select")}
					size="normal"
					methods={methods}
					disabled={disabled}
					options={options}
				/>
			</Box>
		</ClubFormLabel>
	)
}

export const HeadBounceAngleInput = ({
	t,
	name,
	required,
	control,
	selectedValue,
	errors,
	disabled,
	options,
}) => {
	return (
		<ClubFormLabel
			label="Bounce angle"
			required={required}
			errorMsg={errors?.head?.bounce_angle?.message}>
			<Box display="flex" flexDirection="column">
				<ControlledAppSelect
					name={name}
					size="normal"
					options={options}
					control={control}
					placeholder={t("Select")}
					type={SPECIAL_INPUT_TYPES.BOUNCE_ANGLE}
					disabled={disabled}
					sx={{ width: "100%" }}
				/>
				{selectedValue === DIRECT_INPUT_VALUE && (
					<ControlledAppTextField
						sx={{ marginTop: 1 }}
						name={`${name}_direct_input`}
						type={SPECIAL_INPUT_TYPES.BOUNCE_ANGLE}
						control={control}
					/>
				)}
			</Box>
		</ClubFormLabel>
	)
}

export const ShaftBrandInput = ({ name, control, errors, disabled, options }) => {
	return (
		<ClubFormLabel label="Brand" errorMsg={errors?.shaft?.brand?.message}>
			<ControlledAppAutocomplete
				name={name}
				placeholder={t("Enter Brand")}
				disabled={disabled}
				control={control}
				options={options}
			/>
		</ClubFormLabel>
	)
}

export const ShaftModelInput = ({ name, control, errors, disabled, options }) => {
	return (
		<ClubFormLabel label="Model" errorMsg={errors?.shaft?.model?.message}>
			<ControlledAppAutocomplete
				placeholder={t("Enter Model")}
				name={name}
				disabled={disabled}
				control={control}
				options={options || []}
			/>
		</ClubFormLabel>
	)
}

export const ShaftMaterialInput = ({ t, name, required, control, errors, disabled, options }) => {
	return (
		<ClubFormLabel
			label="Shaft material"
			required={required}
			errorMsg={errors?.shaft?.material?.message}>
			<Box display="flex" flexDirection="column">
				<ControlledAppSelect
					name={name}
					size="normal"
					options={options}
					control={control}
					placeholder={t("Select")}
					disabled={disabled}
					sx={{ width: "100%" }}
				/>
			</Box>
		</ClubFormLabel>
	)
}

export const ShaftWeightInput = ({ t, name, required, errors, methods, disabled, options }) => {
	return (
		<ClubFormLabel
			label="Shaft weight"
			required={required}
			errorMsg={errors?.shaft?.weight?.message}>
			<Box display="flex" flexDirection="column">
				{/* need to add special type */}
				<AppSelectTextField
					variant="normal"
					name={name}
					type={SPECIAL_INPUT_TYPES.CLUB_WEIGHT}
					placeholder={t("Select")}
					size="normal"
					methods={methods}
					disabled={disabled}
					options={options}
				/>
			</Box>
		</ClubFormLabel>
	)
}

export const ShaftFlexInput = ({
	t,
	name,
	required,
	control,
	selectedValue,
	errors,
	disabled,
	options,
}) => {
	return (
		<ClubFormLabel
			label="Shaft flex"
			required={required}
			errorMsg={errors?.shaft?.flex?.message}>
			<Box display="flex" flexDirection="column">
				<ControlledAppSelect
					name={name}
					size="normal"
					options={options}
					control={control}
					placeholder={t("Select")}
					disabled={disabled}
					sx={{ width: "100%" }}
					type={SPECIAL_INPUT_TYPES.FLEX}
				/>
				{selectedValue === DIRECT_INPUT_VALUE && (
					<ControlledAppTextField
						sx={{ marginTop: 1 }}
						name={`${name}_direct_input`}
						type={SPECIAL_INPUT_TYPES.FLEX}
						control={control}
					/>
				)}
			</Box>
		</ClubFormLabel>
	)
}

export const ShaftLengthInput = ({ t, name, required, methods, errors, disabled, options }) => {
	return (
		<ClubFormLabel label="Length" required={required} errorMsg={errors?.shaft?.length?.message}>
			<Box display="flex" flexDirection="column">
				{/* need to add special type */}
				<AppSelectTextField
					variant="normal"
					name={name}
					type={SPECIAL_INPUT_TYPES.CLUB_LENGTH}
					placeholder={t("Select")}
					size="normal"
					methods={methods}
					disabled={disabled}
					options={options}
				/>
			</Box>
		</ClubFormLabel>
	)
}

export const ShaftKickPointInput = ({ t, name, control, errors, disabled, options }) => {
	return (
		<ClubFormLabel label="Kickpoint" errorMsg={errors?.shaft?.kickpoint?.message}>
			<Box display="flex" flexDirection="column">
				<ControlledAppSelect
					name={name}
					size="normal"
					options={options}
					control={control}
					placeholder={t("Select")}
					disabled={disabled}
					sx={{ width: "100%" }}
				/>
			</Box>
		</ClubFormLabel>
	)
}

export const ShaftTorqueInput = ({ name, control, errors, disabled }) => {
	return (
		<ClubFormLabel label="Torque" errorMsg={errors?.shaft?.torque?.message}>
			<ControlledAppTextField
				placeholder={t("Enter torque")}
				name={name}
				type="text"
				error={Boolean(errors?.shaft?.torque)}
				control={control}
				disabled={disabled}
			/>
		</ClubFormLabel>
	)
}

export const ShaftGripSizeInput = ({ t, name, control, errors, disabled, options }) => {
	return (
		<ClubFormLabel label="Grip size" errorMsg={errors?.shaft?.grip_size?.message}>
			<Box display="flex" flexDirection="column">
				<ControlledAppSelect
					name={name}
					size="normal"
					options={options}
					control={control}
					placeholder={t("Select")}
					disabled={disabled}
					sx={{ width: "100%" }}
				/>
			</Box>
		</ClubFormLabel>
	)
}

export const PutterShaftFlexInput = ({ name, control, errors, disabled }) => {
	return (
		<ClubFormLabel label="Shaft flex" errorMsg={errors?.shaft?.flex?.message}>
			<ControlledAppTextField
				placeholder={t("Enter flex")}
				name={name}
				type="text"
				error={Boolean(errors?.shaft?.flex)}
				control={control}
				disabled={disabled}
			/>
		</ClubFormLabel>
	)
}
