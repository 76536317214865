import { ENGLISH_LANGUAGE } from "constant"
import { useLocalizedDateUtils } from "hooks"
import { useLanguage } from "stores/slice/app"

import SelectorWrapper from "./SelectorWrapper"

const DaySelector = ({ selectedDay, previousDay, nextDay }) => {
	const language = useLanguage()
	const { formatCalendarDay } = useLocalizedDateUtils()

	return (
		<SelectorWrapper
			onClickPrevious={previousDay}
			onClickNext={nextDay}
			margin={language === ENGLISH_LANGUAGE ? "0 30px" : "0 40px"}
			label={formatCalendarDay(selectedDay.date)}
		/>
	)
}

export default DaySelector
