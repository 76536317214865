import { DIRECT_INPUT_VALUE } from "constant"
import { object, string } from "yup"

export function ironClubShaftSchema(t, register = "store") {
	return object({
		brand: string().required(t("Enter the brand")),
		model: string().required(t("Enter the model")),
		material:
			register === "customer"
				? string().nullable()
				: string().required(t("Enter the material")),
		flex:
			register === "customer" ? string().nullable() : string().required(t("Select the flex")),
		weight:
			register === "customer"
				? string().nullable()
				: string().required(t("Select the weight")),
		length:
			register === "customer"
				? string().nullable()
				: string().required(t("Select the length")),
	})
}

//  NOTE: ** Register can be customer or store. depend on register type some of fields are not required.
const ironClubFormSchema = (t, register) =>
	object({
		club: string().required(t("Select the club")),
		number: string().required(t("Select the number")),
		number_direct_input: string().when("number", {
			is: (value) => value === DIRECT_INPUT_VALUE,
			then: (rule) => rule.required(t("Select the number")),
		}),
		total_weight:
			register === "customer" ? string().nullable() : string().required().nullable(),
		swing_weight: register === "customer" ? string().nullable() : string().required(),
		head: object({
			brand: string().required(t("Enter the brand")),
			model: string().required(t("Enter the model")),
			loft:
				register === "customer"
					? string().nullable()
					: string().required(t("Select the loft")),
			lie_angle:
				register === "customer"
					? string().nullable()
					: string().required(t("Select the lie angle")).nullable(),
		}),
		shaft: ironClubShaftSchema(t, register),
	}).required()

export default ironClubFormSchema
