import { api } from "services/api"
import {
	API_EQUIPMENT_INSTOCKS,
	API_EQUIPMENT_MANAGEMENT,
	API_EQUIPMENT_MANAGEMENT_DELETE,
	API_STORE_ACCESSORIES_EQUIPMENTS,
	API_STORE_EQUIPMENTS,
} from "services/endpoints"
import { TAG_STORE_EQUIPMENT } from "services/tags"

export default api.injectEndpoints({
	endpoints(build) {
		return {
			searchEquipment: build.query({
				query({ params, equipment, clubType }) {
					return {
						url: API_EQUIPMENT_MANAGEMENT(equipment, clubType),
						params: params,
					}
				},

				providesTags: [TAG_STORE_EQUIPMENT],
			}),

			deleteEquipment: build.mutation({
				query({ id, equipment, clubType, clubComponent }) {
					return {
						method: "DELETE",
						url: `${API_EQUIPMENT_MANAGEMENT_DELETE(
							equipment,
							clubType,
							clubComponent
						)}/${id}`,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_STORE_EQUIPMENT] : []),
			}),

			searchStoreEquipments: build.query({
				query({ params, clubType }) {
					return {
						url: API_STORE_EQUIPMENTS(clubType),
						params: params,
					}
				},
				providesTags: [TAG_STORE_EQUIPMENT],
			}),

			searchStoreAccessoriesEquipments: build.query({
				query({ params, equipment }) {
					return {
						url: API_STORE_ACCESSORIES_EQUIPMENTS(equipment),
						params: params,
					}
				},
				providesTags: [TAG_STORE_EQUIPMENT],
			}),

			addEquipmentToInstock: build.mutation({
				query({ data, equipment, clubType }) {
					return {
						method: "POST",
						url: API_EQUIPMENT_INSTOCKS(equipment, clubType),
						data,
					}
				},
				invalidatesTags: (ok) => (ok ? [TAG_STORE_EQUIPMENT] : []),
			}),
		}
	},
})
