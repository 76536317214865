import { Grid, Skeleton } from "@mui/material"

function GridShimmerLoading({ width = 226, height = 300, size = 4 }) {
	return (
		<Grid container gap={2} marginTop={4}>
			{Array.from({ length: size }).map((_, index) => (
				<Skeleton key={index} variant="rectangular" width={width} height={height} />
			))}
		</Grid>
	)
}
export default GridShimmerLoading
