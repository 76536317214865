import { Typography, styled } from "@mui/material"
import Box from "@mui/material/Box"
import Skeleton from "@mui/material/Skeleton"
import Stack from "@mui/material/Stack"
import { getCoreRowModel, useReactTable } from "@tanstack/react-table"
import pxToRem from "assets/theme/functions/pxToRem"
import SearchBox from "components/AppSearchBox"
import { useDebounce } from "hooks"
import { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { handleError } from "utils/helpers"

import { PureTable } from "./pure"

export const StyledSearchBox = styled(SearchBox)(({
	theme: {
		palette: { background },
	},
	fullWidth,
}) => {
	return {
		backgroundColor: background.paper,
		borderRadius: pxToRem(8),
		width: fullWidth ? "100%" : pxToRem(400),
	}
})

export const Search = ({ methods, placeholder, ...props }) => {
	const { t } = useTranslation()
	const [searchKeyword, setSearchKeyword] = useState()
	const debouncedValue = useDebounce(searchKeyword, 1000)

	const { control, setValue, watch } = methods

	useEffect(() => {
		watch("q")
	}, [])

	useEffect(() => {
		debouncedValue !== undefined && setValue("q", debouncedValue)
	}, [debouncedValue])

	return (
		<StyledSearchBox
			name="searchKeyword"
			size="normal"
			type="text"
			fullWidth
			value={searchKeyword}
			placeholder={t(placeholder)}
			control={control}
			onChange={(event) => {
				setSearchKeyword(event.target.value)
			}}
			autoComplete="off"
			{...props}
		/>
	)
}

// note: returning params as string so that
// we can better detect changes when anything changes, it's a hack
// but works, oh well.
function makeParams(table, { getValues }) {
	const {
		sorting: [sortBy],
		pagination: { pageIndex },
	} = table.getState()

	let params = { ...getValues() }

	if (pageIndex > 0) {
		params.page = pageIndex + 1
	}

	if (sortBy) {
		const { id, desc } = sortBy

		params = { ...params, sort: id, order: desc ? "asc" : "desc" }
	}

	return JSON.stringify(params)
}

export default function DataTable({
	columns,
	lazyFetcher,
	search,
	renderExtras,
	renderFilters,
	renderActions,
	wrapperProps,
	formControl,
	formFields,
	showNoResultsMessageOnly,
	...props
}) {
	const { t } = useTranslation()

	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	})

	const methods =
		formControl ||
		useForm({
			defaultValues: {
				q: "",
				page: 1,
				...formFields,
			},
		})

	const { getValues, watch, setValue } = methods

	const [fetcher, state] = handleError(lazyFetcher)

	const { data: { data = [], meta = {} } = {}, isFetching, isLoading } = state

	useEffect(() => {
		watch()
		fetcher(getValues(), { forceRefetch: false })
	}, [])

	useEffect(() => {
		if (formFields) {
			Object.entries(formFields).forEach(([key, value]) => {
				setValue(key, value)
			})
		}
	}, [formFields])

	const cachedColumns = useMemo(
		() =>
			isFetching
				? columns.map((column) => ({
						...column,
						cell: () => <Skeleton variant="rounded" sx={{ pl: 1, pr: 1 }} />,
					}))
				: columns,
		[isFetching, columns]
	)

	const table = useReactTable({
		data,
		columns: cachedColumns,
		state: { pagination },
		manualPagination: true,
		pageCount: meta.last_page ?? 0,
		enableMultiRowSelection: false,
		getCoreRowModel: getCoreRowModel(),
		onPaginationChange: setPagination,
		manualSorting: true,
	})

	const params = makeParams(table, methods)

	useEffect(() => {
		watch()
		fetcher(JSON.parse(params), { forceRefetch: false })
	}, [params])

	const DataTable = <PureTable table={table} isLoading={isLoading} {...props} />

	return (
		<Box {...wrapperProps}>
			<Stack direction="row" justifyContent="space-between">
				{search && (
					<Search methods={methods} fullWidth={renderActions && false} {...search} />
				)}
				{renderActions && renderActions(state)}
			</Stack>

			{renderExtras && renderExtras(state)}

			{renderFilters && renderFilters(state)}

			{showNoResultsMessageOnly ? (
				!isFetching && data.length === 0 ? (
					<Typography
						fontSize={({ typography }) => typography.size.lg}
						color="grey"
						my={3}>
						{t("No search results were found based on the information you entered.")}
					</Typography>
				) : (
					DataTable
				)
			) : (
				DataTable
			)}
		</Box>
	)
}
