import { styled } from "@mui/material"
import { SIDEBAR_WIDTHS_IN_PX } from "constant"

const MainPageContainer = styled("main")(({ theme, ownerState }) => {
	const {
		transitions,
		palette,
		functions: { pxToRem },
		spacing,
	} = theme

	const SMALL_SIDEBAR_WIDTH_IN_REM = pxToRem(SIDEBAR_WIDTHS_IN_PX.SMALL)
	const NORMAL_SIDEBAR_WIDTH_IN_REM = pxToRem(SIDEBAR_WIDTHS_IN_PX.NORMAL)

	const { isWithSidebar, isSmallSidebar } = ownerState

	return {
		position: "relative",
		marginLeft: "auto",
		width: `calc(100% - ${
			isWithSidebar
				? isSmallSidebar
					? SMALL_SIDEBAR_WIDTH_IN_REM
					: NORMAL_SIDEBAR_WIDTH_IN_REM
				: 0
		})`,
		overflowY: "scroll",
		backgroundColor: isWithSidebar ? palette.grey[200] : palette.white.main,
		height: "100vh",
		transition: transitions.create("width", {
			easing: transitions.easing.easeOut,
			duration: transitions.duration.enteringScreen,
		}),
		// padding bottom 100px (10) to deal with the overlapping dropdowns
		// but for pages without sidebars, they tend to wrap their content with BasicLayout component which already has padding bottom 100px (10) applied,
		// so no more padding is needed to be added for those pages
		paddingBottom: isWithSidebar ? spacing(10) : 0,
	}
})

export default MainPageContainer
