import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Typography } from "@mui/material"
import { useTheme } from "@mui/styles"
import {
	AppCheckBox,
	AppLink,
	AuthCard,
	BasicLayout,
	ControlledAppTextField,
	ErrorMessage,
	LangSwitch,
} from "components"
import AutoLoadingButton from "components/AutoLoadingButton"
import WithFooter from "components/WithFooter"
import { FIND_CREDENTIALS_TYPES, INCORRECT_CREDENTIALS_STATUSES } from "constant"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { authApi } from "services/modules"
import { changeSavedId, useSavedId } from "stores/slice/app"
import { addUser, useUser } from "stores/slice/user"
import { ROUTE_FIND_CREDENTIALS } from "urls"
import { handleApiErrors } from "utils/helpers"
import { loginSchema } from "validations"

import SelectStore from "./SelectStore"

const Login = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { spacing } = useTheme()
	const user = useUser()
	const savedId = useSavedId()
	const email = useLocation().state?.email || ""

	const schema = loginSchema(t)

	const [isIdSaved, setIsIdSaved] = useState(false)
	const [login, { data: loginRes, error: loginError, isSuccess: loginIsSuccess }] =
		authApi.useLoginMutation()
	const {
		formState,
		handleSubmit,
		setError,
		control,
		setValue,
		setFocus,
		getValues,
		clearErrors,
	} = useForm({
		resolver: yupResolver(schema),
		mode: "all",
	})

	const userData = loginRes?.data
	const errors = formState.errors

	const handleFindIdPress = () =>
		navigate(ROUTE_FIND_CREDENTIALS, {
			state: {
				type: FIND_CREDENTIALS_TYPES.FIND_ID,
			},
		})

	useEffect(() => {
		loginRes && dispatch(addUser(userData))
	}, [loginRes])

	const handleFindPasswordPress = () =>
		navigate(ROUTE_FIND_CREDENTIALS, {
			state: {
				type: FIND_CREDENTIALS_TYPES.FIND_PASSWORD,
			},
		})

	const handleLoginErrors = (err) => {
		if (INCORRECT_CREDENTIALS_STATUSES.includes(err.response?.status)) {
			setError("serverError", {
				type: "unauthorized",
				message: t("The ID or PW is incorrect. Verify credentials."),
			})

			Object.keys(schema.fields).forEach((field) =>
				setError(field, {
					type: "unauthorized",
					message: "",
				})
			)
		} else {
			handleApiErrors(err, dispatch, setError)
		}
	}

	// for find-id flow
	useEffect(() => {
		// if the page is redirected with email data set the provided email automatically
		// otherwise, if there is no email data in redirection, check if there's saved id to automatically enter the saved id (email)
		if (email !== "" || savedId) {
			setValue("email", email || savedId)
			setFocus("email")
		}
	}, [])

	useEffect(() => {
		if (loginError) {
			handleLoginErrors(loginError)
		}
		// for save-id flow
		else if (loginIsSuccess && isIdSaved) {
			dispatch(changeSavedId(getValues("email")))
		}
	}, [loginError, loginIsSuccess])

	return (
		<WithFooter>
			<BasicLayout>
				<AuthCard>
					{user.id ? (
						<SelectStore user={userData} />
					) : (
						<>
							<Box
								py={3}
								px={4}
								sx={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
								}}>
								<Typography variant="h4">{t("Login")}</Typography>
								<LangSwitch />
							</Box>

							<Box pb={4} px={4}>
								<Box mb={2}>
									<ControlledAppTextField
										name="email"
										type="email"
										placeholder={t("Enter ID (email)")}
										error={Boolean(errors.email)}
										control={control}
										size="normal"
									/>

									{errors.email && (
										<ErrorMessage message={errors.email.message} />
									)}
								</Box>

								<Box mb={2}>
									<ControlledAppTextField
										name="password"
										type="password"
										placeholder={t("Enter PW")}
										error={Boolean(errors.password)}
										control={control}
										size="normal"
										onClear={() => clearErrors("serverError")}
										InputProps={{
											onKeyDown: (e) => {
												if (e.key === "Enter") {
													handleSubmit(login)()
												}
											},
										}}
									/>

									{errors.password && (
										<ErrorMessage message={errors.password.message} />
									)}

									{errors.serverError && (
										<ErrorMessage message={errors.serverError.message} />
									)}
								</Box>

								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										flexDirection: {
											xs: "column",
											md: "row",
										},
									}}>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											marginBottom: {
												xs: `${spacing(1)}`,
												md: `${spacing(0)}`,
											},
										}}>
										<AppCheckBox
											isChecked={isIdSaved}
											onToggle={() => setIsIdSaved((prev) => !prev)}
											size="small"
										/>
										<Typography variant="body3" ml={1}>
											{t("Save email")}
										</Typography>
									</Box>

									<Box sx={{ display: "flex", flexDirection: "row" }} gap={1}>
										<AppLink onClick={handleFindIdPress} label={t("Find ID")} />
										<Typography variant="body3">{"|"}</Typography>
										<AppLink
											onClick={handleFindPasswordPress}
											label={t("Find PW")}
										/>
									</Box>
								</Box>

								<Box mt={4}>
									<AutoLoadingButton
										disabled={!formState.isDirty || !formState.isValid}
										onClick={handleSubmit(login)}
										variant="contained"
										color="primary"
										fullWidth
										sx={{ fontSize: "size.md" }}>
										{t("Login")}
									</AutoLoadingButton>
								</Box>
							</Box>
						</>
					)}
				</AuthCard>
			</BasicLayout>
		</WithFooter>
	)
}

export default Login
