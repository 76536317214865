import { Box, Typography, styled } from "@mui/material"
import { StyledRedDot } from "components/form/styles"
import { useTranslation } from "react-i18next"

const StyledLabelWrap = styled(Box)(() => ({
	display: "flex",
	alignItems: "start",
}))

const RequiredInfoGuideText = () => {
	const { t } = useTranslation()

	return (
		<StyledLabelWrap>
			<StyledRedDot mr={0.4} />
			<Typography variant="guideText" lineHeight={1}>
				{t("Required info")}
			</Typography>
		</StyledLabelWrap>
	)
}

export default RequiredInfoGuideText
