import contained from "assets/theme/components/button/contained"
import outlined from "assets/theme/components/button/outlined"
import root, { COLOR_VARIANTS } from "assets/theme/components/button/root"
import text from "assets/theme/components/button/text"
import { capitalize } from "lodash"

export default {
	defaultProps: {
		disableRipple: false,
	},
	styleOverrides: {
		root: { ...root },
		contained: { ...contained.base },
		containedSizeSmall: { ...contained.small },
		containedSizeLarge: { ...contained.large },
		outlined: { ...outlined.base },
		outlinedSizeSmall: { ...outlined.small },
		outlinedSizeLarge: { ...outlined.large },
		text: { ...text.base },
		textSizeSmall: { ...text.small },
		textSizeLarge: { ...text.large },

		// pour created variant style objects into the styledOverrides object
		...COLOR_VARIANTS.reduce(
			(total, { id }) =>
				Object.assign(
					total,
					{
						[`contained${capitalize(id)}`]: {
							...contained[id],
						},
					},
					{
						[`outlined${capitalize(id)}`]: {
							...outlined[id],
						},
					},
					{
						[`text${capitalize(id)}`]: {
							...text[id],
						},
					}
				),
			{}
		),
	},
}
