import { useLocalizedDateUtils } from "hooks"
import { useTranslation } from "react-i18next"

import SelectorWrapper from "./SelectorWrapper"

const WeekSelector = ({ week, previousWeek, nextWeek }) => {
	const { t } = useTranslation()
	const { formatWeekCalendarDay } = useLocalizedDateUtils()

	const label = `${formatWeekCalendarDay(week[0].date, t, false)} ~ ${formatWeekCalendarDay(
		week[6].date,
		t,
		false
	)}`

	return (
		<SelectorWrapper
			onClickPrevious={previousWeek}
			onClickNext={nextWeek}
			margin="0 40px"
			label={label}
			maxWidth={550}
		/>
	)
}

export default WeekSelector
