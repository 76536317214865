import styled from "@emotion/styled"
import { Container } from "@mui/material"
import { TOPNAV_HEIGHT_IN_PX } from "constant"

// TODO: AppTopnav component will be refactored in cooldown period
const FullWidthContainer = styled(Container)(({ theme, sx: { ownerProps } }) => {
	const {
		palette,
		functions: { pxToRem },
	} = theme

	const { isBordered } = ownerProps

	return {
		"&&": {
			margin: "0 !important",
			maxWidth: "none !important",
			padding: "0 !important",
			height: `${pxToRem(TOPNAV_HEIGHT_IN_PX)}`,
			position: "sticky !important",
			backgroundColor: palette.white.main,
			top: 0,
			zIndex: 1000,
			// Unprotected routes (e.g login / findId) change its isTopnavBordered state by directly passing prop to Topnav component
			// For protected routes (e.g customer detail), isTopnavBordered state is assigned upfront when they are registered in routes.js (that organizes all the protected routes)
			borderBottom: isBordered ? `1px solid ${palette.grey[300]}` : "none",
		},
	}
})

export default FullWidthContainer
