import { object, string } from "yup"

export const STEPABLE = "stepable" // use across forms

// WARNING: don't try to change the field order - the ordering and structure is intended for proper validation for reservation forms
const reservationSchema = (t) =>
	// stepable fields are dependent on their previous fields - like they need to be resetted if their previous field values are changed
	object({
		[STEPABLE]: object({
			fitting_type_id: string().required(t("Please select a fitting type.")),
			fitting_type_item_ids: string().required(t("Please select a fitting type.")),
			reservation_date: string().required(t("Please select a reservation date.")),
			start_time: string().required(t("Please select a reservation time.")),
			app_user_id: string().required(t("Please select a fitter.")),
			room_id: string().required(t("Please select your turn at bat.")),
			items: object().default({}).nullable(), // fitting_clubs
		}),
		normal: object({
			memo: string(),
			status: string(),
		}),
	})

export default reservationSchema
