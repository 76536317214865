import { Box, styled } from "@mui/material"

export const StyledContent = styled(Box)(({ theme, ownerState }) => {
	const { spacing } = theme
	const { isSmall } = ownerState

	return {
		display: "flex",
		width: "100%",
		padding: isSmall ? spacing(1, 0.5) : spacing(2),
		paddingTop: 0,
		flexDirection: "column",
	}
})

StyledContent.defaultProps = {
	gap: 1,
}
