import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	InputLabel,
	Typography,
	useTheme,
} from "@mui/material"
import theme from "assets/theme"
import pxToRem from "assets/theme/functions/pxToRem"
import { AutoLoadingButton } from "components"
import { useTranslation } from "react-i18next"

export function PageTitle({ label }) {
	return (
		<Box
			sx={({ palette }) => ({
				width: "100%",
				textAlign: "center",
				pb: 3,

				borderBottom: `3px solid ${palette.primary.main}`,
			})}>
			<Typography sx={(theme) => ({ ...theme.typography.h4 })}>{label}</Typography>
		</Box>
	)
}

export function InputBox({ label, children, required = false }) {
	const {
		palette: { dark, warning },
	} = useTheme()
	return (
		<Box
			mb={3}
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				gap: pxToRem(5),
			}}>
			<InputLabel
				sx={{
					display: "flex",
					gap: pxToRem(4),
					alignItems: "center",
				}}>
				<Typography fontSize={pxToRem(16)} sx={{ color: dark.main }}>
					{label}
				</Typography>
				{required && (
					<Box
						width={pxToRem(6)}
						height={pxToRem(6)}
						bgcolor={warning.main}
						marginRight={pxToRem(4)}
						borderRadius={pxToRem(6)}
					/>
				)}
			</InputLabel>
			{children}
		</Box>
	)
}

export function ConfirmPage({ title, content, onClick, buttonName, buttonSx }) {
	return (
		<>
			{title && <PageTitle label={title} />}

			<Box px={4} pt={title ? 4 : 0} width="100%">
				<Typography
					fontSize={theme.typography.size.md}
					sx={(theme) => ({
						whiteSpace: "pre-line",
						color: theme.palette.grey[700],
					})}>
					{content}
				</Typography>
				{buttonName ? (
					<AutoLoadingButton
						variant="contained"
						fullWidth
						sx={{ mt: 6, ...buttonSx }}
						size="normal"
						onClick={onClick}>
						{buttonName}
					</AutoLoadingButton>
				) : null}
			</Box>
		</>
	)
}

export function TermsDialog({ open, setOpen, title, content, onClickHandler }) {
	const { t } = useTranslation()
	return (
		<Dialog open={open}>
			<Box width={pxToRem(580)} maxHeight={800} display="flex" flexDirection="column">
				<DialogTitle
					textAlign={"center"}
					sx={({ palette: { primary } }) => ({
						margin: `${pxToRem(40)} ${pxToRem(40)} ${pxToRem(30)}`,
						borderBottom: `${pxToRem(3)} solid ${primary.main}`,
					})}>
					<Typography variant="formDialogTitle">{title}</Typography>
				</DialogTitle>
				<DialogContent
					sx={{
						padding: `${pxToRem(40)}`,
						display: "flex",
						flexDirection: "column",
					}}>
					<Typography variant="body1" flex={1}>
						{content}
					</Typography>
					<AutoLoadingButton
						fullWidth
						variant="contained"
						size="normal"
						color="primary"
						sx={{ marginTop: pxToRem(60), fontSize: "size.md" }}
						onClick={() => {
							setOpen(false)
							onClickHandler()
						}}>
						{t("Confirm")}
					</AutoLoadingButton>
				</DialogContent>
			</Box>
		</Dialog>
	)
}
