import { api } from "services/api"

const termsApi = api.injectEndpoints({
	endpoints(build) {
		return {
			get: build.query({
				async queryFn({ endpoint }, queryApi, extraOptions, baseQuery) {
					const url = `${process.env.REACT_APP_BASE_URL}/vicx/${endpoint}`
					return await baseQuery(url)
				},
			}),
		}
	},
})

export default termsApi
