import { change5xxError } from "stores/slice/app"

import ErrorComponent from "./common"

export default function Page5XX() {
	return (
		<ErrorComponent
			goto={-1}
			confirm={() => change5xxError(false)}
			subtitle="An error has occurred."
		/>
	)
}

export const Component = Page5XX
