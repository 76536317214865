import { DATE_DEFAULT_FORMAT, STRING_DATE_FORMAT } from "constant"
import { addMonths, differenceInMinutes, format, parse } from "date-fns"
import { autoFillDashes, removeNonNumeric } from "utils/string"

// TODO: sync data structure of args (object and primitive are mixed)
export const dateFormatter = ({ date, convertFormat = "yy.MM.dd" }) =>
	format(new Date(date), convertFormat)

export const formatDateString = ({ dateString, formatFrom = "yy.MM.dd", formatTo }) => {
	const date = parse(dateString, formatFrom, new Date())
	return format(date, formatTo)
}

export const convertMinutesToHours = (minutes) => {
	if (typeof minutes !== "number") {
		throw new Error("Input must be a number")
	}

	const hours = minutes / 60
	return hours
}

export const getDiscreteMinsAndHrs = (hourInFloat) => ({
	hours: Math.floor(hourInFloat / 1),
	minutes: (hourInFloat % 1) * 60, // convert floating points into minutes
})

export const addHoursToDate = ({ continuousHour, date }) => {
	const { hours, minutes } = getDiscreteMinsAndHrs(continuousHour)
	date.setHours(date.getHours() + hours)
	date.setMinutes(date.getMinutes() + minutes)
	return date
}

export const getMinuteDifference = (...args) => differenceInMinutes(...args)

export const stringToDate = ({ dateString, formatFrom }) =>
	parse(dateString, formatFrom, new Date())

export const stringToDateV2 = (dateString) => {
	const parsedDate = parse(dateString, STRING_DATE_FORMAT, new Date())
	const formattedDate = format(parsedDate, DATE_DEFAULT_FORMAT)
	return formattedDate
}

export const threeMonthDateFromNow = (today) => {
	const futureDate = addMonths(today, 3)
	return futureDate
}

export const formatDate = (date, output = "yyyy.MM.dd HH:mm", options = {}) => {
	try {
		return format(new Date(date), output, options)
	} catch (err) {
		return date
	}
}

export function defaultDateFormat(date, output = "yyyy.MM.dd") {
	try {
		return format(new Date(date), output)
	} catch (err) {
		return date
	}
}

export function formatV1(date, format = STRING_DATE_FORMAT) {
	return defaultDateFormat(date, format)
}

export function displayDateFormat(date, output = DATE_DEFAULT_FORMAT) {
	try {
		return format(new Date(date), output)
	} catch (err) {
		return date
	}
}

export function formatDOB(inputDOB) {
	if (!inputDOB) return inputDOB
	const numericValue = removeNonNumeric(inputDOB)

	// truncate to the first 8 characters (YYYYMMDD)
	const limitedLengthValue = numericValue.slice(0, 8)

	return autoFillDashes([4, 6], limitedLengthValue)
}

export const convertMsToMinutesAndSeconds = (milliseconds) => {
	const totalSeconds = Math.floor(milliseconds / 1000)
	const minutes = Math.floor(totalSeconds / 60)
	const seconds = totalSeconds % 60

	return { minutes, seconds }
}

// format know format into desired format intended to be used in common components
// where it renders different fields and individual field is unknown
export function wellKnown(name, value) {
	switch (name) {
		case "dob":
			return formatDOB(value)

		case "created_at":
			return formatDate(value)

		case "updated_at":
			return formatDate(value)

		case "purchased_at":
			return formatDate(value)

		// regex match example
		// case name.match(/phone(_number)?/) ? name : Math.random():
		// 	return 123

		default:
			return value
	}
}
