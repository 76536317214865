import { Box, Typography, styled } from "@mui/material"
import { StyledRedDot } from "components/form/styles"
import { bool, string } from "prop-types"

/**
 * Generates a label for a form dialog input.
 *
 * @param {string} label - The text of the label.
 * @param {boolean} isRequired - Whether to show the red dot.
 * @param {object} textProps - Additional props for the Typography component.
 * @param {...rest} rest - Additional props for the StyledLabelWrap component.
 * @return {ReactElement} - The label component for the form dialog input.
 */

const StyledLabelWrap = styled(Box)(() => ({
	display: "flex",
	alignItems: "start",
}))

const FormDialogInputLabel = ({ label, isRequired, textProps, ...rest }) => {
	return (
		<StyledLabelWrap {...rest}>
			<Typography mr={0.4} variant="body2" lineHeight={1} {...textProps}>
				{label}
			</Typography>
			{isRequired && <StyledRedDot />}
		</StyledLabelWrap>
	)
}

FormDialogInputLabel.defaultProps = {
	isRequired: false,
}

FormDialogInputLabel.propTypes = {
	label: string.isRequired,
	isRequired: bool,
}

export default FormDialogInputLabel
