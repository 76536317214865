import { createApi } from "@reduxjs/toolkit/query/react"
import { UNAUTHORIZED_STATUSES } from "constant"
import { change5xxError, change404Error } from "stores/slice/app"
import { resetStore } from "stores/slice/store"
import { removeUser } from "stores/slice/user"

import customAxios from "./customAxios"
import * as Tags from "./tags"

export const api = createApi({
	baseQuery: async (args, { dispatch }) => {
		try {
			const { data } = await customAxios(args)

			return { data }
		} catch (error) {
			const status = error.response?.status

			if (UNAUTHORIZED_STATUSES.includes(status)) {
				dispatch(removeUser())
				dispatch(resetStore())
			} else if (status === 404) {
				dispatch(change404Error(true))
			} else if (status >= 500) {
				dispatch(change5xxError(true))
			}

			return { error }
		}
	},

	endpoints: () => ({}),

	tagTypes: Object.values(Tags),
})
