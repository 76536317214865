import { Icon } from "@mui/material"
import { HamburgerCloseSvg, HamburgerOpenSvg, VicxAdminLogoSvg } from "assets/svgs"
import {
	StyledContent,
	StyledHamburgerMenu,
	StyledHeader,
	StyledHeaderTitle,
	StyledHeaderTitleWrap,
	StyledNavItem,
	StyledNavItemLabel,
	StyledNavItemLabelWrap,
	StyledSidenavRoot,
} from "components/AppSidenav/styles"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import routes from "routes"
import { ROUTE_DEFAULT } from "urls"

const AppSidenav = ({ isSmall, onSizeToggle }) => {
	const { pathname: currentPathName } = useLocation()
	const { t } = useTranslation()
	const navigate = useNavigate()

	const commonOwnerState = {
		isSmall,
	}

	return (
		<StyledSidenavRoot open variant="persistent" ownerState={commonOwnerState}>
			<StyledHeader ownerState={commonOwnerState}>
				<StyledHeaderTitleWrap ownerState={commonOwnerState}>
					<StyledHeaderTitle ownerState={commonOwnerState}>
						<VicxAdminLogoSvg
							style={{ cursor: "pointer", width: 140 }}
							onClick={() => {
								navigate(ROUTE_DEFAULT)
							}}
						/>
					</StyledHeaderTitle>
				</StyledHeaderTitleWrap>
				<StyledHamburgerMenu onClick={onSizeToggle} ownerState={commonOwnerState}>
					{isSmall ? <HamburgerOpenSvg /> : <HamburgerCloseSvg />}
				</StyledHamburgerMenu>
			</StyledHeader>

			<StyledContent ownerState={commonOwnerState}>
				{/* render nav items by looping routes with sidenavVisible true */}
				{routes
					.filter(({ sidenavVisible }) => !!sidenavVisible)
					.map(({ name, icon, id, path }) => {
						const isSelected = path === "/" + currentPathName.split("/")[1] // Note: this will not work for nested routes since there is no nested route it will be fine

						return (
							<StyledNavItem
								to={path}
								key={id}
								ownerState={{
									...commonOwnerState,
									isSelected,
								}}>
								<Icon
									sx={{
										color: isSelected ? "baseColors.BLUES.blue" : "grey.700",
									}}>
									{typeof icon === "function" ? icon(isSelected) : icon}
								</Icon>

								<StyledNavItemLabelWrap ownerState={commonOwnerState}>
									<StyledNavItemLabel
										ownerState={commonOwnerState}
										color={isSelected ? "baseColors.BLUES.blue" : "grey.700"}>
										{t(name)}
									</StyledNavItemLabel>
								</StyledNavItemLabelWrap>
							</StyledNavItem>
						)
					})}
			</StyledContent>
		</StyledSidenavRoot>
	)
}

export default AppSidenav
