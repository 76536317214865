import { Box, Typography, useTheme } from "@mui/material"
import { useLocalizedDateUtils } from "hooks"
import { useIsEnglish } from "stores/slice/app"
import { formatLongText } from "utils/helpers"

const DataBox = ({
	marginTop,
	handleDataBox = new Function(),
	backgroundColor,
	reservation,
	isBig,
	selectedDayCheck,
	isLoading,
}) => {
	const { palette } = useTheme()
	const { getAmPmFromDateString, getAmPmFromDateStringResDataBox } = useLocalizedDateUtils()

	const isEnglish = useIsEnglish()

	const text = reservation
		? isBig
			? isEnglish
				? `${getAmPmFromDateStringResDataBox(
						reservation.start_time
					)} ~ ${getAmPmFromDateStringResDataBox(reservation.end_time)}`
				: `${getAmPmFromDateStringResDataBox(
						reservation.start_time
					)} ~ ${getAmPmFromDateString(reservation.end_time)}`
			: `${getAmPmFromDateStringResDataBox(reservation.start_time)} ${
					reservation?.user?.name
				}`
		: null

	return (
		<Box
			onClick={() => handleDataBox(reservation)}
			sx={{
				minWidth: isBig ? "240px" : "90%",
				width: "90%",
				maxWidth: "95%",
				minHeight: isBig ? "70px" : "30px",
				border: `1px solid ${palette.transparent.main}`,
				"&:hover": {
					border: text ? `1px solid ${palette.primary.main}` : "none",
				},
				marginTop: marginTop,
				backgroundColor: isLoading
					? backgroundColor
					: text
						? backgroundColor ?? palette.grey[200]
						: palette.transparent.main,
				borderRadius: "8px",
				display: "flex",
				justifyContent: "flex-start",
				alignItems: isBig ? "flex-start" : "center",
				cursor: "pointer",
			}}>
			{text ? (
				<>
					<Box
						sx={({ palette }) => ({
							width: isBig ? "14px" : "10px",
							height: isBig ? "13px" : "9px",
							backgroundColor: palette.reservationStatusColors[reservation.status],
							borderRadius: "20px",
							margin: isBig ? "13px 10px 0 10px" : "0 5px 0 9px",
						})}
					/>
					<Typography
						fontSize={isBig ? 16 : 12}
						sx={{
							width: "90%",
							marginTop: isBig ? "7px" : 0,
							marginBottom: isBig ? "7px" : 0,
						}}>
						{formatLongText(text, isBig ? 37 : 12)}
						{isBig && <br />}
						{isBig && formatLongText(reservation?.user?.name, 37)}
					</Typography>
				</>
			) : (
				<>
					<Box
						sx={{
							width: isBig ? "14px" : "10px",
							height: isBig ? "14px" : "9px",
							backgroundColor: palette.transparent.main,
							borderRadius: "20px",
							margin: isBig ? "13px 10px 0 10px" : "0 5px 0 9px",
						}}
					/>
					<Typography
						fontSize={isBig ? 16 : 11}
						color={
							isLoading
								? backgroundColor
								: selectedDayCheck
									? palette.secondary.main
									: palette.white.main
						}
						sx={{
							width: "90%",
							marginTop: isBig ? "7px" : 0,
							marginBottom: isBig ? "7px" : 0,
						}}>
						{""}
					</Typography>
				</>
			)}
		</Box>
	)
}

export default DataBox
