import { COMMON_REGEXES } from "constant"
import { number, object, string } from "yup"

const customerSchema = (t) =>
	object({
		basicInfo: object({
			id: string(),
			name: string().required(),
			status: string().required(),
			phone_number: string()
				.required(t("Enter phone number"))
				.test("KR_PHONE_FORMAT", t("Invalid phone number"), (value) => {
					if (!value) return false
					const numericValue = value.replace(/-/g, "") // remove '-'
					return COMMON_REGEXES.KR_PHONE_FORMAT.test(numericValue)
				}),
			gender: string().required(),
			dob: string(),
			registration_date: string(),
			last_fitting_date: string(),
			fitter: string().required(),
			//TODO: change the fitter to app_user_id
			contact_person: string(),
			memo: string(),
		}),
		defaultAddress: object({
			postal_code: string().required(t("Enter the postal code")),
			address: string().required(t("Enter the address")),
			address_detail: string().required(t("Enter the address")),
			delivery_info: string(),
			manual_delivery_info: string(),
		}),
	})

export default customerSchema

// TODO: refactor the schema to be one level for customer registration form

export const customerGeneralInfoSchema = (t) =>
	object({
		name: string().required(),
		status: string().required(),
		phone_number: string()
			.required(t("Enter phone number"))
			.test("KR_PHONE_FORMAT", t("Invalid phone number"), (value) => {
				if (!value) return false
				const numericValue = value.replace(/-/g, "") // remove '-'
				return COMMON_REGEXES.KR_PHONE_FORMAT.test(numericValue)
			}),
		gender: string().required(),
		dob: string(),
		registration_date: string(),
		last_fitting_date: string(),
		memo: string().nullable(),
		weight: number().required(),
		glove_size: number().required(),
		height: number().required(),
		hand: string().required(),
	})
