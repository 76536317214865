import { Box, InputLabel, Typography, useTheme } from "@mui/material"
import pxToRem from "assets/theme/functions/pxToRem"
import { useTranslation } from "react-i18next"

function ClubFormLabel({ label, errorMsg, children, required = true }) {
	const {
		palette: { warning },
		typography,
	} = useTheme()
	const { t } = useTranslation()

	return (
		<Box>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="start"
				variant="caption"
				mt={pxToRem(30)}
				mb={pxToRem(10)}>
				<InputLabel>
					<Typography color="#000" fontSize={typography.size.md} fontWeight={500}>
						{t(label)}
					</Typography>
				</InputLabel>
				{required && (
					<Box
						width={pxToRem(6)}
						height={pxToRem(6)}
						bgcolor={warning.main}
						marginLeft={pxToRem(4)}
						borderRadius={pxToRem(6)}
					/>
				)}
			</Box>
			{children}
			{errorMsg && (
				<Typography mt={1} color="error" sx={{ fontSize: pxToRem(14) }}>
					{t(errorMsg)}
				</Typography>
			)}
		</Box>
	)
}

export default ClubFormLabel
