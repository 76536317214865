import { object, string } from "yup"

export function wedgeClubShaftSchema(t, register = "store") {
	return object({
		brand: string().required(t("Enter the brand")),
		model: string().required(t("Enter the model")),
		material:
			register === "customer"
				? string().nullable()
				: string().required(t("Enter the material")),
		flex:
			register === "customer" ? string().nullable() : string().required(t("Select the flex")),
		weight:
			register === "customer"
				? string().nullable()
				: string().required(t("Select the weight")),

		length: string().nullable(),
		// length:
		// 	register === "customer"
		// 		? string().nullable()
		// 		: string().required(t("Select the length")),
		// length_direct_input: string().when("length", {
		// 	is: (value) => value === DIRECT_INPUT_VALUE,
		// 	then: (rule) => rule.required(t("Select the number")),
		// }),
	})
}

//  NOTE: ** Register can be customer or store. depend on register type some of fields are not required.
const wedgeClubFormSchema = (t, register) =>
	object({
		club: string().required(t("Select the club")),
		number: string().required(t("Select the number")).nullable(),
		total_weight:
			register === "customer" ? string().nullable() : string().required().nullable(),
		swing_weight: register === "customer" ? string().nullable() : string().required(),
		head: object({
			brand: string().required(t("Enter the brand")),
			model: string().required(t("Enter the model")),
			loft: string().required().nullable(),
			lie_angle:
				register === "customer" ? string().nullable() : string().required().nullable(),
			bounce_angle:
				register === "customer" ? string().nullable() : string().required().nullable(),
		}),
		shaft: wedgeClubShaftSchema(t, register),
	}).required()

export default wedgeClubFormSchema
