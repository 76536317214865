import { InputAdornment, styled, useTheme } from "@mui/material"
import { SearchSvg } from "assets/svgs"
import pxToRem from "assets/theme/functions/pxToRem"
import { AppTextField } from "components/form"
import { AppTextFieldPropTypes } from "components/form/AppTextField"
import PropTypes from "prop-types"

const StyledInputAdornment = styled(InputAdornment)(() => ({
	width: "auto",
	margin: 0,
}))

const StyledAppTextField = styled(AppTextField)(({ theme }) => {
	const { spacing } = theme
	return {
		"& .MuiInputBase-root": {
			borderRadius: `${pxToRem(8)} !important`,
		},
		"& .MuiInputBase-input": {
			padding: spacing(0, 1),
			paddingRight: spacing(2),
			display: "block",
			textOverflow: "ellipsis",
		},
	}
})

const AppSearchBox = ({ onSubmit, withBgColor = false, ...rest }) => {
	const {
		palette,
		functions: { pxToRem },
	} = useTheme()

	const handleKeyDown = (e) => {
		// check if the Enter key is pressed
		if (e.key === "Enter") {
			onSubmit?.() // submit on 'enter' pressed
		}
		// check if the Esc key is pressed
		else if (e.key === "Escape") {
			e.target.blur() // blur the field on 'escape' pressed
		}
	}

	return (
		<StyledAppTextField
			sx={{
				backgroundColor: withBgColor ? palette.background.paper : "transparent",
				borderRadius: `${pxToRem(8)} !important`,
			}}
			onKeyDown={handleKeyDown}
			InputProps={{
				startAdornment: (
					<StyledInputAdornment position="end">
						<SearchSvg
							stroke={palette.grey[700]}
							width={pxToRem(24)}
							height={pxToRem(24)}
						/>
					</StyledInputAdornment>
				),
			}}
			{...rest}
		/>
	)
}

AppSearchBox.propTypes = {
	onSubmit: PropTypes.func,
	...AppTextFieldPropTypes,
}

export default AppSearchBox

/*

consumer guide
==============
onSubmit => submit function that is run on 'enter' key is pressed
*the rest are the same as AppTextField 

USAGE (E.g)
===========
<AppSearchBox
	value={searchQuery}
	onSubmit={() => console.log(`search api is called with ${searchQuery}`)}
	onChange={(e) => setSearchQuery(e.target.value)}
	size="normal"
	placeholder="search..."
/>

*/
