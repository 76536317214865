import colors from "assets/theme/base/palette"
import typography from "assets/theme/base/typography"
import { COLOR_VARIANTS, SIZE_BASED_SPECS } from "assets/theme/components/button/root"
import pxToRem from "assets/theme/functions/pxToRem"

const { transparent, primary } = colors
const { size } = typography

export default {
	base: {
		...SIZE_BASED_SPECS.normal,
		color: primary.main,
		borderColor: `${primary.main}60`,

		"&:hover": {
			backgroundColor: transparent.main,
			borderColor: primary.main,
		},

		"& .material-icon, .material-icons-round, svg": {
			fontSize: `${pxToRem(size.md)} !important`,
		},
	},

	small: {
		...SIZE_BASED_SPECS.small,

		"& .material-icon, .material-icons-round, svg": {
			fontSize: `${pxToRem(size.md)} !important`,
		},
	},

	large: {
		...SIZE_BASED_SPECS.large,

		"& .material-icon, .material-icons-round, svg": {
			fontSize: `${pxToRem(size.lg)} !important`,
		},
	},

	// generate 'outlined' variant style objects based on VARIANTS' SCHEMA
	...COLOR_VARIANTS.reduce(
		(total, { id, color }) =>
			// merge the current variant into the existing variants
			Object.assign(total, {
				[id]: {
					backgroundColor: transparent.main,
					borderColor: `${color.main}60`,
					color: color.main,

					"&:hover": {
						backgroundColor: transparent.main,
						borderColor: color.main,
					},

					// the following two are for loading state
					"&.MuiLoadingButton-root.Mui-disabled.MuiLoadingButton-loading": {
						backgroundColor: "transparent",
						opacity: 1,
						borderColor: color.main,
					},

					"&.Mui-disabled": {
						color: colors.disabled.main,
					},

					"& > .MuiLoadingButton-loadingIndicator": {
						color: color.main,
					},
				},
			}),
		{}
	),
}
