// auth
export const TAG_AUTH = "AuthUser"

// store
export const TAG_STORE = "Store"
export const TAG_STORE_WORKDAY = "StoreWorkday"
export const TAG_STORE_LIST = "StoreList"
export const TAG_STORE_MEMBER = "StoreMember"
export const TAG_STORE_FITTERS = "StoreFitters"
export const TAG_STORE_ROOMS = "StoreRooms"
export const TAG_ANALYZERS = "AnalyzersOfficial"
export const TAG_HOLIDAY = "Holiday"
export const TAG_HOLIDAY_BY_YEAR = "HolidayByYear"
export const TAG_HOLIDAY_FOR_CALENDAR_YEAR = "HolidayForCalendarYear"
export const TAG_STORE_INSTOCK_CLUBS = "StoreInstockClubs"

// app user
export const TAG_APP_USER = "AppUser"

// reservation
export const TAG_RESERVATION_LIST = "ReservationList"
export const TAG_RESERVATION_FITTING_TYPES = "ReservationFittingTypes"
export const TAG_RESERVATION_SELECTABLE_TIME_SLOTS = "ReservationSelectableTimeSlots"
export const TAG_RESERVATION_SELECTABLE_FITTERS = "ReservationSelectableFitters"
export const TAG_RESERVATION_SELECTABLE_ROOMS = "ReservationSelectableRooms"
export const TAG_RESERVATION_SELECTABLE_DAY = "ReservationSelectableDay"
export const TAG_RESERVATION = "Reservation"

// customer
export const TAG_CUSTOMERS = "Customers"
export const TAG_CUSTOMERS_ALL = "CustomerAll"
export const TAG_CUSTOMERS_FROM_OTHER_STORES = "CustomersFromOtherStores"
export const TAG_CUSTOMERS_CLUBS = "CustomersClubs"
export const TAG_CUSTOMERS_CLUB_IRON_SETS = "CustomersClubIronSets"
export const TAG_CUSTOMERS_CLUB_DETAIL = "CustomersClubDetail"
export const TAG_CUSTOMERS_RESERVATION = "CustomersReservation"

// vicx user
export const TAG_VICX_USER = "VicxUser"

export const TAG_CLUB_BRANDS = "ClubBrands"

export const TAG_ROOM_CLUBS = "RoomClubs"
export const TAG_ROOM_IRON_CLUBS = "RoomIronClubs"
export const TAG_ROOM_CLUB_DETAIL = "RoomClubDetail"
export const TAG_ROOM_CLUB_COPY_CLUBS = "ClubCopyClubLists"

// help
export const TAG_STORE_HELP = "StoreHelp"
export const TAG_STORE_HELPS = "StoreHelps"

//equipments
export const TAG_STORE_EQUIPMENT = "StoreEquipments"

//fitting management
export const TAG_FITTING_MANAGEMENT = "FittingManagement"

// valid user common
export const TAG_VALID_USER_COMMON = "validUserCommon"

export const TAG_CLUB_MODELS = "clubModels"
