import { object, string } from "yup"

const resetPasswordSchema = (t) =>
	object({
		newPassword: string()
			.required(t("Please enter new password"))
			.min(8, t("Enter at least 8 characters."))
			.matches(
				/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
				t("Enter combination of English letter + Number + Special character (No space).")
			)
			.test(
				"no-consecutive-identical-letters",
				t("Cannot enter three or more consecutive identical numbers."),
				(value) => {
					return !/(.)\1\1\1/.test(value)
				}
			),
		confirmNewPassword: string()
			.required(t("Please re-enter new password again"))
			.min(8, t("Enter at least 8 characters."))
			.test(
				"password-mismatch",
				t("Please enter the same password"),
				(value, context) => context.parent.newPassword === value
			), // oneOf isn't used since oneOf is not included as 'tests' (array of schema object) by yup, which (tests) is required for SmartValidationRules implementation
	})

export default resetPasswordSchema
