import { Typography } from "@mui/material"
import { LogOutSvg } from "assets/svgs"
import { showModal } from "components"
import { Trans, useTranslation } from "react-i18next"
import { authApi } from "services/modules"
import { handlePromise } from "utils/helpers"

import NavbarLink from "./NavbarLink"
import NavbarMobile from "./NavbarMobile"

function useLogout() {
	const [logout] = handlePromise(
		authApi.useLogoutMutation,
		"Logout successfully",
		"Logout failed"
	)

	return () => {
		return import("components/dialog/ConfirmDialog").then(({ default: ConfirmDialog }) => {
			showModal(({ closeModal }) => (
				<ConfirmDialog
					open
					onClose={() => closeModal()}
					onConfirm={async () => {
						await logout()
						closeModal()
					}}>
					<Typography fontSize="size.md" textAlign="center" pb={4} pt={2.5}>
						<Trans
							i18nKey="Do you want to log out?"
							components={{
								bold: <span style={{ fontWeight: "bold" }} />,
							}}>
							{"Do you want to <bold>log out</bold>?"}
						</Trans>
					</Typography>
				</ConfirmDialog>
			))
		})
	}
}

export function LogoutDesktop() {
	const { t } = useTranslation()

	const logout = useLogout()

	return (
		<NavbarLink
			icon={<LogOutSvg stroke="currentcolor" />}
			name={t("Logout")}
			onClick={async () => await logout()}
		/>
	)
}

export function LogoutMobile({ open, closeMobileNavbar }) {
	const logout = useLogout()

	return (
		<NavbarMobile
			open={open}
			close={closeMobileNavbar}
			handleLogout={async () => await logout()}
		/>
	)
}
