import { Box, InputLabel, Typography, useTheme } from "@mui/material"
import MuiAccordion from "@mui/material/Accordion"
import MuiAccordionDetails from "@mui/material/AccordionDetails"
import MuiAccordionSummary from "@mui/material/AccordionSummary"
import { styled } from "@mui/material/styles"
import { ArrowHeadDownSvg } from "assets/svgs"
import pxToRem from "assets/theme/functions/pxToRem"
import { IRON } from "constant"
import * as React from "react"
import { useTranslation } from "react-i18next"

import ClubNoRegistered from "./ClubNoRegistered"

const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	borderBottom: `1px solid ${theme.palette.grey[300]}`,
	marginBottom: "20px",
	"&:before": {
		display: "none",
	},
}))

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary {...props} expandIcon={<ArrowHeadDownSvg stroke={"#000"} />} />
))(() => ({
	flexDirection: "row",
	paddingTop: "10px",
	paddingBottom: "10px",
	paddingLeft: 0,
	paddingRight: 0,
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(180deg)",
	},
	"& .MuiAccordionSummary-content": {
		marginLeft: 0,
	},
}))

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
	paddingTop: 0,
}))

export default function AppAccordion({
	data,
	isFromCustomer = false,
	isFitting = false,
	noRegisterWidth = 560,
}) {
	const { t } = useTranslation()
	const { palette } = useTheme()

	const black = palette.dark.main

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
			}}>
			<InputLabel sx={{ marginBottom: pxToRem(20) }}>
				{isFitting || isFromCustomer ? null : (
					<Typography fontSize={pxToRem(16)} sx={{ fontWeight: 700, color: black }}>
						{t("Club list")}
					</Typography>
				)}
			</InputLabel>
			<div>
				{data.map((item) => (
					<Accordion key={item.title}>
						<AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
							<Box
								sx={{
									width: "8px",
									height: "8px",
									backgroundColor: black,
									borderRadius: "50%",
									marginRight: "10px",
									marginTop: "9px",
									"@media (max-width: 1400px)": {
										marginTop: "6.5px",
									},
									"@media (max-width: 992px)": {
										marginTop: "5px",
									},
								}}
							/>
							<Typography sx={{ fontSize: pxToRem(16), fontWeight: 500 }}>
								{t(item.title)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails
							sx={{
								paddingX: item?.title === IRON ? 0 : "20px",
								paddingTop: 0,
								paddingBottom: "20px",
							}}>
							{item.detail ? (
								item.detail
							) : (
								<Box ml={item?.title === IRON && isFromCustomer ? "20px" : 0}>
									<ClubNoRegistered
										isFromCustomer={isFromCustomer}
										isFitting={isFitting}
										noRegisterWidth={noRegisterWidth}
									/>
								</Box>
							)}
						</AccordionDetails>
					</Accordion>
				))}
			</div>
		</Box>
	)
}
