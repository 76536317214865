import { useState } from "react"

export default function useAnchorElement(name = "anchor-element") {
	const [anchorEl, setAnchorEl] = useState(null)

	const open = Boolean(anchorEl)
	const id = open ? name : undefined

	return {
		id,
		open,
		anchorEl,
		setAnchorEl,
	}
}
