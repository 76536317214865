import { LoadingButton } from "@mui/lab"
import { forwardRef, useState } from "react"

const AutoLoadingButton = forwardRef(({ onClick, disabled = false, ...rest }, ref) => {
	const [loading, setLoading] = useState(false)

	return (
		<LoadingButton
			ref={ref}
			loading={loading}
			disabled={disabled || loading}
			onClick={async (event) => {
				try {
					setLoading(true)
					return await onClick(event)
				} finally {
					setLoading(false)
				}
			}}
			{...rest}
		/>
	)
})

AutoLoadingButton.defaultProps = {
	size: "normal",
	variant: "contained",
	color: "primary",
	disabled: false,
}

export default AutoLoadingButton
