// to show error if the required func params are not provided
export const isRequired = () => {
	throw new Error("param is required")
}

export const absoluteCenter = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	outline: "none",
}
