import { Box } from "@mui/material"
import pxToRem from "assets/theme/functions/pxToRem"
import { FormDialog } from "components/dialog"
import { useTranslation } from "react-i18next"

const ClubFormWrapper = ({ title, onClose, children }) => {
	const { t } = useTranslation()

	return (
		<FormDialog padding={0} width={580} onClose={onClose} open={true}>
			<Box sx={{ margin: "40px", marginTop: "10px" }}>
				<FormDialog.Header>{t(title)}</FormDialog.Header>
				<FormDialog.Content style={{ paddingTop: pxToRem(4) }}>
					{children}
				</FormDialog.Content>
			</Box>
		</FormDialog>
	)
}

export default ClubFormWrapper
