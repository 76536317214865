import FormLoadingSkeleton from "components/FormLoadingSkeleton"
import { GRIP_SIZE, PUTTER } from "constant"
import React from "react"
import { useTranslation } from "react-i18next"
import { clubsApi } from "services/modules"
import validApi from "services/modules/valid"
import { useUser } from "stores/slice/user"
import { appendClubAdditionalOptions, arrayToSelectOptions } from "utils/helpers"

import { ClubFormHeaderText } from "./components"
import {
	HeadBrandInput,
	HeadLieAngleInput,
	HeadLoftInput,
	HeadModelInput,
	PutterShaftFlexInput,
	ShaftBrandInput,
	ShaftGripSizeInput,
	ShaftLengthInput,
	ShaftMaterialInput,
	ShaftModelInput,
	ShaftWeightInput,
	SwingWeightInput,
	TotalWeightInput,
} from "./components/ClubFormInputs"

/**
 * @param {Object} params
 * @param {import("react-hook-form").UseFormReturn} params.hookFormProps - react-hook-form
 * @param {boolean} params.isEdit
 * @param {boolean} params.isStockShaft
 * @param {"customer" | "store"} params.register
 * @param {boolean} params.onlyShaft
 * @returns  {JSX.Element}
 */
const PutterClubForm = ({
	hookFormProps,
	isEdit,
	onlyShaft,
	register,
	isStockShaft,
	readOnly = false,
}) => {
	const { t } = useTranslation()
	const user = useUser()
	const { data, isFetching } = validApi.useGetClubOptionsQuery({ clubType: PUTTER })
	const { data: brands, isFetching: brandFetching } = clubsApi.useGetAllBrandsQuery()
	const { data: shaftModels, isFetching: shaftModelFetching } = clubsApi.useGetClubModelsQuery({
		clubType: "Putter",
		filter: "shaft",
	})
	const { data: headModels, isFetching: headModelFetching } = clubsApi.useGetClubModelsQuery({
		clubType: "Putter",
		filter: "head",
	})

	const { head, shaft } = data?.data || {}

	const {
		formState: { errors },
		control,
	} = hookFormProps

	const inputProps = {
		t,
		methods: hookFormProps,
		control,
		errors,
		disabled: (user.isStaff && register === "store") || readOnly,
	}

	const brandOptions = brands?.data?.map(({ name }) => ({ label: name, value: name }))
	const headModelOptions = headModels?.data?.map(({ name }) => ({ label: name, value: name }))
	const shaftModelOptions = shaftModels?.data?.map(({ name }) => ({ label: name, value: name }))

	const headLoftOptions = []

	const headLieAngleOptions =
		head?.lie_angles && appendClubAdditionalOptions(t, arrayToSelectOptions(head?.lie_angles))

	const shaftMaterialOptions = shaft?.material && arrayToSelectOptions(shaft?.material, t)

	const shaftWeightOptions = []

	const shaftLengthOptions = []

	const swingWeightOptions = shaft?.swing_weights && arrayToSelectOptions(shaft.swing_weights)

	if (isFetching || brandFetching || shaftModelFetching || headModelFetching) {
		return <FormLoadingSkeleton marginTop={5} />
	}

	return (
		<>
			{!onlyShaft && (
				<>
					<TotalWeightInput
						name="total_weight"
						required={register === "store"}
						{...inputProps}
						options={swingWeightOptions}
					/>

					<SwingWeightInput
						name="swing_weight"
						required={register === "store"}
						{...inputProps}
						options={swingWeightOptions}
					/>

					<ClubFormHeaderText label="Head" />

					<HeadBrandInput
						name="head.brand"
						{...inputProps}
						options={brandOptions}
						disabled={isEdit || inputProps.disabled}
					/>

					<HeadModelInput
						name="head.model"
						{...inputProps}
						disabled={isEdit || inputProps.disabled}
						options={headModelOptions}
					/>

					<HeadLoftInput
						name="head.loft"
						required={register === "store"}
						{...inputProps}
						options={headLoftOptions}
					/>

					<HeadLieAngleInput
						name="head.lie_angle"
						required={register === "store"}
						{...inputProps}
						options={headLieAngleOptions}
					/>
				</>
			)}

			<ClubFormHeaderText label="Shaft" showTip={onlyShaft} onlyShaft />

			<ShaftBrandInput
				name="shaft.brand"
				{...inputProps}
				disabled={isStockShaft ? false : isEdit || inputProps.disabled}
				options={brandOptions}
			/>

			<ShaftModelInput
				name="shaft.model"
				{...inputProps}
				disabled={isStockShaft ? false : isEdit || inputProps.disabled}
				options={shaftModelOptions}
			/>

			<ShaftMaterialInput
				name="shaft.material"
				required={register === "store"}
				{...inputProps}
				options={shaftMaterialOptions}
			/>

			{onlyShaft && (
				<>
					<PutterShaftFlexInput name="shaft.flex" {...inputProps} />

					<ShaftGripSizeInput
						name="shaft.grip_size"
						{...inputProps}
						options={GRIP_SIZE(t)}
					/>
				</>
			)}

			{!onlyShaft && (
				<>
					<ShaftWeightInput
						name="shaft.weight"
						required={register === "store"}
						{...inputProps}
						options={shaftWeightOptions}
					/>

					<ShaftLengthInput
						name="shaft.length"
						required={register === "store"}
						{...inputProps}
						options={shaftLengthOptions}
					/>
				</>
			)}
		</>
	)
}

export default PutterClubForm
