import FormLoadingSkeleton from "components/FormLoadingSkeleton"
import { DRIVER, GRIP_SIZE, KICKPOINT } from "constant"
import React from "react"
import { useTranslation } from "react-i18next"
import { clubsApi } from "services/modules"
import validApi from "services/modules/valid"
import { useUser } from "stores/slice/user"
import {
	appendClubAdditionalOptions,
	arrayToSelectOptions,
	objectToSelectOptions,
} from "utils/helpers"

import { ClubFormHeaderText } from "./components"
import {
	HeadBrandInput,
	HeadLieAngleInput,
	HeadLoftInput,
	HeadModelInput,
	ShaftBrandInput,
	ShaftFlexInput,
	ShaftGripSizeInput,
	ShaftKickPointInput,
	ShaftLengthInput,
	ShaftMaterialInput,
	ShaftModelInput,
	ShaftTorqueInput,
	ShaftWeightInput,
	SwingWeightInput,
	TotalWeightInput,
} from "./components/ClubFormInputs"

/**
 * @param {Object} params
 * @param {import("react-hook-form").UseFormReturn} params.hookFormProps - react-hook-form
 * @param {boolean} params.isEdit
 * @param {boolean} params.isStockShaft
 * @param {"customer" | "store"} params.register
 * @param {boolean} params.onlyShaft
 * @returns  {JSX.Element}
 */
const DriverClubForm = ({
	hookFormProps,
	isEdit,
	register = "store",
	isStockShaft,
	readOnly = false,
	onlyShaft = false,
}) => {
	const { t } = useTranslation()
	const user = useUser()

	const {
		formState: { errors },
		control,
		watch,
	} = hookFormProps

	const inputProps = {
		t,
		methods: hookFormProps,
		control,
		errors,
		disabled: (user.isStaff && register === "store") || readOnly,
	}

	const { data, isFetching } = validApi.useGetClubOptionsQuery({ clubType: DRIVER })
	const { data: brands, isFetching: brandFetching } = clubsApi.useGetAllBrandsQuery()
	const { data: shaftModels, isFetching: shaftModelFetching } = clubsApi.useGetClubModelsQuery({
		clubType: "Driver",
		filter: "shaft",
	})
	const { data: headModels, isFetching: headModelFetching } = clubsApi.useGetClubModelsQuery({
		clubType: "Driver",
		filter: "head",
	})

	const { head, shaft } = data?.data || {}

	const values = watch()

	const brandOptions = brands?.data?.map(({ name }) => ({ label: name, value: name }))
	const headModelOptions = headModels?.data?.map(({ name }) => ({ label: name, value: name }))
	const shaftModelOptions = shaftModels?.data?.map(({ name }) => ({ label: name, value: name }))
	const shaftMaterialOptions = shaft?.material?.map((value) => ({ value, label: t(value) }))

	const headLoftOptions = head?.loft_angles && arrayToSelectOptions(head.loft_angles)

	const headLieAngleOptions =
		head?.lie_angles && appendClubAdditionalOptions(t, arrayToSelectOptions(head?.lie_angles))

	const shaftLengthOptions = shaft?.lengths && arrayToSelectOptions(shaft.lengths)

	const shaftFlexOptions = shaft?.shaft_flexes && objectToSelectOptions(shaft.shaft_flexes)

	const swingWeightOptions = arrayToSelectOptions(shaft?.swing_weights)

	const shaftWeightOptions = () => {
		if (values.shaft.flex) {
			const weights = shaft?.shaft_flexes?.[values.shaft.flex]?.weights
			if (weights) {
				return arrayToSelectOptions(weights)
			}
		}
	}

	if (isFetching || brandFetching || shaftModelFetching || headModelFetching) {
		return <FormLoadingSkeleton marginTop={5} />
	}

	return (
		<>
			{!onlyShaft && (
				<>
					<TotalWeightInput
						name="total_weight"
						{...inputProps}
						required={register === "store"}
						options={appendClubAdditionalOptions(t, [])}
					/>
					<SwingWeightInput
						name="swing_weight"
						{...inputProps}
						required={register === "store"}
						options={swingWeightOptions}
					/>
					<ClubFormHeaderText label="Head" />
					<HeadBrandInput
						name="head.brand"
						{...inputProps}
						options={brandOptions}
						disabled={isEdit || inputProps.disabled}
					/>
					<HeadModelInput
						name="head.model"
						{...inputProps}
						disabled={isEdit || inputProps.disabled}
						options={headModelOptions}
					/>
					<HeadLoftInput
						name="head.loft"
						required={register === "store"}
						{...inputProps}
						options={headLoftOptions}
					/>
					<HeadLieAngleInput
						name="head.lie_angle"
						required={register === "store"}
						{...inputProps}
						options={headLieAngleOptions}
					/>
				</>
			)}

			<ClubFormHeaderText label="Shaft" showTip={onlyShaft} onlyShaft />

			<ShaftBrandInput
				name="shaft.brand"
				{...inputProps}
				options={brandOptions}
				disabled={isStockShaft ? false : isEdit || inputProps.disabled}
			/>

			<ShaftModelInput
				name="shaft.model"
				{...inputProps}
				disabled={isStockShaft ? false : isEdit || inputProps.disabled}
				options={shaftModelOptions}
			/>

			{onlyShaft && (
				<ShaftMaterialInput
					name="shaft.material"
					required={register === "store"}
					{...inputProps}
					options={shaftMaterialOptions}
				/>
			)}

			<ShaftFlexInput
				name="shaft.flex"
				required={register === "store"}
				{...inputProps}
				options={shaftFlexOptions}
			/>

			<ShaftWeightInput
				name="shaft.weight"
				required={register === "store"}
				{...inputProps}
				options={shaftWeightOptions()}
			/>

			{onlyShaft && (
				<>
					<ShaftKickPointInput
						name="shaft.kick_point"
						{...inputProps}
						options={KICKPOINT(t)}
					/>

					<ShaftTorqueInput name="shaft.torque" {...inputProps} />

					<ShaftGripSizeInput
						name="shaft.grip_size"
						{...inputProps}
						options={GRIP_SIZE(t)}
					/>
				</>
			)}

			{!onlyShaft && (
				<ShaftLengthInput
					name="shaft.length"
					required={register === "store"}
					{...inputProps}
					options={shaftLengthOptions}
				/>
			)}
		</>
	)
}

export default DriverClubForm
