import { change404Error } from "stores/slice/app"
import { ROUTE_DEFAULT } from "urls"

import ErrorComponent from "./common"

export default function Page404() {
	return (
		<ErrorComponent
			goto={ROUTE_DEFAULT}
			confirm={() => change404Error(false)}
			subtitle="The page you are looking for cannot be found."
		/>
	)
}

export const Component = Page404
