import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { changeTopbarLoading } from "stores/slice/app"

/**
 * display topbar loading when fetching data
 **  pass isFetching state from rtk-query
 * @param {boolean} isLoading
 */
function useTopbarLoading(isLoading) {
	const dispatch = useDispatch()

	useEffect(() => {
		isLoading && dispatch(changeTopbarLoading(true))
		return () => dispatch(changeTopbarLoading(false))
	}, [isLoading])
}

export default useTopbarLoading
