import { Box, Typography, useTheme } from "@mui/material"
import { CrossOutlineSvg, DotSvg, TickSvg } from "assets/svgs"
import { FORM_VALIDATION_STATES } from "constant"
import PropTypes from "prop-types"

const SmartValidationRules = ({ testOptions, fieldErrors, fieldState }) => {
	const {
		palette: { black, error, primary },
	} = useTheme()

	const states = {
		[FORM_VALIDATION_STATES.UNDIRTY]: {
			color: black.main,
			icon: <DotSvg fill={black.main} />,
		},
		[FORM_VALIDATION_STATES.ERROR]: {
			color: error.main,
			icon: <CrossOutlineSvg stroke={error.main} />,
		},
		[FORM_VALIDATION_STATES.SUCCESS]: {
			color: primary.main,
			icon: <TickSvg stroke={primary.main} />,
		},
	}

	return testOptions.map((ruleObj) => {
		const currentState = !fieldState.isDirty
			? FORM_VALIDATION_STATES.UNDIRTY
			: fieldErrors?.types[ruleObj.name]
				? FORM_VALIDATION_STATES.ERROR
				: FORM_VALIDATION_STATES.SUCCESS

		return (
			<Box
				key={ruleObj.name}
				mt={1}
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-start",
					color: states[currentState].color,
				}}>
				{states[currentState].icon}

				<Typography
					sx={{
						flexBasis: 0,
						flexGrow: 1,
						color: states[currentState].color,
					}}
					pl={0.5} // the gap between icon and text is 10px but since the svg natively has extra paddings, only 0.5 is used instead of 1 here
					variant="validationMessage">
					{ruleObj.message}
				</Typography>
			</Box>
		)
	})
}

export default SmartValidationRules

SmartValidationRules.propTypes = {
	validationRules: PropTypes.arrayOf(PropTypes.object),
	fieldErrors: PropTypes.object,
	fieldState: PropTypes.object.isRequired,
}

// NOTE
// ====
// A component to show state-detective (such as error / success / undirty) validation rule list which changes its style proactively based on what the user types
