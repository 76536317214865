import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Typography } from "@mui/material"
import {
	AppTabs,
	AutoLoadingButton,
	BasicLayout,
	ControlledAppTextField,
	FormSheet,
	SmartValidationRules,
} from "components"
import { FIND_CREDENTIALS_TYPES, UNAUTHORIZED_STATUSES } from "constant"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useResetPasswordMutation } from "services/modules/auth"
import { useVerifyTokenMutation } from "services/modules/common"
import { ROUTE_FIND_CREDENTIALS, ROUTE_LOGIN } from "urls"
import {
	extractFieldTestOptionsFromSchema,
	handleApiErrors,
	isErrorExcludingRequiredCheck,
} from "utils/helpers"
import { resetPasswordSchema } from "validations"

// states
const INVALID_LINK = "invalidLink"
const RESET_PASSWORD = "resetPassword"
const RESET_DONE = "resetDone"

const NotificationMessage = ({ message }) => (
	<Typography variant="body2" sx={{ color: "grey.700" }}>
		{message}
	</Typography>
)

const ResetPassword = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [queryParameters] = useSearchParams()
	const token = queryParameters.get("token")

	const [isResetDone, setIsResetDone] = useState(false)
	const [isInvalidLink, setIsInvalidLink] = useState(false)
	const [isNewPwFocusedForOnce, setIsNewPwFocusedForOnce] = useState(false)
	const [isConfirmPwFocusedForOnce, setIsConfirmPwFocusedForOnce] = useState(false)

	const { setError, control, formState, handleSubmit, getFieldState, resetField, watch } =
		useForm({
			resolver: yupResolver(resetPasswordSchema(t)),
			criteriaMode: "all",
			mode: "all",
		})

	const [verifyToken] = useVerifyTokenMutation()
	const [resetPassword] = useResetPasswordMutation()

	const errors = formState.errors
	const newPasswordState = getFieldState("newPassword")
	const confirmNewPasswordState = getFieldState("confirmNewPassword")
	const [watchNewPassword, watchConfirmNewPassword] = watch(["newPassword", "confirmNewPassword"])

	const handleResetPasswordPress = handleSubmit(async (values) => {
		try {
			await resetPassword({
				password: values.newPassword,
				token,
			}).unwrap()

			setIsResetDone(true)
		} catch (err) {
			if (UNAUTHORIZED_STATUSES.includes(err.response?.status)) {
				setIsInvalidLink(true)
			} else {
				handleApiErrors(err, dispatch, setError)
			}
		}
	})

	const STATE_BASED_UIS = {
		[INVALID_LINK]: {
			id: INVALID_LINK,
			tabItems: [{ id: INVALID_LINK, name: "Reset link expired" }],
			Content: (
				<NotificationMessage message={t("Link expired. Please retry password recovery.")} />
			),
			submitButton: {
				label: t("Retry password reset"),
				onClick: () =>
					navigate(ROUTE_FIND_CREDENTIALS, {
						state: {
							type: FIND_CREDENTIALS_TYPES.FIND_PASSWORD,
						},
					}),
			},
		},

		[RESET_PASSWORD]: {
			id: RESET_PASSWORD,
			tabItems: [{ id: RESET_PASSWORD, name: "Password reset" }],
			Content: (
				<>
					<Box mb={2}>
						<ControlledAppTextField
							name="newPassword"
							type="password"
							placeholder={t("Enter new password")}
							error={isErrorExcludingRequiredCheck(errors.newPassword)}
							control={control}
							onFocus={() => !isNewPwFocusedForOnce && setIsNewPwFocusedForOnce(true)}
							onBlur={() => {
								if (!watchNewPassword) {
									resetField("newPassword")
									setTimeout(() => {
										setIsNewPwFocusedForOnce(false)
									}, 200)
								}
							}}
							onClear={() => resetField("newPassword")}
							size="normal"
						/>

						{isNewPwFocusedForOnce && (
							<SmartValidationRules
								testOptions={
									extractFieldTestOptionsFromSchema(
										resetPasswordSchema(t),
										"newPassword"
									).slice(1) // exclude 'required' check option according to figma
								}
								fieldErrors={errors.newPassword}
								fieldState={newPasswordState}
							/>
						)}
					</Box>

					<Box mb={2}>
						<ControlledAppTextField
							name="confirmNewPassword"
							type="password"
							placeholder={t("Re-enter new password")}
							error={isErrorExcludingRequiredCheck(errors.confirmNewPassword)}
							control={control}
							onFocus={() =>
								!isConfirmPwFocusedForOnce && setIsConfirmPwFocusedForOnce(true)
							}
							onBlur={() => {
								if (!watchConfirmNewPassword) {
									resetField("confirmNewPassword")
									setIsConfirmPwFocusedForOnce(false)
								}
							}}
							onClear={() => resetField("confirmNewPassword")}
							size="normal"
						/>

						{isConfirmPwFocusedForOnce && (
							<SmartValidationRules
								testOptions={
									extractFieldTestOptionsFromSchema(
										resetPasswordSchema(t),
										"confirmNewPassword"
									).slice(1) // exclude 'required' check option according to figma
								}
								fieldErrors={errors.confirmNewPassword}
								fieldState={confirmNewPasswordState}
							/>
						)}
					</Box>
				</>
			),
			submitButton: {
				label: t("Confirm"),
				onClick: handleResetPasswordPress,
			},
		},

		[RESET_DONE]: {
			id: RESET_DONE,
			tabItems: [{ id: RESET_DONE, name: "Password reset" }],
			Content: (
				<NotificationMessage
					message={t(
						"The VICX admin password has been successfully reset. Login with the newly set password."
					)}
				/>
			),
			submitButton: {
				label: t("Login"),
				onClick: () => navigate(ROUTE_LOGIN),
			},
		},
	}

	const currentStateUI = isInvalidLink
		? STATE_BASED_UIS[INVALID_LINK]
		: isResetDone
			? STATE_BASED_UIS[RESET_DONE]
			: STATE_BASED_UIS[RESET_PASSWORD]

	useEffect(() => {
		;(async () => {
			try {
				await verifyToken(token).unwrap()
			} catch (error) {
				setIsInvalidLink(true)
			}
		})()
	}, [])

	return (
		<BasicLayout height="auto">
			<FormSheet>
				<AppTabs tabItems={currentStateUI.tabItems} isReadOnly />
				<Box p={4}>
					{currentStateUI.Content}

					<Box mt={6}>
						<AutoLoadingButton
							disabled={
								currentStateUI.id === RESET_PASSWORD &&
								(!formState.isDirty || !formState.isValid)
							}
							size="normal"
							onClick={currentStateUI.submitButton.onClick}
							variant="contained"
							color="primary"
							fullWidth>
							{currentStateUI.submitButton.label}
						</AutoLoadingButton>
					</Box>
				</Box>
			</FormSheet>
		</BasicLayout>
	)
}

export default ResetPassword
