import styled from "@emotion/styled"
import { Link } from "@mui/material"
import PropTypes from "prop-types"
import React from "react"

const StyledLink = styled(Link)(({ theme: { palette } }) => ({
	transition: "all ease-in-out 0.3s",
	cursor: "pointer",
	"&:hover": {
		color: palette.primary.main,
	},
}))

const AppLink = ({ label, onClick }) => {
	return (
		<StyledLink onClick={onClick} variant="body3">
			{label}
		</StyledLink>
	)
}

AppLink.propTypes = {
	label: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
}

export default AppLink
