import borders from "assets/theme/base/borders"
import palette from "assets/theme/base/palette"
import typography from "assets/theme/base/typography"
import pxToRem from "assets/theme/functions/pxToRem"

const { fontWeightBold, size } = typography
const { borderRadius } = borders
const { grey, primary, secondary, success, error, disabled, white, green } = palette

export default {
	display: "inline-flex",
	justifyContent: "center",
	alignItems: "center",
	fontSize: size.sm,
	fontWeight: fontWeightBold,
	borderRadius: borderRadius.lg,
	padding: `${pxToRem(6.302)} ${pxToRem(16.604)}`,
	lineHeight: 1.6,
	textAlign: "center",
	textTransform: "initial",
	userSelect: "none",
	backgroundSize: "150% !important",
	backgroundPositionX: "25% !important",
	transition: "all ease-in-out 0.3s",
	boxShadow: "none",

	"&:hover": {
		boxShadow: "none",
	},

	"&:disabled": {
		pointerEvent: "none",
		opacity: 0.65,
		color: white.main,
	},

	"& .material-icons": {
		fontSize: pxToRem(15),
		marginTop: pxToRem(-2),
	},
}

export const SIZE_BASED_SPECS = {
	small: {
		minHeight: pxToRem(30),
		padding: `${pxToRem(6)} ${pxToRem(12)}`,
		fontSize: size.sm,
	},

	normal: {
		minHeight: pxToRem(50),
		padding: `${pxToRem(10)} ${pxToRem(20)}`,
		fontSize: size.md,
	},

	large: {
		minHeight: pxToRem(60),
		padding: `${pxToRem(12)} ${pxToRem(24)}`,
		fontSize: size.lg,
	},
}

// to duplicate variants based on the button types (such as outlined / contained / text)
// extend more color variants here
export const COLOR_VARIANTS = [
	{
		id: "primary",
		color: {
			main: primary.main,
			focus: primary.focus,
		},
	},
	{
		id: "secondary",
		color: {
			main: secondary.main,
			focus: secondary.focus,
		},
	},
	{
		id: "grey",
		color: {
			main: grey[700],
			focus: grey[700],
		},
	},
	{
		id: "success",
		color: {
			main: success.main,
			focus: success.focus,
		},
	},
	{
		id: "error",
		color: {
			main: error.main,
			focus: error.focus,
		},
	},
	{
		id: "disabled",
		color: {
			main: disabled.main,
			focus: disabled.focus,
		},
	},
	{
		id: "green",
		color: {
			main: green.main,
			focus: green.focus,
		},
	},
]
