import { createSlice } from "@reduxjs/toolkit"
import { ENGLISH_LANGUAGE } from "constant"
import { DEFAULT_LANG } from "i18n"
import { useSelector } from "react-redux"
import { APP_SLICE } from "stores/slicekeys"

const initialState = {
	language: DEFAULT_LANG,
	topbar_loading: false,
	saved_id: "",
	is_5xx_error: false,
	is_404_error: false,
}

const appSlice = createSlice({
	name: APP_SLICE,
	initialState,
	reducers: {
		changeLanguage(state, action) {
			state.language = action.payload
		},

		changeTopbarLoading(state, action) {
			state.topbar_loading = action.payload
		},

		changeSavedId(state, action) {
			state.saved_id = action.payload
		},

		change5xxError(state, action) {
			state.is_5xx_error = action.payload
		},

		change404Error(state, action) {
			state.is_404_error = action.payload
		},
	},
})

export const {
	changeLanguage,
	changeTopbarLoading,
	changeSavedId,
	change5xxError,
	change404Error,
} = appSlice.actions

export const useTopbarLoadingSelector = () =>
	useSelector((state) => state[APP_SLICE].topbar_loading)

export const useSavedId = () => useSelector((state) => state[APP_SLICE].saved_id)

export const use5xxError = () => useSelector((state) => state[APP_SLICE].is_5xx_error)

export const use404Error = () => useSelector((state) => state[APP_SLICE].is_404_error)

export const useLanguage = () => useSelector((state) => state[APP_SLICE].language)

export const useIsEnglish = () => useLanguage() === ENGLISH_LANGUAGE

export default appSlice.reducer
