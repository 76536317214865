import { Box, Card, useTheme } from "@mui/material"
import pxToRem from "assets/theme/functions/pxToRem"
import PropTypes from "prop-types"

const MAX_FORM_WIDTH = 410
const SIDE_IMAGE_TO_FORM_WIDTH_RATIO = 0.4

const AuthCard = ({ children, withSideImage, sideImage, ...rest }) => {
	const {
		borders: {
			borderRadius: { xxxl: BORDER_RADIUS },
		},
	} = useTheme()

	return (
		<Box
			sx={{
				width: "100%",
				maxWidth: `${pxToRem(
					MAX_FORM_WIDTH +
						(withSideImage
							? Math.floor(MAX_FORM_WIDTH * SIDE_IMAGE_TO_FORM_WIDTH_RATIO)
							: 0) // enhance max width if side image is included
				)} !important`,
			}}>
			<Card
				sx={{
					borderRadius: BORDER_RADIUS,
					width: "100%",
					diplay: "flex",
					flexDirection: "row",
					minHeight: "390px",
				}}
				{...rest}>
				<Box
					sx={{
						flexBasis: pxToRem(MAX_FORM_WIDTH),
						flexGrow: {
							lg: 0,
							xs: 1,
						},
					}}>
					{children}
				</Box>

				{withSideImage && (
					<Card
						sx={{
							display: {
								lg: "block",
								xs: "none",
							},
							flexBasis: 0,
							borderRadius: BORDER_RADIUS,
							flexGrow: 1,
						}}>
						<img
							style={{
								height: "100%",
								width: "100%",
								objectPosition: "center",
								objectFit: "cover",
								borderRadius: BORDER_RADIUS,
							}}
							{...sideImage}
							loading="lazy"
						/>
					</Card>
				)}
			</Card>
		</Box>
	)
}

AuthCard.defaultProps = {
	withSideImage: false,
}

AuthCard.propTypes = {
	children: PropTypes.node.isRequired,
	withSideImage: PropTypes.bool,
	sideImage: (props, propName, componentName) => {
		// validate to include sideImage if withSideImage is true

		if (props.withSideImage && !props[propName]) {
			return new Error(
				`The prop '${propName}' is required when 'withSideImage' is true in '${componentName}'.`
			)
		}

		// validate to be an object with 'src' and 'alt' properties
		if (
			props[propName] &&
			(typeof props[propName].src !== "string" || typeof props[propName].alt !== "string")
		) {
			return new Error(
				`Invalid '${propName}' prop in '${componentName}'. It should be an object with 'src' and 'alt' properties.`
			)
		}
	},
}

export default AuthCard
