import { Box, Snackbar, Typography, useTheme } from "@mui/material"
import pxToRem from "assets/theme/functions/pxToRem"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { closeSnackbar } from "stores/slice/snackbar"

const FormSnackbar = () => {
	const {
		palette: { baseColors },
	} = useTheme()
	const dispatch = useDispatch()
	const state = useSelector((state) => state.snackbar)
	const onClose = () => dispatch(closeSnackbar())

	return (
		<Snackbar
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
			open={state.open}
			onClose={onClose}
			autoHideDuration={state.delay}>
			<Box
				bgcolor={state.severity === "error" ? "error.main" : "success.main"}
				p={2}
				maxWidth={360}
				minWidth={360}
				sx={({ functions: { pxToRem } }) => ({ borderRadius: pxToRem(8) })}>
				<Typography
					textAlign="center"
					fontSize={pxToRem(12)}
					fontWeight={500}
					color={baseColors.WHITE}>
					{state.message}
				</Typography>
			</Box>
		</Snackbar>
	)
}

export default FormSnackbar
