import { ROUTE_MARKETING_CONSENT_POLICY, ROUTE_PRIVACY_POLICY, ROUTE_TOS } from "urls"

import DefaultBallImg from "./assets/images/default_ball.png"
import DefaultDriverImg from "./assets/images/default_driver.png"
import DefaultGlovesImg from "./assets/images/default_gloves.png"
import DefaultHybridImg from "./assets/images/default_hybrid.png"
import DefaultIronSetImg from "./assets/images/default_iron_set.png"
import DefaultPutterImg from "./assets/images/default_putter.png"
import DefaultShaftImg from "./assets/images/default_shaft.png"
import DefaultWedgeImg from "./assets/images/default_wedge.png"
import DefaultWoodImg from "./assets/images/default_wood.png"

export const KOREA_LANGUAGE = "ko"
export const ENGLISH_LANGUAGE = "en"

export const STANDARD_FONT_SIZE = 16

export const GENDER = (t) => [
	{ label: t("Female"), value: "f" },
	{ label: t("Male"), value: "m" },
]

// this object should be in sync with the reservation color object structure in palette file
export const RESERVATION_STATUSES = {
	// WAITING: { name: "Waiting", value: "WAITING", shortName: "WT" },
	RESERVED: { name: "Reservation", value: "RESERVED", shortName: "Res" },
	IN_PROGRESS: { name: "In-Progress", value: "IN_PROGRESS", shortName: "In-pro" },
	COMPLETED: { name: "Complete", value: "COMPLETED", shortName: "Com" },
	NOSHOW: { name: "No-Show", value: "NOSHOW", shortName: "No" },
	CANCELLED: { name: "Cancel_long", value: "CANCELLED", shortName: "Can" },
}

export const DEFAULT_ENABLED_RESERVATION_FILTERS = Object.keys(RESERVATION_STATUSES).filter(
	(i) => i !== RESERVATION_STATUSES.CANCELLED.value
)

export const ICON_NAMES = {
	DELETE: "delete",
	COPY: "copy",
	EDIT: "edit",
	INFO: "info",
	DASHBOARD: "dashboard",
	PERSON: "person",
	SETTINGS: "settings",
	HELP: "help",
	NOTIFICATIONS: "notifications",
	CALENDAR: "calendar_today_icon",
	LOGOUT: "logout",
	SEARCH: "search",
	RESTART: "restart",
	BACK: "back",
	MAIL: "mail",
	CAMERA: "camera_alt",
	VISIBILITY: "visibility",
	VISIBILITY_OFF: "visibility_off",
	STORE: "store",
	GROUP: "group",
	MENU: "menu",
	MENU_OPEN: "menu_open",
	KEY: "key",
	DOUGHNUT_LARGE: "donut_large",
	ARROW_FORWARD_IOS: "arrow_forward_ios",
	CLOSE: "close",
}

export const FIND_CREDENTIALS_TYPES = {
	FIND_ID: "findId",
	FIND_PASSWORD: "findPassword",
}

export const COMMON_REGEXES = {
	KR_LANG: /[ㄱ-ㅎㅏ-ㅣ가-힣]/,
	// ** allowed to start with any number instead of 010 - KR_PHONE_FORMAT
	// ** only need to check 3-4-4 format
	KR_PHONE_FORMAT: /^[0-9]{3}[0-9]{4}[0-9]{4}$/,
	EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
}

export const INFORMATION_MANAGEMENT = {
	STORE: "storeInformationManagement",
	SYSTEM: "systemInformationManagement",
}

export const OTP_INPUT_LENGTH = 6

export const TOPNAV_HEIGHT_IN_PX = 110

export const SIDEBAR_WIDTHS_IN_PX = {
	SMALL: 80,
	NORMAL: 260,
}

export const ANALYZER = {
	TRACKMAN: {
		id: 8,
		name: "Trackman",
	},
	GC_QUAD: {
		id: 9,
		name: "GC Quad",
	},
	FLIGHTSCOPE: {
		id: 7,
		name: "Flightscope",
	},
	MANUAL: {
		id: 19,
		name: "Enter directly",
	},
}

export const STORE_ROLES = {
	ADMIN: { id: "1", value: "store_admin", label: "Manager" },
	STAFF: { id: "2", value: "store_staff", label: "Staff" },
}

export const ANALYSIS = {
	TRACKMAN: "Trackman",
	GC_QUAD: "GCQuad",
	FLIGHTSCOPE: "Flightscope",
}

export const STAFF_STATUS = {
	// values are lowercased to sync with the backend
	ACTIVE: "ACTIVE",
	LEAVE: "LEAVE",
	PENDING: "PENDING",
}

export const UNAUTHORIZED_STATUSES = [401, 403]
export const INCORRECT_CREDENTIALS_STATUSES = [400, 403, 422]
export const NOT_FOUND_STATUSES = [404]

export const FORM_VALIDATION_STATES = {
	UNDIRTY: "undirty",
	ERROR: "error",
	SUCCESS: "success",
}

export const YUP_REQUIRED_CHECK_TEST_TYPES = ["required", "optionality"] // test type names for 'required' check used by yup

export const MEMBER_STATUS_ALL = "ALL"
export const MEMBER_STATUS_ACTIVE = "ACTIVE"
export const MEMBER_STATUS_INACTIVE = "INACTIVE"
export const MEMBER_STATUS_WITHDRAWAL = "WITHDRAWAL"

export const MEMBER_STATUS = (t) => [
	{ label: t("All"), value: MEMBER_STATUS_ALL },
	{ label: t("Active"), value: MEMBER_STATUS_ACTIVE },
	{ label: t("Inactive"), value: MEMBER_STATUS_INACTIVE },
	{ label: t("Withdrawal"), value: MEMBER_STATUS_WITHDRAWAL },
]

export const IS_MEMBER = "member"
export const IS_NOT_MEMBER = "non-member"
export const NEW_MEMBER_STATUS = (t) => [
	// this is temporary
	{ label: t("Member"), value: "member" },
	{ label: t("Non-member"), value: "non-member" },
]

export const LIMITS = (t) =>
	[10, 20, 50, 100].map((i) => ({
		label: t("eachUnit", { unit: i }),
		value: i,
	}))

export const PERIOD_CUSTOM = "custom"

export const PERIOD = (t) => [
	{
		label: t("Total period"),
		value: "all",
	},
	{
		label: t("Today"),
		value: "today",
	},
	{
		label: t("1 Week"),
		value: "1week",
	},
	{
		label: t("3 Months"),
		value: "3months",
	},
	{
		label: t("Enter date"),
		value: PERIOD_CUSTOM,
	},
]

export const DELIVERY_INFO = (t) => [
	{
		value: "배송 전에 미리 연락바랍니다.",
		label: t("Please contact in advance before delivery."),
	},
	{
		value: "부재시 경비실에 맡겨주세요.",
		label: t("Please leave the package in the security office."),
	},
	{
		value: "부재시 전화 주시거나 문자 남겨 주세요.",
		label: t("Please leave a message or call."),
	},
]

export const RECEIPT_METHOD = (t) => [
	{ value: "parcel", label: t("Parcel") },
	{ value: "store", label: t("Store") },
]

export const CRITERIA_REGISTRATION_DATE = "registration_date"
export const CRITERIA = (t) => [{ label: t("Registered date"), value: CRITERIA_REGISTRATION_DATE }]

export const DEFAULT_FILTER_PERIOD = "all"
export const CUSTOMER_LIST_FILTER_OPTIONS_DEFAULT_VALUES = {
	date_filter_type: "registration_date",
	date_filter_period: DEFAULT_FILTER_PERIOD,
	date_filter_start: "",
	date_filter_end: "",
	status_filter: MEMBER_STATUS_ALL,
	per_page: 10,
	manager_id: "",
	order: "DESC",
}

export const CUSTOMER_DIALOGS_TYPES = {
	COMPLETE: "complete",
	PARTIAL: "partial",
}

export const SPECIAL_INPUT_TYPES = {
	TEL: "tel",
	DOB: "dob",
	HEIGHT: "height",
	WEIGHT: "weight",
	SIZE: "size",
	LOFT: "loft",
	LIE_ANGLE: "lie_angle",
	BOUNCE_ANGLE: "bounce_angle",
	CLUB_WEIGHT: "club_weight",
	CLUB_SWING_WEIGHT: "club_swing_weight",
	CLUB_LENGTH: "club_length",
	FLEX: "flex",
	NUMBER: "numbers",
}

export const ERROR_CODES = {
	IS_REGISTERED: 10024,
	IS_REGISTERED_IN_OTHER_STORE: 10025,
}

export const ERROR_PROP = [
	{
		code: ERROR_CODES.IS_REGISTERED,
		title: "New member registration failed",
		confirmBtnLabel: "New member registration",
	},
	{
		code: ERROR_CODES.IS_REGISTERED_IN_OTHER_STORE,
		title: "New member registration failed",
		confirmBtnLabel: "Link member",
	},
]

// date formats compatible with dateFns lib
export const DATE_DEFAULT_FORMAT = "yyyy-MM-dd"
export const STRING_DATE_FORMAT = "yyyyMMdd"
export const DATE_FORMATS = {
	// reservation
	BACKEND_RESERVATION_DF: STRING_DATE_FORMAT,
	SHOWN_RESERVATION_DF_ENG: "MMMM do (EE)",
	SHOWN_RESERVATION_DF: "MMMM do (EE)",
	BACKEND_RESERVATION_TF: "HHmm",
	SHOWN_RESERVATION_TF_EN: "h:mm",
	SHOWN_RESERVATION_TF_KR: "h:mm",
	SHOWN_RESERVATION_DATA_EN: "h:mm a",
	SHOWN_RESERVATION_DATA_KR: "a h:mm",
	RESERVATION_SUCCESS_DF: "PPP",
	SHOWN_FITTING_TF: "k:mm",
}

export const ABOUT = {
	[ROUTE_TOS]: "Terms and Conditions",
	[ROUTE_PRIVACY_POLICY]: "Privacy Policy",
	[ROUTE_MARKETING_CONSENT_POLICY]: "Consent to Receive Promotional Information",
}

export const searchClubUsageDescription = (t) => [
	t("Search for heads and shafts"),
	t("Place the head and shaft in the [shop basket]."),
	t("Click the [Club Registration] button to complete the club registration in the [Club List]."),
	t(
		"If you don't have the brand and model you're looking for, you can register the club through the [Register Club Direct] button."
	),
]

export const equipmentSearchDescription = (t) => [
	t("Search for equipment."),
	t("Add the searched equipment to the [Cart]."),
	t("Click [Register equipment] to complete the registration in the [Equipment] page."),
	t("When registering a club, combine the head and shaft before clicking [Register equipment]."),
]

export const WOOD = "Wood"
export const WEDGE = "Wedge"
export const IRON = "Iron"
export const HYBRID = "Hybrid"
export const DRIVER = "Driver"
export const PUTTER = "Putter"

export const CLUB_TYPES = (t) => [
	{ label: t("Driver"), value: DRIVER },
	{ label: t("Wood"), value: WOOD },
	{ label: t("Hybrid"), value: HYBRID },
	{ label: t("Iron"), value: IRON },
	{ label: t("Wedge"), value: WEDGE },
	{ label: t("Putter"), value: PUTTER },
]

export const CLUB_COMPONENT_HEAD = "head"
export const CLUB_COMPONENT_SHAFT = "shaft"
export const CLUB_COMPONENT = "club"

export const CLUB_COMPONENTS = (t, isShaft = true, isClub = false) => {
	const options = [{ label: t("Head"), value: CLUB_COMPONENT_HEAD }]
	isShaft && options.push({ label: t("Shaft"), value: CLUB_COMPONENT_SHAFT })
	isClub && options.push({ label: t("Club"), value: CLUB_COMPONENT })
	return options
}

export const EQUIPMENT_BALL = "balls"
export const EQUIPMENT_CLUB = "clubs"
export const EQUIPMENT_GLOVES = "gloves"
export const EQUIPMENT_HEAD = "head"
export const EQUIPMENT_SHAFT = "shaft"

export const EQUIPMENT_LIST = (t) => [{ label: t("Club"), value: EQUIPMENT_CLUB }]

export const EQUIPMENT_LIST_NO_HEAD_SHAFT = (t) => [
	...EQUIPMENT_LIST(t),
	{ label: t("Ball"), value: EQUIPMENT_BALL },
	{ label: t("Glove"), value: EQUIPMENT_GLOVES },
]

export const EQUIPMENT_LIST_ALL = (t) => [
	...EQUIPMENT_LIST(t),
	{ label: t("Head"), value: EQUIPMENT_HEAD },
	{ label: t("Shaft"), value: EQUIPMENT_SHAFT },
	...EQUIPMENT_LIST_NO_HEAD_SHAFT(t).filter((item) => item.value !== EQUIPMENT_CLUB),
]

export const GENDER_MALE_VALUE = "m"
export const GENDER_FEMALE_VALUE = "f"

export const GENDER_LIST = (t) => [
	{ label: t("All"), value: null },
	{ label: t("Male"), value: GENDER_MALE_VALUE },
	{ label: t("Female"), value: GENDER_FEMALE_VALUE },
]

export const HAND_DIRECTION = (t) => [
	{ label: t("Right handed"), value: "r" },
	{ label: t("Left handed"), value: "l" },
]

// NOTE - Search Modal tag id
// scrollTarget props in react-infinite-scroll-component required in order to work with nested scroll
// <ClubCombinationBox> component used this id to show back to top button
export const searchModalDialogId = "searchModalDialog"

export const DIRECT_INPUT_VALUE = "Enter directly"

export const NOT_SURE_VALUE = "Not Sure"
export const NOT_SURE_LABEL = "I'm not sure."

export const CS_TYPES = [
	{ label: "Account", value: "account" },
	{ label: "Reservation", value: "reservation" },
	{ label: "Fitting", value: "fitting" },
	{ label: "Equipment", value: "equipment" },
	{ label: "Delivery", value: "delivery" },
	{ label: "Other", value: "other" },
]

export const STANDARD_FITTER = (t) => [{ label: t("Fitting date"), value: "reservation_date" }]
export const DEFAULT_STANDARD_FILTER = "reservation_date"

export const DEFAULT_PER_PAGE = 10

export const CLUB_SELECTION_TYPES = {
	MY_CLUB: "My club",
	STORE_CLUB: "Store club",
	FITTING_CLUB: "Fitting club",
}

export const EQUIPMENT_KEY = {
	[EQUIPMENT_BALL]: "ball",
	[EQUIPMENT_GLOVES]: "glove",
}

export const FITTING_LIST_FILTER_OPTIONS_DEFAULT_VALUES = {
	date_filter_type: "reservation_date",
	date_filter_period: DEFAULT_FILTER_PERIOD,
	date_filter_start: "",
	date_filter_end: "",
	per_page: 10,
	order: "DESC",
}

export const BOOLEAN_OPTION = (t) => [
	{ label: t("Yes"), value: true },
	{ label: t("No"), value: false },
]

export const STORE_ROLE_OPTION = (t) => [
	{ label: t(STORE_ROLES.STAFF.label), value: STORE_ROLES.STAFF.value },
	{ label: t(STORE_ROLES.ADMIN.label), value: STORE_ROLES.ADMIN.value },
]

export const WEDGE_NUMBERS = ["PW", "AW", "SW"]

export const EQUIPMENT_WEIGHT_UNIT = "g"
export const EQUIPMENT_SIZE_UNIT = "mm"
export const EQUIPMENT_STRUCTURE_UNIT = "piece"

export const CLUB_ANGLE_UNIT = "deg"

export const CLUB_HEAD_VOLUMN_UNIT = "cc"

export const CLUB_DEFAULT_IMAGES = {
	[DRIVER]: DefaultDriverImg,
	[WOOD]: DefaultWoodImg,
	[HYBRID]: DefaultHybridImg,
	[IRON]: DefaultIronSetImg,
	[WEDGE]: DefaultWedgeImg,
	[PUTTER]: DefaultPutterImg,
	[CLUB_COMPONENT_SHAFT]: DefaultShaftImg,
	[EQUIPMENT_KEY[EQUIPMENT_BALL]]: DefaultBallImg,
	[EQUIPMENT_KEY[EQUIPMENT_GLOVES]]: DefaultGlovesImg,
}

export const AM = "오전"
export const PM = "오후"

export const GRIP_SIZE = (t) => [
	{ value: "thin", label: t("Thin") },
	{ value: "standard", label: t("Standard") },
	{ value: "thick", label: t("Thick") },
	{ value: "l_thin", label: t("Lady Thin") },
	{ value: "l_standard", label: t("Lady Standard") },
	{ value: "l_thick", label: t("Lady Thick") },
]

export const KICKPOINT = (t) => [
	{ value: "low", label: t("Low") },
	{ value: "mid", label: t("Mid") },
	{ value: "high", label: t("High") },
	{ value: "mid/low", label: t("Mid/ Low") },
	{ value: "mid/high", label: t("Mid/ High") },
]
