import { Avatar, Box } from "@mui/material"
import PropTypes from "prop-types"
import { isKrText } from "utils/helpers"

const sizeOptions = {
	small: 40,
	medium: 56,
	large: 84,
}

const AppAvatar = ({ size, src, alt, onClickHandler, hoverEffect, sx }) => {
	const isKrLang = isKrText(alt)
	const stringAvatar = isKrLang ? alt.slice(-1) : alt.slice(0, 1)
	return (
		<Box
			sx={({ palette }) => ({
				"&:hover": { bgcolor: hoverEffect && palette.gradients.avatar.main },
				display: "inline-block",
				p: 0.6,
				borderRadius: "50%",
				transition: "background-color 0.3s ease",
			})}>
			<Avatar
				onClick={onClickHandler}
				sx={({ functions: { pxToRem } }) => ({
					width: pxToRem(sizeOptions[size]),
					height: pxToRem(sizeOptions[size]),
					cursor: "pointer",
					bgcolor: "primary.main",
					...sx,
				})}
				sizes="large"
				alt="avatar"
				src={src}>
				{stringAvatar}
			</Avatar>
		</Box>
	)
}

AppAvatar.defaultProps = {
	size: sizeOptions.medium,
}

AppAvatar.propTypes = {
	alt: PropTypes.string,
	size: PropTypes.string,
	src: PropTypes.string,
}

export default AppAvatar

// consumer guide
// ==============
// to use the AppAvatar component, only need to pass THREE props(alt, size, src)

// Prop Explain
//=============
// alt - if you don't have any photo to pass as a Prop you should username or
//             a string should be passed, and the first two charaters of string will be shown
// size - default size will be medium and you can adjust THREE sizes (small, medium, large).
//        You can pass these values as string
// src - if you have photo that can pass the source from src

// P.S - if you pass both alt and src and if the src is acceptable src will take place,
//       otherwise the alt will take place
//       At least one of them should be passed, otherwise it will raise error
