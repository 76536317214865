import { Box, Typography, styled, useTheme } from "@mui/material"
import boxShadows from "assets/theme/base/boxShadows"
import { ENGLISH_LANGUAGE, KOREA_LANGUAGE } from "constant"
import { useTopbarLoading } from "hooks"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { changeLanguage as changeAppLanguage } from "stores/slice/app"

const SWITCH_VALUES = [ENGLISH_LANGUAGE, KOREA_LANGUAGE]
const SWITCH_HEIGHT = 24 // in px
const INDICATOR_PILL_WIDTH = 44 // in px
// in px, to handle right area of the option appears larger than left when unselected
const PILL_LEFT_OVERLAP_AREA = 6

const Pill = styled(Box)(({ theme, backgroundColor = theme.palette.grey[300] }) => ({
	backgroundColor,
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	borderRadius: 100,
	height: SWITCH_HEIGHT,
	cursor: "pointer",
}))

const SwitchLabel = styled(Typography)(({ theme: { typography } }) => ({
	color: "white",
	fontWeight: typography.fontWeightBold,
	textTransform: "uppercase",
	fontSize: typography.size.xs,
}))

const LangSwitch = ({ sx }) => {
	const {
		i18n: { changeLanguage, language },
	} = useTranslation()

	const {
		palette: { baseColors },
	} = useTheme()

	const dispatch = useDispatch()

	const [activeIndex, setActiveIndex] = useState(SWITCH_VALUES.indexOf(language))

	useTopbarLoading(language !== SWITCH_VALUES[activeIndex])

	return (
		<Pill sx={{ zIndex: 100, position: "relative", ...sx }}>
			{SWITCH_VALUES.map((lang, index) => (
				<Pill
					onClick={() => {
						if (activeIndex === index) return

						setActiveIndex(index)

						// this removes lag if we switch lang on a complex ui view
						setTimeout(() => {
							changeLanguage(lang)

							dispatch(changeAppLanguage(lang))
						}, 0)
					}}
					key={lang}
					backgroundColor="transparent"
					sx={{
						zIndex: 300,
						width: INDICATOR_PILL_WIDTH,
						px: 1,
						marginLeft: `${index === 0 ? 0 : -PILL_LEFT_OVERLAP_AREA}px`, // handle right area of the option appears larger than left when unselected
					}}>
					<SwitchLabel>{lang}</SwitchLabel>
				</Pill>
			))}
			<Pill
				sx={{
					width: INDICATOR_PILL_WIDTH,
					backgroundColor: baseColors.BLUES.blue,
					boxShadow: boxShadows.md,
					position: "absolute",
					zIndex: 200,
					transition: "all ease-in-out 0.3s",
					left: 0,
					transform: `translateX(${
						activeIndex * (INDICATOR_PILL_WIDTH - PILL_LEFT_OVERLAP_AREA)
					}px)`,
					px: 1,
				}}
			/>
		</Pill>
	)
}

export default LangSwitch
