import { useTranslation } from "react-i18next"
import { useIsEnglish } from "stores/slice/app"

import SelectorWrapper from "./CalenderComponents/SelectorWrapper"

const MonthSelector = ({ monthName, year, nextMonth, previousMonth }) => {
	const { t } = useTranslation()
	const isEng = useIsEnglish()

	return (
		<SelectorWrapper
			onClickPrevious={previousMonth}
			onClickNext={nextMonth}
			margin="0 20px"
			label={isEng ? `${monthName} ${year}` : `${year}년 ${t(monthName)}`}
		/>
	)
}

export default MonthSelector
