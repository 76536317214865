import { OTP_INPUT_LENGTH } from "constant"
import { object, string } from "yup"

const findIdSchema = (t) =>
	object({
		personalInfo: object({
			name: string().required(t("Please enter your name")),
			phone_number: string().required(t("Please enter mobile number")),
		}),
		code: string()
			.required(t("Please enter OTP input"))
			.length(OTP_INPUT_LENGTH, t("Enter 6 digit code.")),
	})

export default findIdSchema
