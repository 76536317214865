import { Box, Typography, useTheme } from "@mui/material"
import pxToRem from "assets/theme/functions/pxToRem"
import { useTranslation } from "react-i18next"

const ClubNoRegistered = ({
	isFromCustomer,
	isFitting,
	noRegisterWidth = 560,
	height = "169px",
}) => {
	const { t } = useTranslation()
	const { palette } = useTheme()

	return (
		<Box
			sx={{
				border: `1px solid ${palette.grey[300]}`,
				borderRadius: "8px",
				marginLeft: isFromCustomer ? pxToRem(64) : isFitting ? "20px" : 0,
				width: isFromCustomer || isFitting ? pxToRem(noRegisterWidth) : "100%",
				height: height,
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}>
			<Typography
				sx={{
					fontSize: "16px",
					color: palette.grey[700],
					fontWeight: 500,
				}}>
				{t("No registered information.")}
			</Typography>
		</Box>
	)
}

export default ClubNoRegistered
