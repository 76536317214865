import colors from "assets/theme/base/palette"
import typography from "assets/theme/base/typography"
import { COLOR_VARIANTS, SIZE_BASED_SPECS } from "assets/theme/components/button/root"
import pxToRem from "assets/theme/functions/pxToRem"

const { text, primary, grey } = colors
const { size } = typography

export default {
	base: {
		backgroundColor: primary.main,
		color: text.main,
		...SIZE_BASED_SPECS.normal,

		"&:hover, &:active, &:active:focus, &:active:hover": {
			backgroundColor: primary.main,
			opacity: 0.9,
		},

		"& .material-icon, .material-icons-round, svg": {
			fontSize: `${pxToRem(size.md)} !important`,
		},
	},

	small: {
		...SIZE_BASED_SPECS.small,

		"& .material-icon, .material-icons-round, svg": {
			fontSize: `${pxToRem(size.sm)} !important`,
		},
	},

	large: {
		...SIZE_BASED_SPECS.large,

		"& .material-icon, .material-icons-round, svg": {
			fontSize: `${pxToRem(size.lg)} !important`,
		},
	},

	// generate 'contained' variant style objects based on VARIANTS' SCHEMA
	...COLOR_VARIANTS.reduce(
		(total, { id, color }) =>
			// merge the current variant into the existing variants
			Object.assign(total, {
				[id]: {
					backgroundColor: color.main,

					"&:hover, &:focus:not(:hover)": {
						backgroundColor: color.main,
						opacity: 0.9,
					},

					"&:focus:not(:hover)": {
						backgroundColor: color.focus,
						opacity: 0.9,
					},

					// the following two are for loading state
					"&.MuiLoadingButton-root.Mui-disabled.MuiLoadingButton-loading": {
						backgroundColor: color.main,
						opacity: 1,
					},

					"& > .MuiLoadingButton-loadingIndicator": {
						color: "white",
					},

					"&:disabled": {
						backgroundColor: grey[300],
						opacity: 1,
					},
				},
			}),
		{}
	),
}
